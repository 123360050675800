var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, Input, Menu, Modal, Space, Switch, Tag } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import ZiraDeletePopconfirm from 'src/ui/ZiraDeletePopconfirm';
import { accountIdSelector } from 'src/state';
import { recipeAutomationsState } from 'src/components/RecipeModal/atom';
import { cloneDeep } from 'lodash';
import { automationBaseCollectionIdState } from 'src/components/Automations/atom';
import { useAutomation } from '../../../hooks/api/useAutomationApi';
import AutomationsBuilder from '../../Automations/AutomationsBuilder';
import ZiraActionButton from '../../../ui/ZiraActionButton';
import AutomationUsage from './AutomationUsage';
var AutomationModal = function (props) {
    var closeModal = props.closeModal, refreshAutomationList = props.refreshAutomationList, isDefaultActive = props.isActive, automationId = props.automationId, name = props.name, isDefault = props.isDefault, isRecipeCustom = props.isRecipeCustom;
    var accountId = useRecoilValue(accountIdSelector);
    var _a = useAutomation(), useSaveAutomation = _a.useSaveAutomation, useDeleteAutomation = _a.useDeleteAutomation;
    var _b = useState(isDefaultActive), isActive = _b[0], setIsActive = _b[1];
    var _c = useState(name !== null && name !== void 0 ? name : 'New Automation Title'), automationName = _c[0], setAutomationName = _c[1];
    var _d = useState({
        accountId: accountId,
        baseCollectionId: undefined,
        triggers: [],
        filters: [],
        actions: [],
    }), saveAutomationData = _d[0], setSaveAutomationData = _d[1];
    // usage modal
    var _e = useState(false), isUsageVisible = _e[0], setIsUsageVisible = _e[1];
    var resetBaseCollectionId = useResetRecoilState(automationBaseCollectionIdState);
    useEffect(function () {
        return function () {
            resetBaseCollectionId();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // Updates isActive and name
    useEffect(function () {
        setSaveAutomationData(function (prevSaveAutomationData) {
            return __assign(__assign({}, prevSaveAutomationData), { isActive: isActive, name: automationName });
        });
    }, [automationName, isActive]);
    var onUpdateAutomationObj = useCallback(function (saveAutomationObject) {
        setSaveAutomationData(function (prevSaveAutomationData) { return (__assign(__assign(__assign({}, prevSaveAutomationData), saveAutomationObject), { isActive: isActive, name: automationName })); });
    }, [automationName, isActive]);
    var recipeId = new URLSearchParams(location.search).get('recipe');
    var setRecipeAutomations = useSetRecoilState(recipeAutomationsState);
    var onSaveAutomation = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!saveAutomationData) return [3 /*break*/, 3];
                    if (!recipeId) return [3 /*break*/, 1];
                    setRecipeAutomations(function (prev) {
                        var temp = cloneDeep(prev);
                        var index = temp.findIndex(function (item) { return item.id === automationId; });
                        if (index >= 0) {
                            temp[index] = __assign(__assign({}, saveAutomationData), { id: automationId });
                        }
                        else {
                            temp.push(__assign(__assign({}, saveAutomationData), { id: automationId }));
                        }
                        return temp;
                    });
                    closeModal();
                    return [3 /*break*/, 3];
                case 1: return [4 /*yield*/, useSaveAutomation.mutateAsync(__assign(__assign({}, saveAutomationData), { id: automationId }), {
                        onSuccess: function () {
                            refreshAutomationList();
                            closeModal();
                        },
                    })];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); }, [
        automationId,
        closeModal,
        recipeId,
        refreshAutomationList,
        saveAutomationData,
        setRecipeAutomations,
        useSaveAutomation,
    ]);
    var onDeleteAutomation = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!automationId) return [3 /*break*/, 2];
                    return [4 /*yield*/, useDeleteAutomation.mutateAsync(automationId, {
                            onSuccess: function () {
                                refreshAutomationList();
                                closeModal();
                            },
                        })];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, [automationId, closeModal, refreshAutomationList, useDeleteAutomation]);
    var _f = useState(false), dropdownVisible = _f[0], setDropdownVisible = _f[1];
    var renderDeleteButton = useCallback(function () { return (_jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to delete this automation?", onConfirm: onDeleteAutomation, placement: "rightBottom", children: _jsx("div", { className: "cursor-pointer text-red-1000", children: "Delete" }) })); }, [onDeleteAutomation]);
    var modalFooter = useMemo(function () { return (_jsxs(_Fragment, { children: [_jsx(Button, { className: "ZiraModalCancelBtn", onClick: closeModal, children: "Cancel" }), _jsx(ZiraActionButton, { className: "ZiraModalSaveBtn", onClickFn: onSaveAutomation, children: "Save" })] })); }, [closeModal, onSaveAutomation]);
    var showUsage = useCallback(function () {
        setIsUsageVisible(!isUsageVisible);
    }, [isUsageVisible]);
    return (_jsxs(Modal, { className: "AutomationModalWrapper", open: true, closable: false, footer: modalFooter, centered: true, width: '100%', onCancel: function () { return closeModal(); }, children: [_jsxs("div", { className: "AutomationModalHeaderWrapper", children: [_jsx(Input, { className: "AutomationTitle", placeholder: "Automation Title", value: automationName, onChange: function (e) { return setAutomationName(e.target.value); }, bordered: false, autoFocus: true }), !isDefault && automationId && (_jsx(Button, { className: "AutomationRunsSection", type: "primary", onClick: showUsage, children: "Runs" })), _jsxs(Space, { className: "StatusSection", align: "end", size: 2, children: [_jsx(Tag, { color: isActive ? 'green' : 'red', onClick: function () { return setIsActive(!isActive); }, children: isActive ? 'Active' : 'Inactive' }), _jsx(Switch, { checked: isActive, onChange: function () { return setIsActive(!isActive); } })] }), automationId && !isDefault && (_jsx(Dropdown, { className: "h-min", open: dropdownVisible, onOpenChange: setDropdownVisible, overlay: _jsx(Menu, { className: "p-0", children: _jsx(Menu.Item, { children: renderDeleteButton() }) }), trigger: ['click'], placement: "bottomRight", children: _jsx(EllipsisOutlined, {}) }))] }), _jsx("div", { className: "AutomationModalBodyWrapper", children: _jsx(AutomationsBuilder, { accountId: accountId, automationId: automationId !== null && automationId !== void 0 ? automationId : null, updateAutomationObj: onUpdateAutomationObj, isDefault: isDefault, isRecipeCustom: isRecipeCustom }) }), isUsageVisible && (_jsx("div", { className: "AutomationUsageWrapper", children: _jsx(AutomationUsage, { automationId: automationId }) }))] }));
};
export default React.memo(AutomationModal);
