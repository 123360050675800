import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Form, Input, Layout } from 'antd';
import React, { useCallback, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { requiredStringInputExtraValidation } from 'src/components/SelfOnboarding/Constant';
import CreateAccountModalImg from '../../../utils/icons/images/CreateAccountModalImg.svg';
import './CreateAccountDetailModal.scss';
import SiderHeader from './SiderHeader';
var Sider = Layout.Sider, Content = Layout.Content;
var CreateAccountDetailModal = function (props) {
    var defaultValues = props.defaultValues, newAccountInfoOnChange = props.newAccountInfoOnChange;
    var phone = (defaultValues || {}).phone;
    var _a = useState(0), formattedPhoneInputLength = _a[0], setFormattedPhoneInputLength = _a[1];
    var _b = useState(0), expectedPhoneLength = _b[0], setExpectedPhoneLength = _b[1];
    var onFinish = useCallback(function (values) {
        var defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        values.timezone = defaultTimezone;
        var phoneValue = values.phone;
        values.phone = "+".concat(phoneValue);
        newAccountInfoOnChange(values);
    }, [newAccountInfoOnChange]);
    var onFinishFailed = useCallback(function () { }, []);
    return (_jsxs(Layout, { children: [_jsxs(Sider, { width: "30vw", className: "CreateAccountDetailSider", children: [_jsx(SiderHeader, { showWelcomeText: true }), _jsx("img", { src: CreateAccountModalImg, className: "CreateAccountImg" })] }), _jsx(Layout, { className: "overflow-y-auto bg-white", children: _jsx(Content, { children: _jsxs("div", { className: "CreateAccountDetailModalContent", children: [_jsx("div", { className: "Title mb-8", children: "Create Your Admin Account" }), _jsx("div", { className: "Title mb-2", children: "Account Details" }), _jsxs(Form, { name: "basic", className: "Form", labelCol: { span: 12 }, wrapperCol: { span: 18 }, initialValues: { remember: true }, onFinish: onFinish, onFinishFailed: onFinishFailed, autoComplete: "off", layout: 'vertical', children: [_jsx(Form.Item, { label: "Account Name", name: "name", rules: [
                                            {
                                                required: true,
                                                message: 'Please input account name!',
                                            },
                                            {
                                                validator: function (_, value) {
                                                    return requiredStringInputExtraValidation(value, 'account name');
                                                },
                                            },
                                        ], children: _jsx(Input, {}) }), _jsx(Form.Item, { label: "First Name", name: "firstName", rules: [
                                            {
                                                required: true,
                                                message: 'Please input your first name!',
                                            },
                                            {
                                                validator: function (_, value) {
                                                    return requiredStringInputExtraValidation(value, 'first name');
                                                },
                                            },
                                        ], children: _jsx(Input, {}) }), _jsx(Form.Item, { label: "Last Name", name: "lastName", rules: [
                                            { required: true, message: 'Please input your last name!' },
                                            {
                                                validator: function (_, value) {
                                                    return requiredStringInputExtraValidation(value, 'last name');
                                                },
                                            },
                                        ], children: _jsx(Input, {}) }), _jsx(Form.Item, { label: "Phone", name: "phone", initialValue: phone, rules: [
                                            {
                                                required: true,
                                                message: 'Please enter a valid phone number!',
                                            },
                                            function () { return ({
                                                validator: function (_, value) {
                                                    if (!value ||
                                                        formattedPhoneInputLength === expectedPhoneLength) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('Please enter a valid phone number!'));
                                                },
                                            }); },
                                        ], children: _jsx(PhoneInput, { country: "us", containerClass: "PhoneInputContainer", inputClass: "PhoneInput", onChange: function (value, countryData, e, formattedValue) {
                                                setFormattedPhoneInputLength(formattedValue.length);
                                                if (expectedPhoneLength !== countryData.format.length) {
                                                    setExpectedPhoneLength(countryData.format.length);
                                                }
                                            } }) }), _jsx(Form.Item, { wrapperCol: { offset: 0, span: 18 }, children: _jsx(Button, { htmlType: "submit", className: "NextButton mt-3 w-full bg-black text-white", children: "Next" }) })] })] }) }) })] }));
};
export default React.memo(CreateAccountDetailModal);
