import { selectorFamily } from 'recoil';
import { accountIdSelector, allEnabledFeaturesState } from '../atoms';
var featureEnableStatusState = selectorFamily({
    key: 'featureEnableStatusState',
    get: function (featureName) {
        return function (_a) {
            var _b;
            var get = _a.get;
            var accountId = get(accountIdSelector);
            var allFeatures = get(allEnabledFeaturesState);
            return (_b = allFeatures[accountId]) === null || _b === void 0 ? void 0 : _b.includes(featureName);
        };
    },
});
export { featureEnableStatusState };
