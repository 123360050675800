import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { lazy, Suspense, useMemo } from 'react';
import { collectionTableSchemaPhoneSchemaSettingSelector } from 'src/state';
import { useRecoilValue } from 'recoil';
import DummyTextCell from '../DummyTextCell';
var LogicPhoneInputCell = lazy(function () { return import('./LogicPhoneInputCell'); });
var PhoneInputCellHOC = function (props) {
    var phone = props.phone, editCell = props.editCell, canEdit = props.canEdit, isClicked = props.isClicked, setIsClicked = props.setIsClicked, cellPos = props.cellPos;
    var phoneSchemaConfig = useRecoilValue(collectionTableSchemaPhoneSchemaSettingSelector(cellPos));
    var displayText = useMemo(function () {
        if (!phone)
            return '';
        if ((phoneSchemaConfig === null || phoneSchemaConfig === void 0 ? void 0 : phoneSchemaConfig.showExtension) === false) {
            var mainPhone = phone.split(' ext.')[0];
            return mainPhone;
        }
        return phone;
    }, [phone, phoneSchemaConfig === null || phoneSchemaConfig === void 0 ? void 0 : phoneSchemaConfig.showExtension]);
    var dummyCell = (_jsx(_Fragment, { children: !isClicked && (_jsx(DummyTextCell, { text: displayText, setIsClicked: setIsClicked, canEdit: canEdit })) }));
    return (_jsxs(_Fragment, { children: [isClicked && canEdit && (_jsx(Suspense, { fallback: dummyCell, children: _jsx(LogicPhoneInputCell, { phone: phone, editCell: editCell, setIsClicked: setIsClicked, configuration: phoneSchemaConfig }) })), dummyCell] }));
};
export default React.memo(PhoneInputCellHOC);
