var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { find } from 'lodash';
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { scheduleEvaluationState } from 'src/state/atoms/schedule';
import { brokenRulesTypeEvaluation } from 'src/services/ScheduleService';
import { tableCollectionIdSelector, collectionTableCellState, collectionTableNativeSchemaSelector, autoSchedulerSettingState, } from 'src/state';
import { CollectionTableType, NativePath } from 'src/shared';
import { StandardScheduleConstraintKey } from '../../Constant';
import { ruleTypeSortOrder } from '../../RulesDrawer';
import IndictmentPopover from '../../IndictmentPopover';
var ShiftCardIndictmentPopover = function (_a) {
    var _b;
    var blockId = _a.blockId, recordId = _a.recordId;
    var autoSchedulerSetting = useRecoilValue(autoSchedulerSettingState);
    var scheduleEvaluation = useRecoilValue(scheduleEvaluationState(blockId));
    var collectionId = useRecoilValue(tableCollectionIdSelector(blockId));
    var shiftAssigneeSchema = useRecoilValue(collectionTableNativeSchemaSelector({ collectionId: collectionId, nativePath: NativePath.SHIFT_USER_ID }));
    var shiftAssigneeCell = useRecoilValue(collectionTableCellState({
        collectionId: collectionId,
        type: CollectionTableType.TABLE,
        recordId: recordId,
        schemaId: (_b = shiftAssigneeSchema === null || shiftAssigneeSchema === void 0 ? void 0 : shiftAssigneeSchema.id) !== null && _b !== void 0 ? _b : '',
    }));
    var _c = useMemo(function () {
        var _a, _b, _c, _d, _e, _f, _g;
        var requirementsMet = [];
        var indictments = [];
        if (scheduleEvaluation) {
            var shiftIndictments_1 = (_b = (_a = find(scheduleEvaluation.indictments.shiftIndictments, function (indictment) { return indictment.shiftRid === recordId; })) === null || _a === void 0 ? void 0 : _a.indictmentNames) !== null && _b !== void 0 ? _b : [];
            var userIndictments = [];
            var userRId_1 = (_c = shiftAssigneeCell === null || shiftAssigneeCell === void 0 ? void 0 : shiftAssigneeCell.options[0]) === null || _c === void 0 ? void 0 : _c.id;
            if (userRId_1) {
                var userIndictment = find(scheduleEvaluation.indictments.userIndictments, function (indictment) { return indictment.userRid === userRId_1; });
                if (userIndictment) {
                    for (var _i = 0, _h = userIndictment.indictmentNames; _i < _h.length; _i++) {
                        var indictmentName = _h[_i];
                        if ((_d = userIndictment.penalizedShiftRids[StandardScheduleConstraintKey[indictmentName]]) === null || _d === void 0 ? void 0 : _d.includes(recordId)) {
                            userIndictments.push(indictmentName);
                        }
                    }
                }
            }
            var indictmentNames_2 = __spreadArray(__spreadArray([], shiftIndictments_1, true), userIndictments, true);
            if (indictmentNames_2 === null || indictmentNames_2 === void 0 ? void 0 : indictmentNames_2.length) {
                // Add rules passed
                var constraintMatchSummary_1 = scheduleEvaluation.constraintMatchSummary;
                Object.keys(constraintMatchSummary_1).map(function (key) {
                    var _a, _b;
                    var constraint = key;
                    if (constraintMatchSummary_1[constraint] !== null) {
                        var displayName = (_b = (_a = constraintMatchSummary_1[constraint]) === null || _a === void 0 ? void 0 : _a.ruleDisplayName) !== null && _b !== void 0 ? _b : 'Untitled Rule';
                        var passed = true;
                        for (var _i = 0, indictmentNames_3 = indictmentNames_2; _i < indictmentNames_3.length; _i++) {
                            var name_1 = indictmentNames_3[_i];
                            if (StandardScheduleConstraintKey[name_1] === constraint) {
                                passed = false;
                                break;
                            }
                        }
                        if (passed) {
                            requirementsMet.push(displayName);
                        }
                    }
                });
                // Add indictments
                for (var _j = 0, indictmentNames_1 = indictmentNames_2; _j < indictmentNames_1.length; _j++) {
                    var name_2 = indictmentNames_1[_j];
                    var constraintKey = StandardScheduleConstraintKey[name_2];
                    var constraintType = (_e = constraintMatchSummary_1[constraintKey]) === null || _e === void 0 ? void 0 : _e.type;
                    var constraintName = (_g = (_f = constraintMatchSummary_1[constraintKey]) === null || _f === void 0 ? void 0 : _f.ruleDisplayName) !== null && _g !== void 0 ? _g : 'Untitled Rule';
                    indictments.push({
                        name: constraintName,
                        type: constraintType,
                    });
                }
            }
            // Sort by rule type
            indictments.sort(function (a, b) { return ruleTypeSortOrder.indexOf(a.type) - ruleTypeSortOrder.indexOf(b.type); });
            return {
                requirementsMet: requirementsMet,
                shiftIndictments: indictments,
                shiftIndictmentNames: indictmentNames_2,
                hasShiftIndictment: !!indictments.length,
            };
        }
        return {
            requirementsMet: [],
            shiftIndictments: [],
            shiftIndictmentNames: [],
            hasShiftIndictment: false,
        };
    }, [recordId, scheduleEvaluation, shiftAssigneeCell === null || shiftAssigneeCell === void 0 ? void 0 : shiftAssigneeCell.options]), requirementsMet = _c.requirementsMet, shiftIndictments = _c.shiftIndictments, shiftIndictmentNames = _c.shiftIndictmentNames, hasShiftIndictment = _c.hasShiftIndictment;
    if (!hasShiftIndictment || !autoSchedulerSetting)
        return null;
    return (_jsx(IndictmentPopover, { requirementsMet: requirementsMet, indictments: shiftIndictments, trigger: "click", className: "IndictmentButton", brokenRulesTypeEvaluation: brokenRulesTypeEvaluation(shiftIndictmentNames, autoSchedulerSetting) }));
};
export default React.memo(ShiftCardIndictmentPopover);
