var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Input, Select, Tag, TimePicker } from 'antd';
var Option = Select.Option, OptGroup = Select.OptGroup;
import { cloneDeep, find, isNil, uniqBy } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSetting } from 'src/hooks/api/useSettingApi';
import { CalendarOutlined, ClockCircleOutlined, CloseOutlined, EditOutlined, } from '@ant-design/icons';
import { getTagTextColor } from 'src/utils/Collection';
import { v4 as uuidv4 } from 'uuid';
import './AutomationsBuilder.scss';
import { usePaginationWithSearch, useTimeZone } from 'src/hooks/component';
import { useRecoilValue } from 'recoil';
import { SELECTION_DROPDOWN_PAGE_SIZE } from 'src/shared';
import { useAutomation } from '../../hooks/api/useAutomationApi';
import { useAccountApi } from '../../hooks/api';
import { getAutomationValueType, getTagColorAndIcon } from '../../utils/Automation';
import TaskSelectionDropdown from '../Settings/Tasks/shared/TaskSelectionDropdown';
import { taskTemplateListState, taskGroupListState } from '../Settings/Tasks/atom';
import { recipeDataChangesState, recipeOverriddenDataChangesState } from '../RecipeModal/atom';
import { ActionType, AutomationComponent, OffsetType, TriggerType, } from './Interface';
import AutomationDynamicVariableSelect from './BuilderComponents/AutomationDynamicVariableSelect';
import { DAYS_OF_WEEK_OPTIONS, MODIFY_OBJECT_MODIFERS, POPULAR_ACTIONS, POPULAR_TRIGGERS, RECURRING_TRIGGER_SUPPORT_ACTION_TYPE_SET, TASK_GROUP_TYPES, TASK_TYPES, TEXT_INPUT_VALUE_TYPES, } from './constant';
import { onSelectFilterOption } from './util';
var BuilderBar = function (props) {
    var _a, _b, _c, _d;
    var accountId = props.accountId, component = props.component, savedTrigger = props.savedTrigger, savedFilter = props.savedFilter, savedAction = props.savedAction, sendDataBack = props.sendDataBack, allCollections = props.allCollections, baseCollectionId = props.baseCollectionId, _e = props.defaultFilterOptions, defaultFilterOptions = _e === void 0 ? [] : _e, _f = props.defaultTriggerOptions, defaultTriggerOptions = _f === void 0 ? [] : _f, _g = props.defaultActionOptions, defaultActionOptions = _g === void 0 ? [] : _g, _h = props.actionSchemaOptions, actionSchemaOptions = _h === void 0 ? [] : _h, recipeDetails = props.recipeDetails;
    var _j = useAccountApi(), useGetUniversalSimpleList = _j.useGetUniversalSimpleList, useGetSpecificUniveralListOptionsByIds = _j.useGetSpecificUniveralListOptionsByIds;
    var useGetGeofenceData = useSetting().useGetGeofenceData;
    var useGetAutomationTriggerOptions = useAutomation().useGetAutomationTriggerOptions;
    var getAutomationTriggerOptionsMutation = useGetAutomationTriggerOptions();
    var needsInit = useMemo(function () { return savedTrigger || savedFilter || savedAction; }, [savedAction, savedFilter, savedTrigger]);
    var _k = useState(false), triggerBuilt = _k[0], setTriggerBuilt = _k[1];
    var _l = useState(false), filterBuilt = _l[0], setFilterBuilt = _l[1];
    var _m = useState(false), actionBuilt = _m[0], setActionBuilt = _m[1];
    var _o = useState(false), usingDynamicVariable = _o[0], setUsingDynamicVariable = _o[1];
    var _p = useState(false), usingDaysOfWeekFilter = _p[0], setUsingDaysOfWeekFilter = _p[1];
    var _q = useState({
        component: component,
        collectionId: null,
        collectionName: null,
        schemaId: null,
        secondarySchemaId: null,
        appliesTo: null,
        geofenceId: null,
        componentName: null,
        valueType: null,
        value: [''],
        modifier: null,
        isMultiSelect: false,
        taskId: null,
        taskGroupId: null,
        recurringDetails: null,
        relativeTimeValue: null,
    }), builderState = _q[0], setBuilderState = _q[1];
    var initialBaseCollectionId = useState(baseCollectionId)[0];
    var _r = useGetGeofenceData({
        options: {
            enabled: false,
        },
    }), geofenceOptions = _r.data, getGeofenceOptions = _r.refetch;
    // Trigger options
    var _s = useState([]), schemaOptions = _s[0], setSchemaOptions = _s[1];
    // Filter options
    var _t = useState([]), modifierOptions = _t[0], setModifierOptions = _t[1];
    var recipeDataChanges = useRecoilValue(recipeDataChangesState);
    var recipeOverridenDataChanges = useRecoilValue(recipeOverriddenDataChangesState);
    var dataChangeIndex = recipeDataChanges.findIndex(function (dataChange) { var _a; return dataChange.schemaId === ((_a = builderState.secondarySchemaId) !== null && _a !== void 0 ? _a : builderState.schemaId); });
    // Specific options request in case missing from paginated list below
    var _u = useGetSpecificUniveralListOptionsByIds({
        body: {
            accountId: accountId,
            schemaId: (_b = (_a = builderState.schemaId) !== null && _a !== void 0 ? _a : builderState.secondarySchemaId) !== null && _b !== void 0 ? _b : '',
            optionIds: (_c = builderState.value.filter(function (s) { return !isNil(s); }).map(function (s) { return s.toString(); })) !== null && _c !== void 0 ? _c : [],
        },
        options: {
            enabled: !!(builderState.schemaId || builderState.secondarySchemaId) &&
                builderState.value.filter(function (v) { return v !== ''; }).length > 0 &&
                builderState.valueType === 'selection',
        },
    }).data, referenceSelectOptions = _u === void 0 ? [] : _u;
    // Universal list options
    var _v = useState([]), selectValueOptions = _v[0], setSelectValueOptions = _v[1];
    var _w = usePaginationWithSearch({}), page = _w.page, searchText = _w.searchText, canLoadMore = _w.canLoadMore, setCanLoadMore = _w.setCanLoadMore, onScroll = _w.onScroll, onSearch = _w.onSearch, setIsLoading = _w.setIsLoading;
    var isFetchingSchema = useGetUniversalSimpleList({
        body: {
            schemaId: (_d = builderState.secondarySchemaId) !== null && _d !== void 0 ? _d : builderState.schemaId,
            pagination: {
                page: page,
                size: SELECTION_DROPDOWN_PAGE_SIZE,
            },
            query: searchText,
        },
        options: {
            enabled: (!!builderState.secondarySchemaId || !!builderState.schemaId) &&
                builderState.valueType === 'selection' &&
                dataChangeIndex === -1 &&
                canLoadMore,
            onSuccess: function (data) {
                if (data) {
                    if (page === 0) {
                        setSelectValueOptions(data);
                    }
                    else {
                        setSelectValueOptions(function (prev) { return uniqBy(__spreadArray(__spreadArray([], prev, true), data, true), 'id'); });
                    }
                    setCanLoadMore(data.length > 0);
                }
            },
            onSettled: function () {
                setIsLoading(false);
            },
        },
    }).isFetching;
    var schemaValueOptions = useMemo(function () {
        if (recipeDetails) {
            if (dataChangeIndex > -1 &&
                recipeDataChanges[dataChangeIndex].defaultOptions &&
                !recipeOverridenDataChanges[dataChangeIndex].existingSchemaId) {
                var recipeSchemaOptions = recipeDataChanges[dataChangeIndex].defaultOptions.map(function (option) {
                    var _a;
                    return ({
                        id: option.valueId,
                        name: option.name,
                        color: (_a = option.color) !== null && _a !== void 0 ? _a : undefined,
                    });
                });
                return recipeSchemaOptions;
            }
        }
        return uniqBy(__spreadArray(__spreadArray([], referenceSelectOptions, true), selectValueOptions, true), 'id');
    }, [
        dataChangeIndex,
        recipeDataChanges,
        recipeDetails,
        recipeOverridenDataChanges,
        referenceSelectOptions,
        selectValueOptions,
    ]);
    var usingBespokeFilter = useMemo(function () {
        return (builderState.modifier == 'first shift in day' ||
            builderState.modifier == 'last shift in day' ||
            builderState.modifier == 'first in day');
    }, [builderState.modifier]);
    var _x = useState(false), selectOneDropdownOpen = _x[0], setSelectOneDropdownOpen = _x[1];
    var _y = useState(false), selectTwoDropdownOpen = _y[0], setSelectTwoDropdownOpen = _y[1];
    var builderStateRef = useRef(builderState);
    var selectRefOne = useRef(null);
    var selectRefTwo = useRef(null);
    var selectOneProps = useMemo(function () { return ({
        open: selectOneDropdownOpen,
        onFocus: function () {
            setSelectOneDropdownOpen(true);
        },
        onClick: function () {
            setSelectOneDropdownOpen(!selectOneDropdownOpen);
        },
        onBlur: function () { return setSelectOneDropdownOpen(false); },
    }); }, [selectOneDropdownOpen]);
    var selectTwoProps = useMemo(function () { return ({
        open: selectTwoDropdownOpen && !selectOneDropdownOpen,
        onFocus: function () {
            setSelectTwoDropdownOpen(true);
        },
        onClick: function () {
            if (!selectTwoDropdownOpen)
                setSelectTwoDropdownOpen(true);
        },
        onBlur: function () { return setSelectTwoDropdownOpen(false); },
    }); }, [selectOneDropdownOpen, selectTwoDropdownOpen]);
    useEffect(function () {
        builderStateRef.current = builderState;
    }, [builderState, builderStateRef]);
    var getSchemaOptions = useCallback(function (collectionId) {
        if (component === AutomationComponent.TRIGGER) {
            getAutomationTriggerOptionsMutation.mutate({
                accountId: accountId,
                collectionId: collectionId,
                recipeDetails: recipeDetails,
            }, {
                onSuccess: function (resp) {
                    var _a;
                    setSchemaOptions((_a = resp === null || resp === void 0 ? void 0 : resp.schemas) !== null && _a !== void 0 ? _a : []);
                },
            });
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId]);
    // Reset if base trigger collection changes
    useEffect(function () {
        if (initialBaseCollectionId &&
            initialBaseCollectionId !== baseCollectionId &&
            (builderStateRef.current.componentName === ActionType.MODIFY_OBJECT ||
                builderStateRef.current.component === AutomationComponent.FILTER)) {
            setBuilderState(function (prevBuilderState) {
                var newBuilderState = __assign(__assign({}, prevBuilderState), { collectionId: null, schemaId: null, secondarySchemaId: null, appliesTo: null, geofenceId: null, valueType: null, value: [''], modifier: null });
                sendDataBack(newBuilderState);
                return newBuilderState;
            });
            setModifierOptions([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseCollectionId]);
    var buildBarFromTriggerData = useCallback(function () {
        var _a, _b;
        if (!savedTrigger)
            return;
        setTriggerBuilt(true);
        var tempBuilderState = cloneDeep(builderStateRef.current);
        // Set componentName
        tempBuilderState.componentName = savedTrigger.type;
        // based on type of trigger, set details of builder bar
        switch (savedTrigger.type) {
            case TriggerType.OBJECT_CREATED: {
                var detail = savedTrigger.detail;
                tempBuilderState.collectionId = detail.collectionId;
                break;
            }
            case TriggerType.OBJECT_DELETED: {
                var detail = savedTrigger.detail;
                tempBuilderState.collectionId = detail.collectionId;
                break;
            }
            case TriggerType.OBJECT_UPDATED: {
                var detail = savedTrigger.detail;
                tempBuilderState.collectionId = detail.collectionId;
                // assigning schema (if specified)
                if (detail.schemaId) {
                    tempBuilderState.schemaId = detail.schemaId;
                }
                break;
            }
            case TriggerType.AT_SCHEDULED_TIME: {
                var detail = savedTrigger.detail;
                tempBuilderState.collectionId = detail.collectionId;
                tempBuilderState.schemaId = detail.schemaId;
                break;
            }
            case TriggerType.ENTERED_GEOFENCE:
            case TriggerType.LEFT_GEOFENCE: {
                var detail = savedTrigger.detail;
                tempBuilderState.geofenceId = detail.geofenceId;
                getGeofenceOptions();
                break;
            }
            case TriggerType.COMMENT_ADDED: {
                var detail = savedTrigger.detail;
                tempBuilderState.collectionId = detail.collectionId;
                break;
            }
            case TriggerType.TASK_ASSIGNED:
            case TriggerType.TASK_COMPLETED: {
                var detail = savedTrigger.detail;
                tempBuilderState.taskId = detail.taskId;
                break;
            }
            case TriggerType.TASK_GROUP_ASSIGNED:
            case TriggerType.TASK_GROUP_COMPLETED: {
                var detail = savedTrigger.detail;
                tempBuilderState.taskGroupId = detail.taskGroupId;
                break;
            }
            case TriggerType.BUTTON_CLICKED: {
                var detail = savedTrigger.detail;
                tempBuilderState.collectionId = detail.collectionId;
                tempBuilderState.schemaId = detail.schemaId;
                break;
            }
        }
        if ([
            TriggerType.OBJECT_CREATED,
            TriggerType.OBJECT_DELETED,
            TriggerType.OBJECT_UPDATED,
            TriggerType.AT_SCHEDULED_TIME,
            TriggerType.BUTTON_CLICKED,
        ].includes(savedTrigger.type)) {
            tempBuilderState.collectionName =
                (_b = (_a = allCollections === null || allCollections === void 0 ? void 0 : allCollections.find(function (collection) { return collection.id === tempBuilderState.collectionId; })) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : null;
            getSchemaOptions(savedTrigger.detail.collectionId);
        }
        setBuilderState(tempBuilderState);
    }, [allCollections, getGeofenceOptions, getSchemaOptions, savedTrigger]);
    var buildBarFromFilterData = useCallback(function () {
        var _a, _b, _c, _d, _e;
        if (!savedFilter)
            return;
        setFilterBuilt(true);
        var tempBuilderState = cloneDeep(builderStateRef.current);
        // assign collection
        tempBuilderState.collectionId = savedFilter.collectionId;
        // assign schema
        tempBuilderState.schemaId = savedFilter.schemaId;
        tempBuilderState.appliesTo = savedFilter.appliesTo;
        // assign secondary schema
        if (savedFilter.secondarySchemaId) {
            tempBuilderState.secondarySchemaId = savedFilter.secondarySchemaId;
        }
        // assign modifier
        tempBuilderState.modifier = savedFilter.clauseValue;
        // assign data
        if (savedFilter.filterValue) {
            var type = (_a = savedFilter.filterValue) === null || _a === void 0 ? void 0 : _a.type;
            var val = (_b = savedFilter.filterValue[type === 'currency' ? 'amount' : type === 'rating' ? 'value' : type]) !== null && _b !== void 0 ? _b : '0';
            tempBuilderState.valueType = ['true', 'false'].includes(String(val)) ? 'boolean' : type;
            tempBuilderState.value = [val];
        }
        else if (savedFilter.valueIds) {
            // selection ids populated
            tempBuilderState.value = savedFilter.valueIds;
            tempBuilderState.valueType = 'selection';
        }
        else if (savedFilter.dynamicValue) {
            setUsingDynamicVariable(true);
            tempBuilderState.valueType = 'dynamic';
            tempBuilderState.dynamicValue = savedFilter.dynamicValue;
        }
        // render bespoke days of week
        if (((_c = savedFilter.bespokeValue) === null || _c === void 0 ? void 0 : _c.type) == 'DAY_OF_WEEK') {
            setUsingDaysOfWeekFilter(true);
            tempBuilderState.daysOfWeek = savedFilter.bespokeValue.daysOfWeek;
        }
        // assign collectionName
        tempBuilderState.collectionName =
            (_e = (_d = allCollections === null || allCollections === void 0 ? void 0 : allCollections.find(function (collection) { return collection.id === tempBuilderState.collectionId; })) === null || _d === void 0 ? void 0 : _d.name) !== null && _e !== void 0 ? _e : null;
        // assign isMultiSelect & modifer options
        defaultFilterOptions.every(function (group) {
            var _a, _b;
            var foundSchemaOption = group.schemas.find(function (schema) {
                return schema.id === savedFilter.schemaId ||
                    schema.id === savedFilter.secondarySchemaId;
            });
            var isMultiSelect = (_a = foundSchemaOption === null || foundSchemaOption === void 0 ? void 0 : foundSchemaOption.isMultiSelect) !== null && _a !== void 0 ? _a : false;
            var modifiers = (_b = foundSchemaOption === null || foundSchemaOption === void 0 ? void 0 : foundSchemaOption.modifiers) !== null && _b !== void 0 ? _b : [];
            if (isMultiSelect) {
                tempBuilderState.isMultiSelect = isMultiSelect;
            }
            if (modifiers.length) {
                setModifierOptions(modifiers);
                return false;
            }
            return true;
        });
        setBuilderState(tempBuilderState);
    }, [allCollections, defaultFilterOptions, savedFilter]);
    var buildBarFromActionData = useCallback(function () {
        var _a;
        if (!savedAction)
            return;
        setActionBuilt(true);
        var tempBuilderState = cloneDeep(builderStateRef.current);
        tempBuilderState.componentName = savedAction.type;
        // based on type of trigger, set details of builder bar
        switch (savedAction.type) {
            case ActionType.MODIFY_OBJECT: {
                var detail_1 = savedAction.detail;
                tempBuilderState.collectionId = detail_1.collectionId;
                // assigning schema (if specified)
                if (detail_1.schemaId) {
                    tempBuilderState.schemaId = detail_1.schemaId;
                }
                // assign secondary schema
                if (detail_1.secondarySchemaId) {
                    tempBuilderState.secondarySchemaId = detail_1.secondarySchemaId;
                }
                // assign modifier (if none included, set to replace)
                tempBuilderState.modifier = detail_1.modifier || 'REPLACE';
                tempBuilderState.valueType =
                    detail_1.modifier === 'CLEAR_VALUE' ? 'text' : tempBuilderState.valueType;
                // assigning data
                if (detail_1.data) {
                    var type = (_a = detail_1.data) === null || _a === void 0 ? void 0 : _a.type;
                    var val = detail_1.data[type === 'currency' ? 'amount' : type === 'rating' ? 'value' : type];
                    tempBuilderState.valueType = ['true', 'false'].includes(String(val))
                        ? 'boolean'
                        : type;
                    tempBuilderState.value = [val];
                }
                else if (detail_1.selectionIds) {
                    // selection ids populated
                    tempBuilderState.value = detail_1.selectionIds;
                    tempBuilderState.valueType = 'selection';
                }
                else if (detail_1.dynamicValue) {
                    setUsingDynamicVariable(true);
                    tempBuilderState.valueType = 'dynamic';
                    tempBuilderState.dynamicValue = detail_1.dynamicValue;
                }
                else if (detail_1.relativeTimeValue) {
                    tempBuilderState.relativeTimeValue = detail_1.relativeTimeValue;
                }
                // assign isMultiSelect
                actionSchemaOptions === null || actionSchemaOptions === void 0 ? void 0 : actionSchemaOptions.every(function (group) {
                    var _a, _b;
                    var isMultiSelect = (_b = (_a = group.schemas.find(function (schema) {
                        return schema.id === detail_1.schemaId ||
                            schema.id === detail_1.secondarySchemaId;
                    })) === null || _a === void 0 ? void 0 : _a.isMultiSelect) !== null && _b !== void 0 ? _b : false;
                    if (isMultiSelect) {
                        tempBuilderState.isMultiSelect = isMultiSelect;
                        return false;
                    }
                    return true;
                });
                break;
            }
            case ActionType.CREATE_RECORD: {
                var detail = savedAction.detail;
                tempBuilderState.collectionId = detail === null || detail === void 0 ? void 0 : detail.collectionId;
                break;
            }
            case ActionType.ASSIGN_TASK: {
                var detail = savedAction.detail;
                tempBuilderState.taskId = detail === null || detail === void 0 ? void 0 : detail.taskId;
                break;
            }
            case ActionType.ASSIGN_TASK_GROUP: {
                var detail = savedAction.detail;
                tempBuilderState.taskGroupId = detail === null || detail === void 0 ? void 0 : detail.taskGroupId;
                break;
            }
        }
        setBuilderState(tempBuilderState);
    }, [actionSchemaOptions, savedAction]);
    var initOptions = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (needsInit) {
                if (savedTrigger && !triggerBuilt) {
                    buildBarFromTriggerData();
                }
                else if (savedFilter && !filterBuilt && defaultFilterOptions.length) {
                    buildBarFromFilterData();
                }
                else if (savedAction &&
                    !actionBuilt &&
                    ((baseCollectionId && actionSchemaOptions.length) || !baseCollectionId)) {
                    buildBarFromActionData();
                }
            }
            return [2 /*return*/];
        });
    }); }, [
        needsInit,
        savedTrigger,
        triggerBuilt,
        savedFilter,
        filterBuilt,
        defaultFilterOptions,
        savedAction,
        actionBuilt,
        actionSchemaOptions,
        baseCollectionId,
        buildBarFromTriggerData,
        buildBarFromFilterData,
        buildBarFromActionData,
    ]);
    useEffect(function () {
        initOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savedTrigger, savedFilter, savedAction, defaultFilterOptions, actionSchemaOptions]);
    // determining next step of state machine after selection
    var itemSelected = useCallback(function (val, elem, type) {
        var _a, _b;
        var tempBuilderState = cloneDeep(builderStateRef.current);
        switch (component) {
            case AutomationComponent.TRIGGER: {
                if (type === 'category') {
                    tempBuilderState.componentName = val;
                    tempBuilderState.collectionId = null;
                    tempBuilderState.schemaId = null;
                    tempBuilderState.collectionName = null;
                    tempBuilderState.geofenceId = null;
                    if ([TriggerType.ENTERED_GEOFENCE, TriggerType.LEFT_GEOFENCE].includes(tempBuilderState.componentName)) {
                        getGeofenceOptions();
                    }
                    setTimeout(function () { var _a; return (_a = selectRefOne.current) === null || _a === void 0 ? void 0 : _a.focus(); }, 100);
                }
                else if (type === 'collection') {
                    tempBuilderState.collectionId = val;
                    tempBuilderState.schemaId = null;
                    tempBuilderState.collectionName = elem.label;
                    getSchemaOptions(val);
                    setTimeout(function () { var _a; return (_a = selectRefTwo.current) === null || _a === void 0 ? void 0 : _a.focus(); }, 100);
                }
                else if (type === 'schema') {
                    if (val !== 'anything') {
                        tempBuilderState.schemaId = val;
                    }
                    else {
                        tempBuilderState.schemaId = null;
                    }
                    (_a = selectRefTwo.current) === null || _a === void 0 ? void 0 : _a.blur();
                }
                else if (type === 'geofence') {
                    tempBuilderState.geofenceId = val;
                }
                else if (type === 'task') {
                    tempBuilderState.taskId = val;
                }
                else if (type === 'taskGroup') {
                    tempBuilderState.taskGroupId = val;
                }
                break;
            }
            case AutomationComponent.FILTER: {
                if (type === 'schema') {
                    tempBuilderState.collectionId = elem.collectionId;
                    tempBuilderState.appliesTo = elem.appliesTo;
                    tempBuilderState.valueType = getAutomationValueType(elem.type);
                    if (tempBuilderState.valueType === 'datetime') {
                        tempBuilderState.value = [moment(new Date()).toISOString()];
                    }
                    else {
                        tempBuilderState.value = elem.needsDropdown ? [] : [''];
                    }
                    tempBuilderState.modifier = 'is';
                    tempBuilderState.isMultiSelect = elem.isMultiSelect;
                    if (elem.parentSchemaId) {
                        tempBuilderState.schemaId = elem.parentSchemaId;
                        tempBuilderState.secondarySchemaId = elem.optionId;
                    }
                    else {
                        tempBuilderState.schemaId = elem.optionId;
                        tempBuilderState.secondarySchemaId = null;
                    }
                    setModifierOptions(elem.modifiers);
                    setTimeout(function () { var _a; return (_a = selectRefOne.current) === null || _a === void 0 ? void 0 : _a.focus(); }, 100);
                }
                else if (type === 'modifier') {
                    tempBuilderState.modifier = val;
                    if (!val.includes('contain') &&
                        !tempBuilderState.isMultiSelect &&
                        tempBuilderState.value.length) {
                        // Remove other values if not multi-select (when switching from contains modifier)
                        tempBuilderState.value = [
                            tempBuilderState.value[tempBuilderState.value.length - 1],
                        ];
                    }
                    (_b = selectRefTwo.current) === null || _b === void 0 ? void 0 : _b.focus();
                }
                break;
            }
            case AutomationComponent.ACTION: {
                if (type === 'category') {
                    tempBuilderState.componentName = val;
                    tempBuilderState.schemaId = null;
                    tempBuilderState.secondarySchemaId = null;
                    tempBuilderState.value = [''];
                    tempBuilderState.valueType = null;
                    tempBuilderState.taskId = null;
                    tempBuilderState.taskGroupId = null;
                    setTimeout(function () { var _a; return (_a = selectRefOne.current) === null || _a === void 0 ? void 0 : _a.focus(); }, 100);
                }
                else if (type === 'schema') {
                    tempBuilderState.collectionId = elem.collectionId;
                    tempBuilderState.valueType = getAutomationValueType(elem.type);
                    if (tempBuilderState.valueType === 'datetime') {
                        tempBuilderState.value = [moment(new Date()).toISOString()];
                    }
                    else {
                        tempBuilderState.value = elem.needsDropdown ? [] : [''];
                    }
                    if (elem.parentSchemaId) {
                        tempBuilderState.schemaId = elem.parentSchemaId;
                        tempBuilderState.secondarySchemaId = elem.optionId;
                    }
                    else {
                        tempBuilderState.schemaId = elem.optionId;
                        tempBuilderState.secondarySchemaId = null;
                    }
                    tempBuilderState.isMultiSelect = elem.isMultiSelect;
                    tempBuilderState.modifier = 'REPLACE';
                    tempBuilderState.relativeTimeValue = null;
                    setTimeout(function () { var _a; return (_a = selectRefTwo.current) === null || _a === void 0 ? void 0 : _a.focus(); }, 100);
                }
                else if (type === 'modifier') {
                    tempBuilderState.modifier = val;
                    if (val === 'CLEAR_VALUE') {
                        tempBuilderState.value = [];
                    }
                }
                else if (type === 'collection') {
                    tempBuilderState.collectionId = val;
                }
                else if (type === 'task') {
                    tempBuilderState.taskId = val;
                }
                else if (type === 'taskGroup') {
                    tempBuilderState.taskGroupId = val;
                }
                tempBuilderState.dynamicValue = undefined;
                setUsingDynamicVariable(false);
                break;
            }
        }
        setBuilderState(tempBuilderState);
        sendDataBack(tempBuilderState);
    }, [component, getGeofenceOptions, getSchemaOptions, sendDataBack]);
    var editValue = useCallback(function (newValues) {
        var _a, _b;
        var tempBuilderState = cloneDeep(builderStateRef.current);
        if (tempBuilderState.isMultiSelect || ((_a = tempBuilderState.modifier) === null || _a === void 0 ? void 0 : _a.includes('contain'))) {
            tempBuilderState.value = newValues;
        }
        else {
            tempBuilderState.value =
                newValues.length === 0 ? [] : [newValues[newValues.length - 1]];
            if (!TEXT_INPUT_VALUE_TYPES.includes(tempBuilderState.valueType)) {
                (_b = selectRefTwo.current) === null || _b === void 0 ? void 0 : _b.blur();
            }
        }
        setBuilderState(tempBuilderState);
        sendDataBack(tempBuilderState);
    }, [sendDataBack]);
    var optionTag = useCallback(function (label, _a) {
        var _b = _a.color, color = _b === void 0 ? '#000' : _b, _c = _a.icon, icon = _c === void 0 ? _jsx(_Fragment, {}) : _c;
        if ([
            TriggerType.OBJECT_CREATED,
            TriggerType.OBJECT_DELETED,
            TriggerType.OBJECT_UPDATED,
            TriggerType.AT_SCHEDULED_TIME,
        ].includes(builderState.componentName)) {
            label = label.replace('Something', '');
        }
        else if (builderState.componentName === ActionType.MODIFY_OBJECT) {
            label = label.replace('something', '');
        }
        return (_jsxs(Tag, { className: "OptionTag", style: {
                color: getTagTextColor(color),
                backgroundColor: color,
                border: '1px solid ' + color,
            }, children: [icon, !label.includes('Something') && !label.includes('something') ? (_jsx(_Fragment, { children: label })) : (_jsxs(_Fragment, { children: [label.includes('Something') && (_jsxs(_Fragment, { children: [_jsx("u", { children: "Something" }), label.split('Something ')[1]] })), label.includes('something') && (_jsxs(_Fragment, { children: [label.split('something')[0], _jsx("u", { children: "something" })] }))] }))] }));
    }, [builderState.componentName]);
    var onRemoveTag = useCallback(function (id) {
        var tempBuilderState = cloneDeep(builderStateRef.current);
        tempBuilderState.value = tempBuilderState.value.filter(function (valueId) { return valueId !== id; });
        setBuilderState(tempBuilderState);
        sendDataBack(tempBuilderState);
    }, [sendDataBack]);
    var schemaValueTagRender = useCallback(function (props) {
        var value = props.value;
        var foundOption = find(schemaValueOptions, function (option) { return option.id === value; });
        var tagColor = foundOption === null || foundOption === void 0 ? void 0 : foundOption.color;
        var tagText = foundOption === null || foundOption === void 0 ? void 0 : foundOption.name;
        return (_jsx(Tag, { className: "SchemaOptionTag", color: tagColor, closeIcon: _jsx(CloseOutlined, { className: "CloseIcon" }), closable: selectTwoDropdownOpen, onClose: function () { return onRemoveTag(value); }, onMouseDown: function (e) {
                e.preventDefault();
                e.stopPropagation();
            }, style: {
                color: getTagTextColor(tagColor),
            }, children: tagText }));
    }, [onRemoveTag, schemaValueOptions, selectTwoDropdownOpen]);
    var schemaValueSelect = useMemo(function () { return (_jsx(_Fragment, { children: builderState.value.length ||
            (schemaValueOptions === null || schemaValueOptions === void 0 ? void 0 : schemaValueOptions.length) ||
            isFetchingSchema ||
            searchText ? (_jsx(Select, __assign({ ref: selectRefTwo, className: "OptionSelect", onChange: editValue, value: builderState.value, mode: "multiple", tagRender: schemaValueTagRender, menuItemSelectedIcon: _jsx(_Fragment, {}), showSearch: true, filterOption: function (input, option) {
                var optionItem = schemaValueOptions.find(function (item) { return item.id === (option === null || option === void 0 ? void 0 : option.value); });
                return optionItem
                    ? optionItem.name
                        .toLowerCase()
                        .indexOf(input.toLowerCase().trim()) > -1
                    : false;
            }, placeholder: "Insert Value", loading: isFetchingSchema, dropdownMatchSelectWidth: false, autoClearSearchValue: false, onSearch: onSearch, onPopupScroll: onScroll }, selectTwoProps, { children: _jsx(_Fragment, { children: schemaValueOptions.map(function (option) { return (_jsx(Option, { value: option.id, label: option.name, children: _jsx("div", { className: "SchemaOptionWrapper", children: _jsx("span", { className: "SchemaOption", style: {
                                backgroundColor: option.color,
                                color: getTagTextColor(option.color),
                            }, children: option.name }) }) }, option.id)); }) }) }))) : (_jsx("span", { className: "NoOptions", children: "No Options Available" })) })); }, [
        builderState.value,
        editValue,
        isFetchingSchema,
        onScroll,
        onSearch,
        schemaValueOptions,
        schemaValueTagRender,
        searchText,
        selectTwoProps,
    ]);
    var _z = useTimeZone(), timeZone = _z.timeZone, timezoneAbbr = _z.timezoneAbbr;
    var valueInput = useMemo(function () { return (_jsxs(_Fragment, { children: [builderState.valueType === 'datetime' &&
                !builderState.relativeTimeValue &&
                !usingBespokeFilter && (_jsxs("div", { className: "DatePicker", onClick: function (e) { return e.stopPropagation(); }, onKeyDown: function (e) { return e.stopPropagation(); }, children: [_jsx(TimePicker, { value: builderState.value[0]
                            ? moment(builderState.value[0]).add(moment.tz(timeZone).utcOffset() -
                                moment().utcOffset(), 'minutes')
                            : undefined, use12Hours: true, format: "h:mm A", onChange: function (newTime) {
                            if (newTime) {
                                editValue([
                                    newTime
                                        .subtract(moment.tz(timeZone).utcOffset() -
                                        moment().utcOffset(), 'minutes')
                                        .startOf('minute')
                                        .toISOString(),
                                ]);
                            }
                        }, showNow: false, allowClear: false, suffixIcon: null }), _jsx("div", { className: "mr-2", children: timezoneAbbr() })] })), builderState.relativeTimeValue && _jsx("div", { className: "DatePicker", children: "Relative" }), TEXT_INPUT_VALUE_TYPES.includes(builderState.valueType) && (_jsx(Input, { className: "min-w-[20%]", ref: selectRefTwo, type: ['text', 'phone'].includes(builderState.valueType) ? 'text' : 'number', value: (builderState.value.length > 0 && builderState.value[0]) ||
                    undefined, onChange: function (e) { return editValue([e.target.value]); }, placeholder: "Insert Value" })), builderState.valueType === 'boolean' && (_jsxs(Select, __assign({ ref: selectRefTwo, className: "OptionSelect", value: builderState.value[0] || undefined, onSelect: function (value) { return editValue([value]); }, placeholder: "Insert Value", dropdownMatchSelectWidth: false }, selectTwoProps, { children: [_jsx(Option, { value: "true", children: _jsx(Tag, { color: "green", children: "Yes" }) }), _jsx(Option, { value: "false", children: _jsx(Tag, { color: "red", children: "No" }) })] })))] })); }, [
        builderState.valueType,
        builderState.relativeTimeValue,
        builderState.value,
        timeZone,
        timezoneAbbr,
        selectTwoProps,
        editValue,
        usingBespokeFilter,
    ]);
    var popularCategories = useCallback(function (popularCategories) {
        return (_jsxs("div", { className: "PopularCategoriesWrapper", style: {
                marginTop: Object.values(TriggerType).includes(popularCategories[0].value)
                    ? '48px'
                    : undefined,
            }, children: ["Popular", _jsx("div", { className: "PopularCategories", children: popularCategories.map(function (_a) {
                        var value = _a.value, displayName = _a.displayName, icon = _a.icon, color = _a.color;
                        return (_jsx("div", { className: "cursor-pointer", onClick: function () { return itemSelected(value, null, 'category'); }, children: optionTag(displayName, { icon: icon, color: color }) }, value));
                    }) })] }));
    }, [itemSelected, optionTag]);
    //! Tasks state
    var taskTemplateList = useRecoilValue(taskTemplateListState);
    var taskGroupList = useRecoilValue(taskGroupListState);
    var selectedTaskName = useMemo(function () {
        var _a, _b;
        if (TASK_TYPES.includes(builderState.componentName)) {
            return (_a = taskTemplateList.find(function (task) { return task.id === builderState.taskId; })) === null || _a === void 0 ? void 0 : _a.name;
        }
        else if (TASK_GROUP_TYPES.includes(builderState.componentName)) {
            return (_b = taskGroupList.find(function (group) { return group.id === builderState.taskGroupId; })) === null || _b === void 0 ? void 0 : _b.name;
        }
    }, [
        builderState.componentName,
        builderState.taskGroupId,
        builderState.taskId,
        taskGroupList,
        taskTemplateList,
    ]);
    var automationTaskSelectionDropdown = useMemo(function () {
        var _a, _b, _c, _d;
        return (_jsx(TaskSelectionDropdown, { selectedTasks: [], onAddTask: TASK_TYPES.includes(builderState.componentName)
                ? function (task) {
                    itemSelected(task.id, '', 'task');
                }
                : undefined, onAddTaskGroup: TASK_GROUP_TYPES.includes(builderState.componentName)
                ? function (taskGroup) {
                    itemSelected(taskGroup.id, '', 'taskGroup');
                }
                : undefined, initialFetch: true, children: _jsx(Select, __assign({ ref: selectRefOne, className: "OptionSelect", value: (_a = builderState.taskId) !== null && _a !== void 0 ? _a : builderState.taskGroupId, placeholder: TASK_TYPES.includes(builderState.componentName)
                    ? 'Choose a Task'
                    : 'Choose a Task Group' }, selectOneProps, { open: false, dropdownMatchSelectWidth: false, children: ((_b = builderState.taskId) !== null && _b !== void 0 ? _b : builderState.taskGroupId) && (_jsx(Option, { value: (_c = builderState.taskId) !== null && _c !== void 0 ? _c : builderState.taskGroupId, children: _jsx("span", { children: selectedTaskName }) }, (_d = builderState.taskId) !== null && _d !== void 0 ? _d : builderState.taskGroupId)) })) }));
    }, [
        builderState.componentName,
        builderState.taskGroupId,
        builderState.taskId,
        itemSelected,
        selectOneProps,
        selectedTaskName,
    ]);
    var triggerOptions = useMemo(function () {
        var _a, _b, _c;
        return (_jsxs(_Fragment, { children: [[
                    TriggerType.OBJECT_CREATED,
                    TriggerType.OBJECT_DELETED,
                    TriggerType.OBJECT_UPDATED,
                    TriggerType.AT_SCHEDULED_TIME,
                    TriggerType.COMMENT_ADDED,
                    TriggerType.BUTTON_CLICKED,
                ].includes(builderState.componentName) && (_jsx(Select, __assign({ ref: selectRefOne, className: "OptionSelect", onSelect: function (val, elem) {
                        return itemSelected(val, elem, 'collection');
                    }, value: builderState.collectionId, placeholder: "Choose a Collection", dropdownMatchSelectWidth: false, showSearch: true, filterOption: onSelectFilterOption }, selectOneProps, { children: _jsx(OptGroup, { label: "Collections", children: allCollections === null || allCollections === void 0 ? void 0 : allCollections.map(function (option) {
                            return (_jsx(Option, { value: option.id, label: option.name, children: _jsx("span", { children: option.name }) }, option.id));
                        }) }) }))), [TriggerType.ENTERED_GEOFENCE, TriggerType.LEFT_GEOFENCE].includes(builderState.componentName) && (_jsx(Select, __assign({ ref: selectRefOne, className: "OptionSelect", onSelect: function (val, elem) {
                        return itemSelected(val, elem, 'geofence');
                    }, value: (_a = builderState.geofenceId) !== null && _a !== void 0 ? _a : '', dropdownMatchSelectWidth: false, showSearch: true, filterOption: onSelectFilterOption }, selectOneProps, { children: _jsx(OptGroup, { label: "Geofences", children: geofenceOptions === null || geofenceOptions === void 0 ? void 0 : geofenceOptions.map(function (option) {
                            return (_jsx(Option, { value: option.id, label: option.name, children: _jsx("div", { children: _jsx("span", { children: option.name }) }) }, option.id));
                        }) }) }))), __spreadArray(__spreadArray([], TASK_TYPES, true), TASK_GROUP_TYPES, true).includes(builderState.componentName) &&
                    automationTaskSelectionDropdown, ((builderState.collectionId &&
                    TriggerType.OBJECT_UPDATED === builderState.componentName) ||
                    TriggerType.BUTTON_CLICKED === builderState.componentName) && (_jsx(Select, __assign({ ref: selectRefTwo, className: "OptionSelect", onSelect: function (val, elem) { return itemSelected(val, elem, 'schema'); }, loading: !!((builderState === null || builderState === void 0 ? void 0 : builderState.schemaId) &&
                        !schemaOptions.find(function (s) { return s.id === builderState.schemaId; })), value: (builderState === null || builderState === void 0 ? void 0 : builderState.schemaId)
                        ? schemaOptions.find(function (s) { return s.id === builderState.schemaId; })
                            ? builderState.schemaId
                            : undefined
                        : 'anything', dropdownMatchSelectWidth: false, showSearch: true, filterOption: onSelectFilterOption }, selectTwoProps, { children: _jsx(OptGroup, { label: builderState.collectionName, children: _jsxs(_Fragment, { children: [_jsx(Option, { value: "anything", children: _jsx("div", { children: _jsx("span", { children: "Anything" }) }) }), schemaOptions === null || schemaOptions === void 0 ? void 0 : schemaOptions.map(function (option) {
                                    return (_jsx(Option, { value: option.id, label: option.name, children: _jsx("div", { children: _jsx("span", { children: option.name }) }) }, option.id));
                                })] }) }) }))), builderState.collectionId &&
                    TriggerType.AT_SCHEDULED_TIME === builderState.componentName && (_jsx(Select, __assign({ loading: !!((builderState === null || builderState === void 0 ? void 0 : builderState.schemaId) &&
                        !schemaOptions.find(function (s) { return s.id === builderState.schemaId; })), ref: selectRefTwo, className: "OptionSelect", onSelect: function (val, elem) {
                        return itemSelected(val, elem, 'schema');
                    }, value: schemaOptions.find(function (s) { return s.id === (builderState === null || builderState === void 0 ? void 0 : builderState.schemaId); })
                        ? builderState === null || builderState === void 0 ? void 0 : builderState.schemaId
                        : undefined, dropdownMatchSelectWidth: false, showSearch: true, filterOption: onSelectFilterOption }, selectTwoProps, { children: _jsx(OptGroup, { label: builderState.collectionName, children: _jsx(_Fragment, { children: schemaOptions === null || schemaOptions === void 0 ? void 0 : schemaOptions.map(function (option) {
                                return (_jsx(Option, { value: option.id, label: option.name, children: _jsx("div", { children: _jsx("span", { children: option.name }) }) }, option.id));
                            }) }) }) }))), defaultTriggerOptions.length > 0 && (_jsx(Select, { className: "OptionSelect", style: { border: 'none' }, onSelect: function (val, elem) {
                        return itemSelected(val, elem, 'category');
                    }, value: (_b = builderState.componentName) !== null && _b !== void 0 ? _b : (_c = savedTrigger === null || savedTrigger === void 0 ? void 0 : savedTrigger.detail) === null || _c === void 0 ? void 0 : _c.type, placeholder: "Select a Trigger", dropdownMatchSelectWidth: false, showSearch: true, filterOption: onSelectFilterOption, children: defaultTriggerOptions.map(function (group) { return (_jsx(OptGroup, { label: group.groupName, children: group.triggers.map(function (option) {
                            return (_jsx(Option, { value: option.triggerType, label: option.displayName, groupName: group.groupName, children: optionTag(option.displayName, getTagColorAndIcon(group.groupName)) }, option.triggerType));
                        }) }, group.groupName)); }) })), !builderState.componentName && _jsx(_Fragment, { children: popularCategories(POPULAR_TRIGGERS) })] }));
    }, [
        builderState.componentName,
        builderState.collectionId,
        builderState.geofenceId,
        builderState === null || builderState === void 0 ? void 0 : builderState.schemaId,
        builderState.collectionName,
        savedTrigger === null || savedTrigger === void 0 ? void 0 : savedTrigger.detail,
        selectOneProps,
        allCollections,
        geofenceOptions,
        automationTaskSelectionDropdown,
        selectTwoProps,
        schemaOptions,
        defaultTriggerOptions,
        popularCategories,
        itemSelected,
        optionTag,
    ]);
    var onDynamicValueSelection = useCallback(function (val, elem) {
        var tempBuilderState = cloneDeep(builderStateRef.current);
        // populate schemaId and secondarySchemaId according to what was selected
        if (elem.parentSchemaId) {
            tempBuilderState.dynamicValue = {
                dynamicSchemaId: elem.parentSchemaId,
                dynamicSecondarySchemaId: elem.optionId,
            };
        }
        else {
            tempBuilderState.dynamicValue = {
                dynamicSchemaId: elem.optionId,
                dynamicSecondarySchemaId: null,
            };
        }
        setBuilderState(tempBuilderState);
        sendDataBack(tempBuilderState);
    }, [sendDataBack]);
    var onDataValueInputChange = useCallback(function () {
        var tempBuilderState = cloneDeep(builderStateRef.current);
        var newUsingDynamicVariable = !usingDynamicVariable;
        if (!newUsingDynamicVariable) {
            if (tempBuilderState.valueType === 'dynamic') {
                tempBuilderState.schemaId = null;
                tempBuilderState.secondarySchemaId = null;
            }
            tempBuilderState.dynamicValue = undefined;
        }
        else {
            tempBuilderState.value = [''];
        }
        setBuilderState(tempBuilderState);
        sendDataBack(tempBuilderState);
        setUsingDynamicVariable(!usingDynamicVariable);
    }, [sendDataBack, usingDynamicVariable]);
    var onDaysOfWeekValueSelection = useCallback(function (value) {
        var tempBuilderState = cloneDeep(builderStateRef.current);
        tempBuilderState.daysOfWeek = value;
        setBuilderState(tempBuilderState);
        sendDataBack(tempBuilderState);
    }, [sendDataBack]);
    var onDateFilterTypeChange = useCallback(function () {
        var tempBuilderState = cloneDeep(builderStateRef.current);
        var newUsingDaysOfWeekFilter = !usingDaysOfWeekFilter;
        if (!newUsingDaysOfWeekFilter) {
            if (tempBuilderState.daysOfWeek) {
                tempBuilderState.daysOfWeek = null;
            }
        }
        else {
            tempBuilderState.value = [''];
            // reset dynamic value parameter in temp builder state
            if (tempBuilderState.valueType === 'dynamic') {
                tempBuilderState.schemaId = null;
                tempBuilderState.secondarySchemaId = null;
            }
            tempBuilderState.dynamicValue = undefined;
            setUsingDynamicVariable(false);
        }
        setBuilderState(tempBuilderState);
        sendDataBack(tempBuilderState);
        setUsingDaysOfWeekFilter(!usingDaysOfWeekFilter);
    }, [sendDataBack, usingDaysOfWeekFilter]);
    var renderDataValueInput = useCallback(function (isFilter) { return (_jsxs(_Fragment, { children: [usingDynamicVariable ? (_jsx(AutomationDynamicVariableSelect, { value: builderState.dynamicValue, onSelect: onDynamicValueSelection })) : usingDaysOfWeekFilter ? (_jsx(Select, { mode: "multiple", className: "OptionSelect dynamicVariableSelector", value: builderState.daysOfWeek, onChange: onDaysOfWeekValueSelection, placeholder: "Select day", showArrow: true, children: DAYS_OF_WEEK_OPTIONS.map(function (day) { return (_jsx(Option, { value: day.value, children: day.text }, day.key)); }) })) : (_jsx(_Fragment, { children: builderState.valueType === 'selection' ? (_jsx(_Fragment, { children: schemaValueSelect })) : (_jsx(_Fragment, { children: valueInput })) })), !usingBespokeFilter && (_jsx(Button, { onClick: onDataValueInputChange, className: "ant-btn ant-btn-primary ml-[5px] min-w-[32px]", icon: _jsx(EditOutlined, {}) })), component === AutomationComponent.ACTION &&
                builderState.componentName === ActionType.MODIFY_OBJECT &&
                (builderState.valueType === 'datetime' || builderState.relativeTimeValue) && (_jsx(Button, { onClick: function () {
                    return setBuilderState(function (prevBuilderState) {
                        var _a;
                        var newBuilderState = __assign(__assign({}, prevBuilderState), { valueType: 'datetime', value: prevBuilderState.relativeTimeValue === null
                                ? prevBuilderState.value
                                : [], relativeTimeValue: prevBuilderState.relativeTimeValue === null
                                ? {
                                    schemaId: (_a = builderState.schemaId) !== null && _a !== void 0 ? _a : '',
                                    offset: 0,
                                    offsetType: OffsetType.MINUTES,
                                }
                                : null });
                        sendDataBack(newBuilderState);
                        return newBuilderState;
                    });
                }, className: "ant-btn ant-btn-primary ml-[5px] min-w-[32px]", icon: _jsx(ClockCircleOutlined, {}) })), isFilter && builderState.valueType === 'datetime' && !usingBespokeFilter && (_jsx(Button, { onClick: onDateFilterTypeChange, className: "ant-btn ant-btn-primary ml-[10px] min-w-[32px]", icon: _jsx(CalendarOutlined, {}) }))] })); }, [
        builderState.componentName,
        builderState.daysOfWeek,
        builderState.dynamicValue,
        builderState.relativeTimeValue,
        builderState.schemaId,
        builderState.valueType,
        component,
        onDataValueInputChange,
        onDateFilterTypeChange,
        onDaysOfWeekValueSelection,
        onDynamicValueSelection,
        schemaValueSelect,
        sendDataBack,
        usingDaysOfWeekFilter,
        usingDynamicVariable,
        valueInput,
        usingBespokeFilter,
    ]);
    var filterOptions = useMemo(function () {
        var _a, _b, _c;
        return (_jsxs(_Fragment, { children: [_jsx(Select, { className: "OptionSelect", onSelect: function (val, elem) { return itemSelected(val, elem, 'schema'); }, value: builderState.secondarySchemaId
                        ? "".concat(builderState.appliesTo).concat(builderState.schemaId).concat(builderState.secondarySchemaId)
                        : builderState.schemaId
                            ? "".concat(builderState.appliesTo).concat(builderState.schemaId)
                            : undefined, placeholder: "Choose a Column", showSearch: true, filterOption: onSelectFilterOption, dropdownMatchSelectWidth: false, children: defaultFilterOptions.map(function (group, groupIdx) { return (_jsx(OptGroup, { label: group.collectionName, children: group.schemas.map(function (option, idx) {
                            var _a;
                            var label = group.parentSchemaId !== null
                                ? "".concat(group.parentSchemaName, " / ").concat(option.name)
                                : "".concat(option.name);
                            return (_jsx(Option, { value: "".concat(group.type).concat((_a = group.parentSchemaId) !== null && _a !== void 0 ? _a : '').concat(option.id), optionId: option.id, label: label, modifiers: option.modifiers, collectionId: group.collectionId, parentSchemaId: group.parentSchemaId, type: option.type, appliesTo: group.type, groupName: group.collectionName, needsDropdown: option.needsDropdown, isMultiSelect: option.isMultiSelect, children: label }, "".concat(group.type, "-").concat(group.collectionId, "-").concat(groupIdx, "-").concat(option.id, "-").concat(label, "-").concat(idx)));
                        }) }, "".concat(group.type, "-").concat(group.collectionId, "-").concat(groupIdx))); }) }), builderState.schemaId && (_jsxs(_Fragment, { children: [_jsx(Select, __assign({ ref: selectRefOne, className: "OptionSelect", onSelect: function (val, elem) {
                                return itemSelected(val, elem, 'modifier');
                            }, value: (_a = builderState.modifier) !== null && _a !== void 0 ? _a : (_b = modifierOptions[0]) === null || _b === void 0 ? void 0 : _b.title, dropdownMatchSelectWidth: false }, selectOneProps, { children: _jsx(OptGroup, { label: "Modifier", children: modifierOptions === null || modifierOptions === void 0 ? void 0 : modifierOptions.map(function (option) {
                                    return (_jsx(Option, { value: option.title, label: option.title, children: _jsx("div", { children: _jsx("span", { children: option.title }) }) }, option.title));
                                }) }) })), !['is empty', 'is not empty'].includes((_c = builderState.modifier) !== null && _c !== void 0 ? _c : '') ? (_jsx(_Fragment, { children: renderDataValueInput(true) })) : (_jsx(_Fragment, {}))] }))] }));
    }, [
        builderState.secondarySchemaId,
        builderState.appliesTo,
        builderState.schemaId,
        builderState.modifier,
        defaultFilterOptions,
        modifierOptions,
        selectOneProps,
        renderDataValueInput,
        itemSelected,
    ]);
    var actionOptions = useMemo(function () {
        var _a, _b, _c;
        return (_jsxs(_Fragment, { children: [_jsx(Select, { className: "OptionSelect", onSelect: function (val, elem) { return itemSelected(val, elem, 'category'); }, value: (_a = builderState.componentName) !== null && _a !== void 0 ? _a : undefined, placeholder: "Select an Action", dropdownMatchSelectWidth: false, children: defaultActionOptions.map(function (group) { return (_jsx(OptGroup, { label: group.groupName, children: group.actions.map(function (option) {
                            if (baseCollectionId ||
                                (!baseCollectionId &&
                                    RECURRING_TRIGGER_SUPPORT_ACTION_TYPE_SET.has(option.actionType))) {
                                return (_jsx(Option, { value: option.actionType, label: option.displayName, groupName: group.groupName, children: optionTag(option.displayName, getTagColorAndIcon(group.groupName, option.displayName)) }, option.actionType));
                            }
                        }) }, group.groupName)); }) }), builderState.componentName === ActionType.MODIFY_OBJECT && (_jsxs(_Fragment, { children: [_jsx(Select, __assign({ ref: selectRefOne, className: "OptionSelect", onSelect: function (val, elem) {
                                itemSelected(val, elem, 'schema');
                            }, value: builderState.secondarySchemaId
                                ? "".concat(builderState.schemaId).concat(builderState.secondarySchemaId)
                                : ((_b = builderState.schemaId) !== null && _b !== void 0 ? _b : undefined), placeholder: "Choose a Column", dropdownMatchSelectWidth: false, showSearch: true, filterOption: onSelectFilterOption }, selectOneProps, { children: actionSchemaOptions === null || actionSchemaOptions === void 0 ? void 0 : actionSchemaOptions.map(function (group) { return (_jsx(OptGroup, { label: group.collectionName, children: group.schemas.map(function (option, idx) {
                                    var _a;
                                    var label = group.parentSchemaId !== null
                                        ? "".concat(group.parentSchemaName, " / ").concat(option.name)
                                        : "".concat(option.name);
                                    return (_jsx(Option, { value: "".concat((_a = group.parentSchemaId) !== null && _a !== void 0 ? _a : '').concat(option.id), optionId: option.id, label: label, collectionId: group.collectionId, parentSchemaId: group.parentSchemaId, type: option.type, groupName: group.collectionName, needsDropdown: option.needsDropdown, isMultiSelect: option.isMultiSelect, children: label }, "".concat(option.id, "-").concat(label, "-").concat(idx)));
                                }) }, group.collectionId)); }) })), _jsx(Select, { className: "OptionSelect", onSelect: function (val, elem) {
                                return itemSelected(val, elem, 'modifier');
                            }, value: builderState.modifier, placeholder: "Choose a Modifier", dropdownMatchSelectWidth: false, children: _jsx(OptGroup, { label: "Modifier", children: MODIFY_OBJECT_MODIFERS.map(function (modifier) {
                                    return (_jsx(Option, { value: modifier.value, children: modifier.title }, uuidv4()));
                                }) }, uuidv4()) }), builderState.schemaId && builderState.modifier != 'CLEAR_VALUE' && (_jsx(_Fragment, { children: renderDataValueInput(false) }))] })), builderState.componentName === ActionType.CREATE_RECORD && (_jsx(_Fragment, { children: _jsx(Select, __assign({ ref: selectRefOne, className: "OptionSelect", onSelect: function (val, elem) {
                            return itemSelected(val, elem, 'collection');
                        }, value: (_c = builderState.collectionId) !== null && _c !== void 0 ? _c : undefined, placeholder: "Choose a Collection", dropdownMatchSelectWidth: false }, selectOneProps, { children: _jsx(OptGroup, { label: "Collections", children: allCollections === null || allCollections === void 0 ? void 0 : allCollections.map(function (option) {
                                return (_jsx(Option, { value: option.id, label: option.name, children: _jsx("span", { children: option.name }) }, option.id));
                            }) }) })) })), [ActionType.ASSIGN_TASK, ActionType.ASSIGN_TASK_GROUP].includes(builderState.componentName) && automationTaskSelectionDropdown, !builderState.componentName && (_jsx(_Fragment, { children: popularCategories(baseCollectionId
                        ? POPULAR_ACTIONS
                        : POPULAR_ACTIONS.filter(function (action) {
                            return RECURRING_TRIGGER_SUPPORT_ACTION_TYPE_SET.has(action.value);
                        })) }))] }));
    }, [
        builderState.componentName,
        builderState.secondarySchemaId,
        builderState.schemaId,
        builderState.modifier,
        builderState.collectionId,
        defaultActionOptions,
        selectOneProps,
        actionSchemaOptions,
        renderDataValueInput,
        allCollections,
        popularCategories,
        itemSelected,
        baseCollectionId,
        optionTag,
        automationTaskSelectionDropdown,
    ]);
    return (_jsxs("div", { className: "BuilderBar", children: [component === AutomationComponent.TRIGGER && _jsx(_Fragment, { children: triggerOptions }), component === AutomationComponent.FILTER && _jsx(_Fragment, { children: filterOptions }), component === AutomationComponent.ACTION && _jsx(_Fragment, { children: actionOptions })] }));
};
export default React.memo(BuilderBar);
