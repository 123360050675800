export var PageType;
(function (PageType) {
    PageType["TABLE"] = "TABLE";
    PageType["SCHEDULE"] = "SCHEDULE";
    PageType["CANVAS"] = "CANVAS";
})(PageType || (PageType = {}));
export var isShiftRequestDisplayExtraFilter = function (extra) {
    return !!extra.contains && !!extra.claimerRids;
};
export var FilterValueType;
(function (FilterValueType) {
    FilterValueType["PLACEMENT_STATUS"] = "PLACEMENT_STATUS";
    FilterValueType["SHIFT_STATUS"] = "SHIFT_STATUS";
})(FilterValueType || (FilterValueType = {}));
export var StandardRelation;
(function (StandardRelation) {
    StandardRelation["SHIFT_BREAKS"] = "SHIFT_BREAKS";
    StandardRelation["USER_TIMEOFFS"] = "USER_TIMEOFFS";
    StandardRelation["USER_SHIFTS"] = "USER_SHIFTS";
    StandardRelation["ROLE_SHIFTS"] = "ROLE_SHIFTS";
    StandardRelation["ROLE_USERS"] = "ROLE_USERS";
    StandardRelation["LOCATION_SHIFTS"] = "LOCATION_SHIFTS";
    StandardRelation["LOCATION_USERS"] = "LOCATION_USERS";
    StandardRelation["USER_DOCUMENTS"] = "USER_DOCUMENTS";
    StandardRelation["SHIFT_GROUP_SHIFTS"] = "SHIFT_GROUP_SHIFTS";
    StandardRelation["PLACEMENT_TASKS"] = "PLACEMENT_TASKS";
})(StandardRelation || (StandardRelation = {}));
export var StatusType;
(function (StatusType) {
    StatusType["ACTIVE"] = "ACTIVE";
    StatusType["ARCHIVED"] = "ARCHIVED";
    StatusType["UPDATED"] = "UPDATED";
})(StatusType || (StatusType = {}));
export var DefaultCollectionCell = {
    content: null,
    options: [],
    files: [],
    versionedOptions: [],
};
export var AccountHistoryContentType;
(function (AccountHistoryContentType) {
    AccountHistoryContentType["EMAIL_SENT"] = "email_sent";
    AccountHistoryContentType["IN_APP_MESSAGE"] = "in_app_message";
    AccountHistoryContentType["SMS_SENT"] = "sms_sent";
    AccountHistoryContentType["RECORD_CREATED"] = "record_created";
    AccountHistoryContentType["RECORD_DELETED"] = "record_deleted";
    AccountHistoryContentType["RECORD_UPDATED"] = "record_updated";
    AccountHistoryContentType["BREAK_CREATED"] = "break_created";
    AccountHistoryContentType["BREAK_UPDATED"] = "break_updated";
    AccountHistoryContentType["CONTACT_CREATED"] = "contact_created";
    AccountHistoryContentType["CONTACT_DELETED"] = "contact_deleted";
    AccountHistoryContentType["CONTACT_UPDATED"] = "contact_updated";
    AccountHistoryContentType["DOCUMENT_COMPLETED"] = "document_completed";
    AccountHistoryContentType["DOCUMENT_CREATED"] = "document_created";
    AccountHistoryContentType["DOCUMENT_DELETED"] = "document_deleted";
    AccountHistoryContentType["DOCUMENT_UPDATED"] = "document_updated";
    AccountHistoryContentType["TASK_CREATED"] = "task_created";
    AccountHistoryContentType["TASK_UPDATED"] = "task_updated";
    AccountHistoryContentType["TASK_DELETED"] = "task_deleted";
    AccountHistoryContentType["LOCATION_CREATED"] = "location_created";
    AccountHistoryContentType["LOCATION_DELETED"] = "location_deleted";
    AccountHistoryContentType["LOCATION_UPDATED"] = "location_updated";
    AccountHistoryContentType["PAY_PERIOD_CREATED"] = "pay_period_created";
    AccountHistoryContentType["PAY_PERIOD_UPDATED"] = "pay_period_updated";
    AccountHistoryContentType["PAY_PERIOD_DELETED"] = "pay_period_deleted";
    AccountHistoryContentType["PROJECT_ENDED"] = "project_ended";
    AccountHistoryContentType["PROJECT_STARTED"] = "project_started";
    AccountHistoryContentType["ROLE_CREATED"] = "role_created";
    AccountHistoryContentType["ROLE_DELETED"] = "role_deleted";
    AccountHistoryContentType["ROLE_UPDATED"] = "role_updated";
    AccountHistoryContentType["SCHEDULE_ACTION"] = "schedule_action";
    AccountHistoryContentType["SCHEDULE_PUBLISHED"] = "schedule_published";
    AccountHistoryContentType["SCHEDULE_COPIED"] = "schedule_copied";
    AccountHistoryContentType["SCHEDULE_CLEARED"] = "schedule_cleared";
    AccountHistoryContentType["SHIFT_BREAK_IN"] = "shift_break_in";
    AccountHistoryContentType["SHIFT_BREAK_OUT"] = "shift_break_out";
    AccountHistoryContentType["SHIFT_CLOCK_IN"] = "shift_clock_in";
    AccountHistoryContentType["SHIFT_CLOCK_OUT"] = "shift_clock_out";
    AccountHistoryContentType["SHIFT_UNSCHEDULED_CLOCK_IN"] = "shift_unscheduled_clock_in";
    AccountHistoryContentType["SHIFT_CREATED"] = "shift_created";
    AccountHistoryContentType["SHIFT_UPDATED"] = "shift_updated";
    AccountHistoryContentType["SHIFT_DELETED"] = "shift_deleted";
    AccountHistoryContentType["SHIFT_CLAIM"] = "shift_claim";
    AccountHistoryContentType["SHIFT_RELEASE"] = "shift_release";
    AccountHistoryContentType["SHIFT_REQUEST_APPROVED"] = "shift_request_approved";
    AccountHistoryContentType["SHIFT_REQUEST_REJECTED"] = "shift_request_rejected";
    AccountHistoryContentType["SHIFT_RECOMMENDED"] = "shift_recommended";
    AccountHistoryContentType["SHIFT_RECOMMENDATION_ACCEPTED"] = "shift_recommendation_accepted";
    AccountHistoryContentType["SHIFT_RECOMMENDATION_REJECTED"] = "shift_recommendation_rejected";
    AccountHistoryContentType["SHIFT_GROUP_CREATED"] = "shift_group_created";
    AccountHistoryContentType["SHIFT_GROUP_UPDATED"] = "shift_group_updated";
    AccountHistoryContentType["SHIFT_GROUP_DELETED"] = "shift_group_deleted";
    AccountHistoryContentType["SHIFT_GROUP_CLAIMED"] = "shift_group_claimed";
    AccountHistoryContentType["SHIFT_GROUP_REQUEST_APPROVED"] = "shift_group_request_approved";
    AccountHistoryContentType["SHIFT_GROUP_REQUEST_REJECTED"] = "shift_group_request_rejected";
    AccountHistoryContentType["SHIFT_WAGE_UPDATED"] = "shift_wage_updated";
    AccountHistoryContentType["RELEASE_REQUEST_APPROVED"] = "release_request_approved";
    AccountHistoryContentType["RELEASE_REQUEST_REJECTED"] = "release_request_rejected";
    AccountHistoryContentType["TIMEOFF_CREATED"] = "timeoff_created";
    AccountHistoryContentType["TIMEOFF_DELETED"] = "timeoff_deleted";
    AccountHistoryContentType["TIMEOFF_UPDATED"] = "timeoff_updated";
    AccountHistoryContentType["USER_CREATED"] = "user_created";
    AccountHistoryContentType["USER_DELETED"] = "user_deleted";
    AccountHistoryContentType["USER_UPDATED"] = "user_updated";
    AccountHistoryContentType["USER_PAY_UPDATED"] = "user_pay_updated";
    AccountHistoryContentType["TASK_TEMPLATE_CREATED"] = "task_template_created";
    AccountHistoryContentType["TASK_TEMPLATE_UPDATED"] = "task_template_updated";
    AccountHistoryContentType["TASK_TEMPLATE_DELETED"] = "task_template_deleted";
    AccountHistoryContentType["TASK_COMPLETED"] = "task_completed";
    AccountHistoryContentType["TASK_ASSIGNED"] = "task_assigned";
    AccountHistoryContentType["TASK_GROUP_TEMPLATE_CREATED"] = "task_group_template_created";
    AccountHistoryContentType["TASK_GROUP_TEMPLATE_UPDATED"] = "task_group_template_updated";
    AccountHistoryContentType["TASK_GROUP_TEMPLATE_DELETED"] = "task_group_template_deleted";
    AccountHistoryContentType["TASK_GROUP_ASSIGNED"] = "task_group_assigned";
    AccountHistoryContentType["TASK_GROUP_COMPLETED"] = "task_group_completed";
    AccountHistoryContentType["TASK_RULE_CREATED"] = "task_rule_created";
    AccountHistoryContentType["TASK_RULE_UPDATED"] = "task_rule_updated";
    AccountHistoryContentType["TASK_RULE_DELETED"] = "task_rule_deleted";
    AccountHistoryContentType["PLACEMENT_CREATED"] = "placement_created";
    AccountHistoryContentType["PLACEMENT_UPDATED"] = "placement_updated";
    AccountHistoryContentType["PLACEMENT_DELETED"] = "placement_deleted";
    AccountHistoryContentType["COMMENT_ADDED"] = "comment_added";
    AccountHistoryContentType["PERMISSION_UPDATED"] = "permission_updated";
    AccountHistoryContentType["BUTTON_CLICKED"] = "button_clicked";
    AccountHistoryContentType["JOB_CREATED"] = "job_created";
    AccountHistoryContentType["JOB_UPDATED"] = "job_updated";
    AccountHistoryContentType["JOB_DELETED"] = "job_deleted";
    AccountHistoryContentType["AUTOMATION_SAVED"] = "automation_saved";
    AccountHistoryContentType["AUTOMATION_CREATED"] = "automation_created";
    AccountHistoryContentType["SHIFT_SPLIT"] = "shift_split";
    AccountHistoryContentType["BROADCAST_DELETED"] = "broadcast_deleted";
})(AccountHistoryContentType || (AccountHistoryContentType = {}));
export var TaskRuleInfoProperty;
(function (TaskRuleInfoProperty) {
    TaskRuleInfoProperty["CONDITIONS"] = "Conditions";
})(TaskRuleInfoProperty || (TaskRuleInfoProperty = {}));
export var TaskTemplateInfoProperty;
(function (TaskTemplateInfoProperty) {
    TaskTemplateInfoProperty["TASK_TYPE"] = "Task Type";
})(TaskTemplateInfoProperty || (TaskTemplateInfoProperty = {}));
export var KamanaDocCollectionType;
(function (KamanaDocCollectionType) {
    KamanaDocCollectionType["DEGREE"] = "DEGREE";
    KamanaDocCollectionType["CERTIFICATION"] = "CERTIFICATION";
    KamanaDocCollectionType["MEDICAL_HISTORY"] = "MEDICAL_HISTORY";
    KamanaDocCollectionType["ADDITIONAL_DOCUMENTS"] = "ADDITIONAL_DOCUMENTS";
    KamanaDocCollectionType["REFERENCE"] = "REFERENCE";
    KamanaDocCollectionType["WORK_HISTORY"] = "WORK_HISTORY";
    KamanaDocCollectionType["CHECKLISTS"] = "CHECKLISTS";
    KamanaDocCollectionType["LICENSE"] = "LICENSE";
})(KamanaDocCollectionType || (KamanaDocCollectionType = {}));
