export var getFileTypeColor = function (type) {
    if (type === 'image')
        return '#00bcd4';
    if (type === 'video')
        return '#ff9800';
    if (type === 'audio')
        return '#ff9800';
    if (type === 'document')
        return '#9c27b0';
    return '#9c27b0';
};
export var getFileExtension = function (fileUrl) {
    var _a;
    return (_a = fileUrl.split('.').pop()) !== null && _a !== void 0 ? _a : '';
};
