var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useRecoilValue } from 'recoil';
import { accountIdSelector } from 'src/state';
import { useFetch } from './useFetch';
var useGenerativeApi = function () {
    var toastRequest = useFetch().toastRequest;
    var accountId = useRecoilValue(accountIdSelector);
    var backfillGenerativeSchema = function (body, schemaName) {
        return toastRequest({
            method: 'POST',
            url: 'generative/backfill',
        }, __assign(__assign({}, body), { accountId: accountId }), "Generating cells for ".concat(schemaName, "..."), 'Backfill completed');
    };
    return { backfillGenerativeSchema: backfillGenerativeSchema };
};
export { useGenerativeApi };
