import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { TEAMBRIDGE_TITLE } from 'src/shared';
import ContactEmailModal from './ContactEmailModal';
import ExpertContactItem from './ExpertContactItem';
// const EXPERTS = [
//     {
//         name: 'Patrick Shunney',
//         title: 'Account Executive',
//         email: 'patrick@teambridge.com',
//     },
// ]
var SUPPORT_CONTACT_INFO = { name: "".concat(TEAMBRIDGE_TITLE, " Support"), title: 'Support Team' };
var ExpertTeamContactWidget = function () {
    var _a = useState(), emailContactModalInfo = _a[0], setEmailContactModalInfo = _a[1];
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "ExpertTeamContactWidgetContainer w-full rounded-[15px] bg-white shadow-block", children: [_jsx("div", { className: "mb-5 flex items-center text-xl font-medium", children: "Need Help?" }), _jsx(ExpertContactItem, { info: SUPPORT_CONTACT_INFO })] }), _jsx(ContactEmailModal, { contactInfo: emailContactModalInfo, onClose: function () { return setEmailContactModalInfo(undefined); } })] }));
};
export default React.memo(ExpertTeamContactWidget);
