var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect } from 'react';
import { useSchemaComponent } from 'src/hooks/component';
import { getColumnTypeKey } from 'src/utils/Collection';
import PlainHeaderDisplay from './PlainHeaderDisplay';
import TableHeaderDropdown, { columnIcon } from './TableHeaderDropdown';
var EDITABLE_CONFIG_TYPES = ['rollup', 'generated_id', 'text', 'plain_selection'];
var ColumnHeaderHOC = function (props) {
    var _a;
    var column = props.column, collectionId = props.collectionId, isClicked = props.isClicked, setIsClicked = props.setIsClicked, extraProps = __rest(props, ["column", "collectionId", "isClicked", "setIsClicked"]);
    var fetchFormulaForSchema = useSchemaComponent().fetchFormulaForSchema;
    useEffect(function () {
        if (column && column.formulaId)
            fetchFormulaForSchema(column.id, column.formulaId);
    }, [column, fetchFormulaForSchema]);
    var hideDropdown = (column &&
        !column.canEdit &&
        !column.formulaId &&
        !EDITABLE_CONFIG_TYPES.includes(column.type)) ||
        !Object.keys(extraProps).length;
    var onVisibleChange = useCallback(function () {
        if (hideDropdown)
            return;
        setIsClicked(!isClicked);
    }, [hideDropdown, isClicked, setIsClicked]);
    var isRequired = (_a = column === null || column === void 0 ? void 0 : column.isRequired) !== null && _a !== void 0 ? _a : false;
    if (!column)
        return _jsx(_Fragment, {});
    if (hideDropdown)
        return (_jsx(PlainHeaderDisplay, { icon: columnIcon(getColumnTypeKey(column.inputMode, column.type, column.nativePath)), title: column.name, isRequired: isRequired }));
    return (_jsxs(_Fragment, { children: [_jsxs("div", { onClick: onVisibleChange, children: [!isClicked && (_jsx(PlainHeaderDisplay, { icon: columnIcon(getColumnTypeKey(column.inputMode, column.type, column.nativePath)), title: column.name, isRequired: isRequired })), isClicked && (_jsx(TableHeaderDropdown, __assign({}, props, { column: column, collectionId: collectionId, handleClose: onVisibleChange })))] }), isClicked && (_jsx("div", { className: "fpOverlay", onClick: function () {
                    onVisibleChange();
                }, style: { display: 'block' } }))] }));
};
export default React.memo(ColumnHeaderHOC);
