import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { IpName } from 'src/shared';
import { getIpDisplayText } from 'src/utils/Account';
var IntegrationDetails = function (_a) {
    var ipName = _a.ipName;
    var defaultDetailsBody = useMemo(function () {
        return (_jsxs("div", { className: "flex flex-col", children: [_jsxs("div", { children: ["Import information directly from ", getIpDisplayText(ipName), " \u00A0\u2714\uFE0F"] }), _jsx("div", { children: "Sync of account information \u00A0\u2714\uFE0F" }), _jsx("div", { children: "24/7 support from your TeamBridge Pilot \u00A0 \u2714\uFE0F" })] }));
    }, [ipName]);
    var greenshadesDetailsBody = useMemo(function () {
        return (_jsxs("div", { className: "flex flex-col", children: [_jsx("div", { children: "Get time card pay data directly from Greenshades \u00A0\u2714\uFE0F" }), _jsx("div", { children: "Automatically include PDF pay stubs on employees' time sheets\u00A0\u2714\uFE0F" })] }));
    }, []);
    var adpDetails = useMemo(function () {
        return "\n            ADP is a comprehensive global provider of cloud-based human capital management (HCM) solutions that unite HR, payroll, talent, time, tax and benefits administration\n            and a leader in business outsourcing services, analytics and compliance expertise.\n        ";
    }, []);
    var detailsBody = useMemo(function () {
        switch (ipName) {
            case IpName.GREENSHADES:
                return greenshadesDetailsBody;
            case IpName.ADP:
                return adpDetails;
            default:
                return defaultDetailsBody;
        }
    }, [ipName, defaultDetailsBody, greenshadesDetailsBody, adpDetails]);
    return _jsx("div", { className: "text-md text-gray-500", children: detailsBody });
};
export default React.memo(IntegrationDetails);
