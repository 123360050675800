import { IpName } from 'src/shared';
import { getNativeType } from 'src/utils/Collection';
import { upperCaseStringToStartCase } from 'src/utils/Tools/LodashTool';
export var formatSnakeCase = function (ipName) {
    return upperCaseStringToStartCase(ipName.replace(/_/g, ' '));
};
export var trimNativeType = function (schema) {
    if (schema.inputMode === 'native') {
        return getNativeType(schema.type);
    }
    return schema.type;
};
var isSignNow = function (ipName) { return ipName === IpName.SIGNNOW; };
var requiresIntegrationClientConnection = function (ipName) {
    return ipName === IpName.PAYCHEX ||
        ipName === IpName.CHECKRPAY ||
        ipName === IpName.PROLIANT_READYPAY ||
        ipName === IpName.GREENSHADES;
};
var requiresIntegrationAPIConnection = function (ipName) {
    return ipName === IpName.SmartRecruiters || ipName === IpName.TITAN;
};
var hideIntegrationSync = function (ipName) {
    return ipName === IpName.DOCUSIGN ||
        ipName === IpName.SIGNNOW ||
        ipName === IpName.CHECKRPAY ||
        ipName === IpName.SmartRecruiters ||
        ipName === IpName.GREENSHADES;
};
export var getIntegrationPartnerConditions = function (ipName) { return ({
    isSignNow: isSignNow(ipName),
    requiresIntegrationClientConnection: requiresIntegrationClientConnection(ipName),
    requiresIntegrationAPIConnection: requiresIntegrationAPIConnection(ipName),
    hideIntegrationSync: hideIntegrationSync(ipName),
}); };
