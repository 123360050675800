var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useMutation, useQuery } from 'react-query';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { accountIdSelector, tableSearchTextState, tableFiltersSelector, tableCollectionCurrentSizeSelector, tableCollectionResetPageState, } from 'src/state';
import { getCollectionTableRefetchSize } from 'src/utils/Collection';
import { upperCaseStringToStartCase } from 'src/utils/Tools/LodashTool';
import { PageType, } from '../../components/CenterPanel/Interface';
import { DefaultPagePagination, OPTIONS_COLOR, } from '../../shared';
import { useFetch } from './useFetch';
var usePageApi = function () {
    var _a;
    var _b = useFetch(), customFetch = _b.request, toastRequest = _b.toastRequest;
    var accountId = useRecoilValue(accountIdSelector);
    var recordId = (_a = new URLSearchParams(location.search).get('ridForPay')) !== null && _a !== void 0 ? _a : undefined;
    var useCreatePageGroup = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: 'pages/page_group',
            }, {
                accountId: accountId,
                name: request.name,
                iconInfo: request.iconInfo,
            });
        });
    };
    var createPageInGroup = function (pageGroupId, type, iconInfo) {
        return customFetch({
            method: 'POST',
            url: 'pages',
        }, {
            pageGroupId: pageGroupId,
            name: type === PageType.TABLE
                ? 'New List Workspace'
                : upperCaseStringToStartCase(type),
            type: type,
            iconInfo: iconInfo,
        });
    };
    var useGetAccountPageTemplateQuery = function (params) {
        var _a;
        return useQuery(['pageTemplates'], function () {
            return customFetch({
                method: 'GET',
                url: 'page_templates',
            });
        }, __assign(__assign({}, params === null || params === void 0 ? void 0 : params.options), { enabled: (_a = params === null || params === void 0 ? void 0 : params.options) === null || _a === void 0 ? void 0 : _a.enabled }));
    };
    var createPageTemplateInPageGroup = function (pageGroupId, templateIdentifier) {
        return customFetch({
            method: 'POST',
            url: 'page_templates/create_page_from_template',
        }, {
            pageGroupId: pageGroupId,
            templateIdentifier: templateIdentifier,
        });
    };
    var useDeletePageGroup = function () {
        return useMutation(function (pageGroupId) {
            return customFetch({
                method: 'DELETE',
                url: 'pages/page_group/' + pageGroupId,
            }, {
                pageGroupId: pageGroupId,
            });
        });
    };
    var useGetPageDetails = function (pageId) {
        return useQuery(['pageDetails', pageId], function () {
            return customFetch({
                method: 'GET',
                url: 'pages/' + pageId,
            });
        }, {
            enabled: !!pageId,
        });
    };
    var useDeletePage = function () {
        return useMutation(function (pageId) {
            return customFetch({
                method: 'POST',
                url: 'pages/delete',
            }, {
                pageId: pageId,
            });
        });
    };
    var useDeleteBlockOrCollection = function () {
        return useMutation(function (_a) {
            var blockId = _a.blockId, deleteCollection = _a.deleteCollection;
            return customFetch({
                method: 'POST',
                url: 'pages/delete_block',
            }, {
                blockId: blockId,
                deleteCollection: deleteCollection,
            });
        });
    };
    var useDeleteCollection = function () {
        return useMutation(function (collectionId) {
            return toastRequest({
                method: 'POST',
                url: 'pages/delete_collection',
            }, {
                collectionId: collectionId,
            }, undefined, 'Block and associated collection(s) were deleted.');
        });
    };
    var useUpdateCollection = function () {
        return useMutation(function (body) {
            return toastRequest({
                method: 'POST',
                url: 'pages/update_collection',
            }, {
                collectionId: body.collectionId,
                name: body.name,
            }, undefined, 'Collection successfully updated.');
        });
    };
    var useUpdatePageGroup = function () {
        return useMutation(function (body) {
            return customFetch({
                method: 'POST',
                url: 'pages/update_page_group',
            }, body);
        });
    };
    var useUpdatePage = function () {
        return useMutation(function (body) {
            return customFetch({
                method: 'POST',
                url: 'pages/update_page',
            }, body);
        });
    };
    var useUpdatePageIcon = function () {
        return useMutation(function (body) {
            return customFetch({
                method: 'POST',
                url: 'pages/update_page_icon',
            }, body);
        });
    };
    var useUpdatePageEmoji = function () {
        return useMutation(function (body) {
            return customFetch({
                method: 'POST',
                url: 'pages/update_page_emoji',
            }, body);
        });
    };
    var useUpdatePageGroupIcon = function () {
        return useMutation(function (body) {
            return customFetch({
                method: 'POST',
                url: 'pages/update_page_group_icon',
            }, body);
        });
    };
    var useUpdatePageGroupEmoji = function () {
        return useMutation(function (body) {
            return customFetch({
                method: 'POST',
                url: 'pages/update_page_group_emoji',
            }, body);
        });
    };
    var useGetPageIconOptions = function (request) {
        return useQuery(['pageIconOptions', request.query, request.pagination], function () {
            return customFetch({
                method: 'POST',
                url: 'pages/get_icons',
            }, __assign({}, request));
        }, {
            cacheTime: Infinity,
            staleTime: Infinity,
        });
    };
    var useListBlockQuery = function (body) {
        return useQuery(['listBlock', body.blockId], function () { return listBlock(body); });
    };
    var listBlock = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (body) {
            var _a;
            var requestBody = body;
            var searchText = snapshot
                .getLoadable(tableSearchTextState(requestBody.blockId))
                .valueMaybe();
            if (searchText)
                return;
            if (!body.pagination) {
                var currentSize = (_a = snapshot
                    .getLoadable(tableCollectionCurrentSizeSelector(requestBody.blockId))
                    .valueMaybe()) !== null && _a !== void 0 ? _a : 0;
                requestBody = __assign(__assign({}, body), { pagination: {
                        page: 0,
                        size: currentSize > DefaultPagePagination.size
                            ? Math.ceil(currentSize / DefaultPagePagination.size) *
                                DefaultPagePagination.size
                            : DefaultPagePagination.size,
                    } });
            }
            var filters = snapshot
                .getLoadable(tableFiltersSelector(requestBody.blockId))
                .valueMaybe();
            // Only pass in filters if already initialized since we don't know displaySettings beforehand
            if (filters) {
                requestBody = __assign(__assign({}, body), { filters: filters });
            }
            return customFetch({
                method: 'POST',
                url: 'pages/list_blocks',
            }, __assign(__assign({}, requestBody), { accountId: accountId, recordId: recordId }));
        };
    }, [accountId, customFetch, recordId]);
    var useGetBlockDisplaySettings = function (params) {
        return useQuery(['blockDisplaySettings', params.blockId], function () {
            return customFetch({
                method: 'GET',
                url: "collections/display_settings/".concat(params.blockId),
            });
        }, __assign({}, params.options));
    };
    var useGetBlockViews = function (params) {
        return useQuery(['blockViews', params.blockId], function () {
            return customFetch({
                method: 'GET',
                url: "collections/views/".concat(params.blockId),
            });
        }, __assign({}, params.options));
    };
    var useGetBlockViewsRecordCount = function (blockId, request) {
        var _a, _b, _c;
        return useQuery(__spreadArray(__spreadArray([
            'blockViewCounts',
            blockId
        ], __spreadArray([], ((_b = (_a = request.body) === null || _a === void 0 ? void 0 : _a.viewIds) !== null && _b !== void 0 ? _b : []), true), true), [
            JSON.stringify((_c = request.body) === null || _c === void 0 ? void 0 : _c.overrideFilters),
        ], false), function () {
            return customFetch({
                method: 'POST',
                url: "collections/view_counts",
            }, request.body);
        }, __assign({}, request.options));
    };
    var useGetAllRecordsCount = function (collectionId) {
        return useQuery(['allRecordsCount'], function () {
            return customFetch({
                method: 'GET',
                url: "collections/record_counts/".concat(collectionId),
            });
        });
    };
    var useAddBlockView = function () {
        return useMutation(function (body) {
            return customFetch({
                method: 'POST',
                url: 'collections/views',
            }, body);
        });
    };
    var useUpdateBlockView = function () {
        return useMutation(function (body) {
            return customFetch({
                method: 'PUT',
                url: "collections/views",
            }, body);
        });
    };
    var useDeleteBlockView = function () {
        return useMutation(function (viewId) {
            return customFetch({
                method: 'DELETE',
                url: "collections/views/".concat(viewId),
            });
        });
    };
    var useListBlockCollectionQuery = function (
    //! Omit pagination so query refetch logic behaves correctly
    request) {
        var _a, _b, _c, _d;
        var body = request.body;
        return useQuery([
            'listBlock',
            body.blockId,
            JSON.stringify((_a = body.displaySettings) === null || _a === void 0 ? void 0 : _a.filters),
            JSON.stringify((_b = body.displaySettings) === null || _b === void 0 ? void 0 : _b.sortBy),
            (_c = body.search) !== null && _c !== void 0 ? _c : '',
            (_d = body.recordId) !== null && _d !== void 0 ? _d : '',
        ], function () { return listBlockCollection(body); }, __assign({}, request.options));
    };
    var listBlockCollection = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (body) {
            var _a, _b;
            var requestBody = body;
            if (!body.pagination) {
                //! For page reset or refetch for useQuery
                var currentSize = (_a = snapshot
                    .getLoadable(tableCollectionCurrentSizeSelector(requestBody.blockId))
                    .valueMaybe()) !== null && _a !== void 0 ? _a : 0;
                var resetPage = (_b = snapshot
                    .getLoadable(tableCollectionResetPageState(requestBody.blockId))
                    .valueMaybe()) !== null && _b !== void 0 ? _b : false;
                requestBody = __assign(__assign({}, body), { pagination: {
                        page: 0,
                        size: resetPage
                            ? DefaultPagePagination.size
                            : getCollectionTableRefetchSize(currentSize, DefaultPagePagination.size),
                    } });
            }
            return customFetch({
                method: 'POST',
                url: 'collections/list',
            }, __assign(__assign({}, requestBody), { recordId: recordId }));
        };
    }, [customFetch, recordId]);
    var useUpdatePageBlock = function () {
        return useMutation(function (body) {
            return customFetch({
                method: 'POST',
                url: 'pages/update_block',
            }, body);
        });
    };
    // add block flow related
    var useGetAccountBlockCategories = function (params) {
        return useQuery(['accountBlockCategories'], function () {
            return customFetch({
                method: 'GET',
                url: "collection_templates/get_block_categories",
            });
        }, __assign({}, params === null || params === void 0 ? void 0 : params.options));
    };
    var useGetCollectionTemplate = function (request) {
        var collectionType = request.collectionType;
        return useQuery(['collectionTemplates', collectionType], function () {
            return customFetch({
                method: 'POST',
                url: "collection_templates/get_collection_templates",
            }, {
                collectionType: collectionType,
            });
        }, request.options);
    };
    var useCreateNewBlockInPage = function () {
        return useMutation(function (body) {
            return customFetch({
                method: 'POST',
                url: 'pages/create_block',
            }, body);
        });
    };
    var useApplyTemplateCollectionToPage = function () {
        return useMutation(function (body) {
            return customFetch({
                method: 'POST',
                url: 'collection_templates/create_collection_from_template',
            }, body);
        });
    };
    var preSaveNewRecord = function (body) {
        return customFetch({
            method: 'POST',
            url: 'collections/v2/pre_save_record_details',
        }, body);
    };
    var usePreSaveNewRecord = function (request) {
        return useQuery(['preSaveNewRecord', JSON.stringify(request)], function () { return preSaveNewRecord(request.body); }, request.options);
    };
    var useAddColorsToCollection = function () {
        var body = {
            accountId: accountId,
            colors: Object.values(OPTIONS_COLOR),
        };
        return useMutation(function (collectionId) {
            return customFetch({
                method: 'POST',
                url: 'collections/add_colors',
            }, __assign(__assign({}, body), { collectionId: collectionId }));
        });
    };
    /**
     * schema related
     */
    var addCollectionSchema = function (body) {
        return toastRequest({
            method: 'POST',
            url: 'collections/schemas',
        }, body, 'Creating column...', 'Successfully created column!');
    };
    var updateCollectionSchema = function (body) {
        return customFetch({
            method: 'POST',
            url: 'collections/schemas/update',
        }, body);
    };
    var deleteCollectionSchema = function (body) {
        return customFetch({
            method: 'POST',
            url: 'collections/schemas/delete',
        }, body);
    };
    var createSchemaOption = function (body) {
        return customFetch({
            method: 'POST',
            url: 'collections/schemas/options/add',
        }, body);
    };
    var bulkAddSchemaOptions = function (body) {
        return customFetch({
            method: 'POST',
            url: 'collections/schemas/options/addBulk',
        }, body);
    };
    var updateSchemaOption = function (optionId, body) {
        return customFetch({
            method: 'PUT',
            url: "collections/schemas/options/".concat(optionId),
        }, body);
    };
    var deleteSchemaOption = function (body) {
        return customFetch({
            method: 'POST',
            url: 'collections/delete_value_selection',
        }, body);
    };
    var checkCollectionForInlineCreation = function (collectionId) {
        return customFetch({
            method: 'GET',
            url: "collections/is_eligible_for_inline_create/".concat(collectionId),
        });
    };
    var getAllSchemasInCollection = function (collectionId) {
        return customFetch({
            method: 'GET',
            url: "collections/schemas/options/".concat(collectionId),
        });
    };
    var useGetSchemasInCollection = function (request) {
        var collectionId = request.collectionId;
        return useQuery(['AllSchemasInCollection', collectionId], function () { return getAllSchemasInCollection(collectionId); }, request.options);
    };
    var createCollectionObjectInSchemaColumn = function (body) {
        return customFetch({
            method: 'POST',
            url: 'collections/create_record_inline',
        }, body);
    };
    var useUpdateDisplaySettings = function () {
        return useMutation(function (body) {
            return customFetch({
                method: 'POST',
                url: 'collections/apply_display_settings',
            }, __assign(__assign({}, body), { recordId: recordId }));
        });
    };
    var usePageFilters = function (requestBody) {
        return useQuery(['collectionFilter', JSON.stringify(requestBody)], function () {
            return customFetch({
                method: 'POST',
                url: 'collections/filters',
            }, requestBody.body);
        }, requestBody.options);
    };
    var getPageFilters = function (body) {
        return customFetch({
            method: 'POST',
            url: "collections/filters",
        }, body);
    };
    var useReportFilters = function (requestBody) {
        return useQuery(['metricFilter', JSON.stringify(requestBody)], function () {
            return customFetch({
                method: 'POST',
                url: 'metrics/filters',
            }, requestBody.body);
        }, requestBody.options);
    };
    var useGetCollectionRecordDetail = function (params) {
        return useQuery(['recordDetail', params.recordId], function () {
            return customFetch({
                method: 'GET',
                url: "collections/v2/record_details/".concat(params.recordId),
            });
        }, params.options);
    };
    var useGetCollectionRecordDetailV3 = function (params) {
        return useQuery(['recordDetailV3', params.recordId], function () {
            return customFetch({
                method: 'GET',
                url: "collections/v3/record_details/".concat(params.recordId),
            });
        }, params.options);
    };
    var useGetRecordDetailMetricsAndSchemas = function (params) {
        return useQuery(['recordMetrics', params.recordId], function () {
            return customFetch({
                method: 'GET',
                url: "record_metrics/".concat(params.recordId),
            });
        }, params.options);
    };
    var useGetApplicableRecordMetrics = function (params) {
        return useQuery(['applicableRecordMetrics', params.collectionId], function () {
            return customFetch({
                method: 'GET',
                url: "record_metrics/applicable_metrics/".concat(params.collectionId),
            });
        }, params.options);
    };
    var useEnableRecordDetailMetric = function () {
        return useMutation(function (body) {
            return customFetch({
                method: 'POST',
                url: 'record_metrics/enable_metric',
            }, body);
        });
    };
    var useDisableRecordDetailMetric = function () {
        return useMutation(function (body) {
            return customFetch({
                method: 'POST',
                url: 'record_metrics/disable_metric',
            }, body);
        });
    };
    var useGetCollectionSchemaGroups = function (params) {
        return useQuery(['collectionSchemaGroups', params.collectionId], function () { return getCollectionSchemaGroups(params.collectionId); }, params.options);
    };
    var getCollectionSchemaGroups = function (collectionId) {
        return customFetch({
            method: 'GET',
            url: "collections/schema_groups/".concat(collectionId),
        });
    };
    var useGetCollectionSchemaGroupsFromCollectionType = function (params) {
        return useQuery(['collectionSchemaGroupsFromCollectionType', params.type], function () { return getCollectionSchemaGroupsFromCollectionType(params.type); }, params.options);
    };
    var useGetCollectionSchemaGroupsIncludingHiddenFromCollectionType = function (params) {
        return useQuery(['collectionSchemaGroupsIncludingHiddenFromCollectionType', params.type], function () { return getCollectionSchemaGroupsFromCollectionType(params.type, true); }, params.options);
    };
    var getCollectionSchemaGroupsFromCollectionType = function (type, includeHidden) {
        return customFetch({
            method: 'GET',
            url: "collections/schemas_of_native_collection/".concat(type).concat(includeHidden ? '?includeHidden=true' : ''),
        });
    };
    var useGetCollectionSchemasAndSecondarySchemasFromCollectionId = function (params) {
        return useQuery(['collectionSchemasAndSecondarySchemas', params.collectionId], function () {
            return customFetch({
                method: 'GET',
                url: "collections/collection_schemas_and_secondary_schemas/".concat(params.collectionId),
            });
        }, params.options);
    };
    var updateCollectionSettings = function (body) {
        return customFetch({
            method: 'POST',
            url: "collections/settings",
        }, body);
    };
    var usePostCollectionTemplateRecordDetail = function (requestBody) {
        return useQuery(['templateRecordDetail', JSON.stringify(requestBody)], function () {
            return customFetch({
                method: 'POST',
                url: "collections/template_record_details",
            }, requestBody.body);
        }, requestBody.options);
    };
    var useGetJobPrefillDetail = function (request) {
        return useQuery(['jobPrefillDetail', JSON.stringify(request.body)], function () {
            var _a;
            return customFetch({
                method: 'GET',
                url: "collections/prefill_job_details/".concat((_a = request.body) === null || _a === void 0 ? void 0 : _a.jobRid),
            });
        }, __assign({}, request.options));
    };
    var useCreateCollectionRecordsQuery = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'collections/v2/create_record',
            }, request);
        });
    };
    var createCollectionRecords = function (request) {
        return toastRequest({
            method: 'POST',
            url: 'collections/v2/create_record',
        }, request);
    };
    var createCollectionRecordV3 = function (request) {
        return toastRequest({
            method: 'POST',
            url: 'collections/v3/create_record',
        }, request);
    };
    var updateCollectionRecords = function (request) {
        return customFetch({
            method: 'POST',
            url: 'collections/v2/update_record',
        }, request);
    };
    var bulkUpdateCollectionRecords = function (body) {
        return toastRequest({
            method: 'POST',
            url: 'collections/bulk_update_records',
        }, body, 'Updating records...', 'Records successfully updated!', '');
    };
    var bulkModifyCollectionRecords = function (body) {
        return toastRequest({
            method: 'POST',
            url: 'collections/bulk_modify_records',
        }, body, 'Updating records...', 'Records successfully updated!', '');
    };
    var bulkDownloadDocuments = function (body) {
        return toastRequest({
            method: 'POST',
            url: 'collections/bulk_download_documents',
        }, body);
    };
    var copyRecord = function (requestBody) {
        return toastRequest({
            method: 'POST',
            url: 'collections/copy_record',
        }, requestBody);
    };
    var updateRecordColor = function (body) {
        return toastRequest({
            method: 'POST',
            url: 'collections/update_record_color',
        }, __assign(__assign({}, body), { accountId: accountId }));
    };
    var useDeleteCollectionRecords = function () {
        return useMutation(function (body) {
            var recordPlural = body.recordIds.length > 1 ? 's' : '';
            return toastRequest({
                method: 'POST',
                url: 'collections/delete_records',
            }, __assign(__assign({}, body), { accountId: accountId }), "Deleting record".concat(recordPlural, "..."), "Record".concat(recordPlural, " successfully deleted!"));
        });
    };
    var useGetPayPeriodComponents = function (recordId) {
        return useQuery(['payPeriodComponents', recordId], function () {
            return customFetch({
                method: 'GET',
                url: "pay/".concat(recordId),
            });
        }, {
            enabled: !!recordId,
        });
    };
    var useGetPayPeriodEvaluation = function (recordId) {
        return useQuery(['evaluatePayComponents', recordId], function () {
            return customFetch({
                method: 'GET',
                url: "pay/evaluate_pay_exceptions/".concat(recordId),
            });
        }, {
            enabled: !!recordId,
        });
    };
    var useGetTimeoffSummaryData = function (requestBody, userId) {
        var _a, _b;
        return useQuery(['timeoffSummary', userId || '', accountId], function () {
            return customFetch({
                method: 'GET',
                url: "timeoff/get_user_summary_table/".concat(accountId, "/").concat(userId),
            });
        }, __assign(__assign({}, requestBody.options), { enabled: !!userId && ((_b = (_a = requestBody.options) === null || _a === void 0 ? void 0 : _a.enabled) !== null && _b !== void 0 ? _b : true) }));
    };
    var getTimeoffRecordInfo = function (rid) {
        return customFetch({
            method: 'GET',
            url: "timeoff/get_timeoff_details/".concat(rid),
        });
    };
    var useGetUserDetailMetrics = function (requestBody, userRId) {
        var _a;
        return useQuery(['userStats', userRId !== null && userRId !== void 0 ? userRId : ''], function () {
            return customFetch({
                method: 'GET',
                url: "users/user_stats/".concat(userRId),
            });
        }, __assign(__assign({}, requestBody.options), { enabled: !!userRId && ((_a = requestBody.options) === null || _a === void 0 ? void 0 : _a.enabled) }));
    };
    var useSaveTimeoffSummaryData = function () {
        return useMutation(function (body) {
            return customFetch({
                method: 'POST',
                url: 'timeoff/save_manual',
            }, body);
        });
    };
    //pay roll related
    var useGetAvailablePayRollIp = function () {
        return useQuery(['availablePayRollIp', accountId], function () {
            return customFetch({
                method: 'GET',
                url: 'ip/payroll/avail_payroll_ip',
            });
        });
    };
    var useGetPaychexPayPeriods = function (options) {
        return useQuery(['paychexPayPeriods'], function () {
            return customFetch({
                method: 'GET',
                url: "ip/payroll/payPeriod/paychex",
            });
        }, options);
    };
    var useGetEarningCode = function (ipName, options) {
        return useQuery(['payrollEarningCode', ipName], function () {
            return customFetch({
                method: 'GET',
                url: "ip/payroll/earning_code/".concat(accountId, "/").concat(ipName),
            });
        }, options);
    };
    var useGetADPPayRollGroupCodes = function (options) {
        return useQuery(['adpGroupCodes', accountId], function () {
            return customFetch({
                method: 'GET',
                url: 'ip/payroll/earning_code/adp/group_name',
            });
        }, options);
    };
    var useGetRipplingPayrollRuns = function (options) {
        return useQuery(['ripplingPayrollRuns', accountId], function () {
            return customFetch({
                method: 'GET',
                url: 'ip/payroll/eligible_payroll_runs/rippling',
            });
        }, options);
    };
    var useSendPayRoll = function () {
        return useMutation(function (body) {
            return customFetch({
                method: 'POST',
                url: 'ip/payroll',
            }, body);
        });
    };
    // invoice related
    var useGetAvailableInvoiceIp = function () {
        return useQuery(['availableInvoiceIp', accountId], function () {
            return customFetch({
                method: 'GET',
                url: 'ip/invoice/avail_invoice_ip',
            });
        });
    };
    var useSendInvoice = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: "ip/invoice/".concat(request.ipName, "/sendInvoice"),
            }, request.body);
        });
    };
    // old import related
    var useGetCsvInfo = function () {
        return useMutation(function (body) {
            return customFetch({
                method: 'POST',
                url: 'import/headers',
            }, body);
        });
    };
    var useSendImportMappingRequest = function () {
        return useMutation(function (body) {
            return customFetch({
                method: 'POST',
                url: 'import',
            }, body);
        });
    };
    // flatfile import related
    var useGetFlatFileToken = function (collectionId, options) {
        return useQuery(['getFlatFileToken', collectionId], function () {
            return customFetch({
                method: 'GET',
                url: "import/flat_file/".concat(collectionId),
            });
        }, __assign({}, options));
    };
    var useImportByFlatFile = function () {
        return useMutation(function (body) {
            return customFetch({
                method: 'POST',
                url: 'import/flat_file',
            }, body);
        });
    };
    // export report related
    var useExportReport = function () {
        return useMutation(function (body) {
            return customFetch({
                method: 'POST',
                url: 'export/export_pay_report',
            }, body);
        });
    };
    var useExportPdfReport = function () {
        return useMutation(function (body) {
            return customFetch({
                method: 'POST',
                url: 'export/export_pdf_pay_report',
            }, body);
        });
    };
    var useGetAggregatePayReportTypes = function (options) {
        return useQuery(['aggregatePayReportTypes', accountId], function () {
            return customFetch({
                method: 'GET',
                url: "export/export_pay_report/".concat(accountId),
            });
        }, __assign({}, options));
    };
    var useGetIndividualPayReportTypes = function (options) {
        return useQuery(['individualPayReportTypes', accountId], function () {
            return customFetch({
                method: 'GET',
                url: "export/export_record_pay_report/".concat(accountId),
            });
        }, __assign({}, options));
    };
    var useGetExportPayReportTemplate = function (options) {
        return useQuery(['reportCsvTemplates', accountId], function () {
            return customFetch({
                method: 'POST',
                url: "export/fetch_export_templates",
            }, { accountId: accountId });
        }, __assign({}, options));
    };
    var useUpdateReportExportTemplate = function () {
        return useMutation(function (body) {
            return customFetch({
                method: 'PUT',
                url: 'export/update_export_template',
            }, body);
        });
    };
    var useCreateReportExportTemplate = function () {
        return useMutation(function (body) {
            return customFetch({
                method: 'POST',
                url: 'export/create_export_template',
            }, __assign(__assign({}, body), { accountId: accountId }));
        });
    };
    var useDeleteReportExportTemplate = function () {
        return useMutation(function (templateId) {
            return customFetch({
                method: 'DELETE',
                url: "export/delete_export_template/".concat(templateId),
            });
        });
    };
    var useGetTemplateReportSchemasAndMetrics = function () {
        return useMutation(function (reportType) {
            return customFetch({
                method: 'POST',
                url: 'export/fetch_export_template_schemas',
            }, { reportType: reportType, accountId: accountId });
        });
    };
    var useExportRecordReport = function () {
        return useMutation(function (body) {
            return customFetch({
                method: 'POST',
                url: 'export/export_record_pay_report',
            }, body);
        });
    };
    var useExportCuttingEdgeReport = function () {
        return useMutation(function (body) {
            return customFetch({
                method: 'POST',
                url: 'export/export_cutting_edge_report',
            }, body);
        });
    };
    var useGetCollectionSchemas = function (request) {
        return useQuery(['collectionSchemas', request.body.collectionId], function () {
            return customFetch({
                method: 'GET',
                url: "collections/schemas/options/".concat(request.body.collectionId),
            });
        }, __assign({}, request.options));
    };
    var useExportCollection = function () {
        return useMutation(function (body) {
            return customFetch({
                method: 'POST',
                url: 'export',
            }, body);
        });
    };
    var useGetMediaBlock = function (body) {
        return useQuery(['getMediaBlockData', body.blockId], function () {
            return customFetch({
                method: 'POST',
                url: "pages/".concat(body.type, "_block"),
            }, {
                blockId: body.blockId,
                pageId: body.pageId,
            });
        });
    };
    var useGetGeomapBlock = function (blockId, pageId) {
        return useQuery(['geomapBlock', blockId], function () {
            return customFetch({
                method: 'POST',
                url: "pages/geomap_block",
            }, {
                blockId: blockId,
                pageId: pageId,
            });
        });
    };
    var useGetUpdateHeadcountBlock = function (blockId, start, end, setting) {
        return useQuery(['headcountBlock', blockId, start, end, setting], function () {
            return customFetch({
                method: 'POST',
                url: "headcount",
            }, {
                start: start,
                end: end,
                setting: setting,
            });
        });
    };
    var addLocationToAllUsers = function (recordId) {
        return toastRequest({
            method: 'POST',
            url: 'locations/bulk_add_users',
        }, {
            locationRid: recordId,
        }, undefined, 'Successfully added location to all users.');
    };
    var useGetAllAvailableGroupBySchemas = function (collectionId) {
        return useQuery(['allAvailableGroupBySchemas', collectionId], function () {
            return customFetch({
                method: 'GET',
                url: "collections/groups/".concat(collectionId),
            });
        });
    };
    var useGetBoardViewGroupsData = function (request) {
        var _a, _b, _c;
        var body = request.body;
        var isBlock = 'blockId' in body;
        var queryKey = isBlock ? 'blockBoardViewGroups' : 'reverseCollection';
        var secondQueryKey = isBlock ? body.blockId : body.collectionId;
        return useQuery([
            queryKey,
            secondQueryKey,
            JSON.stringify((_a = body.displaySettings) === null || _a === void 0 ? void 0 : _a.filters),
            JSON.stringify((_b = body.displaySettings) === null || _b === void 0 ? void 0 : _b.sortBy),
            JSON.stringify((_c = body.displaySettings) === null || _c === void 0 ? void 0 : _c.groupSetting),
        ], function () {
            return customFetch({
                method: 'POST',
                url: "collections/group",
            }, body);
        }, __assign({}, request.options));
    };
    var useClickButtonCell = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'button/click',
            }, request, undefined, 'Successfully triggered button action.');
        });
    };
    return {
        useCreatePageGroup: useCreatePageGroup(),
        createPageInGroup: createPageInGroup,
        useGetAccountPageTemplateQuery: useGetAccountPageTemplateQuery,
        createPageTemplateInPageGroup: createPageTemplateInPageGroup,
        useDeletePageGroup: useDeletePageGroup(),
        useGetPageDetails: useGetPageDetails,
        useDeletePage: useDeletePage(),
        useDeleteBlockOrCollection: useDeleteBlockOrCollection(),
        useDeleteCollection: useDeleteCollection(),
        useUpdateCollection: useUpdateCollection(),
        useUpdatePageGroup: useUpdatePageGroup(),
        useUpdatePage: useUpdatePage(),
        useUpdatePageBlock: useUpdatePageBlock(),
        useUpdatePageIcon: useUpdatePageIcon(),
        useUpdatePageEmoji: useUpdatePageEmoji(),
        useUpdatePageGroupIcon: useUpdatePageGroupIcon(),
        useUpdatePageGroupEmoji: useUpdatePageGroupEmoji(),
        useGetPageIconOptions: useGetPageIconOptions,
        useCreateNewBlockInPage: useCreateNewBlockInPage(),
        useApplyTemplateCollectionToPage: useApplyTemplateCollectionToPage(),
        useGetAccountBlockCategories: useGetAccountBlockCategories,
        useGetCollectionTemplate: useGetCollectionTemplate,
        useGetBlockDisplaySettings: useGetBlockDisplaySettings,
        useGetBlockViews: useGetBlockViews,
        useGetBlockViewsRecordCount: useGetBlockViewsRecordCount,
        useGetAllRecordsCount: useGetAllRecordsCount,
        useAddBlockView: useAddBlockView(),
        useUpdateBlockView: useUpdateBlockView(),
        useDeleteBlockView: useDeleteBlockView(),
        useListBlockQuery: useListBlockQuery,
        listBlock: listBlock,
        useListBlockCollectionQuery: useListBlockCollectionQuery,
        listBlockCollection: listBlockCollection,
        preSaveNewRecord: preSaveNewRecord,
        addCollectionSchema: addCollectionSchema,
        updateCollectionSchema: updateCollectionSchema,
        deleteCollectionSchema: deleteCollectionSchema,
        createSchemaOption: createSchemaOption,
        bulkAddSchemaOptions: bulkAddSchemaOptions,
        updateSchemaOption: updateSchemaOption,
        deleteSchemaOption: deleteSchemaOption,
        useUpdateDisplaySettings: useUpdateDisplaySettings(),
        usePageFilters: usePageFilters,
        getPageFilters: getPageFilters,
        useReportFilters: useReportFilters,
        useGetCollectionRecordDetail: useGetCollectionRecordDetail,
        useGetCollectionRecordDetailV3: useGetCollectionRecordDetailV3,
        usePostCollectionTemplateRecordDetail: usePostCollectionTemplateRecordDetail,
        useCreateCollectionRecordsQuery: useCreateCollectionRecordsQuery,
        createCollectionRecords: createCollectionRecords,
        createCollectionRecordV3: createCollectionRecordV3,
        updateCollectionRecords: updateCollectionRecords,
        bulkUpdateCollectionRecords: bulkUpdateCollectionRecords,
        bulkModifyCollectionRecords: bulkModifyCollectionRecords,
        bulkDownloadDocuments: bulkDownloadDocuments,
        copyRecord: copyRecord,
        updateRecordColor: updateRecordColor,
        createCollectionObjectInSchemaColumn: createCollectionObjectInSchemaColumn,
        useDeleteCollectionRecords: useDeleteCollectionRecords(),
        useAddColorsToCollection: useAddColorsToCollection(),
        useGetPayPeriodComponents: useGetPayPeriodComponents,
        useGetPayPeriodEvaluation: useGetPayPeriodEvaluation,
        useGetTimeoffSummaryData: useGetTimeoffSummaryData,
        getTimeoffRecordInfo: getTimeoffRecordInfo,
        useSaveTimeoffSummaryData: useSaveTimeoffSummaryData,
        useGetAvailablePayRollIp: useGetAvailablePayRollIp,
        useGetEarningCode: useGetEarningCode,
        useSendPayRoll: useSendPayRoll(),
        useGetAvailableInvoiceIp: useGetAvailableInvoiceIp,
        useSendInvoice: useSendInvoice(),
        useExportReport: useExportReport(),
        useExportPdfReport: useExportPdfReport(),
        useExportRecordReport: useExportRecordReport(),
        useExportCuttingEdgeReport: useExportCuttingEdgeReport(),
        useGetAggregatePayReportTypes: useGetAggregatePayReportTypes,
        useGetIndividualPayReportTypes: useGetIndividualPayReportTypes,
        useGetExportPayReportTemplate: useGetExportPayReportTemplate,
        useGetTemplateReportSchemasAndMetrics: useGetTemplateReportSchemasAndMetrics(),
        useCreateReportExportTemplate: useCreateReportExportTemplate(),
        useUpdateReportExportTemplate: useUpdateReportExportTemplate(),
        useDeleteReportExportTemplate: useDeleteReportExportTemplate(),
        useGetCollectionSchemas: useGetCollectionSchemas,
        useGetCsvInfo: useGetCsvInfo,
        useSendImportMappingRequest: useSendImportMappingRequest,
        useGetFlatFileToken: useGetFlatFileToken,
        useImportByFlatFile: useImportByFlatFile(),
        useExportCollection: useExportCollection,
        useGetMediaBlock: useGetMediaBlock,
        useGetGeomapBlock: useGetGeomapBlock,
        useGetUpdateHeadcountBlock: useGetUpdateHeadcountBlock,
        useGetCollectionSchemaGroups: useGetCollectionSchemaGroups,
        getCollectionSchemaGroups: getCollectionSchemaGroups,
        useGetCollectionSchemaGroupsFromCollectionType: useGetCollectionSchemaGroupsFromCollectionType,
        useGetCollectionSchemaGroupsIncludingHiddenFromCollectionType: useGetCollectionSchemaGroupsIncludingHiddenFromCollectionType,
        getCollectionSchemaGroupsFromCollectionType: getCollectionSchemaGroupsFromCollectionType,
        useGetCollectionSchemasAndSecondarySchemasFromCollectionId: useGetCollectionSchemasAndSecondarySchemasFromCollectionId,
        updateCollectionSettings: updateCollectionSettings,
        usePreSaveNewRecord: usePreSaveNewRecord,
        useGetUserDetailMetrics: useGetUserDetailMetrics,
        useGetRecordDetailMetricsAndSchemas: useGetRecordDetailMetricsAndSchemas,
        useGetApplicableRecordMetrics: useGetApplicableRecordMetrics,
        useEnableRecordDetailMetric: useEnableRecordDetailMetric(),
        useDisableRecordDetailMetric: useDisableRecordDetailMetric(),
        useGetADPPayRollGroupCodes: useGetADPPayRollGroupCodes,
        useGetRipplingPayrollRuns: useGetRipplingPayrollRuns,
        addLocationToAllUsers: addLocationToAllUsers,
        useGetAllAvailableGroupBySchemas: useGetAllAvailableGroupBySchemas,
        useGetBoardViewGroupsData: useGetBoardViewGroupsData,
        useGetPaychexPayPeriods: useGetPaychexPayPeriods,
        useClickButtonCell: useClickButtonCell(),
        useGetJobPrefillDetail: useGetJobPrefillDetail,
        checkCollectionForInlineCreation: checkCollectionForInlineCreation,
        getAllSchemasInCollection: getAllSchemasInCollection,
        useGetSchemasInCollection: useGetSchemasInCollection,
    };
};
export { usePageApi };
