var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dropdown, Menu } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { FileIcon } from 'react-file-icon';
import { useRecoilCallback, useRecoilState } from 'recoil';
import { documentPreviewModalDataState } from 'src/components/PageView/DocumentPreviewModal/Atom';
import { toast } from 'react-toastify';
import { useFileApi } from 'src/hooks/api/useFileApi';
import DocuSignLogo from '../../../../utils/icons/IpLogos/docusignLogo.png';
import SignNowLogo from '../../../../utils/icons/IpLogos/SignNow.svg';
import { signedFileUrlState } from './atom';
import { getFileExtension, getFileTypeColor } from './util';
var FileWithIconTag = function (_a) {
    var _b, _c;
    var file = _a.file, actions = _a.actions;
    var _d = actions !== null && actions !== void 0 ? actions : {}, onRename = _d.onRename, onDelete = _d.onDelete;
    var _e = useFileApi(), getKamanaFileUrl = _e.getKamanaFileUrl, fileUrlIsValid = _e.fileUrlIsValid;
    var fileExt = getFileExtension(file.url);
    var fileName = file.name.split('.').shift();
    var isDocusignTemplate = !!file.docusignTemplateId;
    var isSignNowTemplate = !!file.signNowTemplateId;
    var isKamanaFile = !!file.kamanaFile;
    var hasValidFileUrl = !!file.url || !!file.kamanaFile;
    var _f = useRecoilState(signedFileUrlState((_c = (_b = file.kamanaFile) === null || _b === void 0 ? void 0 : _b.id) !== null && _c !== void 0 ? _c : '')), signedUrl = _f[0], setSignedUrl = _f[1];
    var requiresSignedUrl = !file.url && isKamanaFile;
    var _g = useState(false), isRenaming = _g[0], setIsRenaming = _g[1];
    var fileType = useMemo(function () {
        if (file.type.includes('image')) {
            return 'image';
        }
        if (file.type.includes('video')) {
            return 'video';
        }
        if (file.type.includes('audio')) {
            return 'audio';
        }
        return 'document';
    }, [file.type]);
    var fileIcon = useMemo(function () {
        if (isDocusignTemplate) {
            return _jsx("img", { src: DocuSignLogo, alt: "DocuSign" });
        }
        else if (isSignNowTemplate) {
            return _jsx("img", { src: SignNowLogo });
        }
        return (_jsx(FileIcon, { extension: fileExt, type: fileType, labelColor: getFileTypeColor(fileType), labelUppercase: true }));
    }, [isDocusignTemplate, isSignNowTemplate, fileExt, fileType]);
    var getSignedUrl = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var throwError, _a, data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    throwError = function (message) {
                        toast('Failed to retrieve file: Please refresh and try again.', {
                            type: 'error',
                            position: toast.POSITION.BOTTOM_CENTER,
                        });
                        throw new Error("Failed to retrieve file url: ".concat(message));
                    };
                    if (!isKamanaFile) return [3 /*break*/, 4];
                    _a = signedUrl;
                    if (!_a) return [3 /*break*/, 2];
                    return [4 /*yield*/, fileUrlIsValid(signedUrl)];
                case 1:
                    _a = (_b.sent());
                    _b.label = 2;
                case 2:
                    if (_a)
                        return [2 /*return*/, signedUrl];
                    return [4 /*yield*/, getKamanaFileUrl({
                            id: file.kamanaFile.id,
                            kamanaDocCollectionType: file.kamanaFile.collectionType,
                            talentId: file.kamanaFile.talentId,
                        })];
                case 3:
                    data = _b.sent();
                    if (!data) {
                        throwError('Failed to retrieve signed url for file (Kamana)');
                    }
                    setSignedUrl(data.url);
                    return [2 /*return*/, data.url];
                case 4:
                    throwError('File is not a 3rd party file');
                    return [2 /*return*/, ''];
            }
        });
    }); }, [file.kamanaFile, fileUrlIsValid, getKamanaFileUrl, isKamanaFile, setSignedUrl, signedUrl]);
    var handleView = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function () { return __awaiter(void 0, void 0, void 0, function () {
            var previewFile, _a;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        previewFile = file;
                        if (!requiresSignedUrl) return [3 /*break*/, 2];
                        _a = [__assign({}, previewFile)];
                        _b = {};
                        return [4 /*yield*/, getSignedUrl()];
                    case 1:
                        previewFile = __assign.apply(void 0, _a.concat([(_b.url = _c.sent(), _b)]));
                        _c.label = 2;
                    case 2:
                        set(documentPreviewModalDataState, [previewFile]);
                        return [2 /*return*/];
                }
            });
        }); };
    }, [file, getSignedUrl, requiresSignedUrl]);
    var handleDownload = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var link, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    link = document.createElement('a');
                    if (!requiresSignedUrl) return [3 /*break*/, 2];
                    _a = link;
                    return [4 /*yield*/, getSignedUrl()];
                case 1:
                    _a.href = _b.sent();
                    return [3 /*break*/, 3];
                case 2:
                    link.href = file.url;
                    _b.label = 3;
                case 3:
                    link.download = file.name;
                    link.target = '_blank';
                    link.click();
                    return [2 /*return*/];
            }
        });
    }); }, [file.name, file.url, getSignedUrl, requiresSignedUrl]);
    var fileMenu = useMemo(function () { return (_jsx(Menu, { className: "FileCellDropdownContentMenu font-medium", onClick: function (e) {
            e.domEvent.preventDefault();
            e.domEvent.stopPropagation();
        }, children: _jsxs(Menu.ItemGroup, { title: _jsx("div", { className: "text-xs font-semibold", children: "File" }), children: [_jsx(Menu.Divider, {}), onRename && (_jsx(Menu.Item, { className: "px-5", onClick: function () { return setIsRenaming(true); }, children: "Rename" })), hasValidFileUrl && (_jsx(Menu.Item, { className: "px-5", onClick: handleView, children: "View" })), hasValidFileUrl && (_jsx(Menu.Item, { className: "px-5", onClick: handleDownload, children: "Download" })), onDelete && (_jsx(Menu.Item, { className: "px-5 text-negative", onClick: onDelete, children: "Delete" }))] }) })); }, [onRename, hasValidFileUrl, handleView, handleDownload, onDelete]);
    var sharedLabelStyle = "ml-2 font-medium relative break-all ".concat(isDocusignTemplate ? 'top-[-0.5em]' : 'top-[-0.2em]', " text-cell");
    var fileNameInput = useMemo(function () { return (_jsx("input", { className: sharedLabelStyle, type: "text", autoFocus: true, defaultValue: fileName, onBlur: function (e) {
            setIsRenaming(false);
            if (onRename) {
                onRename(e.target.value);
            }
        }, onClick: function (e) { return e.stopPropagation(); }, onKeyDown: function (e) {
            if (e.key === 'Enter') {
                e.currentTarget.blur();
            }
        } })); }, [fileName, onRename, sharedLabelStyle]);
    var fileNameTag = useCallback(function (label) { return (_jsxs("div", { className: "mr-2 inline-block pt-1 hover:cursor-pointer", children: [_jsx("span", { className: "inline-block ".concat(isDocusignTemplate ? 'h-6 w-6' : 'h-5 w-5'), children: fileIcon }), label] })); }, [fileIcon, isDocusignTemplate]);
    return isRenaming ? (fileNameTag(fileNameInput)) : (_jsx(Dropdown, { overlay: fileMenu, destroyPopupOnHide: true, trigger: ['click'], overlayClassName: "FileCellDropdown", children: fileNameTag(_jsx("span", { className: "".concat(sharedLabelStyle, " hover:underline"), children: fileName })) }));
};
export default React.memo(FileWithIconTag);
