var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Collapse } from 'antd';
import { cloneDeep, intersection, isArray, isEqual } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue } from 'recoil';
import { usePageApi } from 'src/hooks/api';
import { collectionNotHiddenSchemaMapSelector, detailModalSchemaGroups } from 'src/state';
import { RightArrowOutlinedIcon } from 'src/utils/icons/RightArrowOutlined';
import { CollectionType } from 'src/shared';
import ConfigureSchemaGroupsDropdown from './ConfigureSchemaGroupsDropdown';
import SchemaGroup from './SchemaGroup';
var SchemaGroupList = function (_a) {
    var collectionId = _a.collectionId, collectionType = _a.collectionType, canEditCollection = _a.canEditCollection, renderSchema = _a.renderSchema, onAddNewColumn = _a.onAddNewColumn, shiftDetailProps = _a.shiftDetailProps;
    var schemaMap = useRecoilValue(collectionNotHiddenSchemaMapSelector(collectionId));
    var _b = useRecoilState(detailModalSchemaGroups(collectionId)), collectionSchemaGroups = _b[0], setCollectionSchemaGroups = _b[1];
    var _c = useState(), expandedGroups = _c[0], setExpandedGroups = _c[1];
    // Set initial expanded groups
    useEffect(function () {
        if (collectionSchemaGroups.length && !expandedGroups) {
            setExpandedGroups(collectionSchemaGroups.map(function (_, groupIndex) { return "schema-group-".concat(groupIndex); }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collectionSchemaGroups]);
    var updateCollectionSettings = usePageApi().updateCollectionSettings;
    var updateSchemaGroups = useCallback(function (newSchemaGroups) { return __awaiter(void 0, void 0, void 0, function () {
        var prevSchemaGroups, settings;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    prevSchemaGroups = cloneDeep(collectionSchemaGroups);
                    setCollectionSchemaGroups(newSchemaGroups);
                    return [4 /*yield*/, updateCollectionSettings({
                            collectionId: collectionId,
                            settings: { schemaGroup: newSchemaGroups },
                        })];
                case 1:
                    settings = _a.sent();
                    if (!settings) {
                        setCollectionSchemaGroups(prevSchemaGroups);
                    }
                    else if (settings.schemaGroup && !isEqual(settings.schemaGroup, newSchemaGroups)) {
                        toast('Group order mismatch: Please refresh and try again.', {
                            type: 'error',
                            position: toast.POSITION.BOTTOM_CENTER,
                        });
                        setCollectionSchemaGroups(settings.schemaGroup);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collectionId, collectionSchemaGroups]);
    var onSchemaDragEnd = useCallback(function (_a) {
        var source = _a.source, destination = _a.destination;
        if (!destination)
            return;
        var temp = cloneDeep(collectionSchemaGroups);
        var sourceSchemaGroupIndex = collectionSchemaGroups.findIndex(function (_, groupIndex) { return "schema-group-".concat(groupIndex) === source.droppableId; });
        var destinationSchemaGroupIndex = collectionSchemaGroups.findIndex(function (_, groupIndex) { return "schema-group-".concat(groupIndex) === destination.droppableId; });
        if (sourceSchemaGroupIndex === -1 || destinationSchemaGroupIndex === -1)
            return;
        var reorderedSchema = temp[sourceSchemaGroupIndex].schemas.splice(source.index, 1)[0];
        temp[destinationSchemaGroupIndex].schemas.splice(destination.index, 0, reorderedSchema);
        updateSchemaGroups(temp);
    }, [collectionSchemaGroups, updateSchemaGroups]);
    var onDeleteSchemaGroup = useCallback(function (groupIndex) {
        if (groupIndex === 0)
            return;
        var temp = cloneDeep(collectionSchemaGroups);
        var schemasToMove = cloneDeep(temp[groupIndex].schemas);
        // Delete schema group
        temp.splice(groupIndex, 1);
        // Move schemas into last schema group
        temp[temp.length - 1].schemas = __spreadArray(__spreadArray([], temp[temp.length - 1].schemas, true), schemasToMove, true);
        updateSchemaGroups(temp);
    }, [collectionSchemaGroups, updateSchemaGroups]);
    var onMoveGroupUpDown = useCallback(function (groupIndex, isUp) {
        if ((groupIndex === 0 && isUp) ||
            (groupIndex === collectionSchemaGroups.length - 1 && !isUp)) {
            return;
        }
        var temp = cloneDeep(collectionSchemaGroups);
        var toIndex = isUp ? groupIndex - 1 : groupIndex + 1;
        var group = temp[groupIndex];
        temp.splice(groupIndex, 1);
        temp.splice(toIndex, 0, group);
        updateSchemaGroups(temp);
    }, [collectionSchemaGroups, updateSchemaGroups]);
    var onRenameSchemaGroup = useCallback(function (groupIndex, newGroupName) {
        if (newGroupName === collectionSchemaGroups[groupIndex].name)
            return;
        var temp = cloneDeep(collectionSchemaGroups);
        temp[groupIndex].name = newGroupName;
        updateSchemaGroups(temp);
    }, [collectionSchemaGroups, updateSchemaGroups]);
    var onEditSchemaGroupDescription = useCallback(function (groupIndex, newGroupDescription) {
        if (newGroupDescription === collectionSchemaGroups[groupIndex].description)
            return;
        var temp = cloneDeep(collectionSchemaGroups);
        temp[groupIndex].description = newGroupDescription;
        updateSchemaGroups(temp);
    }, [collectionSchemaGroups, updateSchemaGroups]);
    return (_jsxs(_Fragment, { children: [_jsx(DragDropContext, { onDragEnd: onSchemaDragEnd, children: _jsx(Collapse, { className: "SchemaGroupsWrapper", activeKey: expandedGroups, onChange: function (key) {
                        if (isArray(key)) {
                            setExpandedGroups(key);
                        }
                        else {
                            setExpandedGroups([key]);
                        }
                    }, expandIcon: function (_a) {
                        var isActive = _a.isActive;
                        return (_jsx("div", { className: "text-modalAccent hover:bg-gray-50", title: isActive ? 'Collapse' : 'Expand', children: isActive ? (_jsx(RightArrowOutlinedIcon, { className: "rotate-90" })) : (_jsx(RightArrowOutlinedIcon, {})) }));
                    }, expandIconPosition: "end", ghost: true, children: collectionSchemaGroups.map(function (_a, groupIndex) {
                        var groupName = _a.name, schemaIds = _a.schemas, description = _a.description;
                        // Hide group if no schema in group is visible due to permissions
                        var noVisibleSchemasWithinGroup = schemaIds.length > 0 &&
                            intersection(Object.keys(schemaMap), schemaIds).length === 0;
                        var isShiftCollectionFirstGroup = collectionType === CollectionType.SHIFT && groupIndex === 0;
                        //! First group is always visible for shift detail due to shift times
                        var shouldHideSchemaGroup = isShiftCollectionFirstGroup
                            ? false
                            : noVisibleSchemasWithinGroup;
                        var groupId = "schema-group-".concat(groupIndex);
                        return (_jsxs(React.Fragment, { children: [_jsx(SchemaGroup, { groupId: groupId, groupName: groupName, groupSchemaIds: schemaIds, groupDescription: description !== null && description !== void 0 ? description : '', schemas: schemaMap, isCollapsed: !(expandedGroups === null || expandedGroups === void 0 ? void 0 : expandedGroups.includes(groupId)), isFirstGroup: groupIndex === 0, canEditCollection: canEditCollection, onEditSchemaGroupDescription: function (newGroupDescription) {
                                        return onEditSchemaGroupDescription(groupIndex, newGroupDescription);
                                    }, renderSchema: renderSchema, shiftDetailProps: shiftDetailProps, hidden: shouldHideSchemaGroup }, groupId), !shouldHideSchemaGroup && _jsx("div", { className: "ModalDivider" })] }, groupId));
                    }) }) }), canEditCollection && (_jsxs(_Fragment, { children: [_jsx("div", { className: "pt-4" }), _jsx(ConfigureSchemaGroupsDropdown, { schemaGroups: collectionSchemaGroups, schemas: schemaMap, onAddNewSchemaGroup: function () {
                            setExpandedGroups(function (prevExpandedGroups) { return __spreadArray(__spreadArray([], (prevExpandedGroups !== null && prevExpandedGroups !== void 0 ? prevExpandedGroups : []), true), [
                                "schema-group-".concat(collectionSchemaGroups.length),
                            ], false); });
                            updateSchemaGroups(__spreadArray(__spreadArray([], collectionSchemaGroups, true), [
                                {
                                    name: 'New Group',
                                    schemas: [],
                                    description: null,
                                    translations: null,
                                },
                            ], false));
                        }, onSchemaDragEnd: onSchemaDragEnd, onMoveGroupUpDown: onMoveGroupUpDown, onDeleteSchemaGroup: onDeleteSchemaGroup, onRenameSchemaGroup: onRenameSchemaGroup, onAddNewColumn: onAddNewColumn
                            ? function (column) { return __awaiter(void 0, void 0, void 0, function () {
                                var schema, temp;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, onAddNewColumn(column)];
                                        case 1:
                                            schema = _a.sent();
                                            if (!schema)
                                                return [2 /*return*/];
                                            temp = cloneDeep(collectionSchemaGroups);
                                            temp[temp.length - 1].schemas.push(schema.id);
                                            updateSchemaGroups(temp);
                                            return [2 /*return*/, schema];
                                    }
                                });
                            }); }
                            : undefined, collectionId: collectionId }), _jsx("div", { className: "pt-4" })] }))] }));
};
export default React.memo(SchemaGroupList);
