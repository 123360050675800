var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useRecoilValue } from 'recoil';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTasksApi } from 'src/hooks/api';
import { collectionTableTypeSelector } from 'src/state';
import { CollectionTableType, CollectionType } from 'src/shared';
import FileRecordItem from './FileRecordItem';
var FileReorderSection = function (_a) {
    var selectedRecordIds = _a.selectedRecordIds, collectionId = _a.collectionId, selectedFilesInOrder = _a.selectedFilesInOrder, onDragEnd = _a.onDragEnd, onUnSelectFile = _a.onUnSelectFile;
    var useGetRecordTitles = useTasksApi().useGetRecordTitles;
    var collectionType = useRecoilValue(collectionTableTypeSelector({ type: CollectionTableType.TABLE, collectionId: collectionId }));
    var recordTitles = useGetRecordTitles({
        body: {
            recordIds: selectedRecordIds,
        },
        options: {
            // Disable this API call for document collections, since file name is the title
            enabled: collectionType !== CollectionType.DOCUMENT,
        },
    }).data;
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "font-medium mb-2", children: "Choose the order in which your PDFs will appear in the merged file" }), _jsx(DragDropContext, { onDragEnd: onDragEnd, children: _jsx(Droppable, { droppableId: "droppable_file_records", children: function (provided) { return (_jsxs("div", __assign({ className: "space-y-2 max-h-[300px] overflow-y-auto pr-2" }, provided.droppableProps, { ref: provided.innerRef, children: [selectedFilesInOrder.map(function (file, index) {
                                return (_jsx(Draggable, { draggableId: "".concat(file.id, "+").concat(index), index: index, children: function (provided) {
                                        var _a;
                                        return (_jsx(FileRecordItem, { draggableProvided: provided, item: file, title: (_a = recordTitles === null || recordTitles === void 0 ? void 0 : recordTitles[file.id]) !== null && _a !== void 0 ? _a : '', onUnSelectFile: function () { return onUnSelectFile(index); } }, "".concat(file.id, "+").concat(index)));
                                    } }, "".concat(file.id, "+").concat(index)));
                            }), provided.placeholder] }))); } }) })] }));
};
export default React.memo(FileReorderSection);
