var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Select, Tag } from 'antd';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { cloneDeep } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { UploadUrlType } from 'src/hooks/api/useUploadApi';
import { useRecoilValue } from 'recoil';
import { accountIdSelector } from 'src/state';
var Option = Select.Option, OptGroup = Select.OptGroup;
import './NewsFeed.scss';
import octoClient from '../../../services/octoClient';
import { NewsFeedSendtoType } from '../../../shared';
import ComponentWithUpload, { UploadFileType } from '../../ComponentWithUpload';
import { renderAuthorEmployeeIcon } from '../../../utils/Account';
import { useFeed } from '../../../hooks/api/useFeedApi';
var catOptions = [
    { title: 'All users', id: 'All users' },
    { title: 'Specific users', id: 'Specific users' },
    { title: 'Users in a role', id: 'Users in a role' },
    { title: 'Users in a location', id: 'Users in a location' },
];
var NewsFeedPostMessageBox = function (props) {
    var sendDataBack = props.sendDataBack, author = props.author;
    var accountId = useRecoilValue(accountIdSelector);
    var getAccessTokenSilently = useAuth0().getAccessTokenSilently;
    var usePostMessage = useFeed().usePostMessage;
    var postMessageMutation = usePostMessage();
    var message = useState('')[0];
    var acceptedFileType = useMemo(function () { return [UploadFileType.IMAGE]; }, []);
    var _a = useState(null), imageUrl = _a[0], setImageUrl = _a[1];
    var _b = useState(false), fileUploading = _b[0], setFileUploading = _b[1];
    var _c = useState(catOptions), options = _c[0], setOptions = _c[1];
    var _d = useState('Select audience'), currentCategory = _d[0], setCurrentCategory = _d[1];
    var _e = useState([]), selectedItems = _e[0], setSelectedItems = _e[1];
    var _f = useState(false), selectorOpen = _f[0], setSelectorOpen = _f[1];
    var _g = useState({
        roleIds: [],
        locationIds: [],
        userIds: [],
    }), builderState = _g[0], setBuilderState = _g[1];
    var messageRef = useRef(message);
    var builderStateRef = useRef(builderState);
    var optionsRef = useRef(options);
    var imageUrlRef = useRef(imageUrl);
    useEffect(function () {
        imageUrlRef.current = imageUrl;
    }, [imageUrl]);
    useEffect(function () {
        builderStateRef.current = builderState;
    }, [builderState]);
    useEffect(function () {
        messageRef.current = message;
    }, [message]);
    useEffect(function () {
        optionsRef.current = options;
    }, [options]);
    var mapToOptions = useCallback(function (objs) {
        var tempOptions = cloneDeep(optionsRef.current);
        var opts = [];
        objs.map(function (obj) {
            var title = 'name' in obj ? obj.name : obj.title;
            opts.push({ title: title, id: obj.recordId });
        });
        tempOptions = opts;
        setOptions(tempOptions);
    }, []);
    var getLocations = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var resp, _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _b = (_a = octoClient).getLocationSimpleList;
                    _c = [{ accountId: accountId }];
                    return [4 /*yield*/, getAccessTokenSilently()];
                case 1: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                case 2:
                    resp = _d.sent();
                    if (!resp)
                        return [2 /*return*/];
                    mapToOptions(resp === null || resp === void 0 ? void 0 : resp.locations);
                    return [2 /*return*/];
            }
        });
    }); }, [accountId, getAccessTokenSilently, mapToOptions]);
    var getUsers = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var resp, _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _b = (_a = octoClient).getSimpleUserList;
                    _c = [{ accountId: accountId }];
                    return [4 /*yield*/, getAccessTokenSilently()];
                case 1: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                case 2:
                    resp = _d.sent();
                    if (!resp)
                        return [2 /*return*/];
                    mapToOptions(resp.users);
                    return [2 /*return*/];
            }
        });
    }); }, [accountId, getAccessTokenSilently, mapToOptions]);
    var getRoles = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var resp, _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _b = (_a = octoClient).getRoleSimpleList;
                    _c = [{ accountId: accountId }];
                    return [4 /*yield*/, getAccessTokenSilently()];
                case 1: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                case 2:
                    resp = _d.sent();
                    if (!resp)
                        return [2 /*return*/];
                    mapToOptions(resp.roles);
                    return [2 /*return*/];
            }
        });
    }); }, [accountId, getAccessTokenSilently, mapToOptions]);
    var itemSelected = useCallback(function (val) {
        var tempBuilderState = cloneDeep(builderStateRef.current);
        var obj = options.find(function (opt) { return opt.title == val; });
        if (!tempBuilderState.currentState) {
            if (val === catOptions[0].title) {
                tempBuilderState.currentState = NewsFeedSendtoType.ALL;
                setOptions([]);
                setSelectorOpen(false);
            }
            else if (val === catOptions[1].title) {
                tempBuilderState.currentState = NewsFeedSendtoType.USER;
                setCurrentCategory('Select users');
                getUsers();
            }
            else if (val === catOptions[2].title) {
                setCurrentCategory('Select roles');
                tempBuilderState.currentState = NewsFeedSendtoType.ROLE;
                getRoles();
            }
            else if (val === catOptions[3].title) {
                setCurrentCategory('Select locations');
                tempBuilderState.currentState = NewsFeedSendtoType.LOCATION;
                getLocations();
            }
        }
        if (obj) {
            switch (builderState.currentState) {
                case NewsFeedSendtoType.USER:
                    tempBuilderState.userIds.push(obj.id);
                    break;
                case NewsFeedSendtoType.LOCATION:
                    tempBuilderState.locationIds.push(obj.id);
                    break;
                case NewsFeedSendtoType.ROLE:
                    tempBuilderState.roleIds.push(obj.id);
                    break;
            }
        }
        setBuilderState(tempBuilderState);
    }, [builderState.currentState, getLocations, getRoles, getUsers, options]);
    var onUploadFile = useCallback(function (info) {
        if (info.file.response.data.url == '')
            return;
        var tempImgUrl = cloneDeep(imageUrlRef.current);
        tempImgUrl = info.file.response.data.url;
        setImageUrl(tempImgUrl);
        setFileUploading(false);
    }, []);
    var resetComponent = useCallback(function () {
        setBuilderState({
            roleIds: [],
            locationIds: [],
            userIds: [],
        });
        setOptions(catOptions);
        setCurrentCategory('Select Audience');
        setSelectedItems([]);
        setImageUrl(null);
    }, []);
    var buildSendToText = useCallback(function (object) {
        var ids = [];
        var text = '';
        switch (object) {
            case 'user':
                ids = builderState.userIds;
                text += 'Specific users: ';
                break;
            case 'role':
                ids = builderState.roleIds;
                text += 'Users in the ';
                break;
            case 'location':
                ids = builderState.locationIds;
                text += 'Users in the ';
                break;
        }
        text += ids.map(function (id) {
            var _a;
            var title = (_a = options.find(function (opt) { return opt.id == id; })) === null || _a === void 0 ? void 0 : _a.title;
            if (title) {
                return title;
            }
        });
        text = text.replaceAll(',', ', ');
        text += object != 'user' ? ' ' + object + (ids.length > 1 ? 's' : '') : '';
        return text;
    }, [builderState.locationIds, builderState.roleIds, builderState.userIds, options]);
    var postMessage = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var div, innerText, ids, sendToText;
        return __generator(this, function (_a) {
            div = document.getElementById('post-message-input');
            innerText = div.innerText;
            if (!innerText || !builderState || !builderState.currentState)
                return [2 /*return*/];
            ids = [];
            sendToText = 'All users';
            switch (builderState.currentState) {
                case NewsFeedSendtoType.ROLE:
                    ids = builderState.roleIds;
                    sendToText = buildSendToText('role');
                    break;
                case NewsFeedSendtoType.LOCATION:
                    ids = builderState.locationIds;
                    sendToText = buildSendToText('location');
                    break;
                case NewsFeedSendtoType.USER:
                    ids = builderState.userIds;
                    sendToText = buildSendToText('user');
                    break;
            }
            postMessageMutation.mutate({
                body: innerText,
                imageUrl: imageUrl,
                sendToType: builderState.currentState,
                sendToIds: ids,
                sendToText: sendToText,
            }, {
                onSuccess: function (resp) {
                    div.innerText = '';
                    setImageUrl('');
                    if (resp)
                        sendDataBack(resp);
                    resetComponent();
                },
                onError: function () { },
            });
            return [2 /*return*/];
        });
    }); }, [builderState, postMessageMutation, imageUrl, buildSendToText, sendDataBack, resetComponent]);
    var tagRender = useCallback(function (props) {
        var value = props.value;
        var color = 'black';
        var backgroundColor = 'white';
        var borderColor = '#F4F4F5';
        var catTitles = catOptions.map(function (opt) { return opt.title; });
        if (catTitles.includes(String(value))) {
            color = 'white';
            backgroundColor = '#6D82E9';
            borderColor = '#6D82E9';
        }
        return (_jsx(_Fragment, { children: _jsx(Tag, { onMouseDown: function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                }, style: {
                    marginRight: 3,
                    color: color,
                    fontWeight: 500,
                    backgroundColor: backgroundColor,
                    borderRadius: '8px',
                    borderColor: borderColor,
                    height: '24px',
                    fontFamily: 'AvenirNext-DemiBold',
                }, closeIcon: _jsx(CloseOutlined, { style: { color: 'grey', marginLeft: '2px' } }), children: value }) }));
    }, []);
    return useMemo(function () {
        return (_jsxs("div", { className: "postBlock shadow-block", children: [_jsxs("div", { className: "postRow", children: [renderAuthorEmployeeIcon(author), _jsx("span", { className: "textarea bg-white", placeholder: "Write something here...", id: "post-message-input", role: "textbox", contentEditable: true, suppressContentEditableWarning: true, children: message }), _jsx("button", { className: "postMessageBtn", onClick: postMessage, children: "Post" })] }), _jsxs("div", { className: "postRow2", children: [_jsx("div", { className: "emptyFiller" }), _jsx(Select, { placeholder: "Select audience", autoClearSearchValue: true, mode: "multiple", allowClear: true, className: 'select', tagRender: function (props) { return tagRender(props); }, open: selectorOpen, style: { width: '100%' }, value: selectedItems, onSelect: itemSelected, onChange: function (values) {
                                setSelectedItems(values);
                                if (values.length == 0)
                                    resetComponent();
                            }, onFocus: function () { return setSelectorOpen(true); }, onBlur: function () {
                                setSelectorOpen(false);
                            }, notFoundContent: _jsx(_Fragment, { children: "No Options" }), children: _jsx(OptGroup, { label: currentCategory, children: options.map(function (option) {
                                    return (_jsx(Option, { value: option.title, children: _jsx("div", { children: _jsx("span", { children: option.title }) }) }, option.id));
                                }) }) }), _jsx("span", { className: "addPhoto", children: _jsxs(ComponentWithUpload, { acceptedFileType: acceptedFileType, successAction: onUploadFile, type: UploadUrlType.Message, children: [_jsx("button", { className: "secondaryBtn", onClick: function () { return setFileUploading(true); }, children: "+ Add a photo" }), _jsx("div", { className: "fileAttached", children: imageUrl
                                            ? '1 file attached'
                                            : fileUploading
                                                ? 'File uploading.. one moment'
                                                : '' })] }) })] })] }));
    }, [
        author,
        message,
        postMessage,
        selectorOpen,
        selectedItems,
        itemSelected,
        currentCategory,
        options,
        acceptedFileType,
        onUploadFile,
        imageUrl,
        fileUploading,
        tagRender,
        resetComponent,
    ]);
};
export default React.memo(NewsFeedPostMessageBox);
