var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { CHAT_FEATURE_GATING_KEY, DASHBOARD_FEATURE_GATING_KEY, NEEDS_COVERAGE, NEW_SHIFT_TEMPLATE, SPLIT_NEEDS_COVERAGE_PERMISSION, } from 'src/components/FeatureGatingKeyConstant';
import LoadingStateAnimation from 'src/ui/LoadingStateAnimation';
import { useSetting } from 'src/hooks/api';
import SettingsState from 'src/hooks/state/Settings';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import { TEAMBRIDGE_TITLE } from 'src/shared';
import { GeneralPermissionKey, GeneralPermissions, OwnerEditableGeneralPermissions, } from '../../../Constants';
import AccessDetailDivider from './AccessDetailDivider';
import AccessDetailHelperBanner from './AccessDetailHelperBanner';
import AccessDetailGroupChild from './AccessDetailListItem/AccessDetailGroupChild';
import AccessDetailGroupItem from './AccessDetailListItem/AccessDetailGroupItem';
var AccessManagementTab = function () {
    var _a = SettingsState.useContainer(), accessGroupDetail = _a.accessGroupDetail, handleGeneralPermissionChange = _a.handleGeneralPermissionChange;
    var generalPermissions = accessGroupDetail === null || accessGroupDetail === void 0 ? void 0 : accessGroupDetail.generalPermissions;
    var isDashboardFeatureEnabled = useRecoilValue(featureEnableStatusState(DASHBOARD_FEATURE_GATING_KEY));
    var isSplitNeedsCoveragePermission = useRecoilValue(featureEnableStatusState(SPLIT_NEEDS_COVERAGE_PERMISSION));
    var isChatFeatureEnabled = useRecoilValue(featureEnableStatusState(CHAT_FEATURE_GATING_KEY));
    var isNewShiftTemplateEnabled = useRecoilValue(featureEnableStatusState(NEW_SHIFT_TEMPLATE));
    var isNeedsCoverageFeatureEnabled = useRecoilValue(featureEnableStatusState(NEEDS_COVERAGE));
    var isNeedsCoverageEnabled = isNeedsCoverageFeatureEnabled && isNewShiftTemplateEnabled;
    var onHandleCheck = useCallback(function (id, checked, linkedPermissionIds //also set these ids to checked
    ) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!id)
                        return [2 /*return*/];
                    return [4 /*yield*/, handleGeneralPermissionChange(id, checked, linkedPermissionIds)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [handleGeneralPermissionChange]);
    var isOwner = (accessGroupDetail === null || accessGroupDetail === void 0 ? void 0 : accessGroupDetail.type) === 'owner';
    return (_jsxs("div", { className: "AccessTabContainer", children: [_jsx(AccessDetailHelperBanner, { title: 'Access Management', description: "".concat(TEAMBRIDGE_TITLE, " gives you the ultimate access control. Here you can determine which groups of users can create, delete, view, edit which type of information.") }), _jsx(GroupNameInputArea, {}), _jsx(AccessDetailDivider, {}), (isOwner ? OwnerEditableGeneralPermissions : GeneralPermissions).map(function (group, index) { return (_jsxs("div", { className: "pt-5", children: [_jsx("span", { className: "AccessMainTitle", children: group.title }), group.permissions.map(function (permission, index) {
                        var _a, _b;
                        var validPermissionChildren = (_a = permission.children) === null || _a === void 0 ? void 0 : _a.filter(function (child) {
                            return !((child.id === GeneralPermissionKey.DASHBOARD &&
                                !isDashboardFeatureEnabled) ||
                                (child.id === GeneralPermissionKey.CHAT &&
                                    !isChatFeatureEnabled) ||
                                (child.id === GeneralPermissionKey.NEEDS_EDIT_PERMISSION &&
                                    !isNeedsCoverageEnabled) ||
                                (child.id ===
                                    GeneralPermissionKey.COVERAGE_EDIT_PERMISSION &&
                                    !isNeedsCoverageEnabled) ||
                                (child.id === GeneralPermissionKey.RATIO_EDIT_PERMISSION &&
                                    !isNeedsCoverageEnabled) ||
                                (child.id ===
                                    GeneralPermissionKey.EXPECTED_EDIT_PERMISSION &&
                                    !isNeedsCoverageEnabled) ||
                                (child.id ===
                                    GeneralPermissionKey.NEEDS_COVERAGE_VIEW_ONLY &&
                                    !isNeedsCoverageEnabled) ||
                                (child.id ===
                                    GeneralPermissionKey.NEEDS_COVERAGE_VIEW_ONLY &&
                                    !isNeedsCoverageEnabled) ||
                                (child.id === GeneralPermissionKey.NEEDS_COVERAGE &&
                                    !isNeedsCoverageEnabled) ||
                                (child.id == GeneralPermissionKey.SCHEDULE_TEMPLATE &&
                                    !isNewShiftTemplateEnabled));
                        });
                        return (_jsxs("div", { className: 'pt-2', children: [(!!(validPermissionChildren === null || validPermissionChildren === void 0 ? void 0 : validPermissionChildren.length) ||
                                    !('children' in permission)) && (_jsx(AccessDetailGroupItem, { leadingIcon: permission.icon, title: permission.title, subtitle: permission.description, onToggle: permission.id
                                        ? function (e) {
                                            return onHandleCheck(permission.id, e.target.checked, permission.reverseLinkedIds);
                                        }
                                        : undefined, defaultChecked: permission.id
                                        ? generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions[permission.id]
                                        : undefined })), validPermissionChildren === null || validPermissionChildren === void 0 ? void 0 : validPermissionChildren.filter(function (child) {
                                    return child.id !==
                                        GeneralPermissionKey.NEEDS_COVERAGE_VIEW_ONLY ||
                                        isSplitNeedsCoveragePermission;
                                }).map(function (child) {
                                    var _a, _b;
                                    var checked = child.id
                                        ? generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions[child.id]
                                        : undefined;
                                    if (child.linkedIds) {
                                        checked =
                                            checked ||
                                                ((_a = child.linkedIds) === null || _a === void 0 ? void 0 : _a.some(function (id) { return generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions[id]; }));
                                    }
                                    return (_jsx(React.Fragment, { children: _jsx(AccessDetailGroupChild, { title: child.title, subTitle: child.description, onCheck: child.id
                                                ? function (e) {
                                                    return onHandleCheck(child.id, e.target.checked, child.reverseLinkedIds ||
                                                        undefined);
                                                }
                                                : undefined, isParentDisabled: (_b = child.linkedIds) === null || _b === void 0 ? void 0 : _b.some(function (id) { return generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions[id]; }), checked: checked }) }, child.id));
                                }), !((_b = permission.children) === null || _b === void 0 ? void 0 : _b.length) && _jsx(AccessDetailDivider, {})] }, "".concat(permission.id, "-").concat(index)));
                    })] }, "".concat(group.title, "-").concat(index))); })] }));
};
var GroupNameInputArea = function () {
    var _a = SettingsState.useContainer(), accessGroupDetail = _a.accessGroupDetail, setAccessGroupDetail = _a.setAccessGroupDetail;
    var useUpdateAccessGroupMutation = useSetting().useUpdateAccessGroupMutation;
    var _b = useUpdateAccessGroupMutation(), isLoading = _b.isLoading, isError = _b.isError, mutateAsync = _b.mutateAsync;
    var onHandleGroupNameChange = useCallback(function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var newName;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!accessGroupDetail)
                        return [2 /*return*/];
                    newName = e.target.value.trim();
                    if (newName === (accessGroupDetail === null || accessGroupDetail === void 0 ? void 0 : accessGroupDetail.name))
                        return [2 /*return*/];
                    return [4 /*yield*/, mutateAsync({ accessGroupId: accessGroupDetail === null || accessGroupDetail === void 0 ? void 0 : accessGroupDetail.id, name: newName })];
                case 1:
                    _a.sent();
                    setAccessGroupDetail(__assign(__assign({}, accessGroupDetail), { name: newName }));
                    return [2 /*return*/];
            }
        });
    }); }, [accessGroupDetail, mutateAsync, setAccessGroupDetail]);
    return (_jsxs("div", { children: [_jsx("div", { className: "mb-4", children: _jsx("span", { className: "AccessMainTitle", children: "Access Group Name" }) }), _jsxs("div", { className: "flex items-center space-x-3", children: [_jsx("input", { defaultValue: accessGroupDetail === null || accessGroupDetail === void 0 ? void 0 : accessGroupDetail.name, placeholder: "Enter Access Group Name", className: "AccessDetailCustomInput ml-[0.15rem] h-10 w-[50%] rounded-md bg-gray-50 p-3 font-medium ", onBlur: onHandleGroupNameChange, onKeyDown: function (e) {
                            if (e.key === 'Enter') {
                                e.currentTarget.blur();
                            }
                        } }), _jsx(LoadingStateAnimation, { isLoading: isLoading, loadingFailed: isError })] })] }));
};
export default React.memo(AccessManagementTab);
