import { useRecoilValue } from 'recoil';
import { accountIdSelector } from 'src/state';
import { useFetch } from '.';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
var useMetricBarApi = function () {
    var customFetch = useFetch().request;
    var accountId = useRecoilValue(accountIdSelector);
    var getAvailableMetrics = function () {
        return customFetch({
            method: 'GET',
            url: "metric_bars/static_list/".concat(accountId),
        });
    };
    var addMetricBarEntry = function (body) {
        return customFetch({
            method: 'POST',
            url: "metric_bars/add_metric",
        }, body);
    };
    var updateMetricBarEntry = function (body) {
        return customFetch({
            method: 'POST',
            url: "metric_bars/update_metric",
        }, body);
    };
    var deleteMetricBarEntry = function (metricId) {
        return customFetch({
            method: 'POST',
            url: "metric_bars/delete_metric/".concat(metricId),
        });
    };
    var recalculateMetricBar = function (blockId) {
        return customFetch({
            method: 'POST',
            url: "metric_bars/calculate/".concat(blockId),
        });
    };
    return {
        getAvailableMetrics: getAvailableMetrics,
        addMetricBarEntry: addMetricBarEntry,
        updateMetricBarEntry: updateMetricBarEntry,
        deleteMetricBarEntry: deleteMetricBarEntry,
        recalculateMetricBar: recalculateMetricBar,
    };
};
export default useMetricBarApi;
