import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Tooltip } from 'antd';
import React, { useCallback, useRef, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './PhoneInputCell.scss';
var PhoneInputCell = function (props) {
    var _a, _b;
    var phone = props.phone, editCell = props.editCell, canEdit = props.canEdit, configuration = props.configuration;
    var showExtension = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.showExtension) !== null && _a !== void 0 ? _a : true;
    var phoneSplit = phone.split(' ext.');
    var ext = showExtension ? ((_b = phoneSplit[1]) !== null && _b !== void 0 ? _b : '') : '';
    var _c = useState(!phone), showEmptyPlaceholder = _c[0], setShowEmptyPlaceholder = _c[1];
    var _d = useState(false), autoFocus = _d[0], setAutoFocus = _d[1];
    var _e = useState(phone), phoneInput = _e[0], setPhoneInput = _e[1];
    var _f = useState(0), formattedPhoneInputLength = _f[0], setFormattedPhoneInputLength = _f[1];
    var _g = useState(0), expectedPhoneLength = _g[0], setExpectedPhoneLength = _g[1];
    var _h = useState(true), isInputValid = _h[0], setIsInputValid = _h[1];
    var _j = useState(false), isOverlayVisible = _j[0], setIsOverlayVisible = _j[1];
    var firstTimeClickOut = useRef(true);
    var onEdit = useCallback(function () {
        setShowEmptyPlaceholder(!phoneInput);
        // pass phone validation
        if (formattedPhoneInputLength === expectedPhoneLength || phoneInput === '') {
            if (phone === phoneInput) {
                setIsOverlayVisible(false);
                setIsInputValid(true);
                return;
            }
            setIsInputValid(true);
            var extensionText = showExtension && ext.length > 0 ? " ext.".concat(ext) : '';
            var newPhoneValue = !phoneInput
                ? ''
                : "+".concat(phoneInput.replace('+', '')).concat(extensionText);
            editCell === null || editCell === void 0 ? void 0 : editCell(newPhoneValue);
            setIsOverlayVisible(false);
        }
        // fail phone validation
        else {
            setIsInputValid(false);
            // second time click overlay area, set everything to original status
            if (firstTimeClickOut.current === false) {
                setIsOverlayVisible(false);
                setIsInputValid(true);
                setPhoneInput(phone);
                setShowEmptyPlaceholder(!phone);
            }
            else {
                // first time click overlay area
                firstTimeClickOut.current = false;
                return;
            }
        }
    }, [
        editCell,
        expectedPhoneLength,
        formattedPhoneInputLength,
        phone,
        phoneInput,
        ext,
        showExtension,
    ]);
    if (showEmptyPlaceholder) {
        return (_jsx("div", { className: "EmptyCellPlacerholder", onClick: function () {
                setShowEmptyPlaceholder(false);
                setAutoFocus(true);
                setIsInputValid(true);
            }, children: "Insert Value" }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, { placement: "top", title: "Invalid phone format, can not be saved!", destroyTooltipOnHide: true, open: !isInputValid, children: _jsx(PhoneInput, { inputProps: {
                        autoFocus: autoFocus,
                    }, containerClass: "PhoneInputContainer", containerStyle: { border: !isInputValid ? '1px solid red' : 'none' }, inputClass: "PhoneInput", dropdownClass: "CountryDropdown", value: phoneInput, country: "us", onChange: function (value, countryData, e, formattedValue) {
                        setPhoneInput(value);
                        setFormattedPhoneInputLength(formattedValue.length);
                        if (expectedPhoneLength !== countryData.format.length) {
                            setExpectedPhoneLength(countryData.format.length);
                        }
                    }, disabled: !canEdit, onFocus: function () {
                        firstTimeClickOut.current = true;
                        setIsOverlayVisible(true);
                    }, onKeyDown: function (e) {
                        if (e.key === 'Enter') {
                            onEdit();
                        }
                    } }) }), _jsx("div", { className: "fpOverlay", onClick: onEdit, style: { display: isOverlayVisible ? 'block' : 'none' } })] }));
};
export default React.memo(PhoneInputCell);
