var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useMutation, useQuery } from 'react-query';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { accountIdSelector, scheduleTableRowState } from 'src/state';
import { DefaultShiftRequestsPagePagination } from 'src/shared';
import { schedulePageSize } from 'src/services';
import { useTimeZone } from 'src/hooks/component';
import { useFetch } from './useFetch';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
var useScheduleApi = function () {
    var _a = useFetch(), customFetch = _a.request, toastRequest = _a.toastRequest;
    var accountId = useRecoilValue(accountIdSelector);
    var timeZone = useTimeZone().timeZone;
    //* Scheduling Block *//
    var useFetchAvailableSchedulingStatOptions = function () {
        return useQuery(['availableSchedulingStatOptions'], function () {
            return customFetch({
                method: 'POST',
                url: 'scheduling/available_stats',
            });
        });
    };
    var useFetchSchedulingStats = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: 'scheduling/stats',
            }, request);
        });
    };
    var postDuplicateShifts = function (request) {
        return toastRequest({
            method: 'POST',
            url: 'scheduling/duplicate_shift',
        }, request, '', 'Shifts successfully duplicated!', '');
    };
    var saveRecurringRuleAndScheduleShifts = function (request) {
        return toastRequest({
            method: 'POST',
            url: 'recurrence',
        }, request, '', 'Shifts successfully scheduled!', '');
    };
    var editRecurringShift = function (request) {
        return toastRequest({
            method: 'POST',
            url: 'recurrence/process_shift_edit',
        }, request, '', 'Shifts successfully scheduled!', '');
    };
    var saveScheduleDisplaySettings = function (request) {
        return customFetch({
            method: 'POST',
            url: 'scheduling/apply_display_settings',
        }, request);
    };
    //* Shift Requests Block *//
    var useFetchShiftRequestsBlockQuery = function (params) {
        return useQuery(['shiftRequests', params], function () { return fetchShiftRequestsBlock(params); }, {
            enabled: !!params.blockId,
            //clears the cache when there are 0 mounted components
            cacheTime: 0,
        });
    };
    var fetchShiftRequestsBlock = function (params) {
        var _a;
        return customFetch({
            method: 'POST',
            url: 'pages/list_blocks',
        }, __assign(__assign({ accountId: accountId, contentType: 'shift_requests' }, params), { pagination: (_a = params.pagination) !== null && _a !== void 0 ? _a : DefaultShiftRequestsPagePagination }));
    };
    var useGetUnpublishedShiftCountQuery = function (request) {
        return useQuery(['unpublishedShiftCount', JSON.stringify(request.body)], function () {
            return customFetch({
                method: 'POST',
                url: 'scheduling/unpublished_shift_count',
            }, request.body);
        }, __assign({}, request.options));
    };
    var fetchShiftRequestsClaimerMetadata = function (shiftRIds, shiftGroupRIds) {
        return customFetch({
            method: 'POST',
            url: 'shift_requests/claimer_meta',
        }, {
            shiftRids: shiftRIds,
            shiftGroupRids: shiftGroupRIds,
        });
    };
    var useShiftRequestAction = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: 'shift_requests/actions',
            }, __assign(__assign({}, request), { accountId: accountId }));
        });
    };
    var useShfitReleaseApprovalAction = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: 'scheduling/release_shift_approval',
            }, request);
        });
    };
    var useCheckUserConflictsOnShiftRequest = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: 'shift_requests/check_conflicts',
            }, __assign(__assign({}, request), { accountId: accountId }));
        });
    };
    //* Shift Release Approval Block *//
    var useFetchShiftReleaseApprovalBlockQuery = function (params) {
        return useQuery(['shiftReleaseApprovals', params], function () { return fetchShiftReleaseApprovalBlock(params); }, {
            enabled: !!params.blockId,
        });
    };
    var fetchShiftReleaseApprovalBlock = function (params) {
        var _a;
        return customFetch({
            method: 'POST',
            url: 'pages/list_blocks',
        }, __assign(__assign({ accountId: accountId, contentType: 'release_requests' }, params), { pagination: (_a = params.pagination) !== null && _a !== void 0 ? _a : DefaultShiftRequestsPagePagination }));
    };
    var usePublishShifts = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'scheduling/publish',
            }, __assign(__assign({}, request), { accountId: accountId }), '', 'Shifts successfully published!', '');
        });
    };
    var useFetchScheduleEvaluation = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: 'autoscheduler/evaluate',
            }, __assign(__assign({}, request), { accountId: accountId }));
        });
    };
    var useCopySchedule = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'scheduling/copy_schedule',
            }, request);
        });
    };
    var useClearSchedule = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'scheduling/clear_schedule',
            }, request);
        });
    };
    var useSetShiftOpenCount = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'shifts/set_open_count',
            }, request, '', 'Open count successfully changed!', '');
        });
    };
    var useListScheduleBlockQuery = function (request) {
        var _a;
        var body = request.body;
        //! Dont refetch on statsOptions, templateFilter, colorCoding, startDayOfWeek, showShiftTimes, cardHeight, bottomBarSettings, schemaOptions change
        var _b = body.schedulingDisplaySettings, statsOptions = _b.statsOptions, templateFilter = _b.templateFilter, colorCoding = _b.colorCoding, startDayOfWeek = _b.startDayOfWeek, showShiftTimes = _b.showShiftTimes, cardHeight = _b.cardHeight, bottomBarSettings = _b.bottomBarSettings, viewDisplaySettings = __rest(_b, ["statsOptions", "templateFilter", "colorCoding", "startDayOfWeek", "showShiftTimes", "cardHeight", "bottomBarSettings"]);
        var _c = body.displaySettings, schemaOptions = _c.schemaOptions, blockDisplaySettings = __rest(_c, ["schemaOptions"]);
        return useQuery([
            'listBlock',
            body.blockId,
            body.start,
            body.end,
            blockDisplaySettings,
            viewDisplaySettings,
            body.searchQuery,
        ], function () { return listScheduleBlock(body); }, {
            enabled: !!Object.values(body.schedulingDisplaySettings).length &&
                !!Object.values(body.displaySettings).length &&
                ((_a = request.options) === null || _a === void 0 ? void 0 : _a.enabled),
        });
    };
    var listScheduleBlock = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (body) {
            var _a, _b;
            var requestBody = body;
            if (!requestBody.pagination) {
                var currentSize = (_b = (_a = snapshot
                    .getLoadable(scheduleTableRowState(requestBody.blockId))
                    .valueMaybe()) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0;
                requestBody = __assign(__assign({}, body), { pagination: {
                        page: 0,
                        size: 
                        // Subtract 1 because of unassigned
                        currentSize - 1 > schedulePageSize
                            ? Math.ceil((currentSize - 1) / schedulePageSize) *
                                schedulePageSize
                            : schedulePageSize,
                    } });
            }
            return customFetch({
                method: 'POST',
                url: 'pages/list_schedule',
            }, __assign(__assign({}, requestBody), { accountId: accountId }));
        };
    }, [accountId, customFetch]);
    var useListScheduleReverseQuery = function (request, collectionId) {
        var _a;
        var body = request.body;
        //! Dont refetch on statsOptions, templateFilter, colorCoding, startDayOfWeek, showShiftTimes, cardHeight, bottomBarSettings, schemaOptions change
        var _b = body.schedulingDisplaySettings, statsOptions = _b.statsOptions, templateFilter = _b.templateFilter, colorCoding = _b.colorCoding, startDayOfWeek = _b.startDayOfWeek, showShiftTimes = _b.showShiftTimes, cardHeight = _b.cardHeight, bottomBarSettings = _b.bottomBarSettings, viewDisplaySettings = __rest(_b, ["statsOptions", "templateFilter", "colorCoding", "startDayOfWeek", "showShiftTimes", "cardHeight", "bottomBarSettings"]);
        var _c = body.displaySettings, schemaOptions = _c.schemaOptions, blockDisplaySettings = __rest(_c, ["schemaOptions"]);
        return useQuery([
            'reverseCollection',
            collectionId,
            body.recordId,
            body.standardCollectionComponent,
            body.start,
            body.end,
            blockDisplaySettings,
            viewDisplaySettings,
            body.searchQuery,
        ], function () { return listScheduleReverseCollection(body); }, {
            enabled: !!Object.values(body.schedulingDisplaySettings).length &&
                !!Object.values(body.displaySettings).length &&
                ((_a = request.options) === null || _a === void 0 ? void 0 : _a.enabled),
        });
    };
    var listScheduleReverseCollection = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (body) {
            var _a, _b;
            var requestBody = body;
            if (!requestBody.pagination) {
                var currentSize = (_b = (_a = snapshot
                    .getLoadable(scheduleTableRowState(requestBody.recordId))
                    .valueMaybe()) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0;
                requestBody = __assign(__assign({}, body), { pagination: {
                        page: 0,
                        size: 
                        // Subtract 1 because of unassigned
                        currentSize - 1 > schedulePageSize
                            ? Math.ceil((currentSize - 1) / schedulePageSize) *
                                schedulePageSize
                            : schedulePageSize,
                    } });
            }
            return customFetch({
                method: 'POST',
                url: 'pages/list_schedule_for_reverse_collection',
            }, __assign(__assign({}, requestBody), { accountId: accountId }));
        };
    }, [accountId, customFetch]);
    var useGetMonthSchedule = function (request) {
        var _a;
        var body = request.body;
        //! Dont refetch on statsOptions, templateFilter, colorCoding, showAll, startDayOfWeek, showShiftTimes, schemaOptions, groupBy change
        var _b = body.schedulingDisplaySettings, statsOptions = _b.statsOptions, templateFilter = _b.templateFilter, colorCoding = _b.colorCoding, showAll = _b.showAll, startDayOfWeek = _b.startDayOfWeek, showShiftTimes = _b.showShiftTimes, viewDisplaySettings = __rest(_b, ["statsOptions", "templateFilter", "colorCoding", "showAll", "startDayOfWeek", "showShiftTimes"]);
        var _c = body.displaySettings, schemaOptions = _c.schemaOptions, groupSetting = _c.groupSetting, blockDisplaySettings = __rest(_c, ["schemaOptions", "groupSetting"]);
        return useQuery([
            'listBlock',
            body.blockId,
            body.start,
            body.end,
            blockDisplaySettings,
            viewDisplaySettings,
            body.searchQuery,
        ], function () {
            return customFetch({
                method: 'POST',
                url: 'pages/month_schedule',
            }, __assign(__assign({}, body), { accountId: accountId }));
        }, {
            enabled: !!Object.values(body.schedulingDisplaySettings).length &&
                !!Object.values(body.displaySettings).length &&
                ((_a = request.options) === null || _a === void 0 ? void 0 : _a.enabled),
        });
    };
    var getBulkEditShiftsInfo = function (body) {
        return customFetch({
            method: 'POST',
            url: 'collections/bulk_update_info',
        }, body);
    };
    var unscheduledShiftClockIn = function (body) {
        return customFetch({
            url: 'scheduling/unscheduled_clock_in',
            method: 'POST',
        }, __assign(__assign({}, body), { accountId: accountId }));
    };
    var scheduledShiftClockIn = function (body) {
        return customFetch({
            url: 'scheduling/clock_in',
            method: 'POST',
        }, __assign(__assign({}, body), { accountId: accountId }));
    };
    var shiftBreakIn = function (body) {
        return customFetch({
            url: 'scheduling/break_in',
            method: 'POST',
        }, __assign(__assign({}, body), { accountId: accountId }));
    };
    var shiftBreakOut = function (body) {
        return customFetch({
            url: 'scheduling/break_out',
            method: 'POST',
        }, __assign(__assign({}, body), { accountId: accountId }));
    };
    var shiftClockOut = function (body) {
        return customFetch({
            url: 'scheduling/clock_out',
            method: 'POST',
        }, __assign(__assign({}, body), { accountId: accountId }));
    };
    var useUnassignShiftsOnTimeoffApproval = function () {
        return useMutation(function (timeoffRecordId) {
            return toastRequest({
                method: 'POST',
                url: 'scheduling/unassign_shifts_on_timeoff_approval',
            }, { timeoffRecordId: timeoffRecordId }, '', 'User shifts that overlap with timeoff have been unassigned!', '');
        });
    };
    var useGetSimpleShiftsByDateRange = function (request) {
        var _a, _b;
        return useQuery(['simpleShiftsByDateRange', (_a = request.body) === null || _a === void 0 ? void 0 : _a.startAt, (_b = request.body) === null || _b === void 0 ? void 0 : _b.endAt], function () {
            var _a, _b, _c;
            return customFetch({
                method: 'POST',
                url: 'shifts/by_date_range',
            }, {
                startAt: (_a = request.body) === null || _a === void 0 ? void 0 : _a.startAt,
                endAt: (_b = request.body) === null || _b === void 0 ? void 0 : _b.endAt,
                timezone: ((_c = request.body) === null || _c === void 0 ? void 0 : _c.timezone) || timeZone,
            });
        }, {
            enabled: !!accountId,
        });
    };
    return {
        useCopySchedule: useCopySchedule,
        useClearSchedule: useClearSchedule,
        postDuplicateShifts: postDuplicateShifts,
        saveRecurringRuleAndScheduleShifts: saveRecurringRuleAndScheduleShifts,
        editRecurringShift: editRecurringShift,
        useFetchAvailableSchedulingStatOptions: useFetchAvailableSchedulingStatOptions,
        useFetchSchedulingStats: useFetchSchedulingStats,
        useFetchShiftRequestsBlockQuery: useFetchShiftRequestsBlockQuery,
        fetchShiftRequestsBlock: fetchShiftRequestsBlock,
        useShiftRequestAction: useShiftRequestAction(),
        useShiftReleaseApprovalAction: useShfitReleaseApprovalAction(),
        useCheckUserConflictsOnShiftRequest: useCheckUserConflictsOnShiftRequest(),
        usePublishShifts: usePublishShifts,
        useFetchScheduleEvaluation: useFetchScheduleEvaluation,
        saveScheduleDisplaySettings: saveScheduleDisplaySettings,
        useSetShiftOpenCount: useSetShiftOpenCount(),
        useListScheduleBlockQuery: useListScheduleBlockQuery,
        listScheduleBlock: listScheduleBlock,
        getBulkEditShiftsInfo: getBulkEditShiftsInfo,
        unscheduledShiftClockIn: unscheduledShiftClockIn,
        scheduledShiftClockIn: scheduledShiftClockIn,
        shiftBreakIn: shiftBreakIn,
        shiftBreakOut: shiftBreakOut,
        shiftClockOut: shiftClockOut,
        useUnassignShiftsOnTimeoffApproval: useUnassignShiftsOnTimeoffApproval(),
        useGetMonthSchedule: useGetMonthSchedule,
        fetchShiftRequestsClaimerMetadata: fetchShiftRequestsClaimerMetadata,
        useFetchShiftReleaseApprovalBlockQuery: useFetchShiftReleaseApprovalBlockQuery,
        useListScheduleReverseQuery: useListScheduleReverseQuery,
        useGetUnpublishedShiftCountQuery: useGetUnpublishedShiftCountQuery,
        useGetSimpleShiftsByDateRange: useGetSimpleShiftsByDateRange,
    };
};
export { useScheduleApi };
