var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal } from 'antd';
import React, { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';
import ZiraActionButton from 'src/ui/ZiraActionButton';
import { CollectionTableType, NativePath } from 'src/shared';
import { useSetting } from 'src/hooks/api';
import { collectionTableCellState, collectionTableNativeSchemaSelector } from 'src/state';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { payRateDataHasDuplicateQualifiers } from 'src/components/Settings/PayRate/util';
import AccountPayRatesWrapper from 'src/components/Settings/PayRate/DefaultRatesTab/PayRateV1/AccountPayRatesWrapper';
var LocationDetailPayRateTable = function (_a) {
    var _b;
    var _c, _d;
    var collectionId = _a.collectionId, recordId = _a.recordId;
    var nameSchema = useRecoilValue(collectionTableNativeSchemaSelector({ collectionId: collectionId, nativePath: NativePath.LOCATION_NAME }));
    var locationNameCell = useRecoilValue(collectionTableCellState({
        type: CollectionTableType.TABLE,
        collectionId: collectionId,
        recordId: recordId,
        schemaId: (_c = nameSchema === null || nameSchema === void 0 ? void 0 : nameSchema.id) !== null && _c !== void 0 ? _c : '',
    }));
    var _e = useSetting(), useSaveRecordQualifierPayRateSettingData = _e.useSaveRecordQualifierPayRateSettingData, usePopulateIndividualPaySettings = _e.usePopulateIndividualPaySettings, useGetPayRatesSetting = _e.useGetPayRatesSetting;
    var _f = useGetPayRatesSetting(), enabledStatuses = _f.data, isLoadingStatuses = _f.isLoading;
    var _g = enabledStatuses !== null && enabledStatuses !== void 0 ? enabledStatuses : {}, isPayEnabled = _g.accountRatesEnabled, individualRatesEnabled = _g.individualRatesEnabled;
    var _h = useState(), payRateData = _h[0], setPayRateData = _h[1];
    // Override individual pay rate settings modal
    var _j = useState(false), isOverrideModalVisible = _j[0], setOverrideModalVisible = _j[1];
    var savePayRateData = useSaveRecordQualifierPayRateSettingData(recordId);
    var applyToIndividualPaySettings = usePopulateIndividualPaySettings();
    var onSavePaySettings = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!payRateData) return [3 /*break*/, 2];
                    if (payRateDataHasDuplicateQualifiers(payRateData)) {
                        toast('Saving pay rates with duplicate qualifiers. Please remove the duplicates to prevent possible conflicts.', {
                            type: 'warning',
                            position: toast.POSITION.BOTTOM_CENTER,
                        });
                    }
                    return [4 /*yield*/, savePayRateData.mutateAsync(payRateData, {
                            onSuccess: function (data) {
                                if (data)
                                    setPayRateData(data);
                            },
                        })];
                case 1:
                    _a.sent();
                    if (individualRatesEnabled) {
                        setOverrideModalVisible(true);
                    }
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, [individualRatesEnabled, payRateData, savePayRateData]);
    var ApplyToIndividualPaySettings = useCallback(function (status) {
        applyToIndividualPaySettings.mutate({ ifUpdate: status });
        setOverrideModalVisible(false);
    }, [applyToIndividualPaySettings]);
    var confirmApplyToIndividualPaySettings = useCallback(function () {
        ApplyToIndividualPaySettings(true);
    }, [ApplyToIndividualPaySettings]);
    var cancelApplyToIndividualPaySettings = useCallback(function () {
        ApplyToIndividualPaySettings(false);
    }, [ApplyToIndividualPaySettings]);
    if (!isLoadingStatuses && !isPayEnabled)
        return null;
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "ml-auto w-min", style: !payRateData ? { opacity: 0.5, pointerEvents: 'none' } : undefined, children: _jsx(ZiraActionButton, { className: "black-btn", onClickFn: onSavePaySettings, children: "Save Pay Rates" }) }), _jsx(AccountPayRatesWrapper, { enabledStatuses: enabledStatuses, recordQualifierId: recordId, defaultQualifiers: (_b = {},
                    _b[collectionId] = {
                        id: uuidv4(),
                        collectionId: collectionId,
                        recordId: recordId,
                        displayName: (_d = locationNameCell === null || locationNameCell === void 0 ? void 0 : locationNameCell.content) !== null && _d !== void 0 ? _d : 'Location',
                        type: 'COLLECTION',
                    },
                    _b), onPayRateChange: setPayRateData }), _jsx(Modal, { open: isOverrideModalVisible, centered: true, okText: "Yes", cancelText: "No", onOk: confirmApplyToIndividualPaySettings, onCancel: cancelApplyToIndividualPaySettings, closable: false, children: "Do you want to override existing individual pay settings?" })] }));
};
export default React.memo(LocationDetailPayRateTable);
