var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Select, Spin } from 'antd';
import { useRecoilValue } from 'recoil';
import { accountIdSelector } from 'src/state';
import { useIntegrationsApi } from 'src/hooks/api/useIntegrationsApi';
import { CollectionType } from '../../../../../shared';
import { SyncType, SourceOfTruth } from '../../Interface';
import SchemaMapping from '../SchemaMapping/SchemaMapping';
import CollectionConfiguration from './CollectionConfiguration';
var Option = Select.Option;
var CollectionColumnMapping = function (_a) {
    var ipName = _a.ipName, ipCollectionName = _a.ipCollectionName, teambridgeCollectionId = _a.teambridgeCollectionId, onDisconnectIpCollection = _a.onDisconnectIpCollection, refetchIpCollections = _a.refetchIpCollections;
    var accountId = useRecoilValue(accountIdSelector);
    var _b = useIntegrationsApi(), useLinkCollections = _b.useLinkCollections, useGetIntegrationSyncSettingOptions = _b.useGetIntegrationSyncSettingOptions;
    var _c = useState(false), isLinking = _c[0], setIsLinking = _c[1];
    var _d = useState(''), selectedTBCollectionId = _d[0], setSelectedTBCollectionId = _d[1];
    var _e = useState(SyncType.ONE_WAY), syncType = _e[0], setSyncType = _e[1];
    var _f = useState(SourceOfTruth.IP), sourceOfTruth = _f[0], setSourceOfTruth = _f[1];
    var isLinked = !!teambridgeCollectionId;
    var _g = useGetIntegrationSyncSettingOptions(ipCollectionName, {
        enabled: !!ipCollectionName,
    }), syncSettingOptions = _g.data, isLoading = _g.isLoading;
    var _h = syncSettingOptions !== null && syncSettingOptions !== void 0 ? syncSettingOptions : {}, supportedTypes = _h.supportedTypes, supportedSourceOfTruth = _h.supportedSourceOfTruth, supportedCollections = _h.supportedCollections;
    useEffect(function () {
        var _a, _b, _c, _d;
        if (syncSettingOptions) {
            var supportedTypes_1 = syncSettingOptions.supportedTypes, supportedSourceOfTruth_1 = syncSettingOptions.supportedSourceOfTruth, supportedCollections_1 = syncSettingOptions.supportedCollections;
            setSyncType(supportedTypes_1[0]);
            setSourceOfTruth(supportedSourceOfTruth_1[0]);
            if (teambridgeCollectionId) {
                setSelectedTBCollectionId(teambridgeCollectionId);
            }
            else {
                setSelectedTBCollectionId((_d = (_b = (_a = supportedCollections_1.find(function (collection) { return collection.type === CollectionType.USER; })) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : (_c = supportedCollections_1[0]) === null || _c === void 0 ? void 0 : _c.id) !== null && _d !== void 0 ? _d : '');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [syncSettingOptions, teambridgeCollectionId]);
    var linkCollection = useCallback(function (tbCollectionId) { return __awaiter(void 0, void 0, void 0, function () {
        var linkCollectionsRequest;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLinking(true);
                    linkCollectionsRequest = {
                        accountId: accountId,
                        ziraCollectionId: tbCollectionId,
                        ipCollectionName: ipCollectionName,
                        syncType: syncType,
                        sourceOfTruth: sourceOfTruth,
                    };
                    return [4 /*yield*/, useLinkCollections.mutateAsync(linkCollectionsRequest, {
                            onSuccess: function (data) {
                                setSelectedTBCollectionId(data.ziraCollectionId);
                                setIsLinking(false);
                                refetchIpCollections();
                            },
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [
        accountId,
        ipCollectionName,
        setSelectedTBCollectionId,
        setIsLinking,
        sourceOfTruth,
        syncType,
        useLinkCollections,
        refetchIpCollections,
    ]);
    return (_jsxs(Spin, { spinning: isLoading, children: [_jsxs("div", { className: "flex flex-col mx-4 mb-4 p-4 bg-gray-50 border-gray-100 rounded-lg", children: [_jsxs("div", { className: "mb-3", children: [_jsx("h6", { className: "text-md font-semibold mb-3", children: "Column Mapping" }), _jsx("span", { className: "font-medium text-gray-400 text-xs", children: "TeamBridge Collection" }), _jsxs("div", { className: "flex flex-row gap-2 w-full mt-2", children: [_jsx(Select, { className: "w-full", value: selectedTBCollectionId, onSelect: setSelectedTBCollectionId, disabled: isLinking || isLinked, children: supportedCollections === null || supportedCollections === void 0 ? void 0 : supportedCollections.map(function (option) {
                                            return (_jsx(Option, { value: option.id, children: option.name }, option.id));
                                        }) }), teambridgeCollectionId ? (_jsx(Button, { danger: true, type: "link", className: "px-3 font-semibold", style: { textShadow: 'none' }, onClick: function () { return onDisconnectIpCollection(teambridgeCollectionId); }, children: "Disconnect" })) : (_jsx(Button, { type: "link", className: "px-3 font-semibold", style: { textShadow: 'none' }, disabled: isLinking, onClick: function () { return linkCollection(selectedTBCollectionId); }, children: "Link Collection" }))] })] }), isLinked && (_jsx(SchemaMapping, { ipName: ipName, ipCollection: ipCollectionName, teambridgeCollectionId: teambridgeCollectionId }))] }), _jsx(CollectionConfiguration, { ipName: ipName, syncType: syncType, setSyncType: setSyncType, supportedTypes: supportedTypes !== null && supportedTypes !== void 0 ? supportedTypes : [], sourceOfTruth: sourceOfTruth, setSourceOfTruth: setSourceOfTruth, supportedSourcesOfTruth: supportedSourceOfTruth !== null && supportedSourceOfTruth !== void 0 ? supportedSourceOfTruth : [] })] }));
};
export default React.memo(CollectionColumnMapping);
