import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { Select, Button, Space } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useScheduleComponent, useTimeZone } from 'src/hooks/component';
import { useRecoilValue } from 'recoil';
import './ScheduleColumnGroupHeader.scss';
import { getGroupByCollectionName, ScheduleView, } from 'src/services/ScheduleService';
import { scheduleGroupBySelector, scheduleTimeZoneSelector, scheduleViewTypeSelector, } from 'src/state/selectors/schedule';
import { scheduleTimeRangeState } from 'src/state/atoms/schedule';
import { CollectionTableType, ScheduleGroupBy } from 'src/shared';
import { collectionOptionsState, collectionTableCanEditSelector, ifUserHasGeneralPermission, tableCollectionIdSelector, } from 'src/state';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import { getTagTextColor } from 'src/utils/Collection';
import { GeneralPermissionKey } from '../Settings/Permission/Constants';
import { ShiftTemplateIcon } from '../../utils/icons/ShiftTemplateIcon';
import { ShiftRulesIcon } from '../../utils/icons/ShiftRulesIcon';
import { NEW_SHIFT_TEMPLATE } from '../FeatureGatingKeyConstant';
import ScheduleTimePickerDropdown from './ScheduleTimePickerDropdown';
var Option = Select.Option;
var ScheduleColumnGroupHeader = function (_a) {
    var isTemplateDrawerOpen = _a.isTemplateDrawerOpen, isRulesDrawerOpen = _a.isRulesDrawerOpen, openTemplateDrawer = _a.openTemplateDrawer, openRulesDrawer = _a.openRulesDrawer, blockId = _a.blockId, type = _a.type;
    var changeCalView = useScheduleComponent().changeCalView;
    var collectionOptions = useRecoilValue(collectionOptionsState);
    var groupBy = useRecoilValue(scheduleGroupBySelector(blockId));
    var scheduleView = useRecoilValue(scheduleViewTypeSelector(blockId));
    var timeRange = useRecoilValue(scheduleTimeRangeState(blockId));
    var scheduleTimezone = useRecoilValue(scheduleTimeZoneSelector(blockId));
    var abbreviateTimezone = useTimeZone(scheduleTimezone).abbreviateTimezone;
    var applyScheduleDisplaySettings = useScheduleComponent().applyScheduleDisplaySettings;
    var collectionId = useRecoilValue(tableCollectionIdSelector(blockId));
    var canEditCollection = useRecoilValue(collectionTableCanEditSelector({ type: CollectionTableType.TABLE, collectionId: collectionId }));
    var ifUserHasSchedulingAnalysisPermission = useRecoilValue(ifUserHasGeneralPermission(GeneralPermissionKey.SCHEDULE_ANALYSIS));
    var currentTimeRangeContent = useCallback(function () {
        var range = timeRange.slice(0, -1);
        var format = scheduleView === ScheduleView.DAY ? 'ddd MMM D' : 'MMM D';
        var start = moment(range[0]).format(format);
        var end = moment(range[range.length - 1]).format(format);
        var startYear = moment(range[0]).format('YYYY');
        var endYear = moment(range[range.length - 1]).format('YYYY');
        var isSameYear = startYear === endYear;
        var timeDisplay = scheduleView === ScheduleView.DAY
            ? "".concat(start, " ").concat(startYear)
            : scheduleView === ScheduleView.MONTH
                ? "".concat(moment(range[0]).format('MMM'), " ").concat(startYear)
                : "".concat(start, " - ").concat(end, " ").concat(isSameYear ? startYear : endYear);
        return _jsx("span", { className: "CurrentTimeRange", children: timeDisplay });
    }, [scheduleView, timeRange]);
    var isNewShiftTemplateEnabled = useRecoilValue(featureEnableStatusState(NEW_SHIFT_TEMPLATE));
    return (_jsxs("div", { className: "ScheduleTableGroupHeader", children: [_jsxs("div", { className: "flex flex-row items-center", children: [_jsx(Select, { className: "ScheduleHeaderSelector", popupClassName: "ScheduleHeaderSelectorDropdown", value: groupBy, bordered: false, size: "small", dropdownMatchSelectWidth: false, onSelect: function (value) {
                            return applyScheduleDisplaySettings(blockId, { groupByKey: value }, type);
                        }, disabled: !canEditCollection, children: Object.keys(ScheduleGroupBy).map(function (key) {
                            var groupByKey = key;
                            if (groupByKey === ScheduleGroupBy.TEMPLATE && !isNewShiftTemplateEnabled) {
                                return null;
                            }
                            return (_jsx(Option, { value: groupByKey, children: "Group by: ".concat(getGroupByCollectionName(groupByKey, collectionOptions)) }, groupByKey));
                        }) }), _jsxs(Select, { className: "ScheduleHeaderSelector", popupClassName: "ScheduleHeaderSelectorDropdown", value: scheduleView, bordered: false, onSelect: function (value) {
                            changeCalView(blockId, value, 0);
                        }, size: "small", dropdownMatchSelectWidth: false, disabled: !canEditCollection, children: [_jsx(Option, { value: ScheduleView.DAY, children: "Day View" }), _jsx(Option, { value: ScheduleView.WEEK, children: "Week View" }), _jsx(Option, { value: ScheduleView.TWO_WEEK, children: "2-Week View" }), _jsx(Option, { value: ScheduleView.FOUR_WEEK, children: "4-Week View" }), _jsx(Option, { value: ScheduleView.MONTH, children: "Month View" })] }), _jsx("span", { className: "font-semibold rounded-md px-2 py-1 ml-3 text-xs", style: {
                            backgroundColor: '#FFECC8',
                            color: getTagTextColor('#FFECC8'),
                        }, children: abbreviateTimezone() })] }), _jsxs("div", { className: "TimeRangeSelector", children: [_jsxs(Button, { className: "ScheduleHeaderBtn", onClick: function () {
                            changeCalView(blockId, scheduleView, -1);
                        }, children: [_jsx(LeftOutlined, { className: "LeftRightIcon" }), "Previous"] }), _jsx(ScheduleTimePickerDropdown, { blockId: blockId, children: currentTimeRangeContent() }), _jsxs(Button, { className: "ScheduleHeaderBtn", onClick: function () { return changeCalView(blockId, scheduleView, 1); }, children: ["Next", _jsx(RightOutlined, { className: "LeftRightIcon" })] })] }), _jsxs(Space, { children: [_jsx(Button, { className: "ScheduleHeaderBtn", onClick: function () { return changeCalView(blockId, scheduleView, 0, true); }, children: "Today" }), ifUserHasSchedulingAnalysisPermission && (_jsx(Button, { className: isRulesDrawerOpen
                            ? 'ScheduleBtnWithDrawerOpened'
                            : 'ScheduleBtnWithDrawerClosed', icon: _jsx(ShiftRulesIcon, {}), onClick: openRulesDrawer, children: "Scheduling Analysis" })), _jsx(Button, { className: isTemplateDrawerOpen
                            ? 'ScheduleBtnWithDrawerOpened'
                            : 'ScheduleBtnWithDrawerClosed', icon: _jsx(ShiftTemplateIcon, { className: "text-gray-400" }), onClick: openTemplateDrawer, children: "Schedule Templates" })] })] }));
};
export default React.memo(ScheduleColumnGroupHeader);
