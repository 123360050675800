var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var TextOutlinedSvg = function () { return (_jsxs("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M14.25 5.25V3.75H3.75V5.25", stroke: "#959595", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M9 3.75V14.25M9 14.25H7.5M9 14.25H10.5", stroke: "#959595", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] })); };
export var TextOutlinedIcon = function (props) { return (_jsx(Icon, __assign({ component: TextOutlinedSvg }, props))); };
