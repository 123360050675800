import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Avatar, Breadcrumb, Button, List } from 'antd';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import HelpActionWrapper from 'src/components/CenterPanel/Header/HelpDrawer/HelpActionWrapper';
import { UserGroupLogo } from 'src/utils/icons/userIcons/userGroupLogo';
import { AdminLogo } from 'src/utils/icons/userIcons/adminLogo';
import { DuplicateOutlinedIcon } from 'src/utils/icons/DropdownIcons/DuplicateOutlined';
import { DeleteOutlinedIcon } from 'src/utils/icons/DropdownIcons/DeleteOutlined';
import { useSetting } from 'src/hooks/api';
import { TEAMBRIDGE_TITLE } from 'src/shared';
import HardConfirmWrapper from 'src/components/HardConfirmWrapper/HardConfirmWrapper';
import SettingsState from '../../../../../hooks/state/Settings';
import './AccessGroupsOverview.scss';
import SettingsContentHeader from '../../../SettingsContentHeader';
// TODO: remove
var PrimaryRandomColors = [
    '#8476D6',
    '#2F4DDF',
    '#2981D1',
    '#FF4A43',
    '#FFC047',
    '#FD7238',
    '#9D5A3E',
    '#FF4A43',
];
var RandomPrimaryColor = function (index) {
    var colorIndex = index % PrimaryRandomColors.length;
    return PrimaryRandomColors[colorIndex];
};
var RenderAccessGroups = function (_a) {
    var item = _a.item;
    var _b = SettingsState.useContainer(), getAccessGroupDetail = _b.getAccessGroupDetail, duplicateAccessGroup = _b.duplicateAccessGroup, deleteAccessGroup = _b.deleteAccessGroup, getAccountAccessGroups = _b.getAccountAccessGroups;
    var handleDuplicateAccessGroup = useCallback(function () { return duplicateAccessGroup(item.id); }, [duplicateAccessGroup, item.id]);
    var handleDeleteAccessGroup = useCallback(function () { return deleteAccessGroup(item.id); }, [deleteAccessGroup, item.id]);
    var handleConfigurePermissions = useCallback(function () { return getAccessGroupDetail(item.id); }, [getAccessGroupDetail, item.id]);
    var useMakeAccessGroupDefaultMutation = useSetting().useMakeAccessGroupDefaultMutation;
    var handleMakeAccessGroupDefault = useCallback(function (id) {
        useMakeAccessGroupDefaultMutation.mutate(id, {
            onSuccess: function () {
                getAccountAccessGroups();
            },
        });
    }, [useMakeAccessGroupDefaultMutation, getAccountAccessGroups]);
    var isOwner = item.type == 'owner';
    var isDefault = item.isDefault;
    var groupAvatars = function (users, total) {
        return (_jsx(Avatar.Group, { maxCount: 4, size: "large", maxStyle: {
                display: 'none',
            }, children: users.map(function (user, index) { return (_jsxs(React.Fragment, { children: [_jsx(Avatar, { shape: "square", style: {
                            borderRadius: '8px',
                            backgroundColor: RandomPrimaryColor(user.firstName.length + user.lastName.length),
                        }, src: user.avatarUrl, children: user.firstName.charAt(0).toUpperCase() +
                            user.lastName.charAt(0).toUpperCase() }, index), index == users.length - 1 && total > 3 && (_jsxs(Avatar, { shape: "square", style: {
                            backgroundColor: '#000',
                            borderRadius: '8px',
                            opacity: 0.7,
                            fontWeight: 600,
                        }, children: ["+ ", total - 3] }, 'overviewUser'))] }, "avatars-".concat(index))); }) }));
    };
    return (_jsxs(List.Item, { className: "accountAccessGroupListItem", children: [_jsxs("div", { className: "accountAccessGroupListItemLeft", children: [_jsx(Avatar, { icon: isOwner ? _jsx(AdminLogo, {}) : _jsx(UserGroupLogo, {}), shape: "square", size: 38, style: {
                            borderRadius: '4px',
                            backgroundColor: RandomPrimaryColor(item.name.length),
                        } }), _jsx("div", { className: "accountAccessGroupListItemText", children: item.name })] }), _jsx("div", { className: "absolute left-[30%] flex items-center", children: groupAvatars(item.usersOverview.overviewUsers, item.usersOverview.total) }), _jsxs("div", { className: "flex justify-between", children: [!isOwner && !isDefault && (_jsx(HardConfirmWrapper, { onConfirm: handleDeleteAccessGroup, changeType: "delete", entityName: "access group", explanationText: "All users currently assigned to this access group will lose access to the account.", children: _jsx(ZiraTooltip, { title: "Delete Access Group", children: _jsx("div", { className: "PermissionDuplicateButton mr-1 p-1 hover:cursor-pointer", children: _jsx(DeleteOutlinedIcon, { fill: "black", className: "mx-[0.2em]" }) }) }) })), _jsx(ZiraTooltip, { title: "Duplicate Access Group", children: _jsx("div", { className: "PermissionDuplicateButton mr-1 p-1 hover:cursor-pointer", onClick: handleDuplicateAccessGroup, children: _jsx(DuplicateOutlinedIcon, { fill: "black", className: "mx-[0.2em]" }) }) }), !isOwner && (_jsx(Button, { className: "".concat(isDefault ? 'PermissionDefaultButton' : 'PermissionMakeDefaultButton', " mr-1"), onClick: isDefault ? function () { } : function () { return handleMakeAccessGroupDefault(item.id); }, children: isDefault ? 'Default' : 'Make Default' })), _jsx(Button, { className: "permissionAndAccessButton", onClick: handleConfigurePermissions, children: "Edit Group Settings" })] })] }));
};
var RenderAccessGroupsMemo = React.memo(RenderAccessGroups);
var AccessGroupsOverview = function () {
    var _a = SettingsState.useContainer(), loadingAccountAccessGroups = _a.loadingAccountAccessGroups, accountAccessGroups = _a.accountAccessGroups, createAccessGroup = _a.createAccessGroup;
    var renderAccessGroups = useCallback(function (item) { return _jsx(RenderAccessGroupsMemo, { item: item }); }, []);
    return (_jsxs(_Fragment, { children: [_jsx(SettingsContentHeader, { children: _jsx(Breadcrumb.Item, { children: "Access Groups" }) }), _jsx("div", { className: "SettingsPageContainer", children: _jsx("div", { className: "SettingContentView", id: "SettingContentView", children: _jsxs("div", { className: "MainSettings", children: [_jsx("div", { className: "WelcomeToSettings mb-10", children: _jsxs("div", { className: "SettingsHeroTextWrapper pb-5", children: [_jsx("div", { className: "SettingsHeroHeaderSm", children: "Access Groups" }), _jsxs("div", { className: "SettingsHeroSubText", children: [TEAMBRIDGE_TITLE, " gives you the ultimate access control. Here you can determine which groups of users can create, delete, view, edit which type of information."] }), _jsx("div", { className: "SettingsHeroButtonWrapper", children: _jsx(HelpActionWrapper, { articleId: "", children: _jsx("div", { className: "SettingsGrayButton", children: "Learn More About Access Groups" }) }) })] }) }), _jsxs("div", { className: "permissionSettingSectionWrapper", children: [_jsx("div", { className: "accountAccessGroups", children: _jsx(List, { loading: loadingAccountAccessGroups, dataSource: accountAccessGroups, footer: null, renderItem: renderAccessGroups }) }), _jsx(Button, { icon: _jsx(PlusOutlined, {}), className: "permissionAndAccessButton", onClick: createAccessGroup, children: "Add Access Group" })] })] }) }) })] }));
};
export default React.memo(AccessGroupsOverview);
