var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useState } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useAccountApi } from 'src/hooks/api';
import { useNavigate } from 'react-router';
import './ApplyAccountTemplateModal.scss';
import { useAppSession } from 'src/hooks/component';
import IndustryCategorySelectionPage from './IndustryCategorySelectionPage';
import AccountTemplateApplySelectionPage from './AccountTemplateApplySelectionPage';
var LoadingIcon = _jsx(LoadingOutlined, { spin: true });
var ApplyAccountTemplateModal = function (props) {
    var _a;
    var accountInfo = props.accountInfo;
    var navigate = useNavigate();
    var _b = useAccountApi(), useGetAccountTemplates = _b.useGetAccountTemplates, useGetAccountTemplatesCategoryMetaData = _b.useGetAccountTemplatesCategoryMetaData, useCreateAccountAndApplyTemplate = _b.useCreateAccountAndApplyTemplate;
    var switchAccountSession = useAppSession().switchAccountSession;
    var accountTemplateData = useGetAccountTemplates().data;
    var accountTemplateCategoryMetaData = useGetAccountTemplatesCategoryMetaData().data;
    var _c = useState(''), selectedCategory = _c[0], setSelectedCategory = _c[1];
    var _d = useState(0), stepIndex = _d[0], setStepIndex = _d[1];
    var goNextStep = useCallback(function () { return setStepIndex(function (prevIndex) { return prevIndex + 1; }); }, []);
    var goPrevStep = useCallback(function () { return setStepIndex(function (prevIndex) { return prevIndex - 1; }); }, []);
    var _e = useState(false), loadingStatus = _e[0], setLoadingStatus = _e[1];
    var changeLoadingStatus = useCallback(function (status) {
        setLoadingStatus(status);
    }, []);
    var onCreateAccountAndApplyTemplate = useCallback(function (type) {
        changeLoadingStatus(true);
        var request = {
            name: accountInfo.name,
            firstName: accountInfo.firstName,
            lastName: accountInfo.lastName,
            phone: accountInfo.phone,
            timezone: accountInfo.timezone,
            templateIdentifier: type,
        };
        useCreateAccountAndApplyTemplate.mutate(request, {
            onSuccess: function (resp) { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!resp) return [3 /*break*/, 2];
                            return [4 /*yield*/, switchAccountSession(resp.accountId)];
                        case 1:
                            _a.sent();
                            navigate('/home', { replace: true });
                            history.go(0);
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            }); },
        });
    }, [
        changeLoadingStatus,
        accountInfo.name,
        accountInfo.firstName,
        accountInfo.lastName,
        accountInfo.phone,
        accountInfo.timezone,
        useCreateAccountAndApplyTemplate,
        switchAccountSession,
        navigate,
    ]);
    return (_jsxs(Spin, { spinning: loadingStatus, tip: "Loading...", size: "large", indicator: LoadingIcon, style: { position: 'initial' }, children: [stepIndex === 0 && accountTemplateCategoryMetaData && accountTemplateData && (_jsx(IndustryCategorySelectionPage, { categoryMetaDataMap: accountTemplateCategoryMetaData, accountTemplateData: accountTemplateData, selectedCategoryOnChange: function (category) { return setSelectedCategory(category); }, goNextStep: goNextStep })), stepIndex === 1 && (accountTemplateCategoryMetaData === null || accountTemplateCategoryMetaData === void 0 ? void 0 : accountTemplateCategoryMetaData[selectedCategory]) && (_jsx(AccountTemplateApplySelectionPage, { templateCards: ((_a = accountTemplateData === null || accountTemplateData === void 0 ? void 0 : accountTemplateData[selectedCategory]) === null || _a === void 0 ? void 0 : _a.templates) || [], categoryMetaData: accountTemplateCategoryMetaData === null || accountTemplateCategoryMetaData === void 0 ? void 0 : accountTemplateCategoryMetaData[selectedCategory], onCreateAccountAndApplyTemplate: onCreateAccountAndApplyTemplate, goPrevStep: goPrevStep }))] }));
};
export default React.memo(ApplyAccountTemplateModal);
