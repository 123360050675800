var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { PdfFileIcon } from 'src/utils/icons/PdfFileIcon';
import { ZipFileIcon } from 'src/utils/icons/ZipFileIcon';
export var DocumentsExportType;
(function (DocumentsExportType) {
    DocumentsExportType["ZIP"] = "ZIP";
    DocumentsExportType["PDF"] = "PDF";
})(DocumentsExportType || (DocumentsExportType = {}));
export var exportTypeOptionsMap = (_a = {},
    _a[DocumentsExportType.ZIP] = {
        name: 'Download as .zip',
        description: 'All selected files will be compressed and packaged into a .zip file.',
        icon: _jsx(ZipFileIcon, {}),
    },
    _a[DocumentsExportType.PDF] = {
        name: 'Merge Files',
        description: 'This will convert and merge all selected files into a single PDF.',
        icon: _jsx(PdfFileIcon, {}),
    },
    _a);
