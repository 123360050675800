import { jsx as _jsx } from "react/jsx-runtime";
import { Select } from 'antd';
import React from 'react';
import { useTimezoneApi } from 'src/hooks/api/useTimezoneApi';
var Option = Select.Option;
var TimeZoneSelector = function (props) {
    var timezoneValue = props.timezoneValue, timezoneOnChange = props.timezoneOnChange, _a = props.canEdit, canEdit = _a === void 0 ? true : _a, _b = props.useValues, useValues = _b === void 0 ? false : _b, _c = props.optionClassName, optionClassName = _c === void 0 ? '' : _c;
    var useGetTimezoneOptions = useTimezoneApi().useGetTimezoneOptions;
    var _d = useGetTimezoneOptions().data, timezoneOptions = _d === void 0 ? [] : _d;
    return (_jsx(Select, { showSearch: true, className: "TimeZoneSelector min-w-[140px]", value: timezoneValue, onChange: function (value) { return timezoneOnChange(value); }, disabled: !canEdit, children: timezoneOptions.map(function (_a) {
            var id = _a.id, title = _a.title, zoneId = _a.zoneId;
            return (_jsx(Option, { value: zoneId, className: optionClassName, children: useValues ? zoneId : title }, id));
        }) }));
};
export default React.memo(TimeZoneSelector);
