var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useContext } from 'react';
import { Button } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { BlackPlusIcon } from 'src/utils/icons/BlackPlusIcon';
import PaymentRulesConfigurationContext from 'src/components/Settings/PayRate/PaymentRulesTab/component/PaymentRulesModal/PaymentRulesConfigurationContext';
import PaymentAdjustmentSelector from 'src/components/Settings/PayRate/PayItemConfigModal/PaymentAdjustmentSection/component/PaymentAdjustmentSelector';
import { useRecoilState, useRecoilValue } from 'recoil';
import { availableShiftPaySchemasState, currentDefaultRateState, } from 'src/components/Settings/PayRate/atoms';
import { PayItemType, PayRuleAdjustmentField, PayRuleAdjustmentType, } from '../../interface';
var PaymentAdjustmentSection = function (_a) {
    var _b;
    var payItemType = _a.payItemType;
    var _c = useContext(PaymentRulesConfigurationContext), paymentRule = _c.paymentRule, setPayRuleAdjustments = _c.setAdjustments;
    var payRuleAdjustment = paymentRule.adjustments;
    var _d = useRecoilState(currentDefaultRateState), currentDefaultRate = _d[0], setCurrentDefaultRate = _d[1];
    var payRateAdjustments = (_b = currentDefaultRate === null || currentDefaultRate === void 0 ? void 0 : currentDefaultRate.adjustments) !== null && _b !== void 0 ? _b : [];
    var adjustments = payItemType === PayItemType.PAY_RULE ? payRuleAdjustment : payRateAdjustments;
    var availableShiftPaySchemas = useRecoilValue(availableShiftPaySchemasState);
    var onAddAdjustment = useCallback(function () {
        var newAdjustments = __spreadArray(__spreadArray([], adjustments, true), [
            {
                id: uuidv4(),
                value: 0,
                field: PayRuleAdjustmentField.PAY_RATE,
                type: PayRuleAdjustmentType.SET_TO,
            },
        ], false);
        if (payItemType === PayItemType.PAY_RULE) {
            setPayRuleAdjustments(newAdjustments);
        }
        else if (payItemType === PayItemType.PAY_RATE) {
            setCurrentDefaultRate(__assign(__assign({}, currentDefaultRate), { adjustments: newAdjustments }));
        }
    }, [adjustments, currentDefaultRate, payItemType, setCurrentDefaultRate, setPayRuleAdjustments]);
    return (_jsxs("div", { className: "flex flex-col gap-2", children: [_jsx("div", { className: "font-medium text-gray-400 text-xs", children: "Adjustments" }), _jsxs("div", { className: 'flex flex-col gap-3', children: [adjustments.map(function (adjustment) {
                        return (_jsx(PaymentAdjustmentSelector, { adjustment: adjustment, payItemType: payItemType, customCurrencySchemas: (availableShiftPaySchemas === null || availableShiftPaySchemas === void 0 ? void 0 : availableShiftPaySchemas.currencySchemas) || [], unitSchemas: (availableShiftPaySchemas === null || availableShiftPaySchemas === void 0 ? void 0 : availableShiftPaySchemas.unitSchemas) || [], formulaSchemas: (availableShiftPaySchemas === null || availableShiftPaySchemas === void 0 ? void 0 : availableShiftPaySchemas.formulaSchemas) || [] }, "".concat(adjustment.id, "-adjustment")));
                    }), _jsx("div", { children: _jsx(Button, { icon: _jsx(BlackPlusIcon, {}), onClick: onAddAdjustment, className: "AddTableFilterButton", type: "primary", children: "Add Adjustment" }) })] })] }));
};
export default PaymentAdjustmentSection;
