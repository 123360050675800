var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';
import { cloneDeep, isEqual, uniqBy } from 'lodash';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import { accountIdSelector, nextPageOfTableState, scheduleEvaluationState, scheduleStatsSummaryState, scheduleTimeRangeState, tableSchedulingDisplaySettingsState, selectedShiftsState, scheduleGroupBySelector, scheduleVisibleStatsOptionsSelector, scheduleViewTypeSelector, scheduleRowLengthSelector, scheduleStartDayOfWeekSelector, tableDisplaySettingsState, tableSelectedViewIdState, scheduleTimeZoneSelector, collectionTableSchemaNativePathMapSelector, tableCollectionIdSelector, tableSearchTextState, scheduleTableState, scheduleTableRowState, scheduleTimeOffsState, scheduleShiftCardBasicInfoSelector, collectionTableSchemasState, autoSchedulerSettingState, } from 'src/state';
import { initCurrentStartDay, schedulePageSize, ScheduleView, scheduleViewToNumber, } from 'src/services/ScheduleService';
import { toast } from 'react-toastify';
import { RecordSchemaType, NativePath, SCHEDULE_UNASSIGNED_KEY, CollectionType, ScheduleGroupBy, CollectionTableType, ScheduleReverseCollectionComponentSet, NonSchemaNativePath, } from '../../shared';
import { useScheduleApi } from '../api/useScheduleApi';
import { getAccountStartDayOfWeek, getWeekDayNumber } from '../../utils/Account';
import { useAccountApi, usePageApi, useReverseCollectionApi } from '../api';
import { useTimezoneApi } from '../api/useTimezoneApi';
import { useRefetchCollection } from './useRefetchCollection';
import { useCollectionComponent } from '.';
var useSchedule = function () {
    var refetchCollection = useRefetchCollection();
    var accountId = useRecoilValue(accountIdSelector);
    var _a = useScheduleApi(), useFetchScheduleEvaluation = _a.useFetchScheduleEvaluation, saveScheduleDisplaySettings = _a.saveScheduleDisplaySettings, useSetShiftOpenCount = _a.useSetShiftOpenCount, getBulkEditShiftsInfo = _a.getBulkEditShiftsInfo;
    var useUpdateReverseCollectionView = useReverseCollectionApi().useUpdateReverseCollectionView;
    var processTableCollectionIntoState = useCollectionComponent().processTableCollectionIntoState;
    var getLocationTimezone = useTimezoneApi().getLocationTimezone;
    var applyScheduleDisplaySettings = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot, set = _a.set;
        return function (tableId_1, newSchedulingDisplaySetting_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(void 0, __spreadArray([tableId_1, newSchedulingDisplaySetting_1], args_1, true), void 0, function (tableId, newSchedulingDisplaySetting, type, shouldSave) {
                var viewId, schedulingDisplaySettings, prevSchedulingDisplaySettings, tempSchedulingDisplaySettings, success;
                if (type === void 0) { type = ScheduleReverseCollectionComponentSet.has(tableId)
                    ? 'reverseCollection'
                    : 'block'; }
                if (shouldSave === void 0) { shouldSave = true; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            viewId = snapshot.getLoadable(tableSelectedViewIdState(tableId)).valueMaybe();
                            schedulingDisplaySettings = snapshot
                                .getLoadable(tableSchedulingDisplaySettingsState(tableId))
                                .valueMaybe();
                            if (!viewId || !schedulingDisplaySettings)
                                return [2 /*return*/];
                            prevSchedulingDisplaySettings = cloneDeep(schedulingDisplaySettings);
                            tempSchedulingDisplaySettings = cloneDeep(__assign(__assign({}, prevSchedulingDisplaySettings), newSchedulingDisplaySetting));
                            if (isEqual(tempSchedulingDisplaySettings, prevSchedulingDisplaySettings))
                                return [2 /*return*/];
                            set(tableSchedulingDisplaySettingsState(tableId), tempSchedulingDisplaySettings);
                            if (!shouldSave)
                                return [2 /*return*/];
                            if (!(type === 'block')) return [3 /*break*/, 2];
                            return [4 /*yield*/, saveScheduleDisplaySettings({
                                    viewId: viewId,
                                    schedulingDisplaySettings: newSchedulingDisplaySetting,
                                })];
                        case 1:
                            success = _a.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, useUpdateReverseCollectionView.mutateAsync({
                                viewId: viewId,
                                schedulingDisplaySettings: newSchedulingDisplaySetting,
                            })];
                        case 3:
                            success = _a.sent();
                            _a.label = 4;
                        case 4:
                            if (!success) {
                                //!MARK: revert state
                                set(tableSchedulingDisplaySettingsState(tableId), prevSchedulingDisplaySettings);
                            }
                            return [2 /*return*/];
                    }
                });
            });
        };
    }, [saveScheduleDisplaySettings, useUpdateReverseCollectionView]);
    var fetchScheduleEvaluation = useFetchScheduleEvaluation();
    var _b = useScheduleApi(), useFetchSchedulingStats = _b.useFetchSchedulingStats, listScheduleBlock = _b.listScheduleBlock;
    var fetchSchedulingStats = useFetchSchedulingStats();
    var useGetAutoSchedulerSetting = useAccountApi().useGetAutoSchedulerSetting;
    var _c = useRecoilState(autoSchedulerSettingState), autoSchedulerSetting = _c[0], setAutoSchedulerSetting = _c[1];
    useGetAutoSchedulerSetting({
        options: {
            enabled: !autoSchedulerSetting,
            onSuccess: function (data) {
                if (data) {
                    setAutoSchedulerSetting(data);
                }
            },
        },
    });
    var getTimeRange = useCallback(function (startMoment, endMoment, unit) {
        if (unit === void 0) { unit = 'days'; }
        var tempTimeRange = [];
        for (var current = startMoment; current.isSameOrBefore(endMoment); current.add(1, unit)) {
            tempTimeRange.push(current.clone().toISOString());
        }
        return tempTimeRange;
    }, []);
    var changeCalView = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot, set = _a.set;
        return function (tableId, value, offset, reset, startOfWeekOffset) {
            if (offset === void 0) { offset = 0; }
            if (reset === void 0) { reset = false; }
            if (startOfWeekOffset === void 0) { startOfWeekOffset = 0; }
            var release = snapshot.retain();
            try {
                //! Should we clean up when cal view changed?
                var timeRange = snapshot
                    .getLoadable(scheduleTimeRangeState(tableId))
                    .valueMaybe();
                var startDayOfWeek = snapshot
                    .getLoadable(scheduleStartDayOfWeekSelector(tableId))
                    .valueMaybe();
                if (!timeRange || !startDayOfWeek)
                    return;
                set(selectedShiftsState(tableId), []);
                var timeSpan = 'days';
                var numOfWeeks = scheduleViewToNumber(value);
                var currentMoment = reset
                    ? initCurrentStartDay(startDayOfWeek, value)
                    : initCurrentStartDay(startDayOfWeek, value, moment(timeRange[0])) ||
                        moment();
                var startMoment = void 0;
                var endMoment = void 0;
                var tempTimeRange = cloneDeep(timeRange);
                if (![ScheduleView.DAY, ScheduleView.MONTH].includes(value)) {
                    var startMoment_1 = currentMoment
                        .clone()
                        .add(offset, 'weeks');
                    var endMoment_1 = currentMoment
                        .clone()
                        .add((offset + numOfWeeks), 'weeks');
                    if (startOfWeekOffset != 0) {
                        startMoment_1 = startMoment_1
                            .clone()
                            .add(startOfWeekOffset, 'weeks');
                        endMoment_1 = endMoment_1
                            .clone()
                            .add(startOfWeekOffset, 'weeks');
                    }
                    tempTimeRange = getTimeRange(startMoment_1, endMoment_1, timeSpan);
                }
                else if (value === ScheduleView.DAY) {
                    startMoment = currentMoment
                        .clone()
                        .add(offset, 'days');
                    endMoment = startMoment.clone().add(1, 'days');
                    tempTimeRange = getTimeRange(startMoment, endMoment, timeSpan);
                }
                else if (value === ScheduleView.MONTH) {
                    startMoment = moment(timeRange[0])
                        .clone()
                        .add(offset, 'month');
                    endMoment = startMoment.clone().add(1, 'month');
                    tempTimeRange = getTimeRange(startMoment, endMoment, timeSpan);
                    set(tableSearchTextState(tableId), '');
                }
                set(scheduleTimeRangeState(tableId), tempTimeRange);
                var scheduleView = snapshot
                    .getLoadable(scheduleViewTypeSelector(tableId))
                    .valueMaybe();
                if (scheduleView !== value) {
                    applyScheduleDisplaySettings(tableId, { viewType: value });
                }
            }
            finally {
                release();
            }
        };
    }, [getTimeRange, applyScheduleDisplaySettings]);
    var scheduleStatVisibleOnChange = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (option, tableId) {
            var release = snapshot.retain();
            try {
                var scheduleStats = snapshot
                    .getLoadable(scheduleVisibleStatsOptionsSelector(tableId))
                    .valueMaybe();
                if (!scheduleStats)
                    return;
                var newScheduleStats = cloneDeep(scheduleStats);
                var existingObjIndex = newScheduleStats.findIndex(function (stat) {
                    return stat.first === option.first &&
                        stat.second === option.second &&
                        stat.operation === option.operation;
                });
                // If the stat is already in the list, remove it
                if (existingObjIndex >= 0) {
                    newScheduleStats.splice(existingObjIndex, 1);
                }
                else {
                    newScheduleStats.push(option);
                }
                applyScheduleDisplaySettings(tableId, { statsOptions: newScheduleStats });
            }
            finally {
                release();
            }
        };
    }, [applyScheduleDisplaySettings]);
    var getScheduleStatsSummaryData = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot, set = _a.set;
        return function (blockId, startAt, endAt) {
            if (!startAt || !endAt)
                return;
            var release = snapshot.retain();
            try {
                var timeZone = snapshot
                    .getLoadable(scheduleTimeZoneSelector(blockId))
                    .valueMaybe();
                var scheduleStats = snapshot
                    .getLoadable(scheduleVisibleStatsOptionsSelector(blockId))
                    .valueMaybe();
                if (!scheduleStats) {
                    return;
                }
                var requestBody = {
                    accountId: accountId,
                    blockId: blockId,
                    endAt: endAt,
                    startFrom: startAt,
                    stats: scheduleStats,
                    scheduleTimeZone: timeZone,
                };
                fetchSchedulingStats.mutate(requestBody, {
                    onSuccess: function (data) {
                        if (data) {
                            set(scheduleStatsSummaryState(blockId), data);
                        }
                    },
                });
            }
            finally {
                release();
            }
        };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId]);
    var getNewShiftDefaults = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (startTime, endTime, blockId, groupByObj) {
            var _a;
            var schemaDefaults = [];
            var collectionId = snapshot
                .getLoadable(tableCollectionIdSelector(blockId))
                .valueMaybe();
            var nativeSchemas = snapshot
                .getLoadable(collectionTableSchemaNativePathMapSelector(collectionId !== null && collectionId !== void 0 ? collectionId : ''))
                .valueMaybe();
            if (!nativeSchemas)
                return;
            var startSchema = nativeSchemas[NativePath.SHIFT_START_AT];
            var endSchema = nativeSchemas[NativePath.SHIFT_END_AT];
            var startSchemaId = startSchema === null || startSchema === void 0 ? void 0 : startSchema.id;
            var endSchemaId = endSchema === null || endSchema === void 0 ? void 0 : endSchema.id;
            if (!startSchemaId || !endSchemaId)
                return;
            var canEditStartSchema = (startSchema === null || startSchema === void 0 ? void 0 : startSchema.canEdit) || (startSchema === null || startSchema === void 0 ? void 0 : startSchema.canOnlyUpdateEmpty);
            var canEditEndSchema = (endSchema === null || endSchema === void 0 ? void 0 : endSchema.canEdit) || (endSchema === null || endSchema === void 0 ? void 0 : endSchema.canOnlyUpdateEmpty);
            var canPrefillTime = canEditStartSchema && canEditEndSchema;
            // Only prefill time in request when both schemas have edit permission
            if (canPrefillTime) {
                schemaDefaults.push({
                    schemaId: startSchemaId,
                    newValues: canPrefillTime ? [startTime] : [],
                }, {
                    schemaId: endSchemaId,
                    newValues: canPrefillTime ? [endTime] : [],
                });
            }
            if (groupByObj) {
                var currentGroupBy = snapshot
                    .getLoadable(scheduleGroupBySelector(blockId))
                    .valueMaybe();
                var nativePath = currentGroupBy == ScheduleGroupBy.USER
                    ? NativePath.SHIFT_USER_ID
                    : currentGroupBy == ScheduleGroupBy.ROLE
                        ? NativePath.SHIFT_ROLE_IDS
                        : currentGroupBy == ScheduleGroupBy.LOCATION
                            ? NativePath.SHIFT_LOCATION_ID
                            : NativePath.SHIFT_TEMPLATE_ID;
                var groupBySchemaId = (_a = nativeSchemas[nativePath]) === null || _a === void 0 ? void 0 : _a.id;
                if (groupBySchemaId && currentGroupBy !== ScheduleGroupBy.TEMPLATE) {
                    schemaDefaults.push({
                        schemaId: groupBySchemaId,
                        newValues: [groupByObj],
                    });
                }
            }
            return schemaDefaults;
        };
    }, []);
    var findSourceAndDstShiftInfo = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (fromShiftCard, toColKey, toRecordKey, blockId, dataSource) {
            var _a, _b, _c, _d, _e, _f;
            var _g, _h;
            // 1. get source shift schema info
            var collectionId = snapshot
                .getLoadable(tableCollectionIdSelector(blockId))
                .valueMaybe();
            var nativeSchemas = snapshot
                .getLoadable(collectionTableSchemaNativePathMapSelector(collectionId !== null && collectionId !== void 0 ? collectionId : ''))
                .valueMaybe();
            if (!nativeSchemas)
                return;
            var timeZone = snapshot
                .getLoadable(scheduleTimeZoneSelector(blockId))
                .valueMaybe();
            var fromColKey = moment((_g = fromShiftCard.clockIn) !== null && _g !== void 0 ? _g : fromShiftCard.startAt)
                .tz(timeZone)
                .format('YYYY-MM-DD');
            // 2. get date to paste
            //    hack here, to replace string of date
            var dayDiff = moment(toColKey).diff(moment(fromColKey), 'days');
            var schemaOverrides = [];
            if (dayDiff !== 0) {
                // Handle start/end time change
                var startSchema = nativeSchemas[NativePath.SHIFT_START_AT];
                var endSchema = nativeSchemas[NativePath.SHIFT_END_AT];
                if (startSchema && endSchema) {
                    var scheduledFromDay = moment(fromShiftCard.startAt)
                        .tz(timeZone)
                        .format('YYYY-MM-DD');
                    var scheduledDayDiff = moment(toColKey).diff(moment(scheduledFromDay), 'days');
                    var newStartToPaste = moment(fromShiftCard.startAt)
                        .add(scheduledDayDiff, 'days')
                        .toISOString();
                    var newEndToPaste = moment(fromShiftCard.endAt)
                        .add(scheduledDayDiff, 'days')
                        .toISOString();
                    schemaOverrides = [
                        (_a = {
                                schemaId: startSchema.id,
                                type: RecordSchemaType.TIMESTAMP
                            },
                            _a[RecordSchemaType.TIMESTAMP] = newStartToPaste,
                            _a),
                        (_b = {
                                schemaId: endSchema.id,
                                type: RecordSchemaType.TIMESTAMP
                            },
                            _b[RecordSchemaType.TIMESTAMP] = newEndToPaste,
                            _b),
                    ];
                }
                // Handle clock in/out change
                var clockInSchema = nativeSchemas[NativePath.SHIFT_CLOCK_IN];
                var clockOutSchema = nativeSchemas[NativePath.SHIFT_CLOCK_OUT];
                if (fromShiftCard.clockIn && clockInSchema) {
                    var newClockInToPaste = moment(fromShiftCard.clockIn)
                        .add(dayDiff, 'days')
                        .toISOString();
                    schemaOverrides.push((_c = {
                            schemaId: clockInSchema.id,
                            type: RecordSchemaType.TIMESTAMP
                        },
                        _c[RecordSchemaType.TIMESTAMP] = newClockInToPaste,
                        _c));
                    if (fromShiftCard.clockOut && clockOutSchema) {
                        var newClockOutToPaste = moment(fromShiftCard.clockOut)
                            .add(dayDiff, 'days')
                            .toISOString();
                        schemaOverrides.push((_d = {
                                schemaId: clockOutSchema.id,
                                type: RecordSchemaType.TIMESTAMP
                            },
                            _d[RecordSchemaType.TIMESTAMP] = newClockOutToPaste,
                            _d));
                    }
                }
            }
            // 3. possibly change group by field
            var proposedGroupByField = (_h = dataSource.find(function (r) { return r.groupByRow.rowKey === toRecordKey; })) === null || _h === void 0 ? void 0 : _h.groupByRow.rowKey;
            var currentGroupBy = snapshot
                .getLoadable(scheduleGroupBySelector(blockId))
                .valueMaybe();
            var nativePath = currentGroupBy == ScheduleGroupBy.USER
                ? NativePath.SHIFT_USER_ID
                : currentGroupBy == ScheduleGroupBy.LOCATION
                    ? NativePath.SHIFT_LOCATION_ID
                    : currentGroupBy == ScheduleGroupBy.ROLE
                        ? NativePath.SHIFT_ROLE_IDS
                        : currentGroupBy == ScheduleGroupBy.TEMPLATE
                            ? NativePath.SHIFT_TEMPLATE_ID
                            : null;
            if (nativePath == null)
                return;
            var nativeGroupBy = nativeSchemas[nativePath];
            if (!(nativeGroupBy === null || nativeGroupBy === void 0 ? void 0 : nativeGroupBy.canEdit)) {
                toast('Permission for Group By column required.', {
                    type: 'error',
                    position: toast.POSITION.BOTTOM_CENTER,
                });
                return;
            }
            if (currentGroupBy == ScheduleGroupBy.ROLE) {
                schemaOverrides.push((_e = {
                        schemaId: nativeGroupBy.id,
                        type: RecordSchemaType.UUIDS
                    },
                    _e[RecordSchemaType.UUIDS] = proposedGroupByField === SCHEDULE_UNASSIGNED_KEY
                        ? []
                        : proposedGroupByField,
                    _e));
            }
            else {
                schemaOverrides.push((_f = {
                        schemaId: nativeGroupBy.id,
                        type: RecordSchemaType.UUID
                    },
                    _f[RecordSchemaType.UUID] = proposedGroupByField === SCHEDULE_UNASSIGNED_KEY
                        ? null
                        : proposedGroupByField,
                    _f));
            }
            return {
                schemaOverrides: schemaOverrides,
            };
        };
    }, []);
    var _d = usePageApi(), copyRecord = _d.copyRecord, updateCollectionRecords = _d.updateCollectionRecords;
    var getNonSchemaOverrides = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (scheduleCollectionId, schemaOverrides) { return __awaiter(void 0, void 0, void 0, function () {
            var collectionSchemas, locationSchema, locationRid, locationTz, _a, shiftTzNonSchemaProp;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        collectionSchemas = snapshot
                            .getLoadable(collectionTableSchemasState(scheduleCollectionId))
                            .valueMaybe();
                        locationSchema = collectionSchemas === null || collectionSchemas === void 0 ? void 0 : collectionSchemas.find(function (schema) { return (schema === null || schema === void 0 ? void 0 : schema.nativePath) === NativePath.SHIFT_LOCATION_ID; });
                        locationRid = (_b = schemaOverrides === null || schemaOverrides === void 0 ? void 0 : schemaOverrides.find(function (schema) { return schema.schemaId === (locationSchema === null || locationSchema === void 0 ? void 0 : locationSchema.id); })) === null || _b === void 0 ? void 0 : _b.uuid;
                        if (!locationRid) return [3 /*break*/, 4];
                        if (!locationRid) return [3 /*break*/, 2];
                        return [4 /*yield*/, getLocationTimezone(locationRid)];
                    case 1:
                        _a = _c.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        _a = null;
                        _c.label = 3;
                    case 3:
                        locationTz = _a;
                        if (locationTz === null || locationTz === void 0 ? void 0 : locationTz.zoneId) {
                            shiftTzNonSchemaProp = {
                                schema: NonSchemaNativePath.SHIFT_TIME_ZONE_ID,
                                zoneId: locationTz.zoneId,
                            };
                            return [2 /*return*/, [shiftTzNonSchemaProp]];
                        }
                        _c.label = 4;
                    case 4: return [2 /*return*/, []];
                }
            });
        }); };
    }, [getLocationTimezone]);
    var dragShiftByDefault = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (blockId, fromShiftCard, fromColumnKey, toColKey, toRecordKey, dataSource) { return __awaiter(void 0, void 0, void 0, function () {
            var scheduleCollectionId, fromShiftCardId, foundInfo, schemaOverrides, request, tempDataSource, pastedCard, fromRecordIdx, toRecordIdx, resp;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        scheduleCollectionId = snapshot
                            .getLoadable(tableCollectionIdSelector(blockId))
                            .valueMaybe();
                        if (!scheduleCollectionId)
                            return [2 /*return*/];
                        fromShiftCardId = fromShiftCard.rid;
                        foundInfo = findSourceAndDstShiftInfo(fromShiftCard, toColKey, toRecordKey, blockId, dataSource);
                        if (!foundInfo)
                            return [2 /*return*/];
                        schemaOverrides = foundInfo.schemaOverrides;
                        _a = {
                            recordId: fromShiftCardId,
                            accountId: accountId,
                            collectionId: scheduleCollectionId,
                            collectionType: CollectionType.SHIFT,
                            schemaProperties: schemaOverrides
                        };
                        return [4 /*yield*/, getNonSchemaOverrides(scheduleCollectionId, schemaOverrides)];
                    case 1:
                        request = (_a.nonSchemaProperties = _b.sent(),
                            _a);
                        tempDataSource = cloneDeep(dataSource);
                        pastedCard = cloneDeep(fromShiftCard);
                        pastedCard.rid = fromShiftCardId;
                        fromRecordIdx = tempDataSource.findIndex(function (record) {
                            if (record[fromColumnKey]) {
                                var idx = record[fromColumnKey].findIndex(function (rid) { return rid === fromShiftCard.rid; });
                                if (idx >= 0) {
                                    // innerIdx = idx
                                    return true;
                                }
                            }
                        });
                        toRecordIdx = tempDataSource.findIndex(function (record) { return record.groupByRow.rowKey === toRecordKey; });
                        if (fromRecordIdx < 0 || toRecordIdx < 0)
                            return [2 /*return*/];
                        return [4 /*yield*/, updateCollectionRecords(request)];
                    case 2:
                        resp = _b.sent();
                        if (resp) {
                            refetchCollection(scheduleCollectionId);
                        }
                        return [2 /*return*/];
                }
            });
        }); };
    }, [
        accountId,
        findSourceAndDstShiftInfo,
        refetchCollection,
        updateCollectionRecords,
        getNonSchemaOverrides,
    ]);
    var pasteShiftByDefault = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (blockId, colKeyToPaste, recordKeyToPaste, dataSource) { return __awaiter(void 0, void 0, void 0, function () {
            var selectedShifts, scheduleCollectionId;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        selectedShifts = (_a = snapshot.getLoadable(selectedShiftsState(blockId)).valueMaybe()) !== null && _a !== void 0 ? _a : [];
                        scheduleCollectionId = snapshot
                            .getLoadable(tableCollectionIdSelector(blockId))
                            .valueMaybe();
                        if (!scheduleCollectionId)
                            return [2 /*return*/];
                        return [4 /*yield*/, Promise.all(selectedShifts.map(function (shiftRId) { return __awaiter(void 0, void 0, void 0, function () {
                                var copiedCard, recordIdxToPaste, foundInfo, schemaOverrides, newRecordId, request;
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            copiedCard = snapshot
                                                .getLoadable(scheduleShiftCardBasicInfoSelector({
                                                type: CollectionTableType.TABLE,
                                                collectionId: scheduleCollectionId,
                                                recordId: shiftRId,
                                            }))
                                                .valueMaybe();
                                            if (!copiedCard)
                                                return [2 /*return*/];
                                            recordIdxToPaste = dataSource.findIndex(function (record) { return record.groupByRow.rowKey == recordKeyToPaste; });
                                            if (recordIdxToPaste < 0)
                                                return [2 /*return*/];
                                            foundInfo = findSourceAndDstShiftInfo(copiedCard, colKeyToPaste, recordKeyToPaste, blockId, dataSource);
                                            if (!foundInfo)
                                                return [2 /*return*/];
                                            schemaOverrides = foundInfo.schemaOverrides;
                                            newRecordId = uuidv4();
                                            _a = {
                                                accountId: accountId,
                                                proposedRecordId: newRecordId,
                                                fromRecordId: shiftRId,
                                                collectionId: scheduleCollectionId,
                                                collectionType: CollectionType.SHIFT,
                                                schemaOverrides: schemaOverrides
                                            };
                                            return [4 /*yield*/, getNonSchemaOverrides(scheduleCollectionId, schemaOverrides)];
                                        case 1:
                                            request = (_a.nonSchemaOverrides = _b.sent(),
                                                _a);
                                            return [4 /*yield*/, copyRecord(request)];
                                        case 2:
                                            _b.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); }))];
                    case 1:
                        _b.sent();
                        if (selectedShifts.length) {
                            refetchCollection(scheduleCollectionId);
                        }
                        return [2 /*return*/];
                }
            });
        }); };
    }, [accountId, copyRecord, findSourceAndDstShiftInfo, refetchCollection, getNonSchemaOverrides]);
    var getBulkEditInfo = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (blockId) { return __awaiter(void 0, void 0, void 0, function () {
            var selectedShifts, bulkEditInfo;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        selectedShifts = snapshot
                            .getLoadable(selectedShiftsState(blockId))
                            .valueMaybe();
                        if (!(selectedShifts === null || selectedShifts === void 0 ? void 0 : selectedShifts.length))
                            return [2 /*return*/];
                        return [4 /*yield*/, getBulkEditShiftsInfo({
                                blockId: blockId,
                                shiftRids: selectedShifts,
                            })];
                    case 1:
                        bulkEditInfo = _a.sent();
                        return [2 /*return*/, {
                                bulkEditModalData: bulkEditInfo,
                                recordIds: selectedShifts,
                            }];
                }
            });
        }); };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var getScheduleEvaluation = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot, set = _a.set;
        return function (start, end, blockId) { return __awaiter(void 0, void 0, void 0, function () {
            var release, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        release = snapshot.retain();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        return [4 /*yield*/, fetchScheduleEvaluation.mutateAsync({ start: start, end: end })];
                    case 2:
                        resp = _a.sent();
                        if (resp) {
                            set(scheduleEvaluationState(blockId), resp);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        release();
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var initTimeRange = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot, set = _a.set;
        return function (tableId, schedulingDisplaySettings) { return __awaiter(void 0, void 0, void 0, function () {
            var release, startDayOfWeek, timeRange, startMoment, endMoment;
            var _a;
            return __generator(this, function (_b) {
                release = snapshot.retain();
                try {
                    startDayOfWeek = (_a = schedulingDisplaySettings.startDayOfWeek) !== null && _a !== void 0 ? _a : getAccountStartDayOfWeek();
                    moment.defineLocale(tableId, { parentLocale: 'en' });
                    moment.updateLocale(tableId, {
                        week: {
                            dow: getWeekDayNumber(startDayOfWeek),
                        },
                    });
                    timeRange = snapshot
                        .getLoadable(scheduleTimeRangeState(tableId))
                        .valueMaybe();
                    if (!(timeRange === null || timeRange === void 0 ? void 0 : timeRange.length) ||
                        // Different start of week
                        moment(timeRange[0]).locale(tableId).day() !==
                            getWeekDayNumber(startDayOfWeek)) {
                        startMoment = initCurrentStartDay(startDayOfWeek, schedulingDisplaySettings.viewType, timeRange ? moment(timeRange[0]) : undefined);
                        endMoment = schedulingDisplaySettings.viewType !== ScheduleView.DAY
                            ? startMoment
                                .clone()
                                .add(scheduleViewToNumber(schedulingDisplaySettings.viewType), 'weeks')
                            : startMoment.clone().add(1, 'days');
                        timeRange = getTimeRange(startMoment, endMoment);
                        set(scheduleTimeRangeState(tableId), timeRange);
                    }
                }
                finally {
                    release();
                }
                return [2 /*return*/];
            });
        }); };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId]);
    var setShiftOpenCount = useCallback(function (shiftRid, openCount) { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, useSetShiftOpenCount.mutateAsync({
                        shiftRid: shiftRid,
                        openCount: openCount,
                    })];
                case 1:
                    data = _a.sent();
                    return [2 /*return*/, data !== undefined
                        // eslint-disable-next-line react-hooks/exhaustive-deps
                    ];
            }
        });
    }); }, []);
    var processScheduleTable = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (tableId, response, pagination, isBlock) {
            if (isBlock === void 0) { isBlock = !ScheduleReverseCollectionComponentSet.has(tableId); }
            var collection = response.collection, table = response.table, rowData = response.rowData, timeOffs = response.timeOffs;
            processTableCollectionIntoState(tableId, collection, pagination, isBlock);
            set(scheduleTableState(tableId), function (prev) {
                if (!(pagination === null || pagination === void 0 ? void 0 : pagination.page))
                    return table;
                return uniqBy(__spreadArray(__spreadArray([], prev, true), table, true), 'rowKey');
            });
            set(scheduleTableRowState(tableId), function (prev) {
                if (!(pagination === null || pagination === void 0 ? void 0 : pagination.page))
                    return rowData;
                return uniqBy(__spreadArray(__spreadArray([], prev, true), rowData, true), 'rowKey');
            });
            set(scheduleTimeOffsState(tableId), function (prev) {
                if (!(pagination === null || pagination === void 0 ? void 0 : pagination.page))
                    return timeOffs;
                return __assign(__assign({}, prev), timeOffs);
            });
        };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var fetchAndProcessScheduleTable = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (blockId, nextPage) { return __awaiter(void 0, void 0, void 0, function () {
            var blockDisplaySettings, viewDisplaySettings, scheduleRowLength, timeRange, searchQuery, pagination, request, resp;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        blockDisplaySettings = snapshot
                            .getLoadable(tableDisplaySettingsState(blockId))
                            .valueMaybe();
                        viewDisplaySettings = snapshot
                            .getLoadable(tableSchedulingDisplaySettingsState(blockId))
                            .valueMaybe();
                        scheduleRowLength = snapshot
                            .getLoadable(scheduleRowLengthSelector(blockId))
                            .valueMaybe();
                        timeRange = snapshot.getLoadable(scheduleTimeRangeState(blockId)).valueMaybe();
                        searchQuery = snapshot.getLoadable(tableSearchTextState(blockId)).valueMaybe();
                        if (!blockDisplaySettings ||
                            !viewDisplaySettings ||
                            !scheduleRowLength ||
                            !timeRange)
                            return [2 /*return*/];
                        pagination = {
                            page: nextPage
                                ? Math.max(Math.floor(scheduleRowLength / schedulePageSize), (_a = snapshot.getLoadable(nextPageOfTableState(blockId)).valueMaybe()) !== null && _a !== void 0 ? _a : 0)
                                : 0,
                            size: !nextPage && scheduleRowLength > schedulePageSize
                                ? scheduleRowLength
                                : schedulePageSize,
                        };
                        request = {
                            blockId: blockId,
                            start: timeRange[0],
                            end: timeRange[timeRange.length - 1],
                            displaySettings: blockDisplaySettings,
                            schedulingDisplaySettings: viewDisplaySettings,
                            pagination: pagination,
                            searchQuery: searchQuery || undefined,
                        };
                        return [4 /*yield*/, listScheduleBlock(request)];
                    case 1:
                        resp = _b.sent();
                        if (resp) {
                            processScheduleTable(blockId, resp, pagination);
                        }
                        return [2 /*return*/];
                }
            });
        }); };
    }, [listScheduleBlock, processScheduleTable]);
    return {
        processScheduleTable: processScheduleTable,
        scheduleStatVisibleOnChange: scheduleStatVisibleOnChange,
        getScheduleStatsSummaryData: getScheduleStatsSummaryData,
        changeCalView: changeCalView,
        getNewShiftDefaults: getNewShiftDefaults,
        pasteShiftByDefault: pasteShiftByDefault,
        dragShiftByDefault: dragShiftByDefault,
        getBulkEditInfo: getBulkEditInfo,
        getScheduleEvaluation: getScheduleEvaluation,
        applyScheduleDisplaySettings: applyScheduleDisplaySettings,
        initTimeRange: initTimeRange,
        setShiftOpenCount: setShiftOpenCount,
        fetchAndProcessScheduleTable: fetchAndProcessScheduleTable,
    };
};
export { useSchedule as useScheduleComponent };
