import { CollectionType } from 'src/shared';
export var SearchableCollectionTypeSet = new Set([
    CollectionType.CUSTOM,
    CollectionType.USER,
    CollectionType.LOCATION,
    CollectionType.ROLE,
    CollectionType.CONTACT,
    CollectionType.PROJECT,
    CollectionType.TASK,
    CollectionType.JOB,
    CollectionType.PLACEMENT,
]);
export var NoTitleColorCollectionTypeSet = new Set([
    CollectionType.SHIFT,
    CollectionType.DOCUMENT,
    CollectionType.TASK,
    CollectionType.JOB,
]);
export var TITLE_COLUMN_ID = 'title';
export var TITLE_COLUMN_WIDTH = 300;
export var ADD_NEW_COLUMN_COLUMN_ID = 'addNewColumnButton';
export var SELECT_RECORD_COLUMN_ID = 'selectRecordColumn';
export var SELECT_RECORD_COLUMN_WIDTH = 30;
export var BULK_EDIT_ICON = '<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1.25C1 1.11193 1.11193 1 1.25 1H7.25C7.38807 1 7.5 1.11193 7.5 1.25V1.75C7.5 1.88807 7.38807 2 7.25 2H1.25C1.11193 2 1 1.88807 1 1.75V1.25Z" fill="black"/><path d="M1 5.75043C1 5.61236 1.11193 5.50043 1.25 5.50043H10.75C10.8881 5.50043 11 5.61236 11 5.75043V6.25043C11 6.3885 10.8881 6.50043 10.75 6.50043H1.25C1.11193 6.50043 1 6.3885 1 6.25043V5.75043Z" fill="black"/><path d="M1 10.25C1 10.1119 1.11193 10 1.25 10H3.75C3.88807 10 4 10.1119 4 10.25V10.75C4 10.8881 3.88807 11 3.75 11H1.25C1.11193 11 1 10.8881 1 10.75V10.25Z" fill="black"/></svg>';
export var BULK_DOWNLOAD_ICON = '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 1 14 14" height="14" width="14"><path stroke="black" stroke-linecap="round" d="M2.97421 5.10519C2.4942 5.15851 2.11286 5.54017 2.05817 6.02002C1.82208 8.09135 1.82208 10.0248 2.05817 12.0961C2.11286 12.5759 2.4942 12.9576 2.97421 13.0109C5.0322 13.2395 8.9678 13.2395 11.0258 13.0109C11.5058 12.9576 11.8871 12.5759 11.9418 12.0961C12.1779 10.0248 12.1779 8.09135 11.9418 6.02002C11.8871 5.54017 11.5058 5.15851 11.0258 5.10519"></path><path stroke="black" stroke-linecap="round" stroke-linejoin="round" d="M7 0.817627L7 8.26514"></path><path stroke="black" stroke-linecap="round" stroke-linejoin="round" d="M9.35498 6.24365C8.63942 7.14776 8.15204 7.56849 7.42572 8.11876C7.16872 8.31347 6.83156 8.31347 6.57455 8.11876C5.84823 7.56849 5.36085 7.14776 4.64529 6.24365"></path></svg>';
