var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useCallback } from 'react';
import { useRecoilCallback, useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { accountAccessGroupDetailsState, accountDetailState, allEnabledFeaturesState, appSessionState, collectionOptionsState, currentAccessGroupState, orgListState, } from 'src/state';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from 'react-query';
import { taskGroupListState, taskTemplateListState } from 'src/components/Settings/Tasks/atom';
import { useAccountApi, useSessionApi } from '../api';
var useAppSession = function () {
    var navigate = useNavigate();
    var logout = useAuth0().logout;
    var queryClient = useQueryClient();
    var _a = useSessionApi(), createAppSession = _a.createAppSession, deleteAppSession = _a.deleteAppSession;
    var _b = useAccountApi(), getAccountDetail = _b.getAccountDetail, getCurrentUserFetch = _b.getCurrentUserFetch, getEnabledFeatureFlags = _b.getEnabledFeatureFlags, getCurrentAccessGroup = _b.getCurrentAccessGroup, getAccountAccessDataFetch = _b.getAccountAccessDataFetch, getCollectionOptions = _b.getCollectionOptions;
    var _c = useRecoilState(appSessionState), appSession = _c[0], setAppSession = _c[1];
    var setAccountDetail = useSetRecoilState(accountDetailState);
    var setCurrentAccessGroup = useSetRecoilState(currentAccessGroupState);
    var setAccountAccessGroupDetails = useSetRecoilState(accountAccessGroupDetailsState);
    var setAllEnabledFeatures = useSetRecoilState(allEnabledFeaturesState);
    var setCollectionOptions = useSetRecoilState(collectionOptionsState);
    var resetOrgState = useResetRecoilState(orgListState);
    var clearAppCache = useCallback(function () {
        var args_1 = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args_1[_i] = arguments[_i];
        }
        return __awaiter(void 0, __spreadArray([], args_1, true), void 0, function (keepAuth) {
            var appSessionId, key;
            if (keepAuth === void 0) { keepAuth = false; }
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        appSessionId = appSession === null || appSession === void 0 ? void 0 : appSession.id;
                        if (!appSessionId) return [3 /*break*/, 2];
                        return [4 /*yield*/, deleteAppSession(appSessionId)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!keepAuth) {
                            localStorage.clear();
                        }
                        else {
                            for (key in localStorage) {
                                if (!key.includes('auth0') && key !== 'accountId') {
                                    localStorage.removeItem(key);
                                }
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    }, [appSession, deleteAppSession]);
    var logoutAndClearCache = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setAppSession(undefined);
                    resetOrgState();
                    clearAppCache();
                    return [4 /*yield*/, logout()];
                case 1:
                    _a.sent();
                    queryClient.clear();
                    navigate('/login', { replace: true });
                    return [2 /*return*/];
            }
        });
    }); }, [navigate, resetOrgState, setAppSession, queryClient, clearAppCache, logout]);
    var createSessionForAccount = useCallback(function (accountId) { return __awaiter(void 0, void 0, void 0, function () {
        var currentUser, session, e_1, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 6, , 7]);
                    return [4 /*yield*/, getCurrentUserFetch(accountId)];
                case 1:
                    currentUser = _a.sent();
                    if (!currentUser) return [3 /*break*/, 5];
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, createAppSession({
                            accountId: accountId,
                            accountUserId: currentUser.id,
                        })];
                case 3:
                    session = _a.sent();
                    if (session) {
                        localStorage.setItem('appSession', JSON.stringify(session));
                        setAppSession({
                            id: session.id,
                            accountId: accountId,
                            accountUserId: currentUser.id,
                        });
                    }
                    return [3 /*break*/, 5];
                case 4:
                    e_1 = _a.sent();
                    setAppSession(undefined);
                    return [3 /*break*/, 5];
                case 5: return [3 /*break*/, 7];
                case 6:
                    e_2 = _a.sent();
                    setAppSession(undefined);
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    }); }, [getCurrentUserFetch, createAppSession, setAppSession]);
    var fetchAccountDetailsForSession = useCallback(function (accountId) { return __awaiter(void 0, void 0, void 0, function () {
        var accessGroup, data, accountDetail, enabledFeatures_1, options, e_3;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 7, , 8]);
                    return [4 /*yield*/, getCurrentAccessGroup()];
                case 1:
                    accessGroup = _b.sent();
                    setCurrentAccessGroup(accessGroup);
                    if (!((accessGroup === null || accessGroup === void 0 ? void 0 : accessGroup.generalPermissions.ACCESS_GROUPS) === true)) return [3 /*break*/, 3];
                    return [4 /*yield*/, getAccountAccessDataFetch(accountId)];
                case 2:
                    data = _b.sent();
                    if (data) {
                        setAccountAccessGroupDetails(data.accessGroups);
                    }
                    _b.label = 3;
                case 3: return [4 /*yield*/, getAccountDetail(accountId)];
                case 4:
                    accountDetail = _b.sent();
                    setAccountDetail(accountDetail);
                    return [4 /*yield*/, getEnabledFeatureFlags()];
                case 5:
                    enabledFeatures_1 = (_a = (_b.sent())) !== null && _a !== void 0 ? _a : [];
                    setAllEnabledFeatures(function (prev) {
                        var _a;
                        return (__assign(__assign({}, prev), (_a = {}, _a[accountId] = enabledFeatures_1, _a)));
                    });
                    return [4 /*yield*/, getCollectionOptions(accountId)];
                case 6:
                    options = _b.sent();
                    setCollectionOptions(options !== null && options !== void 0 ? options : []);
                    return [3 /*break*/, 8];
                case 7:
                    e_3 = _b.sent();
                    logoutAndClearCache();
                    return [3 /*break*/, 8];
                case 8: return [2 /*return*/];
            }
        });
    }); }, [
        getCurrentAccessGroup,
        setCurrentAccessGroup,
        getAccountAccessDataFetch,
        setAccountAccessGroupDetails,
        getAccountDetail,
        setAccountDetail,
        getEnabledFeatureFlags,
        setAllEnabledFeatures,
        getCollectionOptions,
        setCollectionOptions,
        logoutAndClearCache,
    ]);
    var resetRecoilStateOnAccountSwitch = useRecoilCallback(function (_a) {
        var reset = _a.reset;
        return function () {
            reset(accountDetailState);
            reset(appSessionState);
            reset(taskTemplateListState);
            reset(taskGroupListState);
        };
    }, []);
    var switchAccountSession = useCallback(function (newAccountId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, clearAppCache(true)];
                case 1:
                    _a.sent();
                    resetRecoilStateOnAccountSwitch();
                    return [4 /*yield*/, createSessionForAccount(newAccountId)];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, fetchAccountDetailsForSession(newAccountId)];
                case 3:
                    _a.sent();
                    navigate('/home', { replace: true });
                    return [2 /*return*/];
            }
        });
    }); }, [
        clearAppCache,
        createSessionForAccount,
        fetchAccountDetailsForSession,
        navigate,
        resetRecoilStateOnAccountSwitch,
    ]);
    return {
        clearAppCache: clearAppCache,
        switchAccountSession: switchAccountSession,
        createSessionForAccount: createSessionForAccount,
        fetchAccountDetailsForSession: fetchAccountDetailsForSession,
        logoutAndClearCache: logoutAndClearCache,
    };
};
export { useAppSession };
