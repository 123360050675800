var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useCallback, useState } from 'react';
import { createContainer } from 'unstated-next';
import { v4 as uuidv4 } from 'uuid';
import { produce } from 'immer';
import { cloneDeep } from 'lodash';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { accountIdSelector } from 'src/state';
import { DayOfWeek, OvertimeExclusionType, OvertimeRuleType, SpecialOvertimeRuleWithNoMultiplier, } from '../../components/Settings/Compliance/Interface';
import { TimeoffAccrualType } from '../../shared';
import { useSetting } from '../api';
var useCompliancePage = function () {
    var _a = useState([]), overtimeGroups = _a[0], setOvertimeGroups = _a[1];
    var _b = useState(), overtimeCalculationSetting = _b[0], setOvertimeCalculationSetting = _b[1];
    var _c = useState([]), overtimeExclusionConditionSchemas = _c[0], setOvertimeExclusionConditionSchemas = _c[1];
    var _d = useState(), billOvertimeCalculationSetting = _d[0], setBillOvertimeCalculationSetting = _d[1];
    var _e = useState(false), weeklyOvertimeAverageEnabledSetting = _e[0], setWeeklyOvertimeAverageEnabledSetting = _e[1];
    var _f = useState([]), breakPolicies = _f[0], setBreakPolicies = _f[1];
    var _g = useState([]), timeOffPolicies = _g[0], setTimeOfftimeOffPolicies = _g[1];
    var _h = useState([]), timeOffTypes = _h[0], setTimeOffTypes = _h[1];
    var _j = useState([]), dateSchemas = _j[0], setDateSchemas = _j[1];
    var accountId = useRecoilValue(accountIdSelector);
    var _k = useSetting(), useDeleteOvertimePolicy = _k.useDeleteOvertimePolicy, useDeleteBreakPolicy = _k.useDeleteBreakPolicy, useDeleteTimeoffPolicy = _k.useDeleteTimeoffPolicy, useArchiveBreakRule = _k.useArchiveBreakRule, useDeleteTimeoffRule = _k.useDeleteTimeoffRule, useApplyAllOvertimeSetting = _k.useApplyAllOvertimeSetting, useApplyAllTimeOffSetting = _k.useApplyAllTimeOffSetting, useArchiveTimeoffType = _k.useArchiveTimeoffType, useSaveTimeoffSetting = _k.useSaveTimeoffSetting, useSaveOvertimeSetting = _k.useSaveOvertimeSetting, saveOvertimeCalculationSetting = _k.saveOvertimeCalculationSetting, saveBillOvertimeCalculationSetting = _k.saveBillOvertimeCalculationSetting, useSaveWeeklyAverageOvertimeEnabled = _k.useSaveWeeklyAverageOvertimeEnabled;
    var saveTimeoffSetting = useSaveTimeoffSetting();
    var saveOvertimeSetting = useSaveOvertimeSetting();
    var saveWeeklyAverageOvertimeEnabled = useSaveWeeklyAverageOvertimeEnabled();
    var addOvertimeGroup = useCallback(function () {
        var nextState = produce(overtimeGroups, function (draft) {
            draft.push({
                name: 'Untitled Overtime Policy',
                wageOvertimeRule: {
                    dailySettings: [],
                    weeklySettings: [],
                    dayOfWeekSettings: [],
                    specialSettings: [],
                },
                accountId: accountId,
                exclusions: [],
            });
        });
        setOvertimeGroups(nextState);
    }, [accountId, overtimeGroups]);
    var changeOvertimeGroupName = useCallback(function (groupIndex, value) {
        var nextState = produce(overtimeGroups, function (draft) {
            var targetGroup = draft[groupIndex];
            if (targetGroup) {
                targetGroup.name = value;
            }
        });
        setOvertimeGroups(nextState);
    }, [overtimeGroups]);
    var deleteOvertimeGroup = useCallback(function (groupIndex, policyId) {
        var nextState = produce(overtimeGroups, function (draft) {
            draft.splice(groupIndex, 1);
        });
        if (policyId) {
            useDeleteOvertimePolicy.mutate(policyId);
        }
        setOvertimeGroups(nextState);
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [overtimeGroups]);
    var applyToAllUsers = useCallback(function (policyId) {
        useApplyAllOvertimeSetting.mutate({
            accountId: accountId,
            policyId: policyId,
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [overtimeGroups]);
    var addOvertimeRule = useCallback(function (index, type) {
        var nextState = produce(overtimeGroups, function (draft) {
            var targetGroup = draft[index];
            if (targetGroup) {
                switch (type) {
                    case 'DAILY':
                        targetGroup.wageOvertimeRule.dailySettings.push({
                            rule: 'DAILY',
                            multiplier: 'OVERTIME',
                            threshold: '8',
                        });
                        break;
                    case 'WEEKLY':
                        targetGroup.wageOvertimeRule.weeklySettings.push({
                            rule: 'WEEKLY',
                            multiplier: 'OVERTIME',
                            threshold: '40',
                        });
                        break;
                    case 'DAY_OF_WEEK':
                        targetGroup.wageOvertimeRule.dayOfWeekSettings.push({
                            rule: 'DAY_OF_WEEK',
                            dayOfWeek: DayOfWeek.MONDAY,
                            multiplier: 'OVERTIME',
                        });
                        break;
                    case 'SPECIAL':
                        targetGroup.wageOvertimeRule.specialSettings.push({
                            rule: SpecialOvertimeRuleWithNoMultiplier.SEVEN_DAY_RULE,
                        });
                        break;
                }
            }
        });
        setOvertimeGroups(nextState);
    }, [overtimeGroups]);
    var getTargetOvertimeRule = function (obj, type, groupIndex, ruleIndex) {
        var targetGroup = obj[groupIndex].wageOvertimeRule;
        if (!targetGroup) {
            return;
        }
        switch (type) {
            case 'DAILY':
                return targetGroup.dailySettings[ruleIndex];
            case 'WEEKLY':
                return targetGroup.weeklySettings[ruleIndex];
            case 'DAY_OF_WEEK':
                return targetGroup.dayOfWeekSettings[ruleIndex];
            case 'SPECIAL':
                return targetGroup.specialSettings[ruleIndex];
        }
    };
    var changeOvertimeRuleMultiplier = useCallback(function (groupIndex, ruleIndex, type, value) {
        var nextState = produce(overtimeGroups, function (draft) {
            var targetRule = getTargetOvertimeRule(draft, type, groupIndex, ruleIndex);
            if (!targetRule) {
                return;
            }
            targetRule.multiplier = value;
        });
        setOvertimeGroups(nextState);
    }, [overtimeGroups]);
    var changeOvertimeRuleThreshold = useCallback(function (groupIndex, ruleIndex, type, value) {
        var nextState = produce(overtimeGroups, function (draft) {
            var targetRule = getTargetOvertimeRule(draft, type, groupIndex, ruleIndex);
            if (!targetRule || !('threshold' in targetRule)) {
                return;
            }
            targetRule.threshold = value;
        });
        setOvertimeGroups(nextState);
    }, [overtimeGroups]);
    var changeOvertimeRuleDayOfWeek = useCallback(function (groupIndex, ruleIndex, value) {
        var nextState = produce(overtimeGroups, function (draft) {
            var targetRule = getTargetOvertimeRule(draft, OvertimeRuleType.DAY_OF_WEEK, groupIndex, ruleIndex);
            if (!targetRule || !('dayOfWeek' in targetRule)) {
                return;
            }
            targetRule.dayOfWeek = value;
        });
        setOvertimeGroups(nextState);
    }, [overtimeGroups]);
    var changeOvertimeRuleSpecialRule = useCallback(function (groupIndex, ruleIndex, value) {
        var nextState = produce(overtimeGroups, function (draft) {
            var targetRule = getTargetOvertimeRule(draft, OvertimeRuleType.SPECIAL, groupIndex, ruleIndex);
            if (!targetRule || !('rule' in targetRule)) {
                return;
            }
            targetRule.rule = value;
        });
        setOvertimeGroups(nextState);
    }, [overtimeGroups]);
    var deleteOvertimeRule = useCallback(function (groupIndex, ruleIndex, type) {
        var nextState = produce(overtimeGroups, function (draft) {
            var targetGroup = draft[groupIndex].wageOvertimeRule;
            if (!targetGroup) {
                return;
            }
            switch (type) {
                case 'DAILY':
                    targetGroup.dailySettings.splice(ruleIndex, 1);
                    break;
                case 'WEEKLY':
                    targetGroup.weeklySettings.splice(ruleIndex, 1);
                    break;
                case 'DAY_OF_WEEK':
                    targetGroup.dayOfWeekSettings.splice(ruleIndex, 1);
                    break;
                case 'SPECIAL':
                    targetGroup.specialSettings.splice(ruleIndex, 1);
            }
        });
        setOvertimeGroups(nextState);
    }, [overtimeGroups]);
    var changeOvertimeRuleType = useCallback(function (groupIndex, ruleIndex, values) {
        deleteOvertimeRule(groupIndex, ruleIndex, values[0]);
        addOvertimeRule(groupIndex, values[1]);
    }, [addOvertimeRule, deleteOvertimeRule]);
    var convertOvertimePolicyDurationsBeforeSaving = useCallback(function () {
        var copy = cloneDeep(overtimeGroups);
        copy.forEach(function (item) {
            item.wageOvertimeRule.dailySettings.forEach(function (rule) { return (rule.threshold = 'PT' + rule.threshold + 'H'); });
        });
        copy.forEach(function (item) {
            item.wageOvertimeRule.weeklySettings.forEach(function (rule) { return (rule.threshold = 'PT' + rule.threshold + 'H'); });
        });
        return copy;
    }, [overtimeGroups]);
    var saveOvertimeSettings = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var policies, overtimeRequest, hasIncompletePolicy, resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (overtimeCalculationSetting) {
                        saveOvertimeCalculationSetting({
                            overtimeCalculationSetting: overtimeCalculationSetting,
                        });
                    }
                    if (billOvertimeCalculationSetting) {
                        saveBillOvertimeCalculationSetting({
                            overtimeCalculationSetting: billOvertimeCalculationSetting,
                        });
                    }
                    saveWeeklyAverageOvertimeEnabled.mutate({
                        enabled: weeklyOvertimeAverageEnabledSetting,
                    });
                    policies = convertOvertimePolicyDurationsBeforeSaving();
                    overtimeRequest = {
                        accountId: accountId,
                        policies: policies,
                    };
                    hasIncompletePolicy = false;
                    policies.forEach(function (policy) {
                        policy.exclusions.forEach(function (exclusion) {
                            var policyName = policy.name;
                            if (!exclusion.schemaId || !exclusion.clauseValue) {
                                toast("Please complete condition setting for ".concat(policyName, "."), {
                                    type: 'error',
                                    position: toast.POSITION.BOTTOM_CENTER,
                                });
                                hasIncompletePolicy = true;
                                return;
                            }
                        });
                    });
                    if (hasIncompletePolicy) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, saveOvertimeSetting.mutateAsync(overtimeRequest)];
                case 1:
                    resp = _a.sent();
                    return [2 /*return*/, resp
                        // eslint-disable-next-line react-hooks/exhaustive-deps
                    ];
            }
        });
    }); }, [
        accountId,
        convertOvertimePolicyDurationsBeforeSaving,
        overtimeCalculationSetting,
        billOvertimeCalculationSetting,
        weeklyOvertimeAverageEnabledSetting,
    ]);
    var addOvertimeExclusion = useCallback(function (groupIndex) {
        var nextState = produce(overtimeGroups, function (draft) {
            var targetGroup = draft[groupIndex];
            if (targetGroup) {
                targetGroup.exclusions.push({
                    id: uuidv4(),
                    accountId: accountId,
                    type: OvertimeExclusionType.PAY,
                });
            }
        });
        setOvertimeGroups(nextState);
    }, [accountId, overtimeGroups]);
    var updateOvertimeExclusion = useCallback(function (groupIndex, exclusionId, newSetting) {
        var nextState = produce(overtimeGroups, function (draft) {
            var targetGroup = draft[groupIndex];
            var targetExclusionIndex = targetGroup.exclusions.findIndex(function (item) { return item.id === exclusionId; });
            if (targetGroup && targetExclusionIndex !== -1) {
                targetGroup.exclusions[targetExclusionIndex] = newSetting;
            }
        });
        setOvertimeGroups(nextState);
    }, [overtimeGroups]);
    var deleteOvertimeExclusion = useCallback(function (groupIndex, exclusionId) {
        var nextState = produce(overtimeGroups, function (draft) {
            var targetGroup = draft[groupIndex];
            if (targetGroup) {
                var newSetting = targetGroup.exclusions.filter(function (item) { return item.id !== exclusionId; });
                targetGroup.exclusions = newSetting;
            }
        });
        setOvertimeGroups(nextState);
    }, [overtimeGroups]);
    var addBreakPolicy = useCallback(function () {
        var nextState = produce(breakPolicies, function (draft) {
            draft.push({
                policyId: uuidv4(),
                policyName: 'Untitled Break Policy',
                rules: [],
                isDefault: false,
            });
        });
        setBreakPolicies(nextState);
    }, [breakPolicies]);
    var changeBreakPolicyName = useCallback(function (policyId, value) {
        var nextState = produce(breakPolicies, function (draft) {
            var targetPolicy = draft.find(function (policy) { return policy.policyId === policyId; });
            if (targetPolicy) {
                targetPolicy.policyName = value;
            }
        });
        setBreakPolicies(nextState);
    }, [breakPolicies]);
    var changeBreakPolicyIntoDefault = useCallback(function (policyId) {
        var nextState = produce(breakPolicies, function (draft) {
            var targetPolicy = draft.find(function (policy) { return policy.policyId === policyId; });
            if (targetPolicy) {
                var exisitingDefaultPolicy = draft.find(function (policy) { return policy.isDefault === true; });
                if (exisitingDefaultPolicy) {
                    exisitingDefaultPolicy.isDefault = false;
                }
                targetPolicy.isDefault = true;
            }
        });
        setBreakPolicies(nextState);
    }, [breakPolicies]);
    var deleteBreakPolicy = useCallback(function (policyId) {
        useDeleteBreakPolicy.mutate(policyId, {
            onSuccess: function () {
                setBreakPolicies(breakPolicies.filter(function (policy) { return policy.policyId !== policyId; }));
            },
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [breakPolicies]);
    var addBreakRule = useCallback(function (policyId) {
        var nextState = produce(breakPolicies, function (draft) {
            var targetPolicy = draft.find(function (policy) { return policy.policyId === policyId; });
            if (targetPolicy) {
                targetPolicy.rules.push({
                    id: uuidv4(),
                    name: 'Untitled Break',
                    type: 'Unpaid',
                    lengthInMinutes: 0,
                    periodInHours: 0,
                });
            }
        });
        setBreakPolicies(nextState);
    }, [breakPolicies]);
    var getTargetBreakRule = function (obj, policyId, ruleId) {
        var targetPolicy = obj.find(function (policy) { return policy.policyId === policyId; });
        if (!targetPolicy) {
            return;
        }
        var targetRule = targetPolicy.rules.find(function (rule) { return rule.id === ruleId; });
        return targetRule;
    };
    var changeBreakRuleName = useCallback(function (policyId, ruleId, value) {
        var nextState = produce(breakPolicies, function (draft) {
            var targetRule = getTargetBreakRule(draft, policyId, ruleId);
            if (!targetRule) {
                return;
            }
            targetRule.name = value;
        });
        setBreakPolicies(nextState);
    }, [breakPolicies]);
    var changeBreakRuleBreakTime = useCallback(function (policyId, ruleId, value) {
        var nextState = produce(breakPolicies, function (draft) {
            var targetRule = getTargetBreakRule(draft, policyId, ruleId);
            if (!targetRule) {
                return;
            }
            targetRule.lengthInMinutes = value;
        });
        setBreakPolicies(nextState);
    }, [breakPolicies]);
    var changeBreakRulePayStatus = useCallback(function (policyId, ruleId, value) {
        var nextState = produce(breakPolicies, function (draft) {
            var targetRule = getTargetBreakRule(draft, policyId, ruleId);
            if (!targetRule) {
                return;
            }
            targetRule.type = value;
        });
        setBreakPolicies(nextState);
    }, [breakPolicies]);
    var changeBreakRuleTimePeriod = useCallback(function (policyId, ruleId, value) {
        var nextState = produce(breakPolicies, function (draft) {
            var targetRule = getTargetBreakRule(draft, policyId, ruleId);
            if (!targetRule) {
                return;
            }
            targetRule.periodInHours = value;
        });
        setBreakPolicies(nextState);
    }, [breakPolicies]);
    var deleteBreakRule = useCallback(function (policyId, ruleId) {
        var nextState = produce(breakPolicies, function (draft) {
            var targetPolicy = draft.find(function (policy) { return policy.policyId === policyId; });
            if (!targetPolicy) {
                return;
            }
            var targetRuleIndex = targetPolicy.rules.findIndex(function (rule) { return rule.id === ruleId; });
            if (targetRuleIndex < 0) {
                return;
            }
            targetPolicy.rules.splice(targetRuleIndex, 1);
        });
        useArchiveBreakRule.mutateAsync(ruleId, {
            onSuccess: function () {
                setBreakPolicies(nextState);
            },
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [breakPolicies]);
    var addTimeOffPolicy = useCallback(function () {
        var nextState = produce(timeOffPolicies, function (draft) {
            draft.push({
                policyId: uuidv4(),
                policyName: 'Untitled Time off Policy',
                rules: [],
                isDefault: false,
            });
        });
        setTimeOfftimeOffPolicies(nextState);
    }, [timeOffPolicies]);
    var duplicateTimeOffPolicy = useCallback(function (policyId) {
        var nextState = produce(timeOffPolicies, function (draft) {
            var targetPolicy = draft.find(function (policy) { return policy.policyId === policyId; });
            if (!targetPolicy) {
                return;
            }
            draft.push(__assign(__assign({}, targetPolicy), { policyId: uuidv4(), policyName: targetPolicy.policyName + ' (copy)', isDefault: false }));
        });
        setTimeOfftimeOffPolicies(nextState);
    }, [timeOffPolicies]);
    var addTimeOffType = useCallback(function () {
        var newId = uuidv4();
        var nextState = produce(timeOffTypes, function (draft) {
            draft.push({
                id: newId,
                name: 'Untitled Time off type',
            });
        });
        setTimeOffTypes(nextState);
        return newId;
    }, [timeOffTypes]);
    var deleteTimeOffType = useCallback(function (typeId) {
        useArchiveTimeoffType.mutateAsync(typeId, {
            onSuccess: function () {
                setTimeOffTypes(timeOffTypes.filter(function (item) { return item.id !== typeId; }));
            },
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timeOffTypes]);
    var changeTimeOffTypeName = useCallback(function (typeId, value) {
        var nextState = produce(timeOffTypes, function (draft) {
            var target = draft.find(function (item) { return item.id === typeId; });
            if (target) {
                target.name = value;
            }
        });
        setTimeOffTypes(nextState);
    }, [timeOffTypes]);
    var changeTimeOffPolicyName = useCallback(function (groupId, value) {
        var nextState = produce(timeOffPolicies, function (draft) {
            var targetPolicy = draft.find(function (policy) { return policy.policyId === groupId; });
            if (targetPolicy) {
                targetPolicy.policyName = value;
            }
        });
        setTimeOfftimeOffPolicies(nextState);
    }, [timeOffPolicies]);
    var setPolicyAsDefault = useCallback(function (policyId) {
        setTimeOfftimeOffPolicies(function (prev) {
            return prev.map(function (policy) { return (__assign(__assign({}, policy), { isDefault: policy.policyId === policyId })); });
        });
    }, []);
    var deleteTimeOffPolicy = useCallback(function (groupId) {
        useDeleteTimeoffPolicy.mutate(groupId, {
            onSuccess: function () {
                setTimeOfftimeOffPolicies(timeOffPolicies.filter(function (policy) { return policy.policyId !== groupId; }));
            },
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timeOffPolicies]);
    var addTimeOffRule = useCallback(function (policyId, typeId) {
        var nextState = produce(timeOffPolicies, function (draft) {
            var targetPolicy = draft.find(function (policy) { return policy.policyId === policyId; });
            if (targetPolicy) {
                var accrual = {
                    accruedHours: 0,
                    accrualPeriod: 0,
                    accrualType: TimeoffAccrualType.NULL,
                };
                targetPolicy.rules.push({
                    id: uuidv4(),
                    typeId: typeId,
                    policyId: policyId,
                    rule: accrual,
                });
            }
        });
        setTimeOfftimeOffPolicies(nextState);
    }, [timeOffPolicies]);
    var updateTimeOffRule = useCallback(function (policyId, updatedRule) {
        var nextState = produce(timeOffPolicies, function (draft) {
            var targetPolicy = draft.find(function (policy) { return policy.policyId === policyId; });
            if (!targetPolicy) {
                return;
            }
            var targetRule = targetPolicy.rules.find(function (rule) { return rule.id === updatedRule.id; });
            if (!targetRule) {
                return;
            }
            targetRule.rule = updatedRule.rule;
        });
        setTimeOfftimeOffPolicies(nextState);
    }, [timeOffPolicies]);
    var deleteTimeOffRule = useCallback(function (policyId, ruleId) {
        var nextState = produce(timeOffPolicies, function (draft) {
            var targetPolicy = draft.find(function (policy) { return policy.policyId === policyId; });
            if (!targetPolicy) {
                return;
            }
            var targetRuleIndex = targetPolicy.rules.findIndex(function (rule) { return rule.id === ruleId; });
            if (targetRuleIndex < 0) {
                return;
            }
            targetPolicy.rules.splice(targetRuleIndex, 1);
        });
        useDeleteTimeoffRule.mutate(ruleId, {
            onSuccess: function () {
                setTimeOfftimeOffPolicies(nextState);
            },
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timeOffPolicies]);
    var applyTimeOffPolicyToAllUsers = useCallback(function (policyId) {
        useApplyAllTimeOffSetting.mutate({
            accountId: accountId,
            policyId: policyId,
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timeOffPolicies]);
    var onSaveTimeOffSettings = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var timeoffRequest;
        return __generator(this, function (_a) {
            timeoffRequest = {
                accountId: accountId,
                types: timeOffTypes,
                policies: timeOffPolicies,
            };
            saveTimeoffSetting.mutateAsync(timeoffRequest);
            return [2 /*return*/];
        });
    }); }, [accountId, saveTimeoffSetting, timeOffPolicies, timeOffTypes]);
    return {
        overtimeGroups: overtimeGroups,
        setOvertimeGroups: setOvertimeGroups,
        addOvertimeGroup: addOvertimeGroup,
        changeOvertimeGroupName: changeOvertimeGroupName,
        deleteOvertimeGroup: deleteOvertimeGroup,
        applyToAllUsers: applyToAllUsers,
        addOvertimeRule: addOvertimeRule,
        changeOvertimeRuleType: changeOvertimeRuleType,
        changeOvertimeRuleMultiplier: changeOvertimeRuleMultiplier,
        changeOvertimeRuleThreshold: changeOvertimeRuleThreshold,
        changeOvertimeRuleDayOfWeek: changeOvertimeRuleDayOfWeek,
        changeOvertimeRuleSpecialRule: changeOvertimeRuleSpecialRule,
        deleteOvertimeRule: deleteOvertimeRule,
        overtimeCalculationSetting: overtimeCalculationSetting,
        billOvertimeCalculationSetting: billOvertimeCalculationSetting,
        saveOvertimeSettings: saveOvertimeSettings,
        weeklyOvertimeAverageEnabledSetting: weeklyOvertimeAverageEnabledSetting,
        addOvertimeExclusion: addOvertimeExclusion,
        updateOvertimeExclusion: updateOvertimeExclusion,
        deleteOvertimeExclusion: deleteOvertimeExclusion,
        overtimeExclusionConditionSchemas: overtimeExclusionConditionSchemas,
        setOvertimeExclusionConditionSchemas: setOvertimeExclusionConditionSchemas,
        setOvertimeCalculationSetting: setOvertimeCalculationSetting,
        setBillOvertimeCalculationSetting: setBillOvertimeCalculationSetting,
        setWeeklyOvertimeAverageEnabledSetting: setWeeklyOvertimeAverageEnabledSetting,
        convertOvertimePolicyDurationsBeforeSaving: convertOvertimePolicyDurationsBeforeSaving,
        breakPolicies: breakPolicies,
        addBreakPolicy: addBreakPolicy,
        setBreakPolicies: setBreakPolicies,
        changeBreakPolicyName: changeBreakPolicyName,
        changeBreakPolicyIntoDefault: changeBreakPolicyIntoDefault,
        deleteBreakPolicy: deleteBreakPolicy,
        addBreakRule: addBreakRule,
        changeBreakRuleName: changeBreakRuleName,
        changeBreakRuleBreakTime: changeBreakRuleBreakTime,
        changeBreakRulePayStatus: changeBreakRulePayStatus,
        changeBreakRuleTimePeriod: changeBreakRuleTimePeriod,
        deleteBreakRule: deleteBreakRule,
        timeOffTypes: timeOffTypes,
        timeOffPolicies: timeOffPolicies,
        setTimeOffTypes: setTimeOffTypes,
        setTimeOfftimeOffPolicies: setTimeOfftimeOffPolicies,
        dateSchemas: dateSchemas,
        setDateSchemas: setDateSchemas,
        addTimeOffType: addTimeOffType,
        changeTimeOffTypeName: changeTimeOffTypeName,
        deleteTimeOffType: deleteTimeOffType,
        addTimeOffPolicy: addTimeOffPolicy,
        setPolicyAsDefault: setPolicyAsDefault,
        duplicateTimeOffPolicy: duplicateTimeOffPolicy,
        changeTimeOffPolicyName: changeTimeOffPolicyName,
        deleteTimeOffPolicy: deleteTimeOffPolicy,
        addTimeOffRule: addTimeOffRule,
        updateTimeOffRule: updateTimeOffRule,
        deleteTimeOffRule: deleteTimeOffRule,
        applyTimeOffPolicyToAllUsers: applyTimeOffPolicyToAllUsers,
        onSaveTimeOffSettings: onSaveTimeOffSettings,
    };
};
export var CompliancePageState = createContainer(useCompliancePage);
