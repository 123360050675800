var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo, useState } from 'react';
import { Button, Form, Modal, Select, Space, Spin, Switch } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { accountIdSelector, tableCollectionInfoState, collectionTableSchemasState } from 'src/state';
import { useRecoilValue } from 'recoil';
import { UploadUrlType } from 'src/hooks/api/useUploadApi';
import { useRefetchCollection } from 'src/hooks/component/useRefetchCollection';
import TimeZoneSelector from '../TimeZoneSelector/TimeZoneSelector';
import { usePageApi } from '../../hooks/api';
import './ImportCsvModal.scss';
import ComponentWithUpload, { UploadFileType } from '../ComponentWithUpload';
import ImportSelectorCombo from './ImportSelectorCombo';
import { requiredRules } from './Constant';
var LoadingIcon = _jsx(LoadingOutlined, { spin: true });
var Option = Select.Option;
var ImportCsvModal = function (props) {
    var accountId = useRecoilValue(accountIdSelector);
    var _a = usePageApi(), useGetCsvInfo = _a.useGetCsvInfo, useSendImportMappingRequest = _a.useSendImportMappingRequest;
    var getCsvInfoMutation = useGetCsvInfo();
    var sendImportMappingMutation = useSendImportMappingRequest();
    var acceptedFileType = useMemo(function () { return [UploadFileType.CSV]; }, []);
    var isModalVisible = props.isModalVisible, closeModal = props.closeModal, blockId = props.blockId;
    var formInstance = Form.useForm()[0];
    var refetchCollection = useRefetchCollection();
    var _b = useState([]), csvHeaders = _b[0], setCsvHeaders = _b[1];
    var _c = useState([]), dateFormats = _c[0], setDateFormats = _c[1];
    var _d = useState([]), availableTypes = _d[0], setAvailableTypes = _d[1];
    var _e = useState(''), fileUrl = _e[0], setFileUrl = _e[1];
    var _f = useState(false), showColMappingContent = _f[0], setShowColMappingContent = _f[1];
    var _g = useState(false), isDedup = _g[0], setIsDedup = _g[1];
    var _h = useState(false), enableImportButton = _h[0], setEnableImportButton = _h[1];
    var _j = useState(false), isLoading = _j[0], setIsLoading = _j[1];
    var _k = useState(''), timezone = _k[0], setTimezone = _k[1];
    var _l = useRecoilValue(tableCollectionInfoState(blockId)), collectionId = _l.id, collectionName = _l.name, collectionType = _l.type;
    var collectionSchemas = useRecoilValue(collectionTableSchemasState(collectionId));
    var onUploadSucceed = useCallback(function (info) {
        var url = info.file.response.data.url;
        setFileUrl(url);
        setShowColMappingContent(true);
        setEnableImportButton(true);
        getCsvInfoMutation.mutate({ url: url }, {
            onSuccess: function (data) {
                setCsvHeaders(data.headers);
                setDateFormats(data.dateFormats);
                setAvailableTypes(data.availableTypes);
            },
        });
    }, [getCsvInfoMutation]);
    var onImportFileClick = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var formValues, mappingPairs, primaryKeys, requestBody;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, formInstance.validateFields()];
                case 1:
                    formValues = _b.sent();
                    mappingPairs = [];
                    Object.keys(formValues).map(function (valueKey) {
                        if (valueKey === 'importPKs' || valueKey === 'duplicateToggle')
                            return;
                        if (!collectionSchemas)
                            return;
                        var formItemValue = formValues[valueKey];
                        var schema = _.find(collectionSchemas, function (schema) { return schema.id === formItemValue.id; });
                        var dateTimeFormat = formValues[valueKey].date || null;
                        var selectionMode = formValues[valueKey].select || null;
                        var lookupSchemaId = formValues[valueKey].schemaId || null;
                        var newSchemaOrNot = formItemValue.id === 'createNew';
                        if (formItemValue.id !== 'noMatching') {
                            var pairObj = {
                                ziraSchemaId: newSchemaOrNot ? null : schema === null || schema === void 0 ? void 0 : schema.id,
                                csvColumn: { columnName: valueKey },
                                lookupSchemaId: lookupSchemaId === 'name' ? null : lookupSchemaId,
                                dateTimeFormat: dateTimeFormat,
                                newSchemaType: newSchemaOrNot ? formValues[valueKey].schemaType : null,
                                newSchemaInputMode: selectionMode,
                            };
                            mappingPairs.push(pairObj);
                        }
                    });
                    primaryKeys = (_a = formValues.importPKs) === null || _a === void 0 ? void 0 : _a.map(function (pk) {
                        return {
                            columnName: pk,
                        };
                    });
                    requestBody = {
                        accountId: accountId,
                        fileUrl: fileUrl,
                        collectionId: collectionId,
                        collectionType: collectionType,
                        primaryKeys: isDedup ? primaryKeys : [],
                        mappings: mappingPairs,
                        timezone: timezone ? timezone : undefined,
                    };
                    setIsLoading(true);
                    sendImportMappingMutation.mutate(requestBody, {
                        onSuccess: function () {
                            closeModal();
                            refetchCollection(collectionId);
                            setIsLoading(false);
                        },
                        onError: function () { return setIsLoading(false); },
                    });
                    return [2 /*return*/];
            }
        });
    }); }, [
        accountId,
        closeModal,
        collectionId,
        collectionSchemas,
        collectionType,
        fileUrl,
        formInstance,
        isDedup,
        refetchCollection,
        sendImportMappingMutation,
        timezone,
    ]);
    var onCancelClick = useCallback(function () {
        closeModal();
        setShowColMappingContent(false);
    }, [closeModal]);
    var pkOptions = useMemo(function () {
        if (!csvHeaders)
            return _jsx(_Fragment, {});
        return csvHeaders.map(function (header) {
            return (_jsx(Option, { value: header.columnName, children: header.columnName }, header.columnName));
        });
    }, [csvHeaders]);
    var modalFooter = useMemo(function () { return (_jsxs(_Fragment, { children: [_jsx(Button, { className: "CancelBtn", onClick: onCancelClick, children: "Cancel" }), _jsx(Button, { className: "ConfirmImportBtn", disabled: !enableImportButton, onClick: onImportFileClick, children: "Import CSV" })] })); }, [enableImportButton, onCancelClick, onImportFileClick]);
    var colMappingContent = useMemo(function () { return (_jsx("div", { className: "MappingContentContainer", children: _jsxs(Form, { layout: "vertical", requiredMark: false, form: formInstance, children: [_jsx(Form.Item, { name: "importPKs", className: "TimeZoneSelectorContainer", label: _jsx("div", { className: "mr-4", children: "Time Zone" }), children: _jsx(TimeZoneSelector, { timezoneValue: timezone, timezoneOnChange: function (value) { return setTimezone(value); } }) }), _jsx(Form.Item, { name: "duplicateToggle", label: _jsx("div", { className: "SwitchButtonDescription", children: "Turn on deduplication toggle if your import file contains duplicates to the existing records in the collection." }), className: "ToggleContainer", children: _jsx(Switch, { checked: isDedup, onChange: function (value) { return setIsDedup(value); } }) }), isDedup && (_jsx(Form.Item, { name: "importPKs", label: _jsx("div", { children: "Primary Keys" }), rules: isDedup ? requiredRules : undefined, className: "PkKeySelectorContainer", children: _jsx(Select, { mode: "multiple", allowClear: true, placeholder: "Please select your primary keys", className: "PKSelector", children: pkOptions }) })), _jsxs(Space, { size: 227, children: [_jsx("span", { children: "CSV" }), _jsx("span", { children: collectionName })] }), _jsx("div", { className: "HeadersMappingContent", children: csvHeaders.map(function (header) {
                        return (_jsx(Form.Item, { label: _jsx("span", { children: header.columnName }), name: header.columnName, children: _jsx(ImportSelectorCombo, { ziraSchemas: collectionSchemas, csvColumnName: header.columnName, dateFormats: dateFormats, newSchemaTypeOptions: availableTypes }) }, header.columnName));
                    }) })] }) })); }, [
        availableTypes,
        collectionName,
        collectionSchemas,
        csvHeaders,
        dateFormats,
        formInstance,
        isDedup,
        pkOptions,
        timezone,
    ]);
    var modalContent = useMemo(function () { return (_jsxs(Spin, { spinning: isLoading, tip: "Loading...", size: "large", indicator: LoadingIcon, style: { position: 'initial' }, children: [_jsxs("div", { className: "ModalTitle", children: ["Import CSV to ", collectionName] }), _jsx("div", { className: "ModalDescription", children: "We can automatically import and sync your team members, locations and even pull in order information for revenue analysis." }), showColMappingContent ? (collectionSchemas && colMappingContent) : (_jsx("div", { className: "UploadWrapper", children: _jsx(ComponentWithUpload, { acceptedFileType: acceptedFileType, successAction: onUploadSucceed, type: UploadUrlType.Import, children: _jsx(Button, { children: "Choose CSV File from your Computer to Import" }) }) }))] })); }, [
        acceptedFileType,
        colMappingContent,
        collectionName,
        collectionSchemas,
        isLoading,
        onUploadSucceed,
        showColMappingContent,
    ]);
    return (_jsx(Modal, { open: isModalVisible, centered: true, closable: false, footer: modalFooter, destroyOnClose: true, className: "ImportCsvModal", width: 1200, children: modalContent }));
};
export default React.memo(ImportCsvModal);
