var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider, Skeleton } from 'antd';
import React, { useCallback, useMemo } from 'react';
import CustomIconInline from 'src/components/IconSelector/CustomIconInline';
import LoadingStateAnimation from 'src/ui/LoadingStateAnimation';
import { ObjType, TEAMBRIDGE_TITLE } from 'src/shared';
import { useSetting } from 'src/hooks/api';
import SettingsState from 'src/hooks/state/Settings';
import { PagePermissionLevel } from '../../../Constants';
import PagePermissionDropdown from '../../../ObjectPermission/PagePermissionDropdown';
import AccessDetailHelperBanner from './AccessDetailHelperBanner';
import AccessDetailGroupItem from './AccessDetailListItem/AccessDetailGroupItem';
var WorkspaceAccess = function () {
    var accessGroupDetail = SettingsState.useContainer().accessGroupDetail;
    var useGetWorkspacePermission = useSetting().useGetWorkspacePermission;
    var _a = useGetWorkspacePermission(accessGroupDetail.id), data = _a.data, isLoading = _a.isLoading, refetch = _a.refetch;
    var visiblePages = useMemo(function () { var _a; 
    // !MARK: shallow equal here
    return ((_a = data === null || data === void 0 ? void 0 : data.pages) === null || _a === void 0 ? void 0 : _a.filter(function (page) { return page.permission != PagePermissionLevel.NONE; })) || []; }, [data]);
    var hiddenPages = useMemo(function () { var _a; return ((_a = data === null || data === void 0 ? void 0 : data.pages) === null || _a === void 0 ? void 0 : _a.filter(function (page) { return page.permission == PagePermissionLevel.NONE; })) || []; }, [data]);
    var pageGroupsContainingVisiblePages = useMemo(function () {
        return (data === null || data === void 0 ? void 0 : data.pageGroups.filter(function (pageGroup) {
            return pageGroup.pageIds.some(function (pageId) { return visiblePages.find(function (page) { return page.id == pageId; }); });
        })) || [];
    }, [data, visiblePages]);
    var pageGroupsContainingHiddenPages = useMemo(function () {
        return (data === null || data === void 0 ? void 0 : data.pageGroups.filter(function (pageGroup) {
            return pageGroup.pageIds.some(function (pageId) { return hiddenPages.find(function (page) { return page.id == pageId; }); });
        })) || [];
    }, [data, hiddenPages]);
    var pageGroupItem = useCallback(function (group, isHidden) { return (_jsxs("div", { className: "my-5", children: [_jsx("div", { children: group.name }), group.pageIds.map(function (pageId) {
                var page = __spreadArray([], (isHidden ? hiddenPages : visiblePages), true).find(function (page) { return page.id == pageId; });
                if (!page)
                    return null;
                return _jsx(PagePermissionItem, { onRefresh: refetch, page: page }, page.id);
            })] })); }, [hiddenPages, refetch, visiblePages]);
    var pageGroupClass = 'font-medium';
    return (_jsxs("div", { className: "AccessTabContainer", children: [_jsx(AccessDetailHelperBanner, { title: 'Workspace Access', description: 'Build the workforce app that is perfect for you admins, managers and employees by giving them access to the correct spaces on their web or mobile apps.' }), _jsx(AccessDetailGroupItem, { title: 'Visible Pages', subtitle: "Allow users in this group to access the ".concat(TEAMBRIDGE_TITLE, " account via the web or mobile browser from app.teambridge.com. It is recommended that all admins and managers have web access. ") }), _jsx("div", { className: pageGroupClass, children: isLoading ? (_jsx(Skeleton, {})) : pageGroupsContainingVisiblePages.length ? (pageGroupsContainingVisiblePages.map(function (pageGroup) {
                    return pageGroupItem(pageGroup, false);
                })) : (_jsx("div", { className: "text-gray-500", children: "No pages are visible from this group. " })) }), _jsx("div", { className: "mt-10 h-10", children: _jsx(Divider, { className: "mt-5" }) }), _jsx(AccessDetailGroupItem, { title: 'No Access', subtitle: "Allow users in this group to access the ".concat(TEAMBRIDGE_TITLE, " account via the web or mobile browser from app.teambridge.com. It is recommended that all admins and managers have web access. ") }), _jsx("div", { className: pageGroupClass, children: isLoading ? (_jsx(Skeleton, {})) : pageGroupsContainingHiddenPages.length ? (pageGroupsContainingHiddenPages.map(function (pageGroup) {
                    return pageGroupItem(pageGroup, true);
                })) : (_jsx("div", { className: "text-gray-400", children: " No pages are hidden from this group. " })) }), _jsx("div", { className: "h-6" })] }));
};
var PagePermissionItem = function (_a) {
    var _b;
    var page = _a.page, onRefresh = _a.onRefresh;
    var accessGroupDetail = SettingsState.useContainer().accessGroupDetail;
    var useUpdateObjectPermission = useSetting().useUpdateObjectPermission;
    var _c = useUpdateObjectPermission(), updatePagePermission = _c.mutateAsync, isUpdating = _c.isLoading, isError = _c.isError;
    var handlePermissionChange = useCallback(function (permission) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (permission == page.permission)
                return [2 /*return*/];
            updatePagePermission({
                accessGroupId: accessGroupDetail.id,
                type: ObjType.PAGE,
                objectId: page.id,
                permission: permission,
            }, {
                onSuccess: function () {
                    onRefresh();
                },
            });
            return [2 /*return*/];
        });
    }); }, [accessGroupDetail, onRefresh, page.id, page.permission, updatePagePermission]);
    return (_jsxs("div", { className: "mx-3 my-2 flex", children: [page.emoji ? (_jsx("span", { className: "px-1", children: page.emoji })) : page.iconInfo ? (_jsx(CustomIconInline, { iconUrl: page.iconInfo.content, iconSize: 14, backgroundColor: (_b = page.iconInfo) === null || _b === void 0 ? void 0 : _b.backgroundColor, className: "mx-[1px]" })) : (_jsx("span", { className: "w-[24px]" })), _jsx("span", { className: "ml-4 flex-1", children: page.name }), _jsx(LoadingStateAnimation, { isLoading: isUpdating, loadingFailed: isError }), _jsx(PagePermissionDropdown, { currentPermission: page.permission, onUpdate: handlePermissionChange, canUpdate: (accessGroupDetail === null || accessGroupDetail === void 0 ? void 0 : accessGroupDetail.type) !== 'owner' }), _jsx("span", { className: "ml-2 flex-1" })] }, page.id));
};
export default React.memo(WorkspaceAccess);
