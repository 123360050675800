import { jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useRecoilValue } from 'recoil';
import { CollectionTableType } from 'src/shared';
import { useTimeZone } from 'src/hooks/component';
import { tableCollectionIdSelector, scheduleShiftCardBasicInfoSelector, scheduleShowShiftTimesSelector, scheduleTimeZoneSelector, scheduleViewTypeSelector, shiftModalTimeZoneIdState, } from 'src/state';
var ShiftCardDisplayTime = function (_a) {
    var blockId = _a.blockId, recordId = _a.recordId;
    var showDisplayTime = useRecoilValue(scheduleShowShiftTimesSelector(blockId));
    var scheduleView = useRecoilValue(scheduleViewTypeSelector(blockId));
    var collectionId = useRecoilValue(tableCollectionIdSelector(blockId));
    var shiftTimezone = useRecoilValue(shiftModalTimeZoneIdState({
        type: CollectionTableType.TABLE,
        collectionId: collectionId,
        recordId: recordId,
    }));
    var scheduleTimezone = useRecoilValue(scheduleTimeZoneSelector(blockId));
    var _b = useTimeZone(shiftTimezone), formatTimeInShiftTz = _b.timeFormatFn, shiftTzAbbr = _b.abbreviateTimezone;
    var _c = useTimeZone(scheduleTimezone), formatTimeInScheduleTz = _c.timeFormatFn, scheduleTzAbbr = _c.abbreviateTimezone;
    var _d = useRecoilValue(scheduleShiftCardBasicInfoSelector({
        type: CollectionTableType.TABLE,
        collectionId: collectionId,
        recordId: recordId,
    })), startAt = _d.startAt, endAt = _d.endAt, clockIn = _d.clockIn, clockOut = _d.clockOut;
    if (!showDisplayTime || (!startAt && !clockIn))
        return null;
    var shiftTzAbbreviated = shiftTzAbbr();
    var scheduleTzAbbreviated = scheduleTzAbbr();
    var shouldRenderShiftSpecificTimezone = shiftTzAbbreviated !== scheduleTzAbbreviated && scheduleView === 'WEEK';
    return (_jsxs("div", { className: "".concat(shouldRenderShiftSpecificTimezone ? 'flex flex-col' : '', " displayedTime"), children: [_jsxs("span", { children: [formatTimeInScheduleTz(clockIn || startAt, 'h:mma', false).slice(0, -1), ' - ', clockIn && clockOut
                        ? formatTimeInScheduleTz(clockOut, 'h:mma', false).slice(0, -1)
                        : endAt
                            ? formatTimeInScheduleTz(endAt, 'h:mma', false).slice(0, -1)
                            : ''] }), shouldRenderShiftSpecificTimezone && (_jsxs("span", { className: "text-[10px] font-medium h-6", children: [formatTimeInShiftTz(clockIn || startAt, 'h:mma', false).slice(0, -1), ' - ', clockIn && clockOut
                        ? formatTimeInShiftTz(clockOut, 'h:mma', false).slice(0, -1)
                        : endAt
                            ? formatTimeInShiftTz(endAt, 'h:mma', false).slice(0, -1)
                            : '', ' ', shiftTzAbbreviated] }))] }));
};
export default React.memo(ShiftCardDisplayTime);
