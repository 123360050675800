var AppSessionIgnoreApiPOSTRoutes = [
    'users',
    'users/init_user',
    'app_sessions/create',
    'account/template/create_from_template',
    'onboarding/find_progress',
    'onboarding/create_account',
    'onboarding/save_progress',
    'users/is_account_creator',
];
var AppSessionIgnoreApiGETRoutes = [
    'users/account_list',
    'users/current_user',
    'accounts',
    'account/template',
    'collections/options',
    'onboarding',
];
var getIsRouteValidWithoutSession = function (route, method) {
    return method === 'POST'
        ? AppSessionIgnoreApiPOSTRoutes.includes(route)
        : // get matches partial route
            AppSessionIgnoreApiGETRoutes.some(function (r) { return route.includes(r); });
};
export { getIsRouteValidWithoutSession };
