var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var FormulaItemIconOutlinedSvg = function () { return (_jsxs("svg", { width: "14", height: "15", viewBox: "0 0 14 15", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M6.99997 13.1389C10.1142 13.1389 12.6389 10.6142 12.6389 7.49997C12.6389 4.3857 10.1142 1.86108 6.99997 1.86108C3.8857 1.86108 1.36108 4.3857 1.36108 7.49997C1.36108 10.6142 3.8857 13.1389 6.99997 13.1389Z", stroke: "#717177", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M6.31944 4.38892L5.15277 10.6111", stroke: "#717177", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M8.84721 4.38892L7.68054 10.6111", stroke: "#717177", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M3.88885 8.86108H9.52774", stroke: "#717177", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M4.47217 6.13892H10.1111", stroke: "#717177", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] })); };
export var FormulaItemIcon = function (props) { return (_jsx(Icon, __assign({ component: FormulaItemIconOutlinedSvg }, props))); };
