import moment from 'moment';
import { CollectionType, NativePath } from 'src/shared';
import { getNativeFieldCellContent } from 'src/utils/Collection';
export var getShiftTimeDetails = function (type, modalRecord, nativeSchemaMap) {
    var _a, _b, _c, _d;
    if (type !== CollectionType.SHIFT) {
        return {
            startAt: '',
            endAt: '',
            clockInAt: '',
            clockOutAt: '',
        };
    }
    var startAt = (_a = getNativeFieldCellContent(modalRecord, nativeSchemaMap, NativePath.SHIFT_START_AT)) !== null && _a !== void 0 ? _a : '';
    var endAt = (_b = getNativeFieldCellContent(modalRecord, nativeSchemaMap, NativePath.SHIFT_END_AT)) !== null && _b !== void 0 ? _b : '';
    var clockInAt = (_c = getNativeFieldCellContent(modalRecord, nativeSchemaMap, NativePath.SHIFT_CLOCK_IN)) !== null && _c !== void 0 ? _c : '';
    var clockOutAt = (_d = getNativeFieldCellContent(modalRecord, nativeSchemaMap, NativePath.SHIFT_CLOCK_OUT)) !== null && _d !== void 0 ? _d : '';
    return {
        startAt: startAt,
        endAt: endAt,
        clockInAt: clockInAt,
        clockOutAt: clockOutAt,
    };
};
export var findShiftSchemaByNativePath = function (schemaList, nativePath) {
    return schemaList.find(function (schema) { return schema.nativePath === nativePath; });
};
export var getAllShiftTimeSchemaPermissions = function (schemaList, startAt, endAt, clockInAt, clockOutAt) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var canEditStartAtDefault = (_a = findShiftSchemaByNativePath(schemaList, NativePath.SHIFT_START_AT)) === null || _a === void 0 ? void 0 : _a.canEdit;
    var canEditEndAtDefault = (_b = findShiftSchemaByNativePath(schemaList, NativePath.SHIFT_END_AT)) === null || _b === void 0 ? void 0 : _b.canEdit;
    var canEditClockedInDefault = (_c = findShiftSchemaByNativePath(schemaList, NativePath.SHIFT_CLOCK_IN)) === null || _c === void 0 ? void 0 : _c.canEdit;
    var canEditClockedOutDefault = (_d = findShiftSchemaByNativePath(schemaList, NativePath.SHIFT_CLOCK_OUT)) === null || _d === void 0 ? void 0 : _d.canEdit;
    var canOnlyUpdateEmptyStartAt = (_e = findShiftSchemaByNativePath(schemaList, NativePath.SHIFT_START_AT)) === null || _e === void 0 ? void 0 : _e.canOnlyUpdateEmpty;
    var canOnlyUpdateEmptyEndAt = (_f = findShiftSchemaByNativePath(schemaList, NativePath.SHIFT_END_AT)) === null || _f === void 0 ? void 0 : _f.canOnlyUpdateEmpty;
    var canOnlyUpdateEmptyClockedIn = (_g = findShiftSchemaByNativePath(schemaList, NativePath.SHIFT_CLOCK_IN)) === null || _g === void 0 ? void 0 : _g.canOnlyUpdateEmpty;
    var canOnlyUpdateEmptyClockedOut = (_h = findShiftSchemaByNativePath(schemaList, NativePath.SHIFT_CLOCK_OUT)) === null || _h === void 0 ? void 0 : _h.canOnlyUpdateEmpty;
    var canEditStartAt = canEditStartAtDefault && (!canOnlyUpdateEmptyStartAt || !startAt);
    var canEditEndAt = canEditEndAtDefault && (!canOnlyUpdateEmptyEndAt || !endAt);
    var canEditClockedIn = canEditClockedInDefault && (!canOnlyUpdateEmptyClockedIn || !clockInAt);
    var canEditClockedOut = canEditClockedOutDefault && (!canOnlyUpdateEmptyClockedOut || !clockOutAt);
    var canEditScheduledTime = canEditStartAt || canEditEndAt;
    var canEditClockedTime = canEditClockedIn || canEditClockedOut;
    var isScheduledTimeEmpty = !startAt && !endAt;
    var isClockedTimeEmpty = !clockInAt && !clockOutAt;
    var showNullScheduledTime = !canEditScheduledTime && isScheduledTimeEmpty;
    var showNullClockedTime = !canEditClockedTime && isClockedTimeEmpty;
    return {
        canEditStartAt: canEditStartAt,
        canEditEndAt: canEditEndAt,
        canEditClockedIn: canEditClockedIn,
        canEditClockedOut: canEditClockedOut,
        canEditScheduledTime: canEditScheduledTime,
        canEditClockedTime: canEditClockedTime,
        showNullScheduledTime: showNullScheduledTime,
        showNullClockedTime: showNullClockedTime,
    };
};
export var getShiftTimeSchemaIds = function (schemaList) {
    var _a, _b, _c, _d;
    var scheduledStartSchemaId = (_a = schemaList.find(function (schema) { return schema.nativePath == NativePath.SHIFT_START_AT; })) === null || _a === void 0 ? void 0 : _a.id;
    var scheduledEndSchemaId = (_b = schemaList.find(function (schema) { return schema.nativePath == NativePath.SHIFT_END_AT; })) === null || _b === void 0 ? void 0 : _b.id;
    var clockInSchemaId = (_c = schemaList.find(function (schema) { return schema.nativePath == NativePath.SHIFT_CLOCK_IN; })) === null || _c === void 0 ? void 0 : _c.id;
    var clockOutSchemaId = (_d = schemaList.find(function (schema) { return schema.nativePath == NativePath.SHIFT_CLOCK_OUT; })) === null || _d === void 0 ? void 0 : _d.id;
    return {
        scheduledStartSchemaId: scheduledStartSchemaId,
        scheduledEndSchemaId: scheduledEndSchemaId,
        clockInSchemaId: clockInSchemaId,
        clockOutSchemaId: clockOutSchemaId,
    };
};
export var getTemplateApplyToDate = function (schemaList, modalRecord) {
    var _a, _b;
    var _c = getShiftTimeSchemaIds(schemaList), shiftStartSchemaId = _c.scheduledStartSchemaId, shiftClockInSchemaId = _c.clockInSchemaId;
    if (!shiftStartSchemaId && !shiftClockInSchemaId)
        return '';
    var startTime = shiftStartSchemaId ? (_a = modalRecord[shiftStartSchemaId]) === null || _a === void 0 ? void 0 : _a.content : '';
    var clockInTime = shiftClockInSchemaId ? (_b = modalRecord[shiftClockInSchemaId]) === null || _b === void 0 ? void 0 : _b.content : '';
    var defaultShiftStartTime = moment().startOf('day').add(9, 'hours').toISOString();
    // Fall into clock in time for unscheduled completed shift, and use default shift start time for new shift
    return moment(startTime || clockInTime || defaultShiftStartTime).format('YYYY-MM-DD');
};
