var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import { Button, Checkbox } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import { useRefetchCollection } from 'src/hooks/component/useRefetchCollection';
import { useTimezoneApi } from 'src/hooks/api/useTimezoneApi';
import { locationTimezoneUpdateState } from 'src/state';
import { isInternalUserSelector, standardCollectionIdSelector, } from 'src/state/selectors/withAccountDetailState';
import { CollectionType } from 'src/shared';
import { toast } from 'react-toastify';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import { ShiftStatus } from '../ScheduleView/Constant';
import { BULK_UPDATE_COMPLETED_SHIFT_TIMEZONES } from '../FeatureGatingKeyConstant';
var UPDATE_OPTIONS = [
    {
        label: 'Completed shifts',
        value: ShiftStatus.COMPLETED,
    },
    {
        label: 'Ongoing shifts',
        value: ShiftStatus.ONGOING,
    },
    {
        label: 'Upcoming & open shifts',
        value: ShiftStatus.UPCOMING,
    },
];
var LocationTimezoneUpdatePopup = function () {
    var useUpdateShiftsOnLocationTimezoneChange = useTimezoneApi().useUpdateShiftsOnLocationTimezoneChange;
    var _a = useUpdateShiftsOnLocationTimezoneChange(), updateShiftsOnLocationTimezoneChange = _a.mutate, isLoading = _a.isLoading;
    var refetchCollection = useRefetchCollection();
    var isInternalUser = useRecoilValue(isInternalUserSelector);
    var canBulkUpdateCompletedShiftTimezones = useRecoilValue(featureEnableStatusState(BULK_UPDATE_COMPLETED_SHIFT_TIMEZONES));
    var _b = useRecoilState(locationTimezoneUpdateState), locationRecordIdsForTimezoneUpdate = _b[0], setLocationTimezoneUpdateState = _b[1];
    var updateOptions = useMemo(function () {
        return canBulkUpdateCompletedShiftTimezones || isInternalUser
            ? UPDATE_OPTIONS
            : UPDATE_OPTIONS.filter(function (option) { return option.value !== ShiftStatus.COMPLETED; });
    }, [canBulkUpdateCompletedShiftTimezones, isInternalUser]);
    var _c = useState([
        ShiftStatus.UPCOMING,
    ]), selectedUpdateOptions = _c[0], setSelectedUpdateOptions = _c[1];
    var onSubmit = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function () {
            updateShiftsOnLocationTimezoneChange({
                locationRids: locationRecordIdsForTimezoneUpdate !== null && locationRecordIdsForTimezoneUpdate !== void 0 ? locationRecordIdsForTimezoneUpdate : [],
                updateTypes: selectedUpdateOptions,
            }, {
                onSuccess: function () { return __awaiter(void 0, void 0, void 0, function () {
                    var locationCollectionId;
                    return __generator(this, function (_a) {
                        locationCollectionId = snapshot
                            .getLoadable(standardCollectionIdSelector(CollectionType.LOCATION))
                            .valueMaybe();
                        if (locationCollectionId) {
                            refetchCollection(locationCollectionId);
                        }
                        setLocationTimezoneUpdateState(undefined);
                        toast("Updated timezones on relevant shifts!", {
                            type: 'success',
                            position: toast.POSITION.BOTTOM_CENTER,
                        });
                        return [2 /*return*/];
                    });
                }); },
            });
        };
    }, [
        updateShiftsOnLocationTimezoneChange,
        refetchCollection,
        locationRecordIdsForTimezoneUpdate,
        selectedUpdateOptions,
        setLocationTimezoneUpdateState,
    ]);
    return (_jsx(_Fragment, { children: _jsx(Modal, { title: "Location timezone updated", open: true, centered: true, closable: false, destroyOnClose: true, footer: _jsxs("div", { children: [_jsx(Button, { onClick: function () { return setLocationTimezoneUpdateState(undefined); }, children: "Do nothing" }), _jsx(Button, { className: "bg-black text-white hover:bg-black hover:text-white", onClick: onSubmit, loading: isLoading, disabled: isLoading || selectedUpdateOptions.length === 0, children: "Update" })] }), children: _jsxs("div", { className: "flex flex-col gap-4", children: [_jsx("div", { className: "text-sm", children: "Changing a location's timezone can propagate to shifts linked to that location.\n                        Please select which groups of shifts should have their timezone updated:" }), _jsx(Checkbox.Group, { className: "flex flex-col", options: updateOptions, value: selectedUpdateOptions, onChange: function (options) { return setSelectedUpdateOptions(options); } }), _jsx("div", { className: "text-xs", children: "Note: any approved or paid shifts will not be updated." })] }) }) }));
};
export default React.memo(LocationTimezoneUpdatePopup);
