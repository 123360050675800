var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Form } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useAccountApi, usePageApi } from 'src/hooks/api';
import { usePage } from 'src/hooks/component';
import { CollectionType, NativePath } from 'src/shared';
import { accountTimeZoneSelector, standardCollectionIdSelector, } from 'src/state/selectors/withAccountDetailState';
import { find } from 'lodash';
import moment from 'moment';
import { getDefaultFormatOptions, getSelectableUserColumns } from '../utils';
import { aggregateReportFormatOptions, ExportPdfTemplateType, individualReportFormatOptions, PayReportExportType, } from '../../Constant';
export var useExportReportModal = function (props) {
    var payPeriodId = props.payPeriodId, isModalVisible = props.isModalVisible, setIsModalVisible = props.setIsModalVisible, recordId = props.recordId;
    var _a = usePageApi(), useGetAggregatePayReportTypes = _a.useGetAggregatePayReportTypes, useGetExportPayReportTemplate = _a.useGetExportPayReportTemplate, useGetIndividualPayReportTypes = _a.useGetIndividualPayReportTypes, useExportReport = _a.useExportReport, useExportPdfReport = _a.useExportPdfReport, useExportRecordReport = _a.useExportRecordReport;
    var showExportMsg = usePage().showExportMsg;
    var useGetSchemaColumnsInCollection = useAccountApi().useGetSchemaColumnsInCollection;
    var accountTimeZone = useRecoilValue(accountTimeZoneSelector);
    var locationsCollectionId = useRecoilValue(standardCollectionIdSelector(CollectionType.LOCATION));
    var usersCollectionId = useRecoilValue(standardCollectionIdSelector(CollectionType.USER));
    var contactsCollectionId = useRecoilValue(standardCollectionIdSelector(CollectionType.CONTACT));
    var _b = useState(), selectedLocationContactSchemaId = _b[0], setSelectedLocationContactSchemaId = _b[1];
    var _c = useState(accountTimeZone), reportTimeZone = _c[0], setReportTimeZone = _c[1];
    var _d = useState(false), isFormatTypeDropdownOpen = _d[0], setIsFormatTypeDropdownOpen = _d[1];
    var _e = useState(false), isCvsTemplateConfigModalVisible = _e[0], setIsCvsTemplateConfigModalVisible = _e[1];
    var _f = useState(false), isPdfTemplateConfigModalVisible = _f[0], setIsPdfTemplateConfigModalVisible = _f[1];
    var _g = useState(), selectedCsvTemplateInfo = _g[0], setSelectedCsvTemplateInfo = _g[1];
    var _h = useState(), selectedPdfTemplateInfo = _h[0], setSelectedPdfTemplateInfo = _h[1];
    var formInstance = Form.useForm()[0];
    var selectedFormat = Form.useWatch('selectedFormat', formInstance);
    var shouldUseCustomTimezone = Form.useWatch('shouldUseCustomTimezone', formInstance);
    var shouldSendToFacilityContact = Form.useWatch('sendToContacts', formInstance);
    var usersColumns = useGetSchemaColumnsInCollection({
        collectionId: locationsCollectionId !== null && locationsCollectionId !== void 0 ? locationsCollectionId : '',
        options: {
            enabled: isModalVisible && !!locationsCollectionId && !!usersCollectionId,
            onSuccess: function (data) {
                if (data) {
                    setSelectedLocationContactSchemaId(data.columns
                        .filter(function (column) {
                        return (column.refCollectionId &&
                            column.refCollectionId == usersCollectionId) ||
                            (column.nativePath &&
                                column.nativePath == NativePath.LOCATION_EMAIL);
                    })
                        .map(function (column) { return column.id; })[0]);
                }
            },
        },
    }).data;
    var exportReportTemplates = useGetExportPayReportTemplate({
        enabled: !recordId && isModalVisible && isFormatTypeDropdownOpen,
    }).data;
    var aggregatePayReportTypes = useGetAggregatePayReportTypes({
        enabled: !recordId && isModalVisible,
    }).data;
    var individualPayReportTypes = useGetIndividualPayReportTypes({
        enabled: !!recordId && isModalVisible,
    }).data;
    var selectableUsersColumns = useMemo(function () { return getSelectableUserColumns(usersColumns, usersCollectionId, contactsCollectionId); }, [usersColumns, usersCollectionId, contactsCollectionId]);
    var _j = useMemo(function () {
        return getDefaultFormatOptions({
            recordId: recordId,
            individualPayReportTypes: individualPayReportTypes,
            aggregatePayReportTypes: aggregatePayReportTypes,
            individualReportFormatOptions: individualReportFormatOptions,
            aggregateReportFormatOptions: aggregateReportFormatOptions,
        });
    }, [aggregatePayReportTypes, individualPayReportTypes, recordId]), defaultCSVFormatOptions = _j.defaultCSVFormatOptions, defaultPDFFormatOptions = _j.defaultPDFFormatOptions;
    var onOpenExistingTemplate = useCallback(function (templateId) {
        if (exportReportTemplates) {
            var csvTemplate = exportReportTemplates.csvExportTemplates.find(function (template) { return template.id === templateId; });
            if (csvTemplate) {
                setSelectedCsvTemplateInfo(csvTemplate);
                setIsCvsTemplateConfigModalVisible(true);
            }
            else {
                var pdfTemplate = exportReportTemplates.pdfExportTemplates.find(function (template) { return template.id === templateId; });
                if (pdfTemplate) {
                    setSelectedPdfTemplateInfo(__assign(__assign({}, pdfTemplate), { pdfTemplateType: ExportPdfTemplateType.DEFAULT }));
                    setIsPdfTemplateConfigModalVisible(true);
                    return;
                }
                var shiftDetailPdfTemplate = exportReportTemplates.shiftDetailPdfExportTemplates.find(function (template) { return template.id === templateId; });
                if (shiftDetailPdfTemplate) {
                    setSelectedPdfTemplateInfo(__assign(__assign({}, shiftDetailPdfTemplate), { showAccountLogo: false, showPayMetrics: false, showShiftTable: true, showTimeoffTable: false, textContent: '', showAttestationInfo: false, showSignatureFields: false, pdfTemplateType: ExportPdfTemplateType.SHIFT_DETAIL, summaryOnly: false, taxRate: 0 }));
                    setIsPdfTemplateConfigModalVisible(true);
                    return;
                }
                var locationInvoicePdfTemplate = exportReportTemplates.locationInvoicePdfExportTemplates.find(function (template) { return template.id === templateId; });
                if (locationInvoicePdfTemplate) {
                    setSelectedPdfTemplateInfo(__assign(__assign({}, locationInvoicePdfTemplate), { showAccountLogo: false, showPayMetrics: false, showShiftTable: true, showTimeoffTable: false, textContent: '', showAttestationInfo: false, showSignatureFields: false, pdfTemplateType: ExportPdfTemplateType.LOCATION_INVOICE }));
                    setIsPdfTemplateConfigModalVisible(true);
                    return;
                }
                var linkedDataPdfTemplate = exportReportTemplates.linkedDataPdfExportTemplates.find(function (template) { return template.id === templateId; });
                if (linkedDataPdfTemplate) {
                    setSelectedPdfTemplateInfo(__assign(__assign({}, linkedDataPdfTemplate), { showAccountLogo: false, showPayMetrics: false, showShiftTable: true, showTimeoffTable: false, textContent: '', showAttestationInfo: false, showSignatureFields: false, pdfTemplateType: ExportPdfTemplateType.LINKED_DATA, shiftTableSchemas: [], shiftMetrics: [], summaryOnly: false, taxRate: 0 }));
                    setIsPdfTemplateConfigModalVisible(true);
                    return;
                }
            }
        }
    }, [exportReportTemplates]);
    var onExportReport = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var formValues, e_1, requestBody, isDefaultFormat, selectedPdfTemplate, selectedShiftDetailPdfTemplate, selectedLocationInvoicePdfTemplate, selectedLinkedDataPdfTemplate, baseRequestBody, requestBody, shouldContainInvoiceNumber, shouldContainInvoiceDate, shouldContainInvoiceDueDate, requestBody;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    formValues = null;
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, formInstance.validateFields()];
                case 2:
                    formValues = _c.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _c.sent();
                    // If any form fields are invalid by validation rules, this exception contains information about the invalid input, messages should
                    // be displaying below each field, no request should be sent until the user inputs correct information
                    return [2 /*return*/];
                case 4:
                    if (recordId) {
                        requestBody = {
                            payPeriodId: payPeriodId,
                            recordId: recordId,
                            reportFormat: formValues.selectedFormat,
                            militaryTime: formValues.isMilitaryTime,
                            timeZoneId: reportTimeZone,
                        };
                        useExportRecordReport.mutate(requestBody, {
                            onSuccess: function () {
                                setIsModalVisible(false);
                                showExportMsg('/home/files');
                            },
                        });
                    }
                    else {
                        isDefaultFormat = Object.values(PayReportExportType).includes(formValues.selectedFormat);
                        selectedPdfTemplate = find(exportReportTemplates === null || exportReportTemplates === void 0 ? void 0 : exportReportTemplates.pdfExportTemplates, function (template) {
                            return template.id === formValues.selectedFormat;
                        });
                        selectedShiftDetailPdfTemplate = find(exportReportTemplates === null || exportReportTemplates === void 0 ? void 0 : exportReportTemplates.shiftDetailPdfExportTemplates, function (template) {
                            return template.id === formValues.selectedFormat;
                        });
                        selectedLocationInvoicePdfTemplate = find(exportReportTemplates === null || exportReportTemplates === void 0 ? void 0 : exportReportTemplates.locationInvoicePdfExportTemplates, function (template) {
                            return template.id === formValues.selectedFormat;
                        });
                        selectedLinkedDataPdfTemplate = find(exportReportTemplates === null || exportReportTemplates === void 0 ? void 0 : exportReportTemplates.linkedDataPdfExportTemplates, function (template) {
                            return template.id === formValues.selectedFormat;
                        });
                        baseRequestBody = {
                            payPeriodId: payPeriodId,
                            militaryTime: formValues.isMilitaryTime,
                            timeZoneId: reportTimeZone,
                            exportApprovedOnly: formValues.exportApprovedOnly,
                            markExportedAsPaid: formValues.markExportedAsPaid,
                            markExportedAsBilled: formValues.markExportedAsBilled,
                        };
                        if ((_b = (_a = selectedPdfTemplate !== null && selectedPdfTemplate !== void 0 ? selectedPdfTemplate : selectedShiftDetailPdfTemplate) !== null && _a !== void 0 ? _a : selectedLocationInvoicePdfTemplate) !== null && _b !== void 0 ? _b : selectedLinkedDataPdfTemplate) {
                            requestBody = selectedLocationInvoicePdfTemplate
                                ? __assign(__assign({}, baseRequestBody), { pdfExportTemplateId: formValues.selectedFormat, invoiceNumber: formValues.invoiceNumber, invoiceDate: formValues.invoiceDate
                                        ? moment(formValues.invoiceDate).format('YYYY-MM-DD')
                                        : undefined, invoiceDueDate: formValues.invoiceDueDate
                                        ? moment(formValues.invoiceDueDate).format('YYYY-MM-DD')
                                        : undefined, timeZoneId: formValues.shouldUseCustomTimezone ? reportTimeZone : null, locationsContactSchemaId: shouldSendToFacilityContact
                                        ? selectedLocationContactSchemaId
                                        : null }) : __assign(__assign({}, baseRequestBody), { timeZoneId: formValues.shouldUseCustomTimezone ? reportTimeZone : null, pdfExportTemplateId: formValues.selectedFormat });
                            useExportPdfReport.mutate(requestBody, {
                                onSuccess: function () {
                                    setIsModalVisible(false);
                                    showExportMsg('/home/files');
                                },
                            });
                        }
                        else {
                            shouldContainInvoiceNumber = (selectedFormat === PayReportExportType.NURSECORE_DETAILED_REPORT ||
                                selectedFormat === PayReportExportType.SAGE_PAY_CUSTOM_CSV_EXPORT ||
                                selectedFormat === PayReportExportType.INTERIM_SCRANTON_REPORT_EXPORT ||
                                selectedFormat ===
                                    PayReportExportType.INTERIM_SCRANTON_REPORT_SHIFTS_EXPORT ||
                                selectedFormat === PayReportExportType.BLUESKY_PDF_CUSTOM_REPORT) &&
                                formValues.invoiceNumber;
                            shouldContainInvoiceDate = (selectedFormat === PayReportExportType.NURSECORE_DETAILED_REPORT ||
                                selectedFormat === PayReportExportType.SAGE_PAY_CUSTOM_CSV_EXPORT ||
                                selectedFormat ===
                                    PayReportExportType.INTERIM_SCRANTON_REPORT_SHIFTS_EXPORT ||
                                selectedFormat === PayReportExportType.BLUESKY_PDF_CUSTOM_REPORT) &&
                                formValues.invoiceDate;
                            shouldContainInvoiceDueDate = selectedFormat === PayReportExportType.SAGE_PAY_CUSTOM_CSV_EXPORT &&
                                formValues.invoiceDueDate;
                            requestBody = __assign(__assign({}, baseRequestBody), { reportFormat: isDefaultFormat ? formValues.selectedFormat : undefined, exportTemplateId: isDefaultFormat ? undefined : formValues.selectedFormat, invoiceNumber: shouldContainInvoiceNumber
                                    ? formValues.invoiceNumber
                                    : undefined, invoiceDate: shouldContainInvoiceDate
                                    ? moment(formValues.invoiceDate).format('YYYY-MM-DD')
                                    : undefined, invoiceDueDate: shouldContainInvoiceDueDate
                                    ? moment(formValues.invoiceDueDate).format('YYYY-MM-DD')
                                    : undefined, locationsContactSchemaId: shouldSendToFacilityContact
                                    ? selectedLocationContactSchemaId
                                    : null });
                            useExportReport.mutate(requestBody, {
                                onSuccess: function () {
                                    setIsModalVisible(false);
                                    showExportMsg('/home/files');
                                },
                            });
                        }
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [
        formInstance,
        recordId,
        payPeriodId,
        setIsModalVisible,
        exportReportTemplates,
        reportTimeZone,
        selectedFormat,
        shouldSendToFacilityContact,
        selectedLocationContactSchemaId,
    ]);
    return {
        onOpenExistingTemplate: onOpenExistingTemplate,
        onExportReport: onExportReport,
        formInstance: formInstance,
        isCvsTemplateConfigModalVisible: isCvsTemplateConfigModalVisible,
        isPdfTemplateConfigModalVisible: isPdfTemplateConfigModalVisible,
        selectedCsvTemplateInfo: selectedCsvTemplateInfo,
        selectedPdfTemplateInfo: selectedPdfTemplateInfo,
        setIsCvsTemplateConfigModalVisible: setIsCvsTemplateConfigModalVisible,
        setSelectedCsvTemplateInfo: setSelectedCsvTemplateInfo,
        setIsPdfTemplateConfigModalVisible: setIsPdfTemplateConfigModalVisible,
        setSelectedPdfTemplateInfo: setSelectedPdfTemplateInfo,
        defaultCSVFormatOptions: defaultCSVFormatOptions,
        defaultPDFFormatOptions: defaultPDFFormatOptions,
        setSelectedLocationContactSchemaId: setSelectedLocationContactSchemaId,
        selectedLocationContactSchemaId: selectedLocationContactSchemaId,
        selectableUsersColumns: selectableUsersColumns,
        isFormatTypeDropdownOpen: isFormatTypeDropdownOpen,
        setIsFormatTypeDropdownOpen: setIsFormatTypeDropdownOpen,
        reportTimeZone: reportTimeZone,
        setReportTimeZone: setReportTimeZone,
        exportReportTemplates: exportReportTemplates,
        shouldUseCustomTimezone: shouldUseCustomTimezone,
        shouldSendToFacilityContact: shouldSendToFacilityContact,
        selectedFormat: selectedFormat,
    };
};
