var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Card, Divider, InputNumber, Modal, Space, Tag } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import _ from 'lodash';
import { useRecoilState } from 'recoil';
import { autoSchedulerSettingState } from 'src/state';
import { DragHandlerIcon } from '../../utils/icons/DragHandlerIcon';
import { useAccountApi } from '../../hooks/api';
import { MaxMinutesScheduleConstraints, MinMinutesScheduleConstraints, RuleType, scheduleRuleColorSet, StandardScheduleConstraint, } from './Constant';
import './ScheduleRulesModal.scss';
var Meta = Card.Meta;
var SelectRuleDropContainer = function (_a) {
    var type = _a.type, addRule = _a.addRule;
    var _b = useDrop(function () { return ({
        accept: ['RuleItem'],
        drop: function (item) {
            addRule(item.constraint, type);
        },
        collect: function (monitor) { return ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
        }); },
    }); }, []), _c = _b[0], isOver = _c.isOver, canDrop = _c.canDrop, drop = _b[1];
    var isActive = canDrop && isOver;
    var backgroundColor = '#EFEFF2';
    var border = '1px dashed lightgrey';
    if (isActive) {
        backgroundColor = 'white';
        border = '1px solid lightgrey';
    }
    return (_jsx("div", { className: "DropContainer", ref: drop, style: {
            border: border,
            backgroundColor: backgroundColor,
        } }));
};
var RuleItem = function (_a) {
    var _b;
    var rule = _a.rule, selected = _a.selected, removeRuleFromList = _a.removeRuleFromList, addRuleToList = _a.addRuleToList, removeSelectedRule = _a.removeSelectedRule, editSelectedRuleTime = _a.editSelectedRuleTime;
    var name = rule.name, constraint = rule.constraint;
    var type = useMemo(function () { return ('type' in rule ? rule.type : ''); }, [rule]);
    var _c = useDrag(function () { return ({
        type: 'RuleItem',
        item: { constraint: constraint },
        collect: function (monitor) { return ({
            border: monitor.isDragging() ? '1px solid lightblue' : '',
            opacity: monitor.isDragging() ? '0.4' : '1',
        }); },
        end: function (item, monitor) {
            if (monitor.didDrop()) {
                removeRuleFromList === null || removeRuleFromList === void 0 ? void 0 : removeRuleFromList(rule);
            }
        },
    }); }, []), _d = _c[0], border = _d.border, opacity = _d.opacity, drag = _c[1];
    var isMaxHours = useMemo(function () {
        return [
            StandardScheduleConstraint.MAX_MINUTES_PER_DAY,
            StandardScheduleConstraint.MAX_MINUTES_PER_WEEK,
        ].includes(constraint);
    }, [constraint]);
    var isMinHours = useMemo(function () {
        return [
            StandardScheduleConstraint.MIN_MINUTES_PER_DAY,
            StandardScheduleConstraint.MIN_MINUTES_PER_WEEK,
        ].includes(constraint);
    }, [constraint]);
    var _e = useState(((_b = rule.maxMinutes) !== null && _b !== void 0 ? _b : rule.minMinutes) / 60), ruleTime = _e[0], setRuleTime = _e[1];
    return (_jsxs("div", { className: selected ? 'SelectedRuleItem' : 'RuleItem', ref: drag, style: {
            padding: '10px 0px',
            display: 'flex',
            alignItems: 'center',
            border: border,
            opacity: opacity,
        }, children: [_jsxs(Space, { style: { paddingLeft: '10px' }, children: [_jsx(DragHandlerIcon, {}), _jsx("div", { children: name })] }), selected && (_jsxs(Space, { align: "center", style: { marginLeft: 'auto' }, children: [(isMaxHours || isMinHours) && (_jsxs(Space, { style: { marginRight: '20px' }, children: [_jsx("span", { style: { marginBottom: '10px' }, children: name }), _jsx(InputNumber, { min: isMinHours ? 0 : 1, value: ruleTime, onChange: function (value) {
                                    if (value) {
                                        setRuleTime(value);
                                        editSelectedRuleTime === null || editSelectedRuleTime === void 0 ? void 0 : editSelectedRuleTime(rule, value * 60);
                                    }
                                }, style: { width: '50px' } })] })), _jsx(Tag, { color: type ? scheduleRuleColorSet[type] : 'white', style: { borderRadius: '5px' }, className: "font-semibold", children: type }), _jsx(Button, { className: "mb-1", danger: true, onClick: function () {
                            addRuleToList === null || addRuleToList === void 0 ? void 0 : addRuleToList(rule);
                            removeSelectedRule === null || removeSelectedRule === void 0 ? void 0 : removeSelectedRule(rule);
                        }, children: _jsx(CloseOutlined, {}) })] }))] }));
};
var ScheduleRulesModal = function (_a) {
    var closeModal = _a.closeModal;
    var _b = useState([]), availableRules = _b[0], setAvailableRules = _b[1];
    var _c = useState([]), selectedRules = _c[0], setSelectedRules = _c[1];
    var _d = useAccountApi(), useGetAutoSchedulerSetting = _d.useGetAutoSchedulerSetting, useUpdateAutoSchedulerSetting = _d.useUpdateAutoSchedulerSetting;
    var updateAutoSchedulerSetting = useUpdateAutoSchedulerSetting();
    var _e = useRecoilState(autoSchedulerSettingState), autoSchedulerSetting = _e[0], setAutoSchedulerSetting = _e[1];
    useGetAutoSchedulerSetting({
        options: {
            enabled: true,
            onSuccess: function (data) {
                if (data) {
                    setAutoSchedulerSetting(data);
                }
            },
        },
    });
    // Parse and set setting data
    useEffect(function () {
        var _a, _b;
        if (autoSchedulerSetting) {
            var defaultSelectedRules = [];
            var defaultAvailableRules = [];
            var rulesMap = autoSchedulerSetting.rulesMap;
            for (var _i = 0, _c = Object.keys(rulesMap).filter(
            // Hide unused constraints
            function (constraintKey) {
                return ![
                    StandardScheduleConstraint.DESIRED_TIME_FOR_SHIFT,
                    StandardScheduleConstraint.UNDESIRED_TIME_FOR_SHIFT,
                ].includes(constraintKey);
            }); _i < _c.length; _i++) {
                var key = _c[_i];
                var constraint = key;
                if (rulesMap[constraint]) {
                    if (rulesMap[constraint].active) {
                        defaultSelectedRules.push(_.merge(_.omit(rulesMap[constraint], 'active'), {
                            constraint: constraint,
                            name: (_a = rulesMap[constraint].ruleDisplayName) !== null && _a !== void 0 ? _a : 'Untitled Rule',
                        }));
                    }
                    else {
                        defaultAvailableRules.push(_.merge(_.omit(autoSchedulerSetting.rulesMap[constraint], 'active', 'type'), {
                            constraint: constraint,
                            name: (_b = autoSchedulerSetting.rulesMap[constraint].ruleDisplayName) !== null && _b !== void 0 ? _b : 'Untitled Rule',
                        }));
                    }
                }
            }
            setSelectedRules(defaultSelectedRules);
            setAvailableRules(defaultAvailableRules);
        }
    }, [autoSchedulerSetting]);
    var _f = useMemo(function () {
        var mildSelectedRules = _.filter(selectedRules, function (rule) { return rule.type === RuleType.MILD; });
        var moderateSelectedRules = _.filter(selectedRules, function (rule) { return rule.type === RuleType.MODERATE; });
        var strictSelectedRules = _.filter(selectedRules, function (rule) { return rule.type === RuleType.STRICT; });
        return { mildSelectedRules: mildSelectedRules, moderateSelectedRules: moderateSelectedRules, strictSelectedRules: strictSelectedRules };
    }, [selectedRules]), mildSelectedRules = _f.mildSelectedRules, moderateSelectedRules = _f.moderateSelectedRules, strictSelectedRules = _f.strictSelectedRules;
    var saveSettings = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var request, _i, availableRules_1, rule, ruleCategory, _a, selectedRules_1, rule, ruleCategory, desiredTimeForShift, undesiredTimeForShift, resp;
        var _b, _c;
        var _d, _e, _f;
        return __generator(this, function (_g) {
            switch (_g.label) {
                case 0:
                    request = {
                        autoSchedulerSetting: {
                            rulesMap: {},
                        },
                    };
                    // Convert data for request
                    for (_i = 0, availableRules_1 = availableRules; _i < availableRules_1.length; _i++) {
                        rule = availableRules_1[_i];
                        ruleCategory = (_d = autoSchedulerSetting === null || autoSchedulerSetting === void 0 ? void 0 : autoSchedulerSetting.rulesMap[rule.constraint].ruleCategory) !== null && _d !== void 0 ? _d : '';
                        request.autoSchedulerSetting.rulesMap[rule.constraint] = _.merge(_.omit(rule, 'constraint', 'name'), {
                            type: RuleType.STRICT,
                            active: false,
                            ruleDisplayName: rule.name,
                            ruleCategory: ruleCategory,
                        });
                    }
                    for (_a = 0, selectedRules_1 = selectedRules; _a < selectedRules_1.length; _a++) {
                        rule = selectedRules_1[_a];
                        ruleCategory = (_e = autoSchedulerSetting === null || autoSchedulerSetting === void 0 ? void 0 : autoSchedulerSetting.rulesMap[rule.constraint].ruleCategory) !== null && _e !== void 0 ? _e : '';
                        request.autoSchedulerSetting.rulesMap[rule.constraint] = _.merge(_.omit(rule, 'constraint', 'name'), {
                            active: true,
                            ruleDisplayName: rule.name,
                            ruleCategory: ruleCategory,
                        });
                    }
                    desiredTimeForShift = autoSchedulerSetting === null || autoSchedulerSetting === void 0 ? void 0 : autoSchedulerSetting.rulesMap[StandardScheduleConstraint.DESIRED_TIME_FOR_SHIFT];
                    if (desiredTimeForShift) {
                        request.autoSchedulerSetting.rulesMap = __assign(__assign({}, request.autoSchedulerSetting.rulesMap), (_b = {}, _b[StandardScheduleConstraint.DESIRED_TIME_FOR_SHIFT] = desiredTimeForShift, _b));
                    }
                    undesiredTimeForShift = autoSchedulerSetting === null || autoSchedulerSetting === void 0 ? void 0 : autoSchedulerSetting.rulesMap[StandardScheduleConstraint.UNDESIRED_TIME_FOR_SHIFT];
                    if (undesiredTimeForShift) {
                        request.autoSchedulerSetting.rulesMap = __assign(__assign({}, request.autoSchedulerSetting.rulesMap), (_c = {}, _c[StandardScheduleConstraint.UNDESIRED_TIME_FOR_SHIFT] = undesiredTimeForShift, _c));
                    }
                    request.autoSchedulerSetting.shortlistRule = (_f = autoSchedulerSetting === null || autoSchedulerSetting === void 0 ? void 0 : autoSchedulerSetting.shortlistRule) !== null && _f !== void 0 ? _f : null;
                    if (!!_.isEqual(request.autoSchedulerSetting, autoSchedulerSetting)) return [3 /*break*/, 2];
                    return [4 /*yield*/, updateAutoSchedulerSetting.mutateAsync(request)];
                case 1:
                    resp = _g.sent();
                    if (resp) {
                        setAutoSchedulerSetting(resp);
                    }
                    _g.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, [
        autoSchedulerSetting,
        availableRules,
        selectedRules,
        setAutoSchedulerSetting,
        updateAutoSchedulerSetting,
    ]);
    var addToAvailableRules = useCallback(function (rule) {
        delete rule['type'];
        setAvailableRules(function (prevAvailableRules) { return __spreadArray(__spreadArray([], prevAvailableRules, true), [rule], false); });
    }, []);
    var removeFromAvailableRules = useCallback(function (rule) {
        setAvailableRules(function (prevAvailableRules) {
            return _.filter(prevAvailableRules, function (availableRule) { return availableRule.constraint !== rule.constraint; });
        });
    }, []);
    var addToSelectedRules = useCallback(function (constraint, type) {
        var rule = autoSchedulerSetting === null || autoSchedulerSetting === void 0 ? void 0 : autoSchedulerSetting.rulesMap[constraint];
        if (!rule)
            return;
        var selectedRule = {
            name: rule.ruleDisplayName,
            constraint: constraint,
        };
        selectedRule.type = type;
        setSelectedRules(function (prevSelectedRules) {
            var duplicateRuleIndex = _.findIndex(prevSelectedRules, function (rule) { return rule.constraint === constraint; });
            if (duplicateRuleIndex >= 0) {
                // When moving from 1 strictness to another
                if ('maxMinutes' in prevSelectedRules[duplicateRuleIndex]) {
                    selectedRule.maxMinutes = prevSelectedRules[duplicateRuleIndex].maxMinutes;
                }
                else if ('minMinutes' in prevSelectedRules[duplicateRuleIndex]) {
                    selectedRule.minMinutes = prevSelectedRules[duplicateRuleIndex].minMinutes;
                }
                prevSelectedRules.splice(duplicateRuleIndex, 1);
            }
            return __spreadArray(__spreadArray([], prevSelectedRules, true), [selectedRule], false);
        });
    }, [autoSchedulerSetting === null || autoSchedulerSetting === void 0 ? void 0 : autoSchedulerSetting.rulesMap]);
    var removeFromSelectedRules = useCallback(function (rule) {
        setSelectedRules(function (prevSelectedRules) {
            return _.filter(prevSelectedRules, function (selectedRule) { return selectedRule.constraint !== rule.constraint; });
        });
    }, []);
    var editSelectedRuleTime = useCallback(function (selectedRule, minutes) {
        setSelectedRules(function (prevSelectedRules) {
            return prevSelectedRules.map(function (rule) {
                if (selectedRule.constraint === rule.constraint) {
                    if (MaxMinutesScheduleConstraints.has(rule.constraint)) {
                        return __assign(__assign({}, rule), { maxMinutes: minutes });
                    }
                    else if (MinMinutesScheduleConstraints.has(rule.constraint)) {
                        return __assign(__assign({}, rule), { minMinutes: minutes });
                    }
                }
                return rule;
            });
        });
    }, []);
    var selectedRulesContainer = useMemo(function () {
        return (_jsxs(_Fragment, { children: [_jsx("div", { className: "text-lg font-semibold", children: "Strict" }), _jsx(Meta, { description: "These rules should never be broken" }), _jsx(Divider, { style: { marginLeft: '0px' } }), strictSelectedRules.map(function (rule) { return (_jsx(RuleItem, { rule: rule, addRuleToList: addToAvailableRules, removeSelectedRule: removeFromSelectedRules, editSelectedRuleTime: editSelectedRuleTime, selected: true }, rule.constraint)); }), _jsx(SelectRuleDropContainer, { type: RuleType.STRICT, addRule: addToSelectedRules }), _jsx("div", { className: "my-6 text-lg font-semibold", children: "Moderate" }), _jsx(Meta, { description: "These rules should rarely be broken" }), _jsx(Divider, { style: { marginLeft: '0px' } }), moderateSelectedRules.map(function (rule) { return (_jsx(RuleItem, { rule: rule, addRuleToList: addToAvailableRules, removeSelectedRule: removeFromSelectedRules, editSelectedRuleTime: editSelectedRuleTime, selected: true }, rule.constraint)); }), _jsx(SelectRuleDropContainer, { type: RuleType.MODERATE, addRule: addToSelectedRules }), _jsx("div", { className: "my-6 text-lg font-semibold", children: "Mild" }), _jsx(Meta, { description: "These rules can sometimes be broken" }), _jsx(Divider, { style: { marginLeft: '0px' } }), mildSelectedRules.map(function (rule) { return (_jsx(RuleItem, { rule: rule, addRuleToList: addToAvailableRules, removeSelectedRule: removeFromSelectedRules, editSelectedRuleTime: editSelectedRuleTime, selected: true }, rule.constraint)); }), _jsx(SelectRuleDropContainer, { type: RuleType.MILD, addRule: addToSelectedRules })] }));
    }, [
        addToAvailableRules,
        addToSelectedRules,
        editSelectedRuleTime,
        mildSelectedRules,
        moderateSelectedRules,
        removeFromSelectedRules,
        strictSelectedRules,
    ]);
    var ruleList = useMemo(function () {
        return (_jsxs(_Fragment, { children: [_jsx("h2", { children: "Rules" }), _jsx(Meta, { description: "Drag and drop rules" }), _jsx(Divider, { style: { margin: '9px 0px 0px' } }), availableRules.map(function (rule) {
                    return (_jsxs(_Fragment, { children: [_jsx(RuleItem, { rule: rule, removeRuleFromList: removeFromAvailableRules }, rule.constraint), _jsx(Divider, { style: { margin: '0px' } })] }));
                })] }));
    }, [availableRules, removeFromAvailableRules]);
    return (_jsxs(Modal, { className: "ScheduleRulesModal", open: true, closable: false, footer: false, centered: true, width: 1280, onCancel: function () {
            saveSettings();
            closeModal();
        }, children: [_jsx("div", { className: "RulesModalHeader", children: _jsx("div", { className: "HeaderTitle", children: "Scheduling Rules" }) }), _jsx("div", { className: "RulesModalBodyWrapper", children: _jsxs("div", { className: "RulesContainer", children: [_jsx("div", { className: "RulesSelectedWrapper", children: _jsx("div", { className: "RulesSelectedContainer", children: selectedRulesContainer }) }), _jsx("div", { className: "RuleList", children: ruleList })] }) })] }));
};
export default React.memo(ScheduleRulesModal);
