var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { isEqual, uniqBy } from 'lodash';
import { useRecoilCallback, useSetRecoilState } from 'recoil';
import { MessageSenderType, ThreadReceiverParticipantType, } from 'src/components/PageView/ChatPage/interface';
import { departmentsState, selectedThreadState, threadChatMessagesState, threadSummaryListState, } from 'src/state/atoms/chatState';
import { chatParticipantsToBroadcastRecipients, threadIsBroadcast, threadIsBroadcastFromChatRecipients, threadParticipantsToBroadcastRecipients, userToDepartmentThreadInfo, userToUserThreadInfo, } from 'src/components/PageView/ChatPage/utils';
import { arrayEqualUnordered } from 'src/utils/Tools/LodashTool';
import { v4 as uuidv4 } from 'uuid';
import { currentUserRecordIdSelector } from 'src/state/selectors/withAccountDetailState';
import { useChatApi } from '../api/useChatApi';
var useChat = function () {
    var _a = useChatApi(), getThreadById = _a.getThreadById, useSendMessage = _a.useSendMessage, useCreateThread = _a.useCreateThread, useViewThread = _a.useViewThread, usePutDepartment = _a.useUpdateDepartment, useCreateDepartment = _a.useCreateDepartment, useDeleteDepartment = _a.useDeleteDepartment, useArchiveThread = _a.useArchiveThread;
    var setThreadSummaryList = useSetRecoilState(threadSummaryListState);
    var fetchThread = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (threadId) { return __awaiter(void 0, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getThreadById(threadId)];
                    case 1:
                        data = _a.sent();
                        if (data) {
                            set(threadSummaryListState, function (prev) { return uniqBy(__spreadArray([data], prev, true), 'id'); });
                        }
                        return [2 /*return*/];
                }
            });
        }); };
    });
    var addMessage = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot, set = _a.set;
        return function (message_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(void 0, __spreadArray([message_1], args_1, true), void 0, function (message, updateLastSeenSeqNum) {
                var threadSummaryList, threadSummary;
                var _a;
                if (updateLastSeenSeqNum === void 0) { updateLastSeenSeqNum = false; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            set(threadChatMessagesState(message.threadId), function (oldMessages) {
                                var oldMessage = oldMessages.find(function (oldMessage) {
                                    return oldMessage.seqNum === message.seqNum &&
                                        oldMessage.threadId === message.threadId;
                                });
                                return __spreadArray(__spreadArray([], oldMessages.filter(function (oldMessage) {
                                    return !(oldMessage.seqNum === message.seqNum &&
                                        oldMessage.threadId === message.threadId);
                                }), true), [
                                    __assign(__assign({}, oldMessage), message),
                                ], false);
                            });
                            threadSummaryList = (_a = snapshot.getLoadable(threadSummaryListState).valueMaybe()) !== null && _a !== void 0 ? _a : [];
                            threadSummary = threadSummaryList.find(function (thread) { return thread.id === message.threadId; });
                            if (!!threadSummary) return [3 /*break*/, 2];
                            return [4 /*yield*/, fetchThread(message.threadId)];
                        case 1:
                            _b.sent();
                            return [2 /*return*/];
                        case 2:
                            // Update the thread summary of the message
                            set(threadSummaryListState, threadSummaryList.map(function (item) {
                                return item.id === message.threadId
                                    ? __assign(__assign({}, item), { lastMessage: message.seqNum > item.lastSeenSeqNum
                                            ? message
                                            : item.lastMessage, lastSeenSeqNum: updateLastSeenSeqNum && message.seqNum > item.lastSeenSeqNum
                                            ? message.seqNum
                                            : item.lastSeenSeqNum }) : item;
                            }));
                            return [2 /*return*/];
                    }
                });
            });
        };
    });
    var sendMessage = function (message, threadId, department, attachments, onMessageSentSuccess) {
        if (attachments === void 0) { attachments = []; }
        useSendMessage.mutate({
            id: uuidv4(),
            threadId: threadId,
            body: {
                text: message,
                attachments: attachments,
            },
            sendAsDepartment: (department === null || department === void 0 ? void 0 : department.type) === MessageSenderType.DEPARTMENT ? department.id : undefined,
        }, {
            onSuccess: function (data) {
                if (data) {
                    addMessage(data, true);
                    onMessageSentSuccess === null || onMessageSentSuccess === void 0 ? void 0 : onMessageSentSuccess(data);
                }
            },
        });
    };
    var createThread = function (participants_1, message_1, department_1, onMessageSentSuccess_1) {
        var args_1 = [];
        for (var _i = 4; _i < arguments.length; _i++) {
            args_1[_i - 4] = arguments[_i];
        }
        return __awaiter(void 0, __spreadArray([participants_1, message_1, department_1, onMessageSentSuccess_1], args_1, true), void 0, function (participants, message, department, onMessageSentSuccess, attachments) {
            var threadSummary;
            if (attachments === void 0) { attachments = []; }
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, useCreateThread.mutateAsync({
                            participants: __spreadArray(__spreadArray([], participants, true), (department
                                ? [
                                    {
                                        type: department.type,
                                        id: department.id,
                                    },
                                ]
                                : []), true),
                        })];
                    case 1:
                        threadSummary = _a.sent();
                        if (threadSummary) {
                            useSendMessage.mutate({
                                id: uuidv4(),
                                threadId: threadSummary.id,
                                body: {
                                    text: message,
                                    attachments: attachments,
                                },
                                sendAsDepartment: (department === null || department === void 0 ? void 0 : department.type) === MessageSenderType.DEPARTMENT
                                    ? department.id
                                    : undefined,
                            }, {
                                onSuccess: function (data) {
                                    if (data) {
                                        threadSummary.lastMessage = data;
                                        setThreadSummaryList(function (prev) {
                                            return __spreadArray(__spreadArray([], prev.filter(function (thread) { return thread.id !== threadSummary.id; }), true), [
                                                threadSummary,
                                            ], false);
                                        });
                                        onMessageSentSuccess === null || onMessageSentSuccess === void 0 ? void 0 : onMessageSentSuccess(data);
                                    }
                                },
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    var viewThread = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (threadId) { return __awaiter(void 0, void 0, void 0, function () {
            var threadSummaryList, thread;
            var _a, _b, _c;
            return __generator(this, function (_d) {
                threadSummaryList = (_a = snapshot.getLoadable(threadSummaryListState).valueMaybe()) !== null && _a !== void 0 ? _a : [];
                thread = threadSummaryList.find(function (thread) { return thread.id === threadId; });
                if (thread) {
                    useViewThread.mutate({
                        threadId: thread.id,
                        lastSeenSeqNum: (_c = (_b = thread.lastMessage) === null || _b === void 0 ? void 0 : _b.seqNum) !== null && _c !== void 0 ? _c : 0,
                    }, {
                        onSuccess: function (data) {
                            if (data) {
                                setThreadSummaryList(function (prev) {
                                    return __spreadArray([], prev.map(function (item) {
                                        if (item.id === thread.id) {
                                            return __assign(__assign({}, item), { lastSeenSeqNum: data.lastSeenSeqNum });
                                        }
                                        return item;
                                    }), true);
                                });
                            }
                        },
                    });
                }
                return [2 /*return*/];
            });
        }); };
    });
    var deleteDepartment = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot, set = _a.set;
        return function (id) { return __awaiter(void 0, void 0, void 0, function () {
            var departments, department;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        departments = (_a = snapshot.getLoadable(departmentsState).valueMaybe()) !== null && _a !== void 0 ? _a : [];
                        department = departments.find(function (department) { return department.id === id; });
                        if (department) {
                            set(departmentsState, departments.filter(function (department) { return department.id !== id; }));
                        }
                        return [4 /*yield*/, useDeleteDepartment.mutateAsync(id, {
                                onError: function () {
                                    set(departmentsState, __spreadArray([], departments, true));
                                },
                            })];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
    });
    var updateDepartment = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot, set = _a.set;
        return function (request) { return __awaiter(void 0, void 0, void 0, function () {
            var departments, currentUserRId;
            var _a, _b;
            return __generator(this, function (_c) {
                departments = (_a = snapshot.getLoadable(departmentsState).valueMaybe()) !== null && _a !== void 0 ? _a : [];
                currentUserRId = (_b = snapshot.getLoadable(currentUserRecordIdSelector).valueMaybe()) !== null && _b !== void 0 ? _b : '';
                usePutDepartment.mutateAsync(request, {
                    onSuccess: function (data) {
                        if (data) {
                            if (data.users.find(function (user) { return user.id === currentUserRId; })) {
                                set(departmentsState, __spreadArray(__spreadArray([], departments.filter(function (department) { return department.id !== data.id; }), true), [
                                    data,
                                ], false));
                            }
                            else {
                                set(departmentsState, departments.filter(function (department) { return department.id !== data.id; }));
                            }
                        }
                    },
                });
                return [2 /*return*/];
            });
        }); };
    });
    var createDepartment = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot, set = _a.set;
        return function (request) { return __awaiter(void 0, void 0, void 0, function () {
            var currentUserRId, departments;
            var _a, _b;
            return __generator(this, function (_c) {
                currentUserRId = (_a = snapshot.getLoadable(currentUserRecordIdSelector).valueMaybe()) !== null && _a !== void 0 ? _a : '';
                departments = (_b = snapshot.getLoadable(departmentsState).valueMaybe()) !== null && _b !== void 0 ? _b : [];
                useCreateDepartment.mutateAsync(request, {
                    onSuccess: function (data) {
                        if (data && data.users.find(function (user) { return user.id === currentUserRId; })) {
                            set(departmentsState, __spreadArray(__spreadArray([], departments.filter(function (department) { return department.id !== data.id; }), true), [
                                data,
                            ], false));
                        }
                    },
                });
                return [2 /*return*/];
            });
        }); };
    });
    // This is the temporary solution for updating the department list,
    // before the backend supports the PATCH method
    var saveDepartment = function (initialDepartments, newDepartments) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Promise.all(__spreadArray(__spreadArray(__spreadArray([], initialDepartments
                        .filter(function (initialDepartment) {
                        return !newDepartments.find(function (newDepartment) { return newDepartment.id === initialDepartment.id; });
                    })
                        .map(function (department) { return deleteDepartment(department.id); }), true), newDepartments
                        .filter(function (newDepartment) {
                        var initialDepartment = initialDepartments.find(function (initialDepartment) { return initialDepartment.id === newDepartment.id; });
                        return initialDepartment === undefined;
                    })
                        .map(function (department) {
                        return createDepartment({
                            id: department.id,
                            title: department.title,
                            avatarUrl: department.avatarUrl,
                            userRids: department.users.map(function (user) { return user.id; }),
                        });
                    }), true), newDepartments
                        .filter(function (newDepartment) {
                        var initialDepartment = initialDepartments.find(function (initialDepartment) { return initialDepartment.id === newDepartment.id; });
                        return initialDepartment && !isEqual(initialDepartment, newDepartment);
                    })
                        .map(function (department) {
                        return updateDepartment({
                            id: department.id,
                            title: department.title,
                            avatarUrl: department.avatarUrl,
                            userRids: department.users.map(function (user) { return user.id; }),
                        });
                    }), true))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var latestMessageInThreadIds = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (threadIds) {
            var latestMessage = undefined;
            threadIds.forEach(function (threadId) {
                var _a, _b, _c;
                var latestMessageFromThread = (_b = (_a = snapshot
                    .getLoadable(threadSummaryListState)
                    .valueMaybe()) === null || _a === void 0 ? void 0 : _a.find(function (item) { return item.id === threadId; })) === null || _b === void 0 ? void 0 : _b.lastMessage;
                var messagesFromState = (_c = snapshot.getLoadable(threadChatMessagesState(threadId)).valueMaybe()) !== null && _c !== void 0 ? _c : [];
                var messageList = __spreadArray([
                    latestMessageFromThread,
                    latestMessage
                ], messagesFromState, true).filter(function (item) { return !!item; });
                if (messageList.length > 0) {
                    latestMessage = messageList.reduce(function (prev, current) {
                        return Date.parse(prev.createdAt) > Date.parse(current.createdAt)
                            ? prev
                            : current;
                    });
                }
            });
            return latestMessage;
        };
    });
    var unreadMessageCountFromThreadIds = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (threadIds) {
            var currentUserRId = snapshot
                .getLoadable(currentUserRecordIdSelector)
                .valueMaybe();
            var count = 0;
            threadIds.forEach(function (threadId) {
                var _a, _b;
                var lastMessage = latestMessageInThreadIds([threadId]);
                var lastSeenSeqNum = (_b = (_a = snapshot
                    .getLoadable(threadSummaryListState)
                    .valueMaybe()) === null || _a === void 0 ? void 0 : _a.find(function (item) { return item.id === threadId; })) === null || _b === void 0 ? void 0 : _b.lastSeenSeqNum;
                if (lastMessage &&
                    lastSeenSeqNum !== undefined &&
                    lastMessage.seqNum > lastSeenSeqNum &&
                    lastMessage.senderDetail.id !== currentUserRId) {
                    count += lastMessage.seqNum - lastSeenSeqNum;
                }
            });
            return count;
        };
    });
    var broadcastsWithRecipients = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (broadcastRecipients) {
            var _a;
            var threadSummaryList = (_a = snapshot.getLoadable(threadSummaryListState).valueMaybe()) !== null && _a !== void 0 ? _a : [];
            if (broadcastRecipients) {
                return threadSummaryList.filter(function (thread) {
                    if (!threadIsBroadcast(thread)) {
                        return false;
                    }
                    var threadRecipients = threadParticipantsToBroadcastRecipients(thread.participants);
                    return broadcastRecipients.every(function (selectedRecipient) {
                        return threadRecipients.some(function (threadRecipient) {
                            return threadRecipient.id === selectedRecipient.id &&
                                threadRecipient.type === selectedRecipient.type;
                        }) && threadRecipients.length === broadcastRecipients.length;
                    });
                });
            }
        };
    });
    var sortedThread = function (threads) {
        return threads.sort(function (a, b) {
            var _a, _b, _c, _d;
            var aDate = new Date((_b = (_a = latestMessageInThreadIds([a.id])) === null || _a === void 0 ? void 0 : _a.createdAt) !== null && _b !== void 0 ? _b : 0);
            var bDate = new Date((_d = (_c = latestMessageInThreadIds([b.id])) === null || _c === void 0 ? void 0 : _c.createdAt) !== null && _d !== void 0 ? _d : 0);
            return bDate.getTime() - aDate.getTime();
        });
    };
    var getThreadByParticipant = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (newThreadParticipants) {
            var _a;
            var threadSummaryList = (_a = snapshot.getLoadable(threadSummaryListState).valueMaybe()) !== null && _a !== void 0 ? _a : [];
            // If the new thread is a broadcast, find the thread with the same broadcast recipients
            if (threadIsBroadcastFromChatRecipients(newThreadParticipants.participants)) {
                return threadSummaryList.find(function (thread) {
                    var _a, _b, _c;
                    var isAccountBroadcast = !!thread.participants.find(function (participant) {
                        return participant.participantType ===
                            ThreadReceiverParticipantType.BROADCAST_ACCOUNT;
                    });
                    return (threadIsBroadcast(thread) &&
                        // Broadcast recipients are the same
                        arrayEqualUnordered(threadParticipantsToBroadcastRecipients(thread.participants), chatParticipantsToBroadcastRecipients(newThreadParticipants.participants), isEqual) &&
                        (isAccountBroadcast ||
                            // Department sender is the same (Ignore for account broadcast)
                            ((_a = newThreadParticipants.sender) === null || _a === void 0 ? void 0 : _a.id) ===
                                ((_c = (_b = thread.participants.find(function (participant) {
                                    return participant.participantType ===
                                        MessageSenderType.DEPARTMENT;
                                })) === null || _b === void 0 ? void 0 : _b.participantRid) !== null && _c !== void 0 ? _c : '')));
                });
            }
            else {
                // If the new thread is not a broadcast, find the thread with the same participants
                var targetThread = threadSummaryList.find(function (thread) {
                    var isBroadcast = threadIsBroadcast(thread);
                    var threadParticipants = thread.participants.map(function (participant) {
                        return {
                            id: participant.participantRid,
                            type: participant.participantType,
                        };
                    });
                    // new participants + sender
                    var newThreadParticipantsAndSenders = uniqBy(__spreadArray(__spreadArray([], newThreadParticipants.participants.map(function (participant) {
                        return {
                            id: participant.id,
                            type: participant.type,
                        };
                    }), true), (newThreadParticipants.sender
                        ? [
                            {
                                id: newThreadParticipants.sender.id,
                                type: newThreadParticipants.sender.type,
                            },
                        ]
                        : []), true), 'id');
                    return (!isBroadcast &&
                        arrayEqualUnordered(threadParticipants, newThreadParticipantsAndSenders, isEqual));
                });
                return targetThread;
            }
        };
    });
    var archiveThread = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot, set = _a.set;
        return function () {
            var threadId = snapshot.getLoadable(selectedThreadState).valueMaybe();
            if (threadId) {
                useArchiveThread.mutate(threadId, {
                    onSuccess: function () {
                        set(selectedThreadState, undefined);
                        set(threadSummaryListState, function (previousThreadSummaryList) { return __spreadArray([], previousThreadSummaryList.filter(function (threadSummary) { return threadSummary.id !== threadId; }), true); });
                    },
                });
            }
            return Promise.resolve();
        };
    });
    var sortedSenderOptionsByLastMessageTime = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (senderOptions) {
            var _a;
            var threadSummaryList = (_a = snapshot.getLoadable(threadSummaryListState).valueMaybe()) !== null && _a !== void 0 ? _a : [];
            var sortedSenderOptions = [];
            threadSummaryList.forEach(function (thread) {
                var userThreadInfo = userToUserThreadInfo(thread.participants);
                var departmentThreadInfo = userToDepartmentThreadInfo(thread.participants);
                if (userThreadInfo) {
                    var targetSenderOption = senderOptions.find(function (senderOption) {
                        return senderOption.type === MessageSenderType.USER &&
                            userThreadInfo.userRids.includes(senderOption.id);
                    });
                    if (targetSenderOption) {
                        sortedSenderOptions.push(targetSenderOption);
                    }
                }
                else if (departmentThreadInfo) {
                    var targetSenderOption = senderOptions.find(function (senderOption) {
                        return senderOption.type === MessageSenderType.DEPARTMENT &&
                            departmentThreadInfo.departmentId === senderOption.id;
                    });
                    if (targetSenderOption) {
                        sortedSenderOptions.push(targetSenderOption);
                    }
                }
            });
            // All sender options that cannot find in the thread summary list
            var unPushedSenderOptions = senderOptions.filter(function (option) {
                return !sortedSenderOptions.includes(option);
            });
            return __spreadArray(__spreadArray([], sortedSenderOptions, true), unPushedSenderOptions, true);
        };
    });
    return {
        addMessage: addMessage,
        sendMessage: sendMessage,
        createThread: createThread,
        viewThread: viewThread,
        saveDepartment: saveDepartment,
        latestMessageInThreadIds: latestMessageInThreadIds,
        unreadMessageCountFromThreadIds: unreadMessageCountFromThreadIds,
        broadcastsWithRecipients: broadcastsWithRecipients,
        sortedThread: sortedThread,
        getThreadByParticipant: getThreadByParticipant,
        archiveThread: archiveThread,
        sortedSenderOptionsByLastMessageTime: sortedSenderOptionsByLastMessageTime,
    };
};
export { useChat as useChatComponent };
