var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var SignatureOutlinedSvg = function () { return (_jsx("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M2.25 12.75C4.74975 10.2503 6 8.25 6 6.75C6 4.5 5.25 4.5 4.5 4.5C3.75 4.5 2.976 5.31375 3 6.75C3.0255 8.286 4.2435 10.4077 4.875 11.25C6 12.75 6.75 13.125 7.5 12L9 9.75C9.24975 11.7503 9.99975 12.75 11.25 12.75C11.6475 12.75 13.2292 11.25 13.5 11.25C13.8878 11.25 14.6377 11.7503 15.75 12.75", stroke: "#959595", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }) })); };
export var SignatureOutlinedIcon = function (props) { return (_jsx(Icon, __assign({ component: SignatureOutlinedSvg }, props))); };
