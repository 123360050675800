var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Select, Space, Tag } from 'antd';
import { CloseOutlined, LoadingOutlined, PlusOutlined, DownOutlined, UpOutlined, } from '@ant-design/icons';
import '../../../TableView/TableCell/SelectorCell.scss';
import _, { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { collectionTableSchemaSelector, ifCurrentUserHasDataBuilderPermission, } from 'src/state';
import { useRecordComponent, useSchemaComponent } from 'src/hooks/component';
import { useRecoilValue } from 'recoil';
import { usePageApi } from 'src/hooks/api';
import UserTagWithIcon from '../../../User/UserTagWithIcon';
import { allowInlineCreateStandardObj, allowCreateUserObj, isBooleanType, isPlainSelection, isShiftPayrollStatusSchema, NativeMultiSet, OPTIONS_COLOR, ValueDataType, } from '../../../../shared';
import { disableScroll, getTagTextColor } from '../../../../utils/Collection';
import './LogicSelectionCell.scss';
import WarningModalForShiftPayrollStatusSelector from './StandardModals/WarningModalForShiftPayrollStatusSelector';
import AddNewStandardObjConfirmationModal from './StandardModals/AddNewStandardObjConfirmationModal';
import EditSelectOption from './components/EditSelectOption';
var generateRandomIndex = function () { return Math.floor(Math.random() * OPTIONS_COLOR.length); };
var Option = Select.Option;
var LogicSelectionCell = function (props) {
    var _a;
    var cellPos = props.cellPos, selectedOptions = props.displayedOption, availableOptions = props.availableOption, defaultIsUserSelecting = props.isUserSelecting, editCell = props.editCell, setIsClicked = props.setIsClicked, mutateColumnOption = props.mutateColumnOption, deleteColumnOption = props.deleteColumnOption, addColumnOption = props.addColumnOption, reorderColumnOptions = props.reorderColumnOptions, isLoading = props.isLoading, paginationProps = props.paginationProps, selectedAll = props.selectedAll, setSelectedAll = props.setSelectedAll, setAvailableOptions = props.setAvailableOptions;
    var _b = paginationProps || {}, onScroll = _b.onScroll, onSearch = _b.onSearch;
    var schema = useRecoilValue(collectionTableSchemaSelector(cellPos));
    var hasDataBuilderAccess = useRecoilValue(ifCurrentUserHasDataBuilderPermission);
    var _c = useRecordComponent(), onCreateNewRecord = _c.onCreateNewRecord, createNewRecordFromInlineCreationAction = _c.createNewRecordFromInlineCreationAction;
    var addNewStandardObjInColumnOption = useSchemaComponent().addNewStandardObjInColumnOption;
    var checkCollectionForInlineCreation = usePageApi().checkCollectionForInlineCreation;
    var selectRef = useRef(null);
    var _d = useState(null), renameText = _d[0], setRenameText = _d[1];
    var _e = useState(''), newOptionNameInput = _e[0], setNewOptionNameInput = _e[1];
    var _f = useState(generateRandomIndex()), randomColorIndex = _f[0], setRandomColorIndex = _f[1];
    var _g = useState(), newShiftPayrollStatusIdForWarningModal = _g[0], setNewShiftPayrollStatusIdForWarningModal = _g[1];
    var isShiftPayrollStatusWarningModalVisible = !!newShiftPayrollStatusIdForWarningModal;
    var _h = useState(false), isAddNewObjConfirmationModalVisible = _h[0], setIsAddNewObjConfirmationModalVisible = _h[1];
    var _j = useState(false), showCantAddMessage = _j[0], setShowCantAddMessage = _j[1];
    var isShowPlainSelectionActions = useMemo(function () { return isPlainSelection(schema.type); }, [schema.type]);
    var isMulti = useMemo(function () { return schema.inputMode === 'multi' || NativeMultiSet.has(schema.nativePath); }, [schema.inputMode, schema.nativePath]);
    var isUserSelecting = useMemo(function () { return defaultIsUserSelecting || false; }, [defaultIsUserSelecting]);
    var isNewOptionName = useMemo(function () {
        return !_.find(availableOptions, function (option) { return option.text === newOptionNameInput; });
    }, [availableOptions, newOptionNameInput]);
    var areOptionsOnSearch = useMemo(function () {
        return _.find(availableOptions, function (option) {
            return option.text.toLowerCase().indexOf(newOptionNameInput.toLowerCase().trim()) > -1;
        });
    }, [availableOptions, newOptionNameInput]);
    useEffect(function () {
        if (selectedAll && !isLoading) {
            editCell(availableOptions);
            setSelectedAll(false);
        }
    }, [availableOptions, selectedAll, isLoading, setSelectedAll, editCell]);
    useEffect(function () {
        var _a;
        (_a = selectRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        disableScroll(true);
        return function () { return disableScroll(false); };
    }, []);
    var handleDropDownVisibleChange = useCallback(function (dropdownVisible) {
        setIsClicked(dropdownVisible);
    }, [setIsClicked]);
    var onRemoveTag = useCallback(function (id) {
        editCell(isMulti ? selectedOptions.filter(function (item) { return item.id !== id; }) : []);
    }, [isMulti, editCell, selectedOptions]);
    var onClearAll = useCallback(function () {
        editCell([]);
    }, [editCell]);
    var onSelectAll = useCallback(function () {
        setSelectedAll(true);
        editCell(availableOptions);
    }, [availableOptions, editCell, setSelectedAll]);
    var resetSearchInput = useCallback(function () {
        setNewOptionNameInput('');
        onSearch === null || onSearch === void 0 ? void 0 : onSearch('');
    }, [onSearch]);
    var tagRender = useCallback(function (props) {
        var _a, _b, _c;
        var label = props.label;
        // Use "all options" instead of just available so that initial tag rendering
        // has selectedOptions to look @ for color/text before all available options are loaded
        var allOptions = __spreadArray(__spreadArray([], availableOptions, true), selectedOptions, true);
        var tagColor = (_a = _.find(allOptions, function (item) { return item.id === label; })) === null || _a === void 0 ? void 0 : _a.color;
        var tagText = (_b = _.find(allOptions, function (item) { return item.id === label; })) === null || _b === void 0 ? void 0 : _b.text;
        if (!tagText) {
            return _jsx(_Fragment, {});
        }
        var closable = !isBooleanType(schema.type) && !isShiftPayrollStatusSchema(schema.nativePath);
        if (isUserSelecting) {
            var selectCellProps = {
                closable: closable,
                onRemoveTag: onRemoveTag,
                label: label,
            };
            var tagAvatar = (_c = _.find(availableOptions, function (item) { return item.id === label; })) === null || _c === void 0 ? void 0 : _c.userAvatar;
            var avatarProps = {
                avatar: tagAvatar,
            };
            return (_jsx(UserTagWithIcon, { name: tagText, size: 22, selectCellProps: selectCellProps, avatarProps: avatarProps }));
        }
        if (!tagText)
            return _jsx(_Fragment, {});
        return (_jsx(Tag, { color: tagColor, onMouseDown: function (e) {
                e.preventDefault();
                e.stopPropagation();
            }, className: "selector-tag", closeIcon: _jsx(CloseOutlined, { className: "selector-tag-close" }), closable: closable, onClose: function () { return onRemoveTag(label); }, style: { color: getTagTextColor(tagColor) }, children: tagText }));
    }, [
        availableOptions,
        selectedOptions,
        isUserSelecting,
        onRemoveTag,
        schema.nativePath,
        schema.type,
    ]);
    var onTextChange = useCallback(function (newText) {
        setRenameText(newText);
    }, []);
    var onOptionColorChange = useCallback(function (idx) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return function (e) {
            e.domEvent.preventDefault();
            e.domEvent.stopPropagation();
            var newAvailableOptions = cloneDeep(availableOptions);
            newAvailableOptions[idx].color = e.key;
            setAvailableOptions(newAvailableOptions);
            var newSelectedOptions = newAvailableOptions.filter(function (item) {
                return selectedOptions.find(function (s) { return s.id === item.id; });
            });
            editCell(newSelectedOptions);
        };
    }, [editCell, selectedOptions, availableOptions, setAvailableOptions]);
    var deleteSelectedOption = useCallback(function (id) {
        editCell(selectedOptions.filter(function (item) { return item.id !== id; }));
        setAvailableOptions(availableOptions.filter(function (item) { return item.id !== id; }));
    }, [editCell, availableOptions, setAvailableOptions, selectedOptions]);
    var onDeleteColumnOption = useCallback(function (idx) {
        deleteColumnOption === null || deleteColumnOption === void 0 ? void 0 : deleteColumnOption(availableOptions[idx].id);
        deleteSelectedOption(availableOptions[idx].id);
    }, [deleteColumnOption, availableOptions, deleteSelectedOption]);
    var onEditMenuClose = useCallback(function (idx) {
        var editedOption = availableOptions.find(function (_a) {
            var id = _a.id;
            return id === availableOptions[idx].id;
        });
        // cannot find option, no need to mutate
        if (!editedOption)
            return;
        mutateColumnOption === null || mutateColumnOption === void 0 ? void 0 : mutateColumnOption(availableOptions[idx].id, renameText !== null && renameText !== void 0 ? renameText : availableOptions[idx].text, availableOptions[idx].color);
    }, [availableOptions, mutateColumnOption, renameText]);
    var onChangeInput = useCallback(function (values, newOption) {
        resetSearchInput();
        var singleChoiceValue = values.length === 0 ? undefined : values[values.length - 1];
        var selected = isMulti ? values : singleChoiceValue ? [singleChoiceValue] : [];
        if (!isMulti) {
            var newOptions = newOption
                ? [newOption]
                : availableOptions.filter(function (option) { return !!selected.find(function (s) { return option.id === s.id; }); });
            if (!isBooleanType(schema.type) || newOptions.length) {
                //Update shift payroll status
                if (isShiftPayrollStatusSchema(schema.nativePath)) {
                    if (!newOptions.length) {
                        return;
                    }
                    var currentPayrollStatus = availableOptions.find(function (option) { return option.id === selectedOptions[0].id; });
                    // Show warning modal when try to update shift payroll status from Paid or Approved to something else
                    if ((currentPayrollStatus === null || currentPayrollStatus === void 0 ? void 0 : currentPayrollStatus.text) === 'Paid' ||
                        (currentPayrollStatus === null || currentPayrollStatus === void 0 ? void 0 : currentPayrollStatus.text) === 'Approved') {
                        setNewShiftPayrollStatusIdForWarningModal(singleChoiceValue === null || singleChoiceValue === void 0 ? void 0 : singleChoiceValue.id);
                    }
                    else {
                        editCell(newOptions);
                        handleDropDownVisibleChange(false);
                    }
                }
                else {
                    editCell(newOptions);
                    handleDropDownVisibleChange(false);
                }
            }
        }
        else {
            // For multi selection support inline adding, combine existing options and new input option
            editCell(newOption ? __spreadArray(__spreadArray([], selected, true), [newOption], false) : selected);
        }
    }, [
        resetSearchInput,
        isMulti,
        availableOptions,
        schema.type,
        schema.nativePath,
        selectedOptions,
        editCell,
        handleDropDownVisibleChange,
    ]);
    var onSearchInDropdown = useCallback(function (value) {
        if (isBooleanType(schema.type) || isShiftPayrollStatusSchema(schema.nativePath))
            return;
        setNewOptionNameInput(value);
        setShowCantAddMessage(false);
        onSearch === null || onSearch === void 0 ? void 0 : onSearch(value);
    }, [onSearch, schema.nativePath, schema.type]);
    var onFilterOption = useCallback(function (input, option) {
        var search = input.trim().toLowerCase();
        var optionItem = availableOptions.find(function (item) { return item.id === option.value; });
        if (schema.refCollectionId) {
            return !!optionItem;
        }
        return optionItem ? optionItem.text.toLowerCase().indexOf(search) > -1 : false;
    }, [availableOptions, schema.refCollectionId]);
    // Reset random color index to a new color when done adding new option
    var resetRandomColorIndex = useCallback(function () {
        setRandomColorIndex(generateRandomIndex());
    }, []);
    var addNewOption = useCallback(function () {
        var newOptionId = uuidv4();
        var newOption = {
            id: newOptionId,
            text: newOptionNameInput,
            color: isUserSelecting ? '' : OPTIONS_COLOR[randomColorIndex],
        };
        setAvailableOptions(__spreadArray(__spreadArray([], availableOptions, true), [newOption], false));
        addColumnOption === null || addColumnOption === void 0 ? void 0 : addColumnOption(newOptionId, newOptionNameInput, OPTIONS_COLOR[randomColorIndex]);
        // Add option after creating
        onChangeInput(selectedOptions, newOption);
        resetSearchInput();
        resetRandomColorIndex();
    }, [
        newOptionNameInput,
        isUserSelecting,
        randomColorIndex,
        setAvailableOptions,
        availableOptions,
        addColumnOption,
        onChangeInput,
        selectedOptions,
        resetSearchInput,
        resetRandomColorIndex,
    ]);
    var addRecordOptionToCellForInlineCreate = useCallback(function (newOption) {
        var _a;
        onChangeInput(selectedOptions, newOption);
        resetRandomColorIndex();
        resetSearchInput();
        (_a = selectRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, [onChangeInput, selectedOptions, resetRandomColorIndex, resetSearchInput]);
    var addNewStandardObjOption = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var newOptionId, newOption;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    newOptionId = uuidv4();
                    newOption = {
                        id: newOptionId,
                        text: newOptionNameInput,
                        color: OPTIONS_COLOR[randomColorIndex],
                    };
                    return [4 /*yield*/, addNewStandardObjInColumnOption((_a = schema.refCollectionId) !== null && _a !== void 0 ? _a : '', schema.id, newOptionId, newOptionNameInput, OPTIONS_COLOR[randomColorIndex])
                        // Add option after creating
                    ];
                case 1:
                    _b.sent();
                    // Add option after creating
                    addRecordOptionToCellForInlineCreate(newOption);
                    return [2 /*return*/];
            }
        });
    }); }, [
        newOptionNameInput,
        randomColorIndex,
        addNewStandardObjInColumnOption,
        schema.refCollectionId,
        schema.id,
        addRecordOptionToCellForInlineCreate,
    ]);
    var openInlineCreateNewObjectModal = useCallback(function (prefillName) { return __awaiter(void 0, void 0, void 0, function () {
        var targetCollectionId, isNative;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    targetCollectionId = schema.refCollectionId;
                    if (!targetCollectionId)
                        return [2 /*return*/];
                    if (!prefillName) return [3 /*break*/, 2];
                    isNative = schema.type !== ValueDataType.CUSTOM;
                    return [4 /*yield*/, createNewRecordFromInlineCreationAction('', targetCollectionId, prefillName, isNative, addRecordOptionToCellForInlineCreate)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    onCreateNewRecord('', targetCollectionId, undefined, undefined, undefined, undefined, undefined, addRecordOptionToCellForInlineCreate);
                    _a.label = 3;
                case 3:
                    handleDropDownVisibleChange(false);
                    return [2 /*return*/];
            }
        });
    }); }, [
        addRecordOptionToCellForInlineCreate,
        createNewRecordFromInlineCreationAction,
        handleDropDownVisibleChange,
        onCreateNewRecord,
        schema.refCollectionId,
        schema.type,
    ]);
    var onHandleInlineCreation = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var resp;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!schema.refCollectionId) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, checkCollectionForInlineCreation((_a = schema.refCollectionId) !== null && _a !== void 0 ? _a : '')];
                case 1:
                    resp = _b.sent();
                    if (resp === undefined) {
                        return [2 /*return*/];
                    }
                    if (resp) {
                        setIsAddNewObjConfirmationModalVisible(true);
                    }
                    else {
                        openInlineCreateNewObjectModal(newOptionNameInput);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [openInlineCreateNewObjectModal, schema.refCollectionId, schema.name, newOptionNameInput]);
    var onKeyDown = useCallback(function (e) {
        // Add new option on 'Enter'
        if (e.key === 'Enter') {
            if (isNewOptionName && !areOptionsOnSearch) {
                if (isPlainSelection(schema.type)) {
                    addNewOption();
                }
                // Open add new standard obj confirmation modal
                else if (allowInlineCreateStandardObj(schema.type)) {
                    onHandleInlineCreation();
                }
                else {
                    setShowCantAddMessage(true);
                }
            }
        }
    }, [addNewOption, areOptionsOnSearch, isNewOptionName, onHandleInlineCreation, schema.type]);
    var onMoveOptionUpDown = useCallback(function (optionIndex, isUp) {
        var isDown = !isUp;
        if ((optionIndex === 0 && isUp) ||
            (optionIndex === availableOptions.length - 1 && isDown)) {
            return;
        }
        var newAvailableOptions = cloneDeep(availableOptions);
        var toIndex = isUp ? optionIndex - 1 : optionIndex + 1;
        var optionToMove = newAvailableOptions[optionIndex];
        newAvailableOptions.splice(optionIndex, 1);
        newAvailableOptions.splice(toIndex, 0, optionToMove);
        setAvailableOptions(newAvailableOptions);
        reorderColumnOptions === null || reorderColumnOptions === void 0 ? void 0 : reorderColumnOptions(newAvailableOptions.map(function (option) { return option.id; }));
    }, [availableOptions, setAvailableOptions, reorderColumnOptions]);
    var notFoundContent = useMemo(function () {
        if ((isPlainSelection(schema.type) && hasDataBuilderAccess) ||
            allowInlineCreateStandardObj(schema.type)) {
            return (_jsx("div", { className: "selector-not-found-content-wrapper mb-2", children: newOptionNameInput && (_jsx("div", { className: "selector-not-found-content", children: _jsxs("a", { className: "selector-not-found-content-link", onClick: function () {
                            if (isPlainSelection(schema.type)) {
                                addNewOption();
                            }
                            else {
                                onHandleInlineCreation();
                            }
                        }, children: ["Add ", allowInlineCreateStandardObj(schema.type) ? schema.name : '', _jsx("span", { className: "ml-1", style: {
                                    backgroundColor: OPTIONS_COLOR[randomColorIndex],
                                    color: getTagTextColor(OPTIONS_COLOR[randomColorIndex]),
                                }, children: newOptionNameInput })] }) })) }));
        }
        else {
            return _jsx(_Fragment, { children: !isLoading && _jsx("span", { children: "No options found" }) });
        }
    }, [
        schema.type,
        schema.name,
        hasDataBuilderAccess,
        newOptionNameInput,
        randomColorIndex,
        addNewOption,
        onHandleInlineCreation,
        isLoading,
    ]);
    var dropdownMenuRender = useCallback(function (menu) { return (_jsxs("div", { children: [_jsxs("div", { className: "selector-dropdown-wrapper", children: ["Select an option", (isPlainSelection(schema.type) ||
                        allowInlineCreateStandardObj(schema.type)) && _jsx(_Fragment, { children: " or create one" }), isMulti && !newOptionNameInput && (_jsxs(Space, { className: "SelectAndClearAllWrapper", size: "large", children: [selectedOptions.length !== availableOptions.length && (_jsx("span", { className: "SelectAll", onClick: onSelectAll, children: "Select All" })), !!selectedOptions.length && (_jsx("span", { className: "ClearAll", onClick: onClearAll, children: "Clear All" }))] }))] }), menu, showCantAddMessage && (_jsx("div", { className: "selector-dropdown-wrapper", children: allowCreateUserObj(schema.type)
                    ? "Please click Create New ".concat(schema.name, " if you want to add new record.")
                    : 'You cannot add records to a linked object from the reference. Please add the record to the Data Source first.' })), allowCreateUserObj(schema.type) && (_jsxs("div", { className: "cursor-pointer bg-gray-50 py-2 px-3 font-medium flex items-center text-black", onClick: function () { return openInlineCreateNewObjectModal(); }, children: [_jsx(PlusOutlined, { className: "mr-1 text-xs mb-[1px]" }), " Create New ", schema.name] })), isLoading && (_jsxs(Space, { className: "selector-dropdown-wrapper", children: ["Loading...", _jsx(LoadingOutlined, {})] }))] })); }, [
        availableOptions.length,
        isLoading,
        isMulti,
        newOptionNameInput,
        onClearAll,
        onSelectAll,
        openInlineCreateNewObjectModal,
        schema.name,
        schema.type,
        selectedOptions.length,
        showCantAddMessage,
    ]);
    // To prevent a blank flash until availableOptions are loaded when there are in fact available selections (aka if there are selections),
    // show the "selectedOptions" as available in the meantime
    var availableOptionsToRender = selectedOptions.length > 0 && availableOptions.length === 0
        ? selectedOptions
        : availableOptions;
    return (_jsxs(_Fragment, { children: [_jsx(Select, { ref: selectRef, placeholder: '', open: true, showSearch: true, searchValue: newOptionNameInput, bordered: false, mode: 'multiple', className: "LogicSelectorCellWrapper", dropdownMatchSelectWidth: 400, optionFilterProp: "children", value: isMulti
                    ? selectedOptions.map(function (_a) {
                        var id = _a.id;
                        return id;
                    })
                    : selectedOptions.length > 0
                        ? [selectedOptions[selectedOptions.length - 1].id]
                        : [], onChange: function (valueIds) {
                    var values = valueIds.map(function (id) {
                        return __spreadArray(__spreadArray([], selectedOptions, true), availableOptions, true).find(function (option) { return option.id === id; });
                    });
                    onChangeInput(values);
                }, onSearch: onSearchInDropdown, filterOption: onFilterOption, menuItemSelectedIcon: _jsx(_Fragment, {}), tagRender: tagRender, optionLabelProp: "label", notFoundContent: notFoundContent, dropdownRender: dropdownMenuRender, dropdownStyle: { zIndex: 1100 }, onKeyDown: onKeyDown, loading: isLoading, onPopupScroll: onScroll, children: _jsx(_Fragment, { children: availableOptionsToRender.map(function (option, index) { return (_jsx(Option, { value: option.id, label: option.id, children: _jsxs("div", { className: "selector-option-wrapper", children: [isUserSelecting ? (_jsx(UserTagWithIcon, { name: option.text, avatarProps: { avatar: option.userAvatar }, size: 22 })) : (_jsx("span", { className: "selector-option-text", style: {
                                        backgroundColor: option.color,
                                        color: getTagTextColor(option.color),
                                    }, children: option.text })), isShowPlainSelectionActions && hasDataBuilderAccess && (_jsxs("span", { className: "text-gray-300 float-right", children: [_jsx(UpOutlined, { className: "cursor-pointer hover:text-black pl-1", onClick: function (e) {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                onMoveOptionUpDown(index, true);
                                            } }), _jsx(DownOutlined, { className: "cursor-pointer hover:text-black pl-1", onClick: function (e) {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                onMoveOptionUpDown(index, false);
                                            } })] })), _jsx("span", { onClick: function (e) { return e.stopPropagation(); }, onKeyDown: function (e) { return e.stopPropagation(); }, children: isShowPlainSelectionActions && (_jsx(EditSelectOption, { schema: schema, editOptionIndex: index, availableOptions: availableOptions, selectedColor: option.color, onOptionColorChange: onOptionColorChange, onTextChange: onTextChange, onDeleteColumnOption: onDeleteColumnOption, onEditMenuClose: onEditMenuClose })) })] }) }, option.id)); }) }) }), isShiftPayrollStatusSchema(schema.nativePath) &&
                isShiftPayrollStatusWarningModalVisible && (_jsx(WarningModalForShiftPayrollStatusSelector, { onClose: function () {
                    setNewShiftPayrollStatusIdForWarningModal(undefined);
                    handleDropDownVisibleChange(false);
                }, onConfirm: function () {
                    var newOptions = availableOptions.filter(function (option) { return option.id === newShiftPayrollStatusIdForWarningModal; });
                    editCell(newOptions);
                    handleDropDownVisibleChange(false);
                    setNewShiftPayrollStatusIdForWarningModal(undefined);
                } })), isAddNewObjConfirmationModalVisible && (_jsx(AddNewStandardObjConfirmationModal, { schemaName: schema.name, collectionId: (_a = schema.refCollectionId) !== null && _a !== void 0 ? _a : '', onClose: function () {
                    var _a;
                    setIsAddNewObjConfirmationModalVisible(false);
                    (_a = selectRef.current) === null || _a === void 0 ? void 0 : _a.focus();
                }, onAddNewObj: addNewStandardObjOption })), _jsx("div", { className: "fpOverlay", onClick: function () {
                    handleDropDownVisibleChange(false);
                }, style: { display: 'block' } })] }));
};
export default React.memo(LogicSelectionCell);
