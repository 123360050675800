import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Switch } from 'antd';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ENABLE_DEFAULT_REQUIRE_FIELDS, ENABLE_HIDDEN_FIELDS, } from 'src/components/FeatureGatingKeyConstant';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
var DefaultsConfiguration = function (_a) {
    var _b, _c;
    var defaultValues = _a.defaultValues, canSetRequired = _a.canSetRequired, changeSchemaDefaultConfig = _a.changeSchemaDefaultConfig;
    var shouldEnableRequiredFields = useRecoilValue(featureEnableStatusState(ENABLE_DEFAULT_REQUIRE_FIELDS));
    var shouldEnableHiddenFields = useRecoilValue(featureEnableStatusState(ENABLE_HIDDEN_FIELDS));
    var _d = useState((_b = defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.required) !== null && _b !== void 0 ? _b : false), isRequired = _d[0], setIsRequired = _d[1];
    var _e = useState((_c = defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.hidden) !== null && _c !== void 0 ? _c : false), isHidden = _e[0], setIsHidden = _e[1];
    if (!shouldEnableRequiredFields && !shouldEnableHiddenFields) {
        return null;
    }
    return (_jsxs("div", { className: "mb-4", children: [_jsx("div", { className: "mb-1 text-xs font-medium opacity-[0.45]", children: "Configure" }), shouldEnableRequiredFields && canSetRequired && (_jsxs("div", { className: "flex justify-between items-center", children: [_jsx("div", { className: "font-medium", children: "Mark Required" }), _jsx(Switch, { className: "mt-0", checked: isRequired, 
                        // If the field is hidden, it can't be required
                        disabled: isHidden, onChange: function (checked) {
                            setIsRequired(checked);
                            changeSchemaDefaultConfig({
                                required: checked,
                            });
                            // If required is checked, it can't be hidden
                            if (checked) {
                                setIsHidden(false);
                                changeSchemaDefaultConfig({
                                    hidden: false,
                                });
                            }
                        } })] })), shouldEnableHiddenFields && (_jsxs("div", { className: "flex justify-between items-center", style: { marginTop: '8px' }, children: [_jsx("div", { className: "font-medium", children: "Mark Hidden" }), _jsx(Switch, { className: "mt-0", checked: isHidden, 
                        // If the field is required, it can't be hidden
                        disabled: isRequired, onChange: function (checked) {
                            setIsHidden(checked);
                            changeSchemaDefaultConfig({
                                hidden: checked,
                            });
                            // If hidden is checked, it can't be required
                            if (checked) {
                                setIsRequired(false);
                                changeSchemaDefaultConfig({
                                    required: false,
                                });
                            }
                        } })] }))] }));
};
export default React.memo(DefaultsConfiguration);
