import { jsxs as _jsxs, Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useRecoilValue } from 'recoil';
import { PayRuleAdjustmentField, PayRuleAdjustmentType, } from 'src/components/Settings/PayRate/interface';
import PaymentRulesPill from 'src/components/Settings/PayRate/PaymentRulesTab/component/PaymentRulesTable/component/PaymentRulesTableCell/component/PaymentRulesPill';
import { startCase, toLower } from 'lodash';
import PaymentRulesTag from 'src/components/Settings/PayRate/PaymentRulesTab/component/PaymentRulesTable/component/PaymentRulesTableCell/component/PaymentRulesTag';
import { useMemo } from 'react';
import { availableShiftPaySchemasState } from 'src/components/Settings/PayRate/atoms';
import { getAccountCurrencyCode } from 'src/utils/Account';
import { parseExpressionToOnlyDisplaySchemaNames } from 'src/components/Settings/PayRate/util';
var PaymentRulesAdjustmentPill = function (_a) {
    var adjustment = _a.adjustment;
    var availableShiftPaySchemas = useRecoilValue(availableShiftPaySchemasState);
    var accountCurrency = getAccountCurrencyCode();
    var isCurrency = adjustment.type === PayRuleAdjustmentType.SET_TO ||
        adjustment.type === PayRuleAdjustmentType.BY_AMOUNT ||
        adjustment.type === PayRuleAdjustmentType.PER_UNIT;
    var value = useMemo(function () {
        var _a;
        if (adjustment.formula) {
            // Prepend '=' to the expression to indicate it's a formula
            var formattedExpression = parseExpressionToOnlyDisplaySchemaNames(adjustment.formula.expression, adjustment.formula.variables.items, (_a = availableShiftPaySchemas === null || availableShiftPaySchemas === void 0 ? void 0 : availableShiftPaySchemas.formulaSchemas) !== null && _a !== void 0 ? _a : []);
            var expression = '=' + formattedExpression;
            return expression;
        }
        else {
            return Number(isCurrency ? adjustment.value : Number(adjustment.value) / 100);
        }
    }, [adjustment.formula, adjustment.value, availableShiftPaySchemas === null || availableShiftPaySchemas === void 0 ? void 0 : availableShiftPaySchemas.formulaSchemas, isCurrency]);
    var valueString = value.toLocaleString(undefined, {
        style: isCurrency ? 'currency' : 'percent',
        currency: accountCurrency,
        maximumFractionDigits: 2,
    });
    var color = isNaN(Number(value))
        ? '#E5F4EF'
        : !isNaN(Number(value)) && Number(value) >= 0
            ? '#E5F4EF'
            : '#FFEDEC';
    var symbol = adjustment.type !== PayRuleAdjustmentType.SET_TO &&
        adjustment.type !== PayRuleAdjustmentType.PER_UNIT &&
        !isNaN(Number(value)) &&
        Number(value) >= 0 &&
        '+';
    var adjustmentFieldName = useMemo(function () {
        var _a, _b;
        if (adjustment.field === PayRuleAdjustmentField.CUSTOM) {
            var schema = (_a = availableShiftPaySchemas === null || availableShiftPaySchemas === void 0 ? void 0 : availableShiftPaySchemas.currencySchemas) === null || _a === void 0 ? void 0 : _a.find(function (schema) { return schema.id === adjustment.customSchemaId; });
            return (_b = schema === null || schema === void 0 ? void 0 : schema.name) !== null && _b !== void 0 ? _b : 'NA';
        }
        else {
            return startCase(toLower(adjustment.field.toString()));
        }
    }, [adjustment.customSchemaId, adjustment.field, availableShiftPaySchemas === null || availableShiftPaySchemas === void 0 ? void 0 : availableShiftPaySchemas.currencySchemas]);
    var unitSchemaName = useMemo(function () {
        var _a;
        var unitSchemas = availableShiftPaySchemas === null || availableShiftPaySchemas === void 0 ? void 0 : availableShiftPaySchemas.unitSchemas;
        var unitSchema = unitSchemas === null || unitSchemas === void 0 ? void 0 : unitSchemas.find(function (schema) { return schema.id === adjustment.unitSchemaId; });
        return (_a = unitSchema === null || unitSchema === void 0 ? void 0 : unitSchema.name) !== null && _a !== void 0 ? _a : '';
    }, [adjustment.unitSchemaId, availableShiftPaySchemas === null || availableShiftPaySchemas === void 0 ? void 0 : availableShiftPaySchemas.unitSchemas]);
    var renderContent = useMemo(function () {
        if (adjustment.type === PayRuleAdjustmentType.PER_UNIT) {
            return (_jsxs(_Fragment, { children: ["Per Unit", _jsxs(PaymentRulesTag, { color: color, children: [valueString, symbol] }), "Per ", unitSchemaName] }));
        }
        else {
            return (_jsxs(PaymentRulesTag, { color: color, children: [symbol, valueString] }));
        }
    }, [adjustment.type, color, symbol, unitSchemaName, valueString]);
    return (_jsxs(PaymentRulesPill, { children: [_jsx("span", { className: "nowrap", children: adjustmentFieldName }), renderContent] }));
};
export default PaymentRulesAdjustmentPill;
