var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useQuery } from 'react-query';
import { useFetch } from './useFetch';
var useCommentApi = function () {
    var customFetch = useFetch().request;
    var useGetRecordComments = function (request, options) {
        return useQuery(['getRecordComments', request.recordId, request.pagination], function () {
            return customFetch({
                method: 'GET',
                url: "comment/record/".concat(request.recordId, "?size=").concat(request.pagination.size, "&page=").concat(request.pagination.page),
            });
        }, __assign({}, options));
    };
    var deleteRecordComment = function (id) {
        return customFetch({
            method: 'DELETE',
            url: "comment/".concat(id),
        });
    };
    var useGetAccountComments = function (request, options) {
        return useQuery(['getAccountComments', request.pagination.page, request.pagination.size], function () {
            return customFetch({
                method: 'GET',
                url: "comment/account?size=".concat(request.pagination.size, "&page=").concat(request.pagination.page),
            });
        }, __assign({}, options));
    };
    var createCommentRequest = function (request) {
        return customFetch({
            method: 'POST',
            url: 'comment',
        }, request);
    };
    return {
        useGetRecordComments: useGetRecordComments,
        useGetAccountComments: useGetAccountComments,
        createCommentRequest: createCommentRequest,
        deleteRecordComment: deleteRecordComment,
    };
};
export { useCommentApi };
