import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from 'react';
import { Button } from 'antd';
import './Generative.scss';
import { TriangleRightOutlinedIcon } from 'src/utils/icons/DropdownIcons/TriangleRightOutlined';
import { DownOutlined, LoadingOutlined, UpOutlined } from '@ant-design/icons';
import { useQuery } from 'react-query';
import { CollectionTableType, SchemaConfigurationType, ValueDataType } from 'src/shared';
import { useFetch } from 'src/hooks/api';
import { useRecoilValue } from 'recoil';
import { accountIdSelector, collectionIdToBlockIdsState, collectionTableCellDataBulkSelector, collectionTableSchemasState, tableCollectionRowsState, } from 'src/state';
import { useTimeZone } from 'src/hooks/component';
import { GenerativeConfigurationContext } from 'src/components/CollectionComponents/CreateSchemaModal/configuration/Context';
import { isEmptyCellData } from '../../Cell/util';
import { getDisplayValue } from './util';
var GenerativeTestWrapper = function (_a) {
    var collectionId = _a.collectionId, selectOptions = _a.selectOptions;
    var context = useContext(GenerativeConfigurationContext);
    if (!context)
        throw new Error('context not provided');
    var value = context.value;
    var generativeType = useMemo(function () { return value.type; }, [value]);
    var accountId = useRecoilValue(accountIdSelector);
    var collectionSchemas = useRecoilValue(collectionTableSchemasState(collectionId));
    var collectionBlocks = useRecoilValue(collectionIdToBlockIdsState(collectionId));
    var collectionRows = useRecoilValue(tableCollectionRowsState(collectionBlocks[0]));
    var cells = useMemo(function () {
        if (!collectionRows.length)
            return [];
        var recordId = collectionRows[0].recordId;
        return collectionSchemas.map(function (schema) {
            return {
                collectionId: collectionId,
                schemaId: schema.id,
                recordId: recordId,
            };
        });
    }, [collectionRows, collectionSchemas, collectionId]);
    var collectionCells = useRecoilValue(collectionTableCellDataBulkSelector({
        dataSource: {
            type: CollectionTableType.TABLE,
            collectionId: collectionId,
        },
        cells: cells,
    }));
    var timeFormatFn = useTimeZone().timeFormatFn;
    var contextSchemaValues = useMemo(function () {
        var schemaIdToIndex = {};
        var schemaValues = {};
        collectionSchemas.forEach(function (schema, i) {
            schemaIdToIndex[schema.id] = i;
        });
        var schemaIds = value.contextSchemaIds;
        schemaIds === null || schemaIds === void 0 ? void 0 : schemaIds.forEach(function (id) {
            var index = schemaIdToIndex[id];
            var cellData = collectionCells[index];
            var displayValue = cellData && !isEmptyCellData(cellData)
                ? getDisplayValue(cellData, timeFormatFn)
                : '';
            schemaValues[id] = displayValue;
        });
        return schemaValues;
    }, [value, collectionCells, collectionSchemas, timeFormatFn]);
    var contextSchemaNames = useMemo(function () {
        var schemaIdToName = {};
        collectionSchemas.forEach(function (schema) { return (schemaIdToName[schema.id] = schema.name); });
        return schemaIdToName;
    }, [collectionSchemas]);
    var _b = useState(''), testResponse = _b[0], setTestResponse = _b[1];
    var _c = useState(''), testJustification = _c[0], setTestJustification = _c[1];
    var customFetch = useFetch().request;
    var _d = useQuery([
        'useTestTextPromptQuery',
        { schemaType: ValueDataType, contextSchemaValues: contextSchemaValues, prompt: prompt, accountId: accountId },
    ], function () {
        return customFetch({
            method: 'POST',
            url: "generative/testSchemaPrompt",
        }, {
            schemaType: ValueDataType.TEXT,
            contextSchemaValues: contextSchemaValues,
            prompt: value.prompt,
            accountId: accountId,
        });
    }, {
        enabled: false,
        onSuccess: function (data) {
            if (data) {
                var response = data.response, justification = data.justification;
                setTestResponse(response);
                setTestJustification(justification);
            }
        },
    }), refetchText = _d.refetch, isFetchingText = _d.isFetching;
    var _e = useQuery([
        'useTestSelectPromptQuery',
        {
            schemaType: ValueDataType,
            contextSchemaValues: contextSchemaValues,
            prompt: prompt,
            accountId: accountId,
            selectOptions: selectOptions,
        },
    ], function () {
        return customFetch({
            method: 'POST',
            url: "generative/testSelectSchemaPrompt",
        }, {
            schemaType: ValueDataType.TEXT,
            contextSchemaValues: contextSchemaValues,
            prompt: value.selectPrompt,
            accountId: accountId,
            options: selectOptions || [],
            isMulti: value.isMulti || false,
        });
    }, {
        enabled: false,
        onSuccess: function (data) {
            if (data) {
                var response_1 = data.response, justification = data.justification;
                if (value.isMulti) {
                    var responseOptions = response_1.map(function (optionId) {
                        return selectOptions === null || selectOptions === void 0 ? void 0 : selectOptions.find(function (option) { return option.id == optionId; }).name;
                    });
                    setTestResponse(responseOptions.join(', '));
                }
                else {
                    var responseOption = selectOptions === null || selectOptions === void 0 ? void 0 : selectOptions.find(function (option) { return option.id == response_1; }).name;
                    setTestResponse(responseOption);
                }
                setTestJustification(justification);
            }
        },
    }), refetchSelect = _e.refetch, isFetchingSelect = _e.isFetching;
    var isFetching = useMemo(function () { return isFetchingText || isFetchingSelect; }, [isFetchingText, isFetchingSelect]);
    var onTestClick = useCallback(function () {
        setIsExpanded(true);
        if (generativeType == SchemaConfigurationType.GENERATIVE_TEXT) {
            refetchText();
        }
        else if (generativeType == SchemaConfigurationType.GENERATIVE_SELECT) {
            refetchSelect();
        }
    }, [refetchText, refetchSelect, generativeType]);
    var _f = useState(false), isExpanded = _f[0], setIsExpanded = _f[1];
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "border border-gray-100 rounded-lg p-4", children: [_jsxs("div", { className: "flex justify-between", children: [_jsx(Button, { type: "primary", className: "TestButton", onClick: onTestClick, children: _jsxs("div", { className: "flex items-center", children: [_jsx(TriangleRightOutlinedIcon, { className: "mr-[6px]" }), "Run Test"] }) }), _jsx("button", { onClick: function () { return setIsExpanded(!isExpanded); }, children: isExpanded ? _jsx(UpOutlined, {}) : _jsx(DownOutlined, {}) })] }), isExpanded && (_jsxs(_Fragment, { children: [_jsx("div", { className: "font-medium text-gray-400 text-xs mt-4", children: "Response" }), _jsx("div", { className: "pt-2 font-medium", children: isFetching ? _jsx(LoadingOutlined, {}) : testResponse }), _jsx("div", { className: "font-medium text-gray-400 text-xs mt-4", children: "Rationale" }), _jsx("div", { className: "pt-2 font-medium", children: isFetching ? _jsx(LoadingOutlined, {}) : testJustification }), Object.entries(contextSchemaValues).map(function (_a) {
                            var schemaId = _a[0], value = _a[1];
                            return (_jsxs("div", { children: [_jsx("div", { className: "font-medium text-gray-400 text-xs mt-4", children: contextSchemaNames[schemaId] }), _jsx("div", { className: "rounded-lg bg-gray-50 px-3 py-2 mt-2", children: value })] }, schemaId));
                        })] }))] }) }));
};
export default GenerativeTestWrapper;
