import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import SVG from 'react-inlinesvg';
import { BULK_DOWNLOAD_ICON } from '../../../Constants';
import BulkDownloadFilesActionModal from './BulkDownloadFilesActionModal';
var BulkDownloadFilesButton = function (_a) {
    var collectionId = _a.collectionId, selectedRecordIds = _a.selectedRecordIds;
    var _b = useState(false), openModal = _b[0], setOpenModal = _b[1];
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "BulkEditButtonOption space-x-[6px]", onClick: function () { return setOpenModal(true); }, children: [_jsx(SVG, { src: BULK_DOWNLOAD_ICON, color: "black" }), _jsx("div", { children: "Download" })] }), openModal && (_jsx(BulkDownloadFilesActionModal, { selectedRecordIds: selectedRecordIds, onModalClose: function () { return setOpenModal(false); }, isModalVisible: openModal, collectionId: collectionId }))] }));
};
export default React.memo(BulkDownloadFilesButton);
