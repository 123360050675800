var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useRef, useState } from 'react';
import './CommentFeed.scss';
import { InView } from 'react-intersection-observer';
import { useCommentApi } from 'src/hooks/api/useCommentApi';
import { DEFAULT_COMMENT_PAGE_SIZE } from 'src/shared';
import { usePaginationWithSearch } from 'src/hooks/component';
import Comment from './Comment';
import CommentFeedEmptyState from './CommentFeedEmptyState';
var AccountCommentFeed = function () {
    var useGetAccountComments = useCommentApi().useGetAccountComments;
    var scrollContainerRef = useRef(null);
    var _a = useState([]), accountComments = _a[0], setAccountComments = _a[1];
    var _b = usePaginationWithSearch({}), page = _b.page, setCanLoadMore = _b.setCanLoadMore, onScroll = _b.onScroll, setIsLoading = _b.setIsLoading;
    useGetAccountComments({
        pagination: {
            page: page,
            size: DEFAULT_COMMENT_PAGE_SIZE,
        },
    }, {
        onSuccess: function (data) {
            if (data && page === 0) {
                setAccountComments(data.commentFullDetailsView);
            }
            else if (data && page > 0) {
                setAccountComments(function (prev) { return __spreadArray(__spreadArray([], prev, true), data.commentFullDetailsView, true); });
                if (data.commentFullDetailsView.length === 0) {
                    setCanLoadMore(false);
                }
            }
        },
        onSettled: function () {
            setIsLoading(false);
        },
    });
    if ((accountComments === null || accountComments === void 0 ? void 0 : accountComments.length) === 0) {
        return (_jsx("div", { className: "commentsContainer h-full", children: _jsx(CommentFeedEmptyState, {}) }));
    }
    return (_jsx("div", { className: "commentsContainer", children: _jsx("div", { ref: scrollContainerRef, className: "accountFeedScrollable", onScroll: onScroll, children: accountComments === null || accountComments === void 0 ? void 0 : accountComments.map(function (comment, index) {
                return (_jsx(InView, { root: scrollContainerRef.current || undefined, rootMargin: '200px 0px', triggerOnce: true, children: function (_a) {
                        var inView = _a.inView, ref = _a.ref;
                        return (_jsx("div", { ref: ref, children: inView && (_jsx(Comment, { comment: comment, isLastComment: index === accountComments.length - 1 }, index)) }));
                    } }, index));
            }) }) }));
};
export default React.memo(AccountCommentFeed);
