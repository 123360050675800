import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider } from 'antd';
import React, { useContext, useEffect } from 'react';
import { OrganizationContext } from 'src/App';
import { useSetRecoilState } from 'recoil';
import { isSelfOnboardingOnState } from 'src/state/atoms/accountOnboardingState';
import { TEAMBRIDGE_TITLE } from 'src/shared';
import LoginButton from './LoginButton';
import SignupButton from './SignupButton';
import './AuthPage.scss';
var SsoLoginPage = function () {
    var setIsSelfOnboardingOn = useSetRecoilState(isSelfOnboardingOnState);
    var org = useContext(OrganizationContext);
    useEffect(function () {
        setIsSelfOnboardingOn(false);
    }, [setIsSelfOnboardingOn]);
    return (_jsx("div", { className: "AuthPageContainer", children: _jsxs("div", { children: [_jsx("div", { className: "AuthPageTitle", children: org ? 'Here for the first time?' : "Welcome to ".concat(TEAMBRIDGE_TITLE) }), _jsxs("div", { children: [!org && _jsx("div", { className: "AuthPageSignupPrompt", children: "Here for the first time?" }), _jsx(SignupButton, { className: "AuthPageSignupButton", text: "Register My Account" }), _jsx("div", { className: "AuthPageSignupHint", children: "Please use the email added to the account where you received your invitation" })] }), _jsx(Divider, {}), _jsxs("div", { children: [_jsx("div", { className: "AuthPageLoginPrompt", children: "Already have an account and password?" }), _jsx(LoginButton, { className: "AuthPageLoginButton" })] })] }) }));
};
export default React.memo(SsoLoginPage);
