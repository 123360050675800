import moment from 'moment-timezone';
import { useRecoilValue } from 'recoil';
import { accountTimeZoneSelector } from 'src/state/selectors/withAccountDetailState';
// check
export var isTimeZone = function (timeZone) {
    return moment.tz.zone(timeZone) !== null;
};
export var useTimeZone = function (defaultTimeZone) {
    // default timezone -> get account
    var accountTimeZone = useRecoilValue(accountTimeZoneSelector);
    var initTimeZone = function () {
        return defaultTimeZone !== null && defaultTimeZone !== void 0 ? defaultTimeZone : accountTimeZone;
    };
    var timeZone = initTimeZone();
    var timezoneAbbr = function (forceToShow) {
        var shouldShow = forceToShow === true;
        var deviceTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        var tzAbbr = moment().tz(timeZone).zoneAbbr();
        return shouldShow ? tzAbbr : deviceTimezone === timeZone ? '' : tzAbbr;
    };
    // Simpler version of timezoneAbbr
    var abbreviateTimezone = function () {
        return moment().tz(timeZone).zoneAbbr();
    };
    var timeFormatFn = function (time, format, showTimeZoneAbbr, toISO) {
        var isToISO = toISO === true;
        var needToShowTimeZone = (showTimeZoneAbbr !== null && showTimeZoneAbbr !== void 0 ? showTimeZoneAbbr : true) && !isToISO;
        var tzAbbr = needToShowTimeZone ? " ".concat(timezoneAbbr()) : '';
        return time
            ? isToISO
                ? format
                    ? moment.tz(time, format, timeZone).toISOString()
                    : moment.tz(time, timeZone).toISOString()
                : "".concat(moment.tz(time, timeZone).format(format)).concat(tzAbbr)
            : '';
    };
    var momentFormatFn = function (time, format, toISO) {
        var isToISO = toISO === true;
        return isToISO
            ? time.tz(timeZone).toISOString()
            : format
                ? time.tz(timeZone).format(format)
                : time.tz(timeZone).format();
    };
    return {
        timeZone: timeZone,
        timeFormatFn: timeFormatFn,
        momentFormatFn: momentFormatFn,
        timezoneAbbr: timezoneAbbr,
        abbreviateTimezone: abbreviateTimezone,
    };
};
