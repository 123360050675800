var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useRecordComponent } from 'src/hooks/component/useRecord';
import { cloneDeep } from 'lodash';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { CollectionType, NativePath, NonSchemaNativePath, } from 'src/shared';
import { accountIdSelector, collectionTableModalRecordIdSelector, collectionTableState, } from 'src/state';
import { collectionTableSchemaMutationsState, collectionTableNonSchemaMutationsState, } from 'src/state';
import { useCallback } from 'react';
import { visibleSchemaColumnsInDetailModalSiderState } from 'src/components/RecordDetailModal/RecordDetailModalHeader/Schemas/atom';
import moment from 'moment';
import { isEditRecordForTimeOffApproval } from 'src/utils/Collection';
import { usePageApi } from '../api';
import { useRefetchCollection } from './useRefetchCollection';
import { usePage } from './usePage';
import { usePayComponent } from './usePay';
var useModalRecord = function () {
    var accountId = useRecoilValue(accountIdSelector);
    var _a = usePageApi(), createCollectionRecords = _a.createCollectionRecords, createCollectionRecordV3 = _a.createCollectionRecordV3;
    var openUnassignShiftsModal = usePage().openUnassignShiftsModal;
    var refetchCollection = useRefetchCollection();
    var refetchPayViewDataForShiftAndTimeoffRecordMutation = usePayComponent().refetchPayViewDataForShiftAndTimeoffRecordMutation;
    var editRecordCell = useRecordComponent().editRecordCell;
    /** Calls `editRecordCell` in a loop */
    var bulkEditModalCell = useCallback(function (collectionTableType, cellPositions, newValues, timeZone) {
        if (cellPositions.length !== newValues.length)
            return;
        for (var i = 0; i < cellPositions.length; i++) {
            editRecordCell(collectionTableType, cellPositions[i], newValues[i], timeZone);
        }
    }, [editRecordCell]);
    /**
     * Creates new record from detail/quick-create modal.
     * If there are logic updates, make sure to update `createNewRecordV3` as well.
     */
    var createNewRecord = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot, reset = _a.reset;
        return function (collectionDataSource, collectionType, referenceRecordId, proposedRecordId) { return __awaiter(void 0, void 0, void 0, function () {
            var release, recordId, schemasToMutate, nonSchemasToMutate, nonSchemaProperties, collectionId, body, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        release = snapshot.retain();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        recordId = proposedRecordId !== null && proposedRecordId !== void 0 ? proposedRecordId : snapshot
                            .getLoadable(collectionTableModalRecordIdSelector(collectionDataSource))
                            .valueMaybe();
                        schemasToMutate = snapshot
                            .getLoadable(collectionTableSchemaMutationsState(collectionDataSource))
                            .valueMaybe();
                        nonSchemasToMutate = snapshot
                            .getLoadable(collectionTableNonSchemaMutationsState(collectionDataSource))
                            .valueMaybe();
                        if (!recordId || !schemasToMutate || !nonSchemasToMutate) {
                            return [2 /*return*/];
                        }
                        nonSchemaProperties = cloneDeep(nonSchemasToMutate);
                        if (collectionType === CollectionType.BREAK && referenceRecordId) {
                            nonSchemaProperties.push({
                                schema: NonSchemaNativePath.BREAK_SHIFT_RID,
                                uuid: referenceRecordId,
                            });
                        }
                        collectionId = collectionDataSource.collectionId;
                        body = {
                            recordId: recordId,
                            accountId: accountId,
                            collectionType: collectionType,
                            collectionId: collectionId,
                            schemaProperties: schemasToMutate,
                            nonSchemaProperties: nonSchemaProperties,
                        };
                        return [4 /*yield*/, createCollectionRecords(body)];
                    case 2:
                        resp = _a.sent();
                        if (collectionType === CollectionType.TIMEOFF &&
                            isEditRecordForTimeOffApproval(schemasToMutate)) {
                            openUnassignShiftsModal(recordId);
                        }
                        if (resp) {
                            // Refetch collection blocks
                            refetchCollection(collectionId, 'create');
                            // Refetch pay data when add shift/timeoff record under pay
                            refetchPayViewDataForShiftAndTimeoffRecordMutation(collectionType);
                            // Reset state
                            reset(collectionTableState(collectionDataSource));
                            reset(collectionTableSchemaMutationsState(collectionDataSource));
                            reset(collectionTableNonSchemaMutationsState(collectionDataSource));
                        }
                        return [2 /*return*/, body.recordId];
                    case 3:
                        release();
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId]);
    /**
     * Creates new record and returns data about the new record
     * If there are logic updates, make sure to update `createNewRecord` as well.
     */
    var createNewRecordV3 = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot, reset = _a.reset;
        return function (collectionDataSource, collectionType, referenceRecordId, proposedRecordId) { return __awaiter(void 0, void 0, void 0, function () {
            var release, recordId, schemasToMutate, nonSchemasToMutate, nonSchemaProperties, collectionId, body, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        release = snapshot.retain();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        recordId = proposedRecordId !== null && proposedRecordId !== void 0 ? proposedRecordId : snapshot
                            .getLoadable(collectionTableModalRecordIdSelector(collectionDataSource))
                            .valueMaybe();
                        schemasToMutate = snapshot
                            .getLoadable(collectionTableSchemaMutationsState(collectionDataSource))
                            .valueMaybe();
                        nonSchemasToMutate = snapshot
                            .getLoadable(collectionTableNonSchemaMutationsState(collectionDataSource))
                            .valueMaybe();
                        if (!recordId || !schemasToMutate || !nonSchemasToMutate) {
                            return [2 /*return*/];
                        }
                        nonSchemaProperties = cloneDeep(nonSchemasToMutate);
                        if (collectionType === CollectionType.BREAK && referenceRecordId) {
                            nonSchemaProperties.push({
                                schema: NonSchemaNativePath.BREAK_SHIFT_RID,
                                uuid: referenceRecordId,
                            });
                        }
                        collectionId = collectionDataSource.collectionId;
                        body = {
                            recordId: recordId,
                            accountId: accountId,
                            collectionType: collectionType,
                            collectionId: collectionId,
                            schemaProperties: schemasToMutate,
                            nonSchemaProperties: nonSchemaProperties,
                        };
                        return [4 /*yield*/, createCollectionRecordV3(body)];
                    case 2:
                        resp = _a.sent();
                        if (collectionType === CollectionType.TIMEOFF &&
                            isEditRecordForTimeOffApproval(schemasToMutate)) {
                            openUnassignShiftsModal(recordId);
                        }
                        if (resp) {
                            // Refetch collection blocks
                            refetchCollection(collectionId, 'create');
                            // Refetch pay data when add shift/timeoff record under pay
                            refetchPayViewDataForShiftAndTimeoffRecordMutation(collectionType);
                            // Reset state
                            reset(collectionTableState(collectionDataSource));
                            reset(collectionTableSchemaMutationsState(collectionDataSource));
                            reset(collectionTableNonSchemaMutationsState(collectionDataSource));
                        }
                        return [2 /*return*/, resp];
                    case 3:
                        release();
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId]);
    /** Edits scheduled start & scheduled end time for bespoke scheduled time picker in shift detail/quick-create modal */
    var editScheduledShiftTime = useCallback(function (collectionTableType, schemas, collectionId, recordId, timeZone, start, end, canEditStartAt, canEditEndAt) {
        var _a, _b;
        var startAtSchemaId = (_a = schemas.find(function (schema) { return schema.nativePath === NativePath.SHIFT_START_AT; })) === null || _a === void 0 ? void 0 : _a.id;
        var endAtSchemaId = (_b = schemas.find(function (schema) { return schema.nativePath === NativePath.SHIFT_END_AT; })) === null || _b === void 0 ? void 0 : _b.id;
        if (startAtSchemaId && !!canEditStartAt) {
            if (start != undefined) {
                var startAt = start
                    ? moment.tz(start, 'MMM D, YYYY h:mm A', timeZone).toISOString()
                    : '';
                editRecordCell(collectionTableType, { collectionId: collectionId, recordId: recordId, schemaId: startAtSchemaId }, [startAt], timeZone);
            }
        }
        if (endAtSchemaId && !!canEditEndAt) {
            if (end != undefined) {
                var endAt = end
                    ? moment.tz(end, 'MMM D, YYYY h:mm A', timeZone).toISOString()
                    : '';
                editRecordCell(collectionTableType, { collectionId: collectionId, recordId: recordId, schemaId: endAtSchemaId }, [endAt], timeZone);
            }
        }
    }, [editRecordCell]);
    /**  Edits clock in and clock out time for bespoke clock in/out time picker in shift detail/quick-create modal */
    var editClockInOutShiftTime = useCallback(function (collectionTableType, schemas, collectionId, recordId, timeZone, clockIn, clockOut, canEditClockedIn, canEditClockedOut) {
        var _a, _b;
        var clockInSchemaId = (_a = schemas.find(function (schema) { return schema.nativePath === NativePath.SHIFT_CLOCK_IN; })) === null || _a === void 0 ? void 0 : _a.id;
        var clockOutSchemaId = (_b = schemas.find(function (schema) { return schema.nativePath === NativePath.SHIFT_CLOCK_OUT; })) === null || _b === void 0 ? void 0 : _b.id;
        if (clockInSchemaId && !!canEditClockedIn) {
            if (clockIn != undefined) {
                var clockInTime = clockIn
                    ? moment.tz(clockIn, 'MMM D, YYYY h:mm A', timeZone).toISOString()
                    : '';
                editRecordCell(collectionTableType, { collectionId: collectionId, recordId: recordId, schemaId: clockInSchemaId }, [clockInTime], timeZone);
            }
        }
        if (clockOutSchemaId && !!canEditClockedOut) {
            if (clockOut != undefined) {
                var clockOutTime = clockOut
                    ? moment.tz(clockOut, 'MMM D, YYYY h:mm A', timeZone).toISOString()
                    : '';
                editRecordCell(collectionTableType, { collectionId: collectionId, recordId: recordId, schemaId: clockOutSchemaId }, [clockOutTime], timeZone);
            }
        }
    }, [editRecordCell]);
    var detailModalSiderSchemasVisibleOnChange = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot, set = _a.set;
        return function (collectionId, schemaId, visible) {
            var visibleSchemas = snapshot
                .getLoadable(visibleSchemaColumnsInDetailModalSiderState(collectionId))
                .valueMaybe();
            if (!visibleSchemas)
                return;
            var newVisibleSchemas = cloneDeep(visibleSchemas);
            if (visible) {
                set(visibleSchemaColumnsInDetailModalSiderState(collectionId), newVisibleSchemas.concat(schemaId));
            }
            else {
                var schemaIndex = newVisibleSchemas.indexOf(schemaId);
                if (schemaIndex !== -1) {
                    newVisibleSchemas.splice(schemaIndex, 1);
                }
                set(visibleSchemaColumnsInDetailModalSiderState(collectionId), newVisibleSchemas);
            }
        };
    }, []);
    return {
        bulkEditModalCell: bulkEditModalCell,
        createNewRecord: createNewRecord,
        createNewRecordV3: createNewRecordV3,
        editScheduledShiftTime: editScheduledShiftTime,
        editClockInOutShiftTime: editClockInOutShiftTime,
        detailModalSiderSchemasVisibleOnChange: detailModalSiderSchemasVisibleOnChange,
    };
};
export { useModalRecord as useModalRecordComponent };
