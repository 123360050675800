import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Form, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { requiredRules } from 'src/components/ImportCsvModal/Constant';
import { usePageApi } from 'src/hooks/api';
import { formatSnakeCase, trimNativeType } from '../../utils';
var Option = Select.Option;
var lookUpTypeOptions = ['Name', 'Other schema'];
var SchemaMappingSelector = function (_a) {
    var ipSchema = _a.ipSchema, teambridgeSchemas = _a.teambridgeSchemas, isSaving = _a.isSaving;
    var useGetCollectionSchemas = usePageApi().useGetCollectionSchemas;
    var _b = useState(), refCollectionId = _b[0], setRefCollectionId = _b[1];
    var _c = useState(), lookUpType = _c[0], setLookUpType = _c[1];
    var _d = useGetCollectionSchemas({
        body: { collectionId: refCollectionId },
        options: { enabled: !!refCollectionId && lookUpType === 'Other schema' },
    }), lookUpSchemaOptions = _d.data, isFetchingLookUpOptions = _d.isLoading;
    var lookUpTypeOnChange = useCallback(function (value) {
        setLookUpType(value);
    }, []);
    useEffect(function () {
        var schema = teambridgeSchemas.find(function (schema) { return schema.id === ipSchema.ziraSchema; });
        if (schema === null || schema === void 0 ? void 0 : schema.refCollectionId) {
            setRefCollectionId(schema.refCollectionId);
            // If there's a ref collection but no lookupSchemaId, it means the BE defaulted to or WILL default to the name schema
            setLookUpType(ipSchema.lookupSchemaId ? 'Other schema' : 'Name');
        }
    }, [teambridgeSchemas, ipSchema]);
    return (_jsxs("div", { className: "grid grid-cols-2 gap-4 place-items-start", children: [_jsx(Select, { className: "w-full", style: { pointerEvents: 'none' }, showArrow: false, value: ipSchema.displayName, children: _jsxs(Select.Option, { value: ipSchema.displayName, label: ipSchema.displayName, children: [_jsx("span", { className: "font-medium", children: ipSchema.displayName }), _jsx("span", { className: "text-gray-300 ml-[10px] text-xs", children: ipSchema.type })] }, ipSchema.displayName) }), _jsxs("div", { className: "flex flex-col w-full", children: [_jsx(Form.Item, { name: [ipSchema.displayName, 'schemaId'], colon: false, initialValue: ipSchema === null || ipSchema === void 0 ? void 0 : ipSchema.ziraSchema, className: "w-full mb-6", children: _jsx(Select, { allowClear: true, disabled: isSaving, className: "w-full", onChange: function (schemaId) {
                                var selectedSchema = teambridgeSchemas.find(function (schema) { return schema.id === schemaId; });
                                if (selectedSchema === null || selectedSchema === void 0 ? void 0 : selectedSchema.refCollectionId) {
                                    setRefCollectionId(selectedSchema.refCollectionId);
                                }
                                else {
                                    setRefCollectionId(undefined);
                                }
                            }, showSearch: true, optionFilterProp: "label", getPopupContainer: function (triggerNode) { return triggerNode.parentNode; }, children: teambridgeSchemas.map(function (schema) { return (_jsxs(Select.Option, { value: schema.id, label: schema.name, children: [_jsx("span", { children: schema.name }), _jsx("span", { className: "text-gray-200 ml-[10px] text-xs", children: formatSnakeCase(trimNativeType(schema)) })] }, schema.id)); }) }) }, ipSchema.displayName), refCollectionId && (_jsxs("div", { className: "flex flex-col", children: [_jsx(Select, { allowClear: true, placeholder: 'Select schema type (optional)', disabled: isFetchingLookUpOptions, onChange: lookUpTypeOnChange, defaultValue: lookUpType, className: "w-full mb-6", getPopupContainer: function (triggerNode) { return triggerNode.parentNode; }, children: lookUpTypeOptions.map(function (option) {
                                    return (_jsx(Option, { value: option, children: option }, option));
                                }) }), lookUpType === 'Other schema' && lookUpSchemaOptions && (_jsx(Form.Item, { name: [ipSchema.displayName, 'lookupSchemaId'], rules: requiredRules, initialValue: ipSchema.lookupSchemaId, children: _jsx(Select, { allowClear: true, placeholder: "Select secondary schema", dropdownMatchSelectWidth: false, getPopupContainer: function (triggerNode) { return triggerNode.parentNode; }, children: lookUpSchemaOptions.map(function (option) {
                                        return (_jsxs(Select.Option, { value: option.id, children: [_jsx("span", { children: option.name }), _jsx("span", { className: "text-gray-200 ml-[10px] text-xs", children: formatSnakeCase(trimNativeType(option)) })] }, option.id));
                                    }) }) }))] }))] })] }));
};
export default React.memo(SchemaMappingSelector);
