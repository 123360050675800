import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { Space } from 'antd';
import _ from 'lodash';
import React from 'react';
import { brokenRulesTypeEvaluation } from 'src/services/ScheduleService';
import { useRecoilValue } from 'recoil';
import { autoSchedulerSettingState } from 'src/state';
import { RuleType } from './Constant';
import IndictmentPopover from './IndictmentPopover';
import { ruleTypeSortOrder } from './RulesDrawer';
var generateScheduleIndictments = function (indictmentNames, autoSchedulerSetting) {
    var _a, _b;
    var indictments = [];
    for (var _i = 0, indictmentNames_1 = indictmentNames; _i < indictmentNames_1.length; _i++) {
        var name_1 = indictmentNames_1[_i];
        var rule = autoSchedulerSetting.rulesMap[name_1];
        if (rule) {
            var constraintType = (_a = rule.type) !== null && _a !== void 0 ? _a : RuleType.STRICT;
            var constraintName = (_b = rule.ruleDisplayName) !== null && _b !== void 0 ? _b : name_1;
            indictments.push({
                name: constraintName,
                type: constraintType,
            });
        }
    }
    return indictments.sort(function (a, b) { return ruleTypeSortOrder.indexOf(a.type) - ruleTypeSortOrder.indexOf(b.type); });
};
var generateRequirementsMetRules = function (autoSchedulerSetting, indictment) {
    var rules = [];
    _.entries(autoSchedulerSetting.rulesMap).map(function (_a) {
        var key = _a[0], value = _a[1];
        if (!indictment.includes(key) && value) {
            rules.push(value.ruleDisplayName);
        }
    });
    return rules;
};
var UserEvaluationPopover = function (_a) {
    var indictmentNames = _a.indictmentNames, showIndictmentCount = _a.showIndictmentCount, trigger = _a.trigger, children = _a.children;
    var autoSchedulerSetting = useRecoilValue(autoSchedulerSettingState);
    if (!indictmentNames || !autoSchedulerSetting) {
        return _jsx(_Fragment, {});
    }
    return (_jsx(Space, { style: { marginLeft: 'auto' }, children: indictmentNames.length ? (_jsx(IndictmentPopover, { showIndictmentCount: showIndictmentCount, requirementsMet: generateRequirementsMetRules(autoSchedulerSetting, indictmentNames), indictments: generateScheduleIndictments(indictmentNames, autoSchedulerSetting), trigger: trigger !== null && trigger !== void 0 ? trigger : 'hover', className: "IndictmentPopover", brokenRulesTypeEvaluation: brokenRulesTypeEvaluation(indictmentNames, autoSchedulerSetting), children: children })) : (_jsx(IndictmentPopover, { showIndictmentCount: showIndictmentCount, requirementsMet: generateRequirementsMetRules(autoSchedulerSetting, indictmentNames), indictments: [], trigger: trigger !== null && trigger !== void 0 ? trigger : 'hover', className: "IndictmentPopover", children: children })) }));
};
export default React.memo(UserEvaluationPopover);
