var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import { StatusType, } from 'src/components/CenterPanel/Interface';
import { collectionTableModalRecordSelector, collectionTableNonSchemaMutationsState, collectionTableSchemaNativePathMapSelector, shiftModalTimeZoneIdState, } from 'src/state';
import UserAvatar from 'src/components/User/UserAvatar';
import { NativePath, NonSchemaNativePath } from 'src/shared';
import { getNativeFieldCellContent, getNativeFieldCellOptions, getTagTextColor, } from 'src/utils/Collection';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { ShiftStatus, ShiftStatusDisplayNameMap, ShiftStatusTagColorMap, } from 'src/components/ScheduleView/Constant';
import { isArray, uniqBy } from 'lodash';
import { useTimeZone } from 'src/hooks/component';
import { getShiftStatus } from 'src/utils/Schedule';
import ShiftModalTemplateTagPopover from 'src/components/ScheduleView/NewShiftTemplate/ShiftModalTemplateTagPopover';
import TimeZoneSelector from 'src/components/TimeZoneSelector/TimeZoneSelector';
import RecordDetailModalTitle from '../RecordDetailModalTitle';
import ShiftFacePicContainer from './ShiftFacePicContainer';
import './ShiftDetailModalTitle.scss';
var ShiftDetailModalTitle = function (_a) {
    var dataSource = _a.dataSource, shiftTimeDetails = _a.shiftTimeDetails, isBulkEdit = _a.isBulkEdit, shiftFacePicData = _a.shiftFacePicData, recurringShiftData = _a.recurringShiftData, shiftTemplateData = _a.shiftTemplateData, onDetachExistingTemplate = _a.onDetachExistingTemplate, isCreatingNew = _a.isCreatingNew, recordId = _a.recordId, timeZoneId = _a.timeZoneId, canEditCollection = _a.canEditCollection;
    var collectionId = dataSource.collectionId;
    var modalRecord = useRecoilValue(collectionTableModalRecordSelector(dataSource));
    var nativeSchemaMap = useRecoilValue(collectionTableSchemaNativePathMapSelector(collectionId));
    var _b = useRecoilState(shiftModalTimeZoneIdState({
        type: dataSource.type,
        collectionId: collectionId,
        recordId: recordId,
    })), timeZoneIdState = _b[0], setTimeZoneIdState = _b[1];
    var _c = useTimeZone(isCreatingNew ? timeZoneId : timeZoneIdState), timeFormatFn = _c.timeFormatFn, abbreviateTimezone = _c.abbreviateTimezone;
    var timeZoneSuffix = abbreviateTimezone();
    var shiftTitle = useMemo(function () {
        var _a = shiftTimeDetails, startAt = _a.startAt, endAt = _a.endAt, clockInAt = _a.clockInAt, clockOutAt = _a.clockOutAt;
        var assignee = getNativeFieldCellOptions(modalRecord, nativeSchemaMap, NativePath.SHIFT_USER_ID);
        var lastUpdatedTime = (shiftTemplateData === null || shiftTemplateData === void 0 ? void 0 : shiftTemplateData.status.data)
            ? timeFormatFn(shiftTemplateData.status.data.updatedAt, 'MM/DD/YY', false)
            : undefined;
        var shiftTemplateSchema = nativeSchemaMap[NativePath.SHIFT_TEMPLATE_ID];
        var recordExistingTemplateInfo = getNativeFieldCellOptions(modalRecord, nativeSchemaMap, NativePath.SHIFT_TEMPLATE_ID)[0];
        var canEditTemplate = !shiftTemplateSchema
            ? false
            : shiftTemplateSchema.canEdit &&
                (!recordExistingTemplateInfo || !shiftTemplateSchema.canOnlyUpdateEmpty);
        return (_jsxs("div", { className: "flex flex-col gap-1", children: [shiftTemplateData && (_jsx(ShiftModalTemplateTagPopover, { template: shiftTemplateData, onDetachExistingTemplate: onDetachExistingTemplate, lastUpdatedTime: lastUpdatedTime, canEdit: canEditTemplate, children: _jsxs("div", { className: "ShiftTemplateInfoTag mb-1 cursor-pointer", style: {
                            backgroundColor: shiftTemplateData.color,
                            color: getTagTextColor(shiftTemplateData.color),
                        }, children: [shiftTemplateData.name, shiftTemplateData.status.status === StatusType.ARCHIVED &&
                                " (Archived)", shiftTemplateData.status.status === StatusType.ACTIVE &&
                                shiftTemplateData.status.data &&
                                " (Revised ".concat(lastUpdatedTime, ")")] }) })), _jsxs("div", { className: "mb-1 flex items-center space-x-1", children: [timeZoneSuffix && (_jsx("span", { className: "RecordStatusTag mb-1", style: {
                                backgroundColor: '#FFECC8',
                                color: getTagTextColor('#FFECC8'),
                            }, children: timeZoneSuffix })), recurringShiftData && (_jsx(ZiraTooltip, { title: "This is a recurring shift.", children: _jsx("span", { className: "RecordStatusTag mb-1 cursor-pointer", style: {
                                    backgroundColor: '#EAEDFC',
                                    color: getTagTextColor('#EAEDFC'),
                                }, children: "Recurring" }) }))] }), assignee[0] && (_jsxs("div", { className: "mb-1 flex items-center space-x-1", children: [_jsx(UserAvatar, { avatar: 'avatarUrl' in assignee[0] ? assignee[0].avatarUrl : undefined, name: assignee[0].name, size: 24, color: assignee[0].color }), _jsx("span", { className: "text-sm", children: assignee[0].name })] })), clockInAt || startAt ? (_jsx("span", { className: "font-light", children: timeFormatFn(clockInAt || startAt, 'ddd, MMM Do', false) })) : null, _jsx("span", { children: clockInAt || startAt
                        ? (clockInAt && clockOutAt
                            ? timeFormatFn(clockInAt, 'h:mma', false)
                            : startAt
                                ? timeFormatFn(startAt, 'h:mma', false)
                                : ' ') +
                            ' - ' +
                            (clockInAt && clockOutAt
                                ? timeFormatFn(clockOutAt, 'h:mma', false)
                                : endAt
                                    ? timeFormatFn(endAt, 'h:mma', false)
                                    : '')
                        : 'No Date' })] }));
    }, [
        shiftTimeDetails,
        modalRecord,
        nativeSchemaMap,
        timeZoneSuffix,
        recurringShiftData,
        shiftTemplateData,
        onDetachExistingTemplate,
        timeFormatFn,
    ]);
    var shiftTag = useMemo(function () {
        var _a;
        var findAssignee = getNativeFieldCellOptions(modalRecord, nativeSchemaMap, NativePath.SHIFT_USER_ID);
        var published = (_a = getNativeFieldCellContent(modalRecord, nativeSchemaMap, NativePath.SHIFT_PUBLISHED)) !== null && _a !== void 0 ? _a : getNativeFieldCellOptions(modalRecord, nativeSchemaMap, NativePath.SHIFT_PUBLISHED);
        var hasAssignee = findAssignee.length ? true : false;
        var status = (isArray(published)
            ? published[0]
                ? published[0].id === 'false'
                : true
            : published === 'false' || isBulkEdit)
            ? ShiftStatus.DRAFT
            : getShiftStatus(shiftTimeDetails.startAt, shiftTimeDetails.endAt, shiftTimeDetails.clockInAt, shiftTimeDetails.clockOutAt, hasAssignee);
        var backgroundColor = ShiftStatusTagColorMap[status];
        return (_jsx("span", { className: "RecordStatusTag", style: {
                backgroundColor: backgroundColor,
                color: getTagTextColor(backgroundColor),
            }, children: ShiftStatusDisplayNameMap[status] }));
    }, [isBulkEdit, modalRecord, nativeSchemaMap, shiftTimeDetails]);
    var renderFacePic = useCallback(function () {
        var clockInPic = shiftFacePicData === null || shiftFacePicData === void 0 ? void 0 : shiftFacePicData.clockIn;
        var clockOutPic = shiftFacePicData === null || shiftFacePicData === void 0 ? void 0 : shiftFacePicData.clockOut;
        return (_jsxs("div", { className: "flex pr-5", children: [clockInPic && _jsx(ShiftFacePicContainer, { picUrl: clockInPic, text: "clock in" }), clockOutPic && _jsx(ShiftFacePicContainer, { picUrl: clockOutPic, text: "clock out" })] }));
    }, [shiftFacePicData]);
    // Set timezone
    var onTimeZoneChange = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (timeZoneId) {
            setTimeZoneIdState(timeZoneId);
            set(collectionTableNonSchemaMutationsState(dataSource), function (prev) {
                return uniqBy(__spreadArray([
                    {
                        schema: NonSchemaNativePath.SHIFT_TIME_ZONE_ID,
                        zoneId: timeZoneId,
                    }
                ], prev, true), 'schema');
            });
        };
    }, [dataSource, setTimeZoneIdState]);
    var timeZonePicker = (_jsx("div", { className: "ShiftModalTimeZoneSelector", children: _jsx(TimeZoneSelector, { useValues: true, timezoneValue: timeZoneIdState || timeZoneId, timezoneOnChange: onTimeZoneChange, optionClassName: "text-xs", canEdit: canEditCollection }) }));
    return (_jsx(RecordDetailModalTitle, { title: shiftTitle, extras: _jsxs(_Fragment, { children: [shiftTag, !isCreatingNew && timeZonePicker, shiftFacePicData && renderFacePic()] }) }));
};
export default React.memo(ShiftDetailModalTitle);
