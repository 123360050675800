import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useRecoilValue } from 'recoil';
import { archiveOptionsPopupState, locationTimezoneUpdateState } from 'src/state';
import ArchiveOptionsPopup from './ArchiveOptionsPopup';
import LocationTimezoneUpdatePopup from './LocationTimezoneUpdatePopup';
var RecordUpdateSideEffectsContainer = function () {
    var isArchiveOptionsPopupVisible = useRecoilValue(archiveOptionsPopupState);
    var isLocationTimezoneUpdatePopupVisible = !!useRecoilValue(locationTimezoneUpdateState);
    if (isArchiveOptionsPopupVisible) {
        return _jsx(ArchiveOptionsPopup, {});
    }
    else if (isLocationTimezoneUpdatePopupVisible) {
        return _jsx(LocationTimezoneUpdatePopup, {});
    }
    return null;
};
export default React.memo(RecordUpdateSideEffectsContainer);
