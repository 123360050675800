import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { EllipsisOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { Flatfile } from '@flatfile/sdk';
import React, { Suspense, lazy, useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { usePage } from 'src/hooks/component';
import { useAccountApi, usePageApi } from 'src/hooks/api';
import { useReactToPrint } from 'react-to-print';
import { tableCollectionInfoState, tableSelectedViewIdState, blockTitleSelector, collectionTableSchemasState, } from 'src/state';
import { CellInputMode, CollectionType } from 'src/shared';
import { useRef } from 'react';
import { importTypeSet } from '../ImportCsvModal/Constant';
import ImportCsvModal from '../ImportCsvModal/ImportCsvModal';
import FlatFileCustomSchemaImportWarningModal from '../FlatFileCustomSchemaImportWarningModal/FlatFileCustomSchemaImportWarningModal';
var PrintOnlyBlockCollectionTable = lazy(function () { return import('./PrintView/PrintOnlyBlockCollectionTable'); });
var splitCustomFields = function (allFields) {
    var fieldsWithValidName = [];
    var fieldsWithInvalidName = [];
    allFields.forEach(function (field) {
        if (field.label.includes('#') || field.label.includes('&')) {
            fieldsWithInvalidName.push(field);
        }
        else {
            fieldsWithValidName.push(field);
        }
    });
    return [fieldsWithValidName, fieldsWithInvalidName];
};
var TableMoreOptionDropdown = function (_a) {
    var blockId = _a.blockId, canEditView = _a.canEditView, displaySettings = _a.displaySettings;
    var _b = useState(false), isDropdownVisible = _b[0], setIsDropdownVisible = _b[1];
    var _c = useState(false), isImportCsvModalVisible = _c[0], setIsImportCsvModalVisible = _c[1];
    var _d = useState(false), isImportWarningModalVisible = _d[0], setIsImportWarningModalVisible = _d[1];
    var viewId = useRecoilValue(tableSelectedViewIdState(blockId));
    var _e = useRecoilValue(tableCollectionInfoState(blockId)), collectionId = _e.id, collectionType = _e.type;
    var isUserTable = collectionType === 'user';
    var blockTitle = useRecoilValue(blockTitleSelector(blockId));
    var collectionSchemas = useRecoilValue(collectionTableSchemasState(collectionId));
    var showExportMsg = usePage().showExportMsg;
    var _f = usePageApi(), useExportCollection = _f.useExportCollection, useImportByFlatFile = _f.useImportByFlatFile, useGetFlatFileToken = _f.useGetFlatFileToken;
    var showImportOption = importTypeSet.has(collectionType);
    var allowImportByFlatFile = collectionType !== CollectionType.TIMEOFF &&
        collectionType !== CollectionType.JOB &&
        collectionType !== CollectionType.PLACEMENT;
    var flatFileToken = useGetFlatFileToken(collectionId, {
        enabled: isDropdownVisible && allowImportByFlatFile && showImportOption,
    }).data;
    var customFieldsForFlatFile = useMemo(function () {
        var result = [];
        collectionSchemas.forEach(function (schema) {
            if (
            // Filter out metric and native schemas
            schema.inputMode !== CellInputMode.NATIVE &&
                schema.inputMode !== CellInputMode.METRIC &&
                schema.inputMode !== CellInputMode.GENERATED) {
                result.push({
                    field: schema.id,
                    type: 'string',
                    label: schema.name,
                });
            }
        });
        return result;
    }, [collectionSchemas]);
    // Passing valid fields to flatfile so one invalid field will not fail the whole custom fields parse progress
    // Invalid fields will surface inside warning popup modal
    var _g = splitCustomFields(customFieldsForFlatFile), validCustomFields = _g[0], invalidCustomFields = _g[1];
    var containInvalidFields = invalidCustomFields.length !== 0;
    var useInviteEmployees = useAccountApi().useInviteEmployees;
    var exportCollectionMutation = useExportCollection();
    var requestCsvFileExport = useCallback(function () {
        exportCollectionMutation.mutate({
            collectionId: collectionId,
            collectionName: blockTitle,
            withFilters: false,
            collectionViewId: viewId,
            displaySettings: displaySettings,
        }, {
            onSuccess: function () {
                showExportMsg('/home/files');
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blockTitle, collectionId, viewId, displaySettings]);
    var requestCsvFileExportWithFilter = useCallback(function () {
        exportCollectionMutation.mutate({
            collectionId: collectionId,
            collectionName: blockTitle,
            withFilters: true,
            collectionViewId: viewId,
            displaySettings: displaySettings,
        }, {
            onSuccess: function () {
                showExportMsg('/home/files');
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blockTitle, collectionId, viewId, displaySettings]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var onBeforeGetContentResolve = useRef(null);
    var _h = useState(false), isPrinting = _h[0], setIsPrinting = _h[1];
    var printRef = useRef(null);
    var handlePrint = useReactToPrint({
        documentTitle: blockTitle,
        content: function () {
            return printRef.current;
        },
        onBeforeGetContent: function () {
            return new Promise(function (resolve) {
                setIsPrinting(true);
                onBeforeGetContentResolve.current = resolve;
            });
        },
        onAfterPrint: function () { return setIsPrinting(false); },
    });
    var printCallbackRef = useCallback(function (node) {
        if (node !== null && onBeforeGetContentResolve.current !== null) {
            printRef.current = node;
            onBeforeGetContentResolve.current();
        }
    }, []);
    var handleInviteUser = useCallback(function (inviteAll) {
        useInviteEmployees.mutate(inviteAll);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var onLaunchFlatFile = useCallback(function () {
        if (flatFileToken) {
            Flatfile.requestDataFromUser({
                token: flatFileToken,
                customFields: validCustomFields,
                onComplete: function (_a) {
                    var batchId = _a.batchId;
                    useImportByFlatFile.mutate({
                        flatFileBatchId: batchId,
                        collectionId: collectionId,
                    });
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collectionId, flatFileToken, customFieldsForFlatFile]);
    var items = useMemo(function () {
        var items = [];
        if (!canEditView) {
            return [
                {
                    key: 'export-filter',
                    label: 'Export the Whole Table',
                    onClick: requestCsvFileExportWithFilter,
                },
            ];
        }
        if (showImportOption && allowImportByFlatFile) {
            items.push({
                key: 'import-csv-flatfile',
                label: 'Import CSV from FlatFile',
                onClick: function () {
                    if (containInvalidFields) {
                        setIsImportWarningModalVisible(true);
                    }
                    else if (flatFileToken) {
                        onLaunchFlatFile();
                    }
                },
            });
            items.push({ type: 'divider' });
        }
        if (showImportOption) {
            items.push({
                key: 'import-csv',
                label: 'Import CSV',
                onClick: function () { return setIsImportCsvModalVisible(true); },
            });
            items.push({ type: 'divider' });
        }
        items.push({
            key: 'export-whole',
            label: 'Export the Whole Table',
            onClick: requestCsvFileExport,
        });
        items.push({ type: 'divider' });
        items.push({
            key: 'export-filter',
            label: 'Export with Filters',
            onClick: requestCsvFileExportWithFilter,
        });
        items.push({ type: 'divider' });
        items.push({
            key: 'print',
            label: 'Print',
            onClick: handlePrint,
        });
        if (isUserTable) {
            items.push({ type: 'divider' });
            items.push({
                key: 'invite-users',
                label: 'Invite All Users',
                onClick: function () { return handleInviteUser(true); },
            });
            items.push({ type: 'divider' });
            items.push({
                key: 'invite-inactive-users',
                label: 'Invite Inactive Users',
                onClick: function () { return handleInviteUser(false); },
            });
        }
        return items;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        allowImportByFlatFile,
        containInvalidFields,
        isUserTable,
        requestCsvFileExport,
        requestCsvFileExportWithFilter,
        showImportOption,
        flatFileToken,
    ]);
    return (_jsxs(_Fragment, { children: [_jsx(Dropdown, { menu: { items: items }, trigger: ['hover'], placement: "bottomRight", open: isDropdownVisible, onOpenChange: setIsDropdownVisible, children: _jsx(EllipsisOutlined, { className: "EllipsisIcon" }) }), _jsx(ImportCsvModal, { blockId: blockId, isModalVisible: isImportCsvModalVisible, closeModal: function () { return setIsImportCsvModalVisible(false); } }), _jsx(FlatFileCustomSchemaImportWarningModal, { isModalVisible: isImportWarningModalVisible, onLaunchFlatFile: onLaunchFlatFile, onModalClose: function () { return setIsImportWarningModalVisible(false); }, fields: invalidCustomFields }), isPrinting && (_jsx(Suspense, { fallback: null, children: _jsx("div", { style: {
                        display: 'none',
                    }, children: _jsx(PrintOnlyBlockCollectionTable, { blockId: blockId, blockTitle: blockTitle, ref: printCallbackRef }) }) }))] }));
};
export default React.memo(TableMoreOptionDropdown);
