import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../App.less';
import './Workspace.scss';
import { Layout } from 'antd';
import ActivityFeedState from 'src/hooks/state/ActivityFeed';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { accountIdSelector, currentGeneralPermissions, currentUserState, naviTreeStructureStateSelector, } from 'src/state';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import { useIntercom } from 'react-use-intercom';
import { getAccountStartDayOfWeek, getCannyQueryParameterByName, getCannySSORedirectURL, getWeekDayNumber, } from 'src/utils/Account';
import moment from 'moment';
import { useRecordDetail } from 'src/hooks/component';
import { useAccountApi } from '../hooks/api';
import CenterPanel from './CenterPanel/CenterPanel';
import NavigationSideBar from './NavigationSideBar/NavigationSideBar';
import ActivityFeedSider from './CenterPanel/Feed/ActivityFeed/ActivityFeedSider';
import { DASHBOARD_FEATURE_GATING_KEY } from './FeatureGatingKeyConstant';
var Workspace = function () {
    var _a = useState(true), isInit = _a[0], setIsInit = _a[1];
    var _b = useState(false), collapsed = _b[0], setCollapsed = _b[1];
    var _c = useState(true), accountHistoryCollapsed = _c[0], setAccountHistoryCollapsed = _c[1];
    var navigate = useNavigate();
    var location = useLocation();
    var accountId = useRecoilValue(accountIdSelector);
    var treeNode = useRecoilValue(naviTreeStructureStateSelector);
    var generalPermissions = useRecoilValue(currentGeneralPermissions);
    var isDashboardFeatureEnabled = useRecoilValue(featureEnableStatusState(DASHBOARD_FEATURE_GATING_KEY));
    var openRecordDetail = useRecordDetail().openRecordDetail;
    var _d = useAccountApi(), useGetCurrentUser = _d.useGetCurrentUser, useGetCannySSOToken = _d.useGetCannySSOToken;
    var currentUserInfo = useGetCurrentUser().data;
    var update = useIntercom().update;
    var setCurrentUserState = useSetRecoilState(currentUserState);
    useEffect(function () {
        if (currentUserInfo) {
            update({
                userId: currentUserInfo.recordId,
                name: currentUserInfo.fullName,
                company: {
                    companyId: currentUserInfo.accountId,
                    name: currentUserInfo.accountName,
                    createdAt: currentUserInfo.accountCreatedAt,
                },
                createdAt: currentUserInfo.createdAt,
            });
            if (currentUserInfo.email) {
                update({
                    email: currentUserInfo.email,
                });
            }
            setCurrentUserState(currentUserInfo);
        }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUserInfo]);
    var currentSearchParams = useMemo(function () { return new URLSearchParams(location.search); }, [location.search]);
    // Extract canny redirect url and company id when using canny SSO flow url
    var cannyRedirectURL = getCannyQueryParameterByName('redirect');
    var cannyCompanyID = getCannyQueryParameterByName('companyID');
    // Only generate canny SSO token when using canny SSO flow
    var shouldGenerateCannySSOToken = !!cannyRedirectURL && !!cannyCompanyID && cannyRedirectURL.indexOf('https://') === 0;
    var cannySSOToken = useGetCannySSOToken({
        enabled: shouldGenerateCannySSOToken,
    }).data;
    useEffect(function () {
        var _a;
        if (accountId && generalPermissions) {
            var cannySSORedirectURL = shouldGenerateCannySSOToken && cannySSOToken
                ? getCannySSORedirectURL(cannySSOToken, cannyRedirectURL, cannyCompanyID)
                : '';
            // Redirect to tb canny page if canny sso redirect url is provided and valid
            if (cannySSORedirectURL) {
                window.location.assign(cannySSORedirectURL);
            }
            else {
                if (location.pathname === '/home' && treeNode) {
                    if (!generalPermissions.DASHBOARD || !isDashboardFeatureEnabled) {
                        var firstGroupId = Object.keys(treeNode.items)[1];
                        var firstPageId = (_a = treeNode.items[firstGroupId]) === null || _a === void 0 ? void 0 : _a.children[0];
                        if (firstPageId) {
                            navigate({
                                pathname: "/home/page/".concat(firstGroupId, "/").concat(firstPageId),
                                search: currentSearchParams.toString(),
                            }, { replace: true });
                        }
                    }
                    else {
                        navigate({
                            pathname: '/home/dashboard',
                            search: currentSearchParams.toString(),
                        }, { replace: true });
                    }
                }
            }
            return;
        }
    }, [
        treeNode,
        location.pathname,
        accountId,
        isDashboardFeatureEnabled,
        generalPermissions,
        currentSearchParams,
        navigate,
        cannyRedirectURL,
        cannyCompanyID,
        cannySSOToken,
        shouldGenerateCannySSOToken,
    ]);
    var startDayOfWeek = getAccountStartDayOfWeek();
    useEffect(function () {
        moment.locale('en', {
            week: {
                dow: getWeekDayNumber(startDayOfWeek),
            },
        });
    }, [startDayOfWeek]);
    var onToggleNaviBar = useCallback(function () {
        setCollapsed(!collapsed);
    }, [collapsed]);
    var onToggleActivityFeed = useCallback(function () {
        setAccountHistoryCollapsed(!accountHistoryCollapsed);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountHistoryCollapsed]);
    var onCollapse = useCallback(function (collapsed) {
        setCollapsed(collapsed);
    }, []);
    var accountHistoryOnCollapse = useCallback(function (accountHistoryCollapsed) {
        if (isInit) {
            setIsInit(false);
            return;
        }
        setAccountHistoryCollapsed(accountHistoryCollapsed);
    }, [isInit]);
    return (_jsxs(Layout, { className: "Layout", children: [_jsx(NavigationSideBar, { collapsed: collapsed, onCollapse: onCollapse, onOpenRecordDetail: openRecordDetail }), _jsx(CenterPanel, { collapsed: collapsed, accountHistoryCollapsed: accountHistoryCollapsed, toggleNaviBar: onToggleNaviBar, toggleActivityFeed: onToggleActivityFeed }), _jsx(ActivityFeedState.Provider, { children: _jsx(ActivityFeedSider, { collapsed: accountHistoryCollapsed, onCollapse: accountHistoryOnCollapse }) })] }));
};
var WorkspaceMonitor = function () {
    return (_jsx(Sentry.ErrorBoundary, { onError: function (err, componentStack) {
            var _a;
            // eslint-disable-next-line no-console
            console.error(componentStack, err);
            if ((_a = process.env.REACT_APP_CLIENT_BASE_URL) === null || _a === void 0 ? void 0 : _a.includes('app')) {
                location.replace('/home');
            }
        }, children: _jsx(Workspace, {}) }));
};
export default React.memo(WorkspaceMonitor);
