import { useFetch } from './useFetch';
var useSessionApi = function () {
    var request = useFetch().request;
    var createAppSession = function (body) {
        return request({
            method: 'POST',
            url: 'app_sessions/create',
        }, body);
    };
    // !MARK: pass sessionId here as param to for async work
    var deleteAppSession = function (sessionId) {
        return request({ method: 'DELETE', url: "app_sessions/".concat(sessionId) });
    };
    return {
        createAppSession: createAppSession,
        deleteAppSession: deleteAppSession,
    };
};
export { useSessionApi };
