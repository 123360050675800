var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilCallback, useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState, } from 'recoil';
import { CollectionTableType } from 'src/shared';
import { usePageApi } from 'src/hooks/api';
import { useRecordComponent, useTimeZone } from 'src/hooks/component';
import { collectionTableNonSchemaMutationsState, collectionTableNonSchemaNativeFieldsState, collectionTableRecordState, collectionTableSchemaMutationsState, collectionTableSchemasState, collectionTableState, detailModalActiveTab, detailModalInstancesState, detailModalSchemaGroups, existingDetailModalInfo, shiftModalTimeZoneIdState, } from 'src/state';
import { prevIpSettingUrlState } from 'src/state/atoms/integrationSettingState';
import { LoadingLogo } from 'src/utils/icons/LoadingLogo';
import ZiraModal from '../../ui/ZiraModal/ZiraModal';
import RecordDetailModal from './RecordDetailModal';
var RecordDetailModalWrapper = function (props) {
    var _a, _b, _c;
    var defaultModalData = props.defaultModalData, recordId = props.recordId, bulkEditData = props.bulkEditData, isCreatingNew = props.isCreatingNew, schemaDefaults = props.schemaDefaults, defaultTimeZone = props.defaultTimeZone, isTopOfModalRenderStack = props.isTopOfModalRenderStack, addRecordOptionToCellForInlineCreate = props.addRecordOptionToCellForInlineCreate;
    var bulkMode = !!bulkEditData;
    var needsInit = !defaultModalData && !bulkMode && !isCreatingNew;
    var timeZone = useTimeZone(defaultTimeZone).timeZone;
    var detailModalInstances = useRecoilValue(detailModalInstancesState);
    var setDetailModalInstances = useSetRecoilState(detailModalInstancesState);
    var collectionTableType = isCreatingNew
        ? CollectionTableType.NEW_DETAIL_MODAL
        : CollectionTableType.DETAIL_MODAL;
    var _d = usePageApi(), useGetCollectionRecordDetailV3 = _d.useGetCollectionRecordDetailV3, useGetCollectionSchemaGroups = _d.useGetCollectionSchemaGroups;
    var editRecordCell = useRecordComponent().editRecordCell;
    var _e = useGetCollectionRecordDetailV3({
        recordId: recordId,
        options: {
            enabled: needsInit && isTopOfModalRenderStack,
            cacheTime: 0,
        },
    }), modalData = _e.data, isFetchingModalData = _e.isFetching;
    var initialModalData = modalData !== null && modalData !== void 0 ? modalData : defaultModalData;
    var initDetailModalCollection = useRecoilCallback(function (_a) {
        var set = _a.set, reset = _a.reset;
        return function (collectionTableData) {
            var collectionId = collectionTableData.collectionInfo.id;
            var dataSource = {
                type: collectionTableType,
                collectionId: collectionId,
            };
            set(collectionTableState(dataSource), function (prev) {
                var _a, _b;
                var tableData = collectionTableData;
                var isMidEditOfNewRecord = false;
                if (isCreatingNew &&
                    ((_a = prev === null || prev === void 0 ? void 0 : prev.rows[0]) === null || _a === void 0 ? void 0 : _a.recordId) == ((_b = collectionTableData.rows[0]) === null || _b === void 0 ? void 0 : _b.recordId) // recordId is the same
                ) {
                    //! Keep previous state when returning to new record modal (would be reset if was under a create new modal of same collection as recordId woule be different)
                    isMidEditOfNewRecord = true;
                    tableData = prev;
                }
                if (!isMidEditOfNewRecord) {
                    // Reset edit state
                    reset(collectionTableNonSchemaNativeFieldsState(dataSource));
                    reset(collectionTableSchemaMutationsState(dataSource));
                    reset(collectionTableNonSchemaMutationsState(dataSource));
                }
                return tableData;
            });
            set(collectionTableSchemasState(collectionId), function (prev) {
                if (isEqual(prev, collectionTableData.columns))
                    return prev;
                return collectionTableData.columns;
            });
            if (schemaDefaults === null || schemaDefaults === void 0 ? void 0 : schemaDefaults.length) {
                //! setTimeout so collectionTableSchemasState gets set first
                setTimeout(function () {
                    for (var _i = 0, schemaDefaults_1 = schemaDefaults; _i < schemaDefaults_1.length; _i++) {
                        var schemaDefault = schemaDefaults_1[_i];
                        editRecordCell(dataSource.type, { collectionId: collectionId, recordId: recordId, schemaId: schemaDefault.schemaId }, schemaDefault.newValues, timeZone);
                    }
                }, 0);
            }
            if (!isCreatingNew) {
                //! Update global record state if loading an existing record
                set(collectionTableRecordState({
                    type: CollectionTableType.TABLE,
                    collectionId: collectionId,
                    recordId: recordId,
                }), collectionTableData.cells[recordId]);
            }
        };
    }, [editRecordCell, collectionTableType, isCreatingNew, recordId, schemaDefaults, timeZone]);
    useEffect(function () {
        if (initialModalData && isTopOfModalRenderStack) {
            initDetailModalCollection(initialModalData.mainRecord);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialModalData, isTopOfModalRenderStack]);
    var _f = useGetCollectionSchemaGroups({
        collectionId: (_a = initialModalData === null || initialModalData === void 0 ? void 0 : initialModalData.mainRecord.collectionInfo.id) !== null && _a !== void 0 ? _a : '',
        options: {
            enabled: !!(initialModalData === null || initialModalData === void 0 ? void 0 : initialModalData.mainRecord.collectionInfo.id),
            cacheTime: 0,
        },
    }), schemaGroups = _f.data, isFetchingSchemaGroups = _f.isFetching;
    var setDetailModalSchemaGroup = useSetRecoilState(detailModalSchemaGroups((_b = initialModalData === null || initialModalData === void 0 ? void 0 : initialModalData.mainRecord.collectionInfo.id) !== null && _b !== void 0 ? _b : ''));
    var prevIpSettingUrl = useRecoilValue(prevIpSettingUrlState);
    useEffect(function () {
        if (schemaGroups) {
            setDetailModalSchemaGroup(schemaGroups);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schemaGroups]);
    var location = useLocation();
    var locationState = location.state;
    var navigate = useNavigate();
    var isStackedModal = detailModalInstances.length > 1;
    var queryClient = useQueryClient();
    var resetActiveTab = useResetRecoilState(detailModalActiveTab(recordId));
    var closeModal = useCallback(function (isBack) {
        // If hitting `X` - close all modals in stack
        if (!isBack) {
            setDetailModalInstances([]);
        }
        else {
            // Otherwise push the top modal off the stack
            setDetailModalInstances(function (prev) { return prev.slice(0, prev.length - 1); });
        }
        if ((locationState === null || locationState === void 0 ? void 0 : locationState.referrer) && !isCreatingNew && !isBack && isStackedModal) {
            navigate("".concat(location.pathname.split('?')[0]), { replace: true });
        }
        else if ((locationState === null || locationState === void 0 ? void 0 : locationState.referrer) && !isCreatingNew && isBack) {
            // Stacked record modal (Clicking "Save" or "Back")
            navigate(-1);
        }
        // Close under workspace view
        if (location.pathname.split('/')[1] === 'home') {
            if (!(isCreatingNew || bulkMode)) {
                // Single record modal or stacked record modal (Clicking "X" or mask overlay)
                navigate("".concat(location.pathname.split('?')[0]), { replace: true });
            }
        }
        // Close under integration settings
        else if (location.pathname.split('/').includes('settings') &&
            location.pathname.split('/').includes('integrations')) {
            if (!isStackedModal) {
                navigate("".concat(prevIpSettingUrl), { replace: true });
            }
        }
        if (!isCreatingNew) {
            // Invalidate reverse collection queries so it refetch on detail modal open
            queryClient.invalidateQueries({
                predicate: function (query) {
                    return query.queryKey[0] === 'reverseCollection' && query.queryKey[3] === recordId;
                },
                refetchActive: false,
            });
        }
        resetActiveTab();
    }, [
        locationState === null || locationState === void 0 ? void 0 : locationState.referrer,
        isCreatingNew,
        location.pathname,
        resetActiveTab,
        navigate,
        bulkMode,
        isStackedModal,
        prevIpSettingUrl,
        queryClient,
        recordId,
        setDetailModalInstances,
    ]);
    var setExistingDetailModalInfo = useSetRecoilState(existingDetailModalInfo);
    useEffect(function () {
        if (modalData) {
            setExistingDetailModalInfo(modalData.mainRecord);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalData]);
    var isLoading = isFetchingModalData || isFetchingSchemaGroups || !initialModalData || !schemaGroups;
    var _g = useRecoilState(shiftModalTimeZoneIdState({
        type: isCreatingNew
            ? CollectionTableType.NEW_DETAIL_MODAL
            : CollectionTableType.DETAIL_MODAL,
        collectionId: (_c = initialModalData === null || initialModalData === void 0 ? void 0 : initialModalData.mainRecord.collectionInfo.id) !== null && _c !== void 0 ? _c : '',
        recordId: recordId,
    })), shiftModalTimezone = _g[0], setShiftModalTimezone = _g[1];
    var initialShiftModalTimezone = useRef(shiftModalTimezone);
    useEffect(function () {
        if (!initialShiftModalTimezone.current) {
            initialShiftModalTimezone.current = shiftModalTimezone;
        }
    }, [shiftModalTimezone]);
    var onCancel = function () {
        // There's a chance the user might have changed shiftTimezoneIdState but not actually have saved,
        // in which case we'd want to revert the recoil state back to what it was when cancelling/closing the modal
        setShiftModalTimezone(initialShiftModalTimezone.current);
    };
    return (_jsx(ZiraModal, { show: true, onClose: function () {
            onCancel();
            closeModal();
        }, modalStyle: props.style, showCloseButton: !isLoading, size: "lg", children: isLoading ? (_jsx("div", { className: "logo-container h-full w-full", children: _jsx(LoadingLogo, { className: "main-loading-logo" }) })) : (_jsx(RecordDetailModal, __assign({}, props, { defaultModalData: initialModalData, onClose: closeModal, defaultTimeZone: timeZone, isStackedModal: isStackedModal, addRecordOptionToCellForInlineCreate: addRecordOptionToCellForInlineCreate }))) }));
};
export default React.memo(RecordDetailModalWrapper);
