var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var FileOutlinedSvg = function () { return (_jsx("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M13.75 4.25C13.336 4.25 13 4.586 13 5V11.75C13 13.818 11.318 15.5 9.25 15.5C7.182 15.5 5.5 13.818 5.5 11.75V4.75C5.5 3.509 6.509 2.5 7.75 2.5C8.991 2.5 10 3.509 10 4.75V11.75C10 12.164 9.664 12.5 9.25 12.5C8.836 12.5 8.5 12.164 8.5 11.75V5C8.5 4.586 8.164 4.25 7.75 4.25C7.336 4.25 7 4.586 7 5V11.75C7 12.991 8.009 14 9.25 14C10.491 14 11.5 12.991 11.5 11.75V4.75C11.5 2.682 9.818 1 7.75 1C5.682 1 4 2.682 4 4.75V11.75C4 14.645 6.355 17 9.25 17C12.145 17 14.5 14.645 14.5 11.75V5C14.5 4.586 14.164 4.25 13.75 4.25Z", fill: "#959595" }) })); };
export var FileOutlinedIcon = function (props) { return (_jsx(Icon, __assign({ component: FileOutlinedSvg }, props))); };
