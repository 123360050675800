import { atom, DefaultValue, selector } from 'recoil';
import { setAccountCurrency, setAccountStartDayOfWeek } from 'src/utils/Account';
import { localStorageEffect } from './localStorageEffects';
import { appSessionState } from './appSessionState';
var accountIdSelector = selector({
    key: 'accountIdSelector',
    get: function (_a) {
        var _b, _c;
        var get = _a.get;
        return (_c = (_b = get(appSessionState)) === null || _b === void 0 ? void 0 : _b.accountId) !== null && _c !== void 0 ? _c : '';
    },
});
var accountDetailState = atom({
    key: 'accountDetailState',
    default: undefined,
    effects: [
        function (_a) {
            var onSet = _a.onSet;
            onSet(function (newData) {
                if (newData instanceof DefaultValue || !newData)
                    return;
                setAccountCurrency(newData.currency);
                setAccountStartDayOfWeek(newData.startDayOfWeek);
            });
        },
        localStorageEffect("accountDetail"),
    ],
});
var autoSchedulerSettingState = atom({
    key: 'autoSchedulerSettingState',
    default: undefined,
});
// Map of accountId to array of enabled features for that account
var allEnabledFeaturesState = atom({
    key: 'allEnabledFeaturesState',
    default: {},
});
var orgListState = atom({
    key: 'orgListState',
    default: undefined,
});
var collectionOptionsState = atom({
    key: 'collectionOptionsState',
    default: [],
    effects: [localStorageEffect('collectionOptionsState')],
});
var currentUserState = atom({
    key: 'currentUserState',
    effects: [localStorageEffect('currentUserState')],
});
var currentOrganizationState = atom({
    key: 'currentOrganizationState',
    default: undefined,
    effects: [localStorageEffect('currentOrganizationState')],
});
export { accountIdSelector, accountDetailState, allEnabledFeaturesState, collectionOptionsState, currentUserState, currentOrganizationState, orgListState, autoSchedulerSettingState, };
