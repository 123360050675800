var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import * as Sentry from '@sentry/react';
import { CollectionTableType, CollectionType, DefaultPagePagination } from 'src/shared';
import { Skeleton } from 'antd';
import { usePageApi } from 'src/hooks/api';
import { useCollectionComponent, useDisplaySettings, useWindowSize } from 'src/hooks/component';
import { useRecordComponent } from 'src/hooks/component/useRecord';
import { usePrevious } from 'src/hooks/component/usePrevious';
import { tableCollectionIdSelector, tableCollectionTypeSelector, tableDisplaySettingsState, tableSearchTextState, tableVisibleColumnIdsSelector, tableVisibleColumnWidthsSelector, collectionTableCanEditSelector, collectionTableCanEditSchemasSelector, tableCollectionResetPageState, tableSelectedViewIdState, tableViewRecordOrdersState, isCurrentPageCanvasTypeSelector, nextPageOfTableState, tableCollectionCurrentSizeSelector, isCurrentPageTableTypeSelector, tableRecordCountsState, collectionTableFileSchemasSelector, collectionTableTypeSelector, } from 'src/state';
import { hasTitleColumn } from 'src/utils/Collection';
import { displaySettingsForCollectionTable } from 'src/services';
import { isEqual } from 'lodash';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import ZiraRowsSkeleton from '../../ui/ZiraRowsSkeleton';
import { BULK_DOWNLOAD_FILES } from '../FeatureGatingKeyConstant';
import AddNewRecordButton from './AddNewRecordButton';
import CollectionTable from './CollectionTable';
import { TITLE_COLUMN_ID } from './Constants';
import './BlockCollectionTable.scss';
import PaginationButton from './PaginationButton';
import AddNewColumnFromBlockButton from './AddNewColumnFromBlockButton';
import RecordsCountSummary from './RecordsCountSummary';
//!IMPORTANT - for block only
var BlockCollectionTable = function (_a) {
    var _b;
    var blockId = _a.blockId, defaultDisplaySettings = _a.initialDisplaySettings, isLoading = _a.isLoading, setIsLoading = _a.setIsLoading, isPayPeriodModal = _a.isPayPeriodModal;
    var initialDisplaySettings = useState(defaultDisplaySettings)[0];
    var viewId = useRecoilValue(tableSelectedViewIdState(blockId));
    var recordOrders = useRecoilValue(tableViewRecordOrdersState(viewId));
    var displaySettings = useRecoilValue(tableDisplaySettingsState(blockId));
    var searchText = useRecoilValue(tableSearchTextState(blockId));
    var setResetPage = useSetRecoilState(tableCollectionResetPageState(blockId));
    var prevFilter = usePrevious(displaySettings.filters);
    var prevSortBy = usePrevious(displaySettings.sortBy);
    var prevSearch = usePrevious(searchText);
    var processTableCollectionIntoState = useCollectionComponent().processTableCollectionIntoState;
    var editRecordCell = useRecordComponent().editRecordCell;
    var _c = useDisplaySettings(), updateColumnWidth = _c.updateColumnWidth, updateColumnOrder = _c.updateColumnOrder;
    var useListBlockCollectionQuery = usePageApi().useListBlockCollectionQuery;
    var requestBody = useMemo(function () { return ({
        blockId: blockId,
        displaySettings: displaySettingsForCollectionTable(initialDisplaySettings, displaySettings, isLoading),
        search: searchText || undefined,
    }); }, [blockId, displaySettings, initialDisplaySettings, searchText, isLoading]);
    //! Fetching query
    var _d = useListBlockCollectionQuery({
        body: requestBody,
        options: {
            enabled: !!viewId && !!requestBody.displaySettings,
        },
    }), data = _d.data, isFetching = _d.isFetching;
    useEffect(function () {
        if (data && !isFetching) {
            processTableCollectionIntoState(blockId, data.collection, undefined, true).then(function () {
                setIsLoading(false);
                setResetPage(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isFetching]);
    //! Reset page on filter/search change
    useEffect(function () {
        if (!isEqual(prevFilter, displaySettings.filters) || !isEqual(prevSearch, searchText)) {
            setResetPage(true);
        }
    }, [displaySettings.filters, prevFilter, prevSearch, searchText, setResetPage]);
    //! Process record orders for view if not set & display settings is the same
    useEffect(function () {
        if (!recordOrders &&
            isEqual(prevFilter, displaySettings.filters) &&
            isEqual(prevSortBy, displaySettings.sortBy) &&
            data &&
            !isFetching) {
            processTableCollectionIntoState(blockId, data.collection, undefined, true).then(function () {
                setIsLoading(false);
                setResetPage(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displaySettings.filters, displaySettings.sortBy, prevFilter, prevSortBy]);
    var collectionId = useRecoilValue(tableCollectionIdSelector(blockId));
    var visibleSchemaIds = useRecoilValue(tableVisibleColumnIdsSelector({ tableId: blockId, collectionId: collectionId }));
    var columnWidthsMap = useRecoilValue(tableVisibleColumnWidthsSelector(blockId));
    var tableDataSource = { type: CollectionTableType.TABLE, collectionId: collectionId };
    var canEditRecord = useRecoilValue(collectionTableCanEditSelector(tableDataSource));
    var canAddNewColumn = useRecoilValue(collectionTableCanEditSchemasSelector(tableDataSource));
    var fileSchemas = useRecoilValue(collectionTableFileSchemasSelector(collectionId));
    var collectionType = useRecoilValue(collectionTableTypeSelector(tableDataSource));
    var enableBulkDownloadFiles = useRecoilValue(featureEnableStatusState(BULK_DOWNLOAD_FILES));
    var canBulkDownloadFiles = enableBulkDownloadFiles &&
        (fileSchemas.length !== 0 || collectionType === CollectionType.TASK);
    var onEndResize = useCallback(function (columnWidth) {
        var columnId = Object.keys(columnWidth)[0];
        updateColumnWidth(blockId, columnId, columnWidth[columnId], isPayPeriodModal);
    }, [blockId, isPayPeriodModal, updateColumnWidth]);
    var onEndDragAndDrop = useCallback(function (columnOrderIds) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updateColumnOrder(blockId, undefined, columnOrderIds, isPayPeriodModal)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [blockId, isPayPeriodModal, updateColumnOrder]);
    var hasExtraTitleColumn = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function () {
            var type = snapshot.getLoadable(tableCollectionTypeSelector(blockId)).valueMaybe();
            if (!type)
                return;
            return hasTitleColumn(type);
        };
    }, [blockId]);
    var onEditCell = useCallback(function (cellPos, newValues, timeZone) {
        editRecordCell(CollectionTableType.TABLE, cellPos, newValues, timeZone);
    }, [editRecordCell]);
    /**
     * Pagination Logic
     */
    var fetchAndProcessBlockCollectionIntoState = useCollectionComponent().fetchAndProcessBlockCollectionIntoState;
    var collectionSize = useRecoilValue(tableCollectionCurrentSizeSelector(blockId));
    var _e = useState(-1), prevCollectionSize = _e[0], setPrevCollectionSize = _e[1];
    var _f = useState(false), pageLoading = _f[0], setPageLoading = _f[1];
    var blockTableRef = useRef(null);
    var page = useMemo(function () { return Math.floor(collectionSize / DefaultPagePagination.size); }, [collectionSize]);
    var canLoadMore = useMemo(function () { return prevCollectionSize !== collectionSize; }, [collectionSize, prevCollectionSize]);
    useEffect(function () {
        setPrevCollectionSize(-1);
    }, [(_b = requestBody.displaySettings) === null || _b === void 0 ? void 0 : _b.filters, requestBody.search]);
    var onLoadNextPage = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function () { return __awaiter(void 0, void 0, void 0, function () {
            var release, blockNextPageIndex, pagination, request;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        release = snapshot.retain();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 4, 5]);
                        if (!!pageLoading) return [3 /*break*/, 3];
                        setPrevCollectionSize(collectionSize);
                        setPageLoading(true);
                        blockNextPageIndex = snapshot
                            .getLoadable(nextPageOfTableState(blockId))
                            .valueMaybe();
                        pagination = {
                            page: blockNextPageIndex !== null && blockNextPageIndex !== void 0 ? blockNextPageIndex : page,
                            size: DefaultPagePagination.size,
                        };
                        request = __assign(__assign({}, requestBody), { pagination: pagination });
                        return [4 /*yield*/, fetchAndProcessBlockCollectionIntoState(request)];
                    case 2:
                        _a.sent();
                        setPageLoading(false);
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        release();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
    }, [
        blockId,
        collectionSize,
        fetchAndProcessBlockCollectionIntoState,
        page,
        pageLoading,
        requestBody,
    ]);
    // For full table page view only
    var handlePaginationScroll = useCallback(function (event) {
        event.stopPropagation();
        var atBottom = event.target.scrollTop > 0 &&
            event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight <=
                100;
        if (!pageLoading && atBottom && canLoadMore) {
            onLoadNextPage();
        }
    }, [canLoadMore, onLoadNextPage, pageLoading]);
    var windowWidth = useWindowSize().windowWidth;
    // Special render logic
    var isPayDetailBlock = new URLSearchParams(location.search).has('ridForPay');
    var isCanvasPageType = useRecoilValue(isCurrentPageCanvasTypeSelector);
    var isTablePageType = useRecoilValue(isCurrentPageTableTypeSelector);
    var recordCounts = useRecoilValue(tableRecordCountsState(blockId));
    var _g = useState(0), xScrollbarWidth = _g[0], setXScrollbarWidth = _g[1]; // Position Add New record button
    useEffect(function () {
        if (!blockTableRef.current)
            return;
        var tableObserver = new ResizeObserver(function (entries) {
            if (entries[0].target) {
                setXScrollbarWidth(blockTableRef.current.offsetHeight - blockTableRef.current.clientHeight);
            }
        });
        var table = blockTableRef.current.querySelector('.table');
        if (table) {
            tableObserver.observe(table);
        }
        return function () {
            tableObserver.disconnect();
        };
    }, [displaySettings.schemaOptions, windowWidth, recordCounts]);
    return isLoading ? (_jsx(Skeleton, { active: true })) : (_jsxs("div", { className: "BlockTableWrapper", onScroll: handlePaginationScroll, ref: blockTableRef, children: [recordOrders ? (_jsx(CollectionTable, { collectionId: collectionId, recordOrders: recordOrders !== null && recordOrders !== void 0 ? recordOrders : [], schemaIds: hasExtraTitleColumn()
                    ? __spreadArray([TITLE_COLUMN_ID], visibleSchemaIds, true) : visibleSchemaIds, columnWidthsMap: columnWidthsMap, blockId: blockId, editCell: onEditCell, addNewRecordButton: canEditRecord && !isTablePageType ? (_jsx(AddNewRecordButton, { blockId: blockId })) : undefined, paginationButton: isCanvasPageType && (_jsx(PaginationButton, { isLoading: pageLoading, noRecordsFound: !canLoadMore, onLoadNextPage: onLoadNextPage })), addNewColumnButton: canAddNewColumn ? (_jsx(AddNewColumnFromBlockButton, { blockId: blockId })) : undefined, recordsCountSummary: isPayDetailBlock ? _jsx(RecordsCountSummary, { blockId: blockId }) : undefined, paginationLoadingSummary: isTablePageType &&
                    !isFetching &&
                    pageLoading &&
                    (recordCounts === null || recordCounts === void 0 ? void 0 : recordCounts[viewId]) !== collectionSize ? (_jsx("div", { className: "py-[8px]", children: _jsx(ZiraRowsSkeleton, { rowNumber: (recordCounts === null || recordCounts === void 0 ? void 0 : recordCounts[viewId])
                            ? Math.min(recordCounts[viewId] - collectionSize, DefaultPagePagination.size)
                            : DefaultPagePagination.size }) })) : undefined, hasSelectRecordColumn: canEditRecord || canBulkDownloadFiles, onEndResize: onEndResize, onEndDragAndDrop: onEndDragAndDrop })) : (_jsx(ZiraRowsSkeleton, { rowNumber: 5 })), canEditRecord && isTablePageType && !pageLoading && (_jsx("div", { className: "absolute z-50 flex h-[14px] w-full cursor-pointer bg-white pl-[16px] shadow-card transition-[height] hover:h-[42px] hover:bg-[#fafafa]", style: { bottom: xScrollbarWidth }, children: _jsx(AddNewRecordButton, { blockId: blockId }) })), isTablePageType && !collectionSize && !isFetching && (_jsx("div", { className: "grid h-1/2 w-full place-items-center text-xl font-semibold", children: "No records found." }))] }));
};
var SentryProfilerBlockCollectionTable = Sentry.withProfiler(BlockCollectionTable, {
    name: 'BlockCollectionTable',
});
export default React.memo(SentryProfilerBlockCollectionTable);
