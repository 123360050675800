import { CellInputMode, ValueDataType } from 'src/shared';
import { isNil } from 'lodash';
import { WhichShift, TriggerType, } from './Interface';
export var schemaTypeIsValidForAutomationSendMessage = function (type, isInAppMessage) {
    if (isInAppMessage) {
        return [ValueDataType.NATIVE_USER_ID, ValueDataType.USER].includes(type);
    }
    return [ValueDataType.NATIVE_USER_ID, ValueDataType.USER, ValueDataType.CONTACT].includes(type);
};
export var formCollectionValue = function (builderState) {
    if (builderState.modifier == 'CLEAR_VALUE') {
        return {};
    }
    switch (builderState.valueType) {
        case 'text':
        case 'boolean':
            return {
                data: {
                    type: 'text',
                    inputMode: CellInputMode.PLAIN,
                    text: String((builderState.value.length && builderState.value[0]) || ''),
                },
            };
        case 'number':
            return {
                data: {
                    type: 'number',
                    inputMode: CellInputMode.PLAIN,
                    number: Number(builderState.value[0]),
                },
            };
        case 'rating':
            return {
                data: {
                    type: 'rating',
                    inputMode: CellInputMode.RATING,
                    value: Number(builderState.value[0]),
                },
            };
        case 'metric':
            return {
                data: {
                    type: 'metric',
                    inputMode: CellInputMode.PLAIN,
                    metric: Number(builderState.value[0]),
                },
            };
        case 'currency':
            return {
                data: {
                    type: 'currency',
                    inputMode: CellInputMode.PLAIN,
                    amount: Number(builderState.value[0]),
                },
            };
        case 'phone':
            return {
                data: {
                    type: 'phone',
                    inputMode: CellInputMode.PLAIN,
                    phone: String(builderState.value[0]),
                },
            };
        case 'datetime':
            return {
                data: {
                    type: 'datetime',
                    inputMode: CellInputMode.PLAIN,
                    datetime: builderState.value[0],
                },
            };
        case 'selection':
            return {
                valueIds: builderState.value.filter(function (value) { return !isNil(value); }),
            };
        default:
            return {};
    }
};
export var formDynamicValue = function (builderState) {
    var _a, _b;
    return builderState.dynamicValue
        ? {
            dynamicSchemaId: (_a = builderState.dynamicValue) === null || _a === void 0 ? void 0 : _a.dynamicSchemaId,
            dynamicSecondarySchemaId: (_b = builderState.dynamicValue) === null || _b === void 0 ? void 0 : _b.dynamicSecondarySchemaId,
        }
        : null;
};
export var formBespokeValue = function (builderState) {
    if (builderState.daysOfWeek) {
        return {
            type: 'DAY_OF_WEEK',
            daysOfWeek: builderState.daysOfWeek,
        };
    }
    if (builderState.modifier == 'first shift in day') {
        return {
            type: 'ONE_SHIFT_PER_DAY',
            whichShift: WhichShift.FIRST,
        };
    }
    if (builderState.modifier == 'last shift in day') {
        return {
            type: 'ONE_SHIFT_PER_DAY',
            whichShift: WhichShift.LAST,
        };
    }
    if (builderState.modifier == 'first in day') {
        return {
            type: 'FIRST_CLOCK_IN',
        };
    }
    return null;
};
export var formFilterSaveObject = function (builderState) {
    var _a = formCollectionValue(builderState), data = _a.data, valueIds = _a.valueIds;
    var dynamicValue = formDynamicValue(builderState);
    var bespokeValue = formBespokeValue(builderState);
    var filterSaveObj = {
        collectionId: builderState.collectionId,
        schemaId: builderState.schemaId,
        secondarySchemaId: builderState.secondarySchemaId,
        appliesTo: builderState.appliesTo,
        clauseValue: builderState.modifier,
        filterValue: dynamicValue ? undefined : data,
        valueIds: dynamicValue ? undefined : valueIds,
        dynamicValue: dynamicValue,
        bespokeValue: bespokeValue,
    };
    return filterSaveObj;
};
export var onSelectFilterOption = function (input, option) { var _a; return ((_a = option === null || option === void 0 ? void 0 : option.label) !== null && _a !== void 0 ? _a : '').toLowerCase().includes(input.trim().toLowerCase()); };
export var recurringTriggerCheck = function (trigger) {
    return trigger.type === TriggerType.RECURRING;
};
