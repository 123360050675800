import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { Menu } from 'antd';
import { useRecoilValue } from 'recoil';
import { useNavigate, useLocation } from 'react-router-dom';
import { FileHubOutlinedIcon } from 'src/utils/icons/FileHubIcon';
import { HomePageOutlinedIcon } from 'src/utils/icons/HomePageIcon';
import { CommunicationHubIcon } from 'src/utils/icons/CommunicationHubIcon';
import { HealthMetricsIcon } from 'src/utils/icons/HealthMetricsIcon';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import { accountIdSelector, allEnabledFeaturesState, currentGeneralPermissions } from 'src/state';
import { hasUnreadMessageSelector } from 'src/state/selectors/withChatState';
import { CHAT_FEATURE_GATING_KEY, DASHBOARD_FEATURE_GATING_KEY, HEALTH_METRICS_FEATURE_GATING_KEY, } from '../FeatureGatingKeyConstant';
var NavigationStaticMenu = function (_a) {
    var onSiderCollapse = _a.onSiderCollapse;
    var navigate = useNavigate();
    var location = useLocation();
    var currentPath = useMemo(function () { return location.pathname.split('/')[2]; }, [location.pathname]);
    var generalPermissions = useRecoilValue(currentGeneralPermissions);
    var isDashboardFeatureEnabled = useRecoilValue(featureEnableStatusState(DASHBOARD_FEATURE_GATING_KEY));
    var isChatFeatureEnabled = useRecoilValue(featureEnableStatusState(CHAT_FEATURE_GATING_KEY));
    var hasUnreadMessage = useRecoilValue(hasUnreadMessageSelector);
    var isHealthMetricsFeatureEnabled = useRecoilValue(featureEnableStatusState(HEALTH_METRICS_FEATURE_GATING_KEY));
    var accountId = useRecoilValue(accountIdSelector);
    // Without this condition, the files menuItem renders before all the other menu items on load/account switch :|
    var canRenderMenu = !!useRecoilValue(allEnabledFeaturesState)[accountId];
    if (!canRenderMenu ||
        ((!isDashboardFeatureEnabled || (generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.DASHBOARD) === false) &&
            (!isChatFeatureEnabled || (generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.CHAT) === false) &&
            (!isHealthMetricsFeatureEnabled || (generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.GOALS) === false) &&
            (generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.IMPORT_EXPORT) === false)) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(Menu, { className: "NavStaticMenu", onClick: function (info) {
            if (window.innerWidth < 576) {
                onSiderCollapse(true);
            }
            var menuKey = info.keyPath[0];
            if (menuKey === 'home') {
                navigate({ pathname: "/home/dashboard" });
            }
            else if (menuKey === 'communicate') {
                navigate({ pathname: "/home/team_chat" });
            }
            else if (menuKey === 'files') {
                navigate({ pathname: "/home/files" });
            }
            else if (menuKey === 'goals') {
                navigate({ pathname: "/home/goals" });
            }
        }, children: [isDashboardFeatureEnabled && (generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.DASHBOARD) && (_jsx(Menu.Item, { className: currentPath === 'dashboard' ? 'SelectedOption' : '', icon: _jsx(HomePageOutlinedIcon, {}), children: "Home" }, "home")), isHealthMetricsFeatureEnabled && (generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.GOALS) && (_jsx(Menu.Item, { className: currentPath === 'goals' ? 'SelectedOption' : '', icon: _jsx(HealthMetricsIcon, {}), children: "Goals" }, "goals")), isChatFeatureEnabled && (generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.CHAT) && (_jsxs(Menu.Item, { className: currentPath === 'team_chat' ? 'SelectedOption' : '', icon: _jsx(CommunicationHubIcon, {}), children: ["Team Chat", hasUnreadMessage && _jsx("div", { className: "UnreadMessageNotificationIndicator" })] }, "communicate")), (generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.IMPORT_EXPORT) && (_jsx(Menu.Item, { className: currentPath === 'files' ? 'SelectedOption' : '', icon: _jsx(FileHubOutlinedIcon, {}), children: "Files" }, "files"))] }));
};
export default React.memo(NavigationStaticMenu);
