var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { CellInputMode, CollectionTableType } from 'src/shared';
import { useSchemaComponent, useCollectionComponent } from 'src/hooks/component';
import { isSchemaNativeName, isSchemaTitle } from 'src/services';
import { collectionTableCanEditSchemasSelector, collectionTableSchemaSelector, ifCurrentUserHasDataBuilderPermission, } from 'src/state';
import ColumnHeaderHOC from './ColumnHeaderHOC';
var ColumnHeaderFromModal = function (_a) {
    var schemaId = _a.schemaId, collectionId = _a.collectionId, reverseRelationId = _a.reverseRelationId;
    var _b = useSchemaComponent(), updateColumnName = _b.updateColumnName, deleteColumn = _b.deleteColumn, mutateColumn = _b.mutateColumn;
    var addColorsToCollection = useCollectionComponent().addColorsToCollection;
    var _c = useState(false), isClicked = _c[0], setIsClicked = _c[1];
    var handleUpdateColumnName = useCallback(function (editedColumn) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            updateColumnName({ requestFrom: 'record_details' }, editedColumn.id, editedColumn, collectionId);
            return [2 /*return*/];
        });
    }); }, [collectionId, updateColumnName]);
    var handleDelete = useCallback(function () {
        deleteColumn({ requestFrom: 'record_details' }, schemaId, collectionId, reverseRelationId);
    }, [collectionId, deleteColumn, reverseRelationId, schemaId]);
    var handleMutate = useCallback(function (editedColumn) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, mutateColumn === null || mutateColumn === void 0 ? void 0 : mutateColumn({ requestFrom: 'record_details' }, editedColumn.id, editedColumn, collectionId, reverseRelationId)];
        });
    }); }, [collectionId, mutateColumn, reverseRelationId]);
    var handleAddColors = useCallback(function () {
        addColorsToCollection('', collectionId);
    }, [addColorsToCollection, collectionId]);
    var schema = useRecoilValue(collectionTableSchemaSelector({ collectionId: collectionId, schemaId: schemaId }));
    var canEditSchemas = useRecoilValue(collectionTableCanEditSchemasSelector({ type: CollectionTableType.TABLE, collectionId: collectionId }));
    var hasDataBuilderAccess = useRecoilValue(ifCurrentUserHasDataBuilderPermission);
    var limitedFunctions = useMemo(function () { return (schema === null || schema === void 0 ? void 0 : schema.inputMode) === CellInputMode.GENERATED; }, [schema]);
    var isNativeInputMode = useMemo(function () {
        return (schema === null || schema === void 0 ? void 0 : schema.inputMode) === CellInputMode.NATIVE;
    }, [schema]);
    var isRecordTitle = useMemo(function () { return (schema ? isSchemaTitle(schema) : false); }, [schema]);
    var isRecordNativeName = useMemo(function () { return (schema ? isSchemaNativeName(schema) : false); }, [schema]);
    var canEditSchemasOverride = canEditSchemas && hasDataBuilderAccess;
    var extraProps = canEditSchemasOverride &&
        !limitedFunctions &&
        !isNativeInputMode &&
        !isRecordTitle &&
        !isRecordNativeName
        ? {
            deleteColumn: handleDelete,
            mutateColumn: handleMutate,
            updateColumnName: handleUpdateColumnName,
        }
        : canEditSchemasOverride && isRecordTitle
            ? {
                addColorsToCollection: handleAddColors,
                mutateColumn: handleMutate,
                updateColumnName: handleUpdateColumnName,
            }
            : canEditSchemasOverride && isRecordNativeName
                ? {
                    addColorsToCollection: handleAddColors,
                    updateColumnName: handleUpdateColumnName,
                }
                : canEditSchemasOverride && isNativeInputMode && !isRecordTitle
                    ? {
                        updateColumnName: handleUpdateColumnName,
                    }
                    : {};
    return (_jsx(ColumnHeaderHOC, __assign({ collectionId: collectionId, column: schema, isClicked: isClicked, setIsClicked: setIsClicked }, extraProps)));
};
export default React.memo(ColumnHeaderFromModal);
