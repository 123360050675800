import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { NativePath } from 'src/shared';
import { collectionTableSchemaSelector } from 'src/state';
import { getTagTextColor } from 'src/utils/Collection';
import { convertStyleToColors } from 'src/utils/Collection';
import './DummyTagCell.scss';
import { useTimezoneApi } from 'src/hooks/api/useTimezoneApi';
import { useTimeZone } from 'src/hooks/component';
import HoverRecordMenu from './HoverRecordMenu';
import HoverTaskEntityMenu from './HoverTaskEntityMenu';
var DummyTagCell = function (props) {
    var _a;
    var defaultTags = props.tags, cellPos = props.cellPos, setIsClicked = props.setIsClicked, canEdit = props.canEdit, _b = props.isModalCell, isModalCell = _b === void 0 ? false : _b;
    var schema = useRecoilValue(collectionTableSchemaSelector(cellPos));
    var isLocationOnShift = (schema === null || schema === void 0 ? void 0 : schema.nativePath) === NativePath.SHIFT_LOCATION_ID;
    var useGetLocationTimezone = useTimezoneApi().useGetLocationTimezone;
    var locationTimezone = useGetLocationTimezone((_a = defaultTags[0]) === null || _a === void 0 ? void 0 : _a.id, {
        enabled: isLocationOnShift && isModalCell && defaultTags.length > 0 && !!defaultTags[0].id,
    }).data;
    var abbreviateTimezone = useTimeZone(locationTimezone === null || locationTimezone === void 0 ? void 0 : locationTimezone.zoneId).abbreviateTimezone;
    var tags = useMemo(function () {
        return defaultTags;
    }, [defaultTags]);
    var showLogicCell = useCallback(function () {
        if (canEdit) {
            setIsClicked(true);
        }
    }, [canEdit, setIsClicked]);
    var tagContent = useCallback(function (option) {
        var color = convertStyleToColors(option.color);
        return isLocationOnShift && isModalCell && locationTimezone ? (_jsxs("div", { className: "flex flex-row gap-1 items-center", children: [_jsx("span", { className: "DummyTag", style: {
                        color: getTagTextColor(color),
                        backgroundColor: color,
                    }, children: option.text }, option.id), _jsx("span", { className: "text-[11px] text-gray-400", children: abbreviateTimezone() })] })) : (_jsx("span", { className: "DummyTag", style: {
                color: getTagTextColor(color),
                backgroundColor: color,
            }, children: option.text }, option.id));
    }, [locationTimezone, isModalCell, isLocationOnShift, abbreviateTimezone]);
    var isTaskEditorNativePath = [
        NativePath.TASK_TEMPLATE_ID,
        NativePath.TASK_RULE_ID,
        NativePath.TASK_GROUP_ID,
    ].some(function (path) { return path === schema.nativePath; });
    return (_jsx("div", { className: "DummyTagCell flex flex-row flex-wrap items-center content-start", onClick: showLogicCell, children: tags.map(function (tag) {
            var _a, _b;
            if (schema.refCollectionId) {
                return (_jsx(HoverRecordMenu, { rid: tag.id, title: (_a = tag.text) !== null && _a !== void 0 ? _a : 'Record', children: tagContent(tag) }, tag.id));
            }
            else if (isTaskEditorNativePath) {
                return (_jsx(HoverTaskEntityMenu, { taskEntityId: tag.id, entityName: (_b = tag.text) !== null && _b !== void 0 ? _b : 'Record', nativePath: schema.nativePath, children: tagContent(tag) }, tag.id));
            }
            else {
                return tagContent(tag);
            }
        }) }));
};
export default React.memo(DummyTagCell);
