import moment from 'moment';
import { ReportMetricType, TrendReportTimespan } from 'src/components/ReportBlock';
import { getAccountCurrencySymbol } from 'src/utils/Account';
export var getReportCellValue = function (value) {
    var _a, _b, _c, _d, _e;
    if (!value)
        return 0; // Value should be defined but in case it's not, return 0 to prevent crash
    return ((_e = (_d = (_c = (_b = (_a = value.title) !== null && _a !== void 0 ? _a : value.count) !== null && _b !== void 0 ? _b : value.currency) !== null && _c !== void 0 ? _c : value.formula) !== null && _d !== void 0 ? _d : value.value) !== null && _e !== void 0 ? _e : (value.duration !== undefined
        ? parseFloat(moment.duration(value.duration).asHours().toFixed(2))
        : parseFloat(value.percentage.toFixed(2))));
};
export var getReportCellDisplayValue = function (value) {
    var _a, _b, _c, _d;
    if (!value)
        return 0; // Value should be defined but in case it's not, return 0 to prevent crash
    return ((_d = (_c = (_b = (_a = value.title) !== null && _a !== void 0 ? _a : value.count) !== null && _b !== void 0 ? _b : value.formula) !== null && _c !== void 0 ? _c : value.value) !== null && _d !== void 0 ? _d : (value.duration !== undefined
        ? moment.duration(value.duration).asHours().toFixed(2)
        : value.currency !== undefined
            ? "".concat(getAccountCurrencySymbol()).concat(value.currency.toFixed(2))
            : "".concat(value.percentage.toFixed(2), "%")));
};
export var formatMetricValue = function (value, metricType) {
    switch (metricType) {
        case ReportMetricType.COUNT:
            return value.toFixed(0);
        case ReportMetricType.DURATION:
            return "".concat(value.toFixed(2), " hr");
        case ReportMetricType.CURRENCY:
            return "".concat(getAccountCurrencySymbol()).concat(value.toFixed(2));
        case ReportMetricType.PERCENTAGE:
            return "".concat(value.toFixed(2), "%");
        case ReportMetricType.FORMULA:
            return value.toFixed(2);
        default:
            return value.toFixed(2);
    }
};
export var getMetricTypeUnit = function (metricType) {
    switch (metricType) {
        case ReportMetricType.COUNT:
            return '#';
        case ReportMetricType.DURATION:
            return 'Hours';
        case ReportMetricType.CURRENCY:
            return getAccountCurrencySymbol();
        case ReportMetricType.PERCENTAGE:
            return '%';
        case ReportMetricType.FORMULA:
            return '';
        default:
            return '';
    }
};
export var getVariableDateRangeTimespan = function (dateRangeType) {
    switch (dateRangeType) {
        case 'last_seven_days':
        case 'last_fourteen_days':
        case 'last_thirty_days':
        case 'week_to_date':
        case 'last_week':
        case 'yesterday':
        case 'today':
        case 'tomorrow':
        case 'this_week':
        case 'next_week':
        case 'last_pay_period':
        case 'current_pay_period':
            return TrendReportTimespan.DAY;
        case 'month_to_date':
        case 'last_month':
        case 'this_month':
        case 'next_month':
            return TrendReportTimespan.WEEK;
        case 'year_to_date':
        case 'last_year':
        case 'this_year':
        case 'next_year':
        case 'before_today':
        case 'after_today':
        case 'today_and_after':
            return TrendReportTimespan.MONTH;
        default:
            return TrendReportTimespan.DAY;
    }
};
/**
 * Returns column width percent for bar chart
 * @description Value returned ranges from 30 - 80 using a logistic growth function
 */
export var getColumnWidthPercent = function (xAxisLength) {
    return 30 + 50 / (1 + 30 * Math.exp(-xAxisLength / 3));
};
