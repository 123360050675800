var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTimeZone } from 'src/hooks/component/useTimeZone';
import { useAccountApi } from 'src/hooks/api';
import FormulaCellHOC from 'src/components/OptimizedTableView/Cell/FormulaCell/FormulaCellHOC';
import FileCell from 'src/components/OptimizedTableView/Cell/FileCell/FileCell';
import { useRecoilValue } from 'recoil';
import { autoSchedulerSettingState } from 'src/state';
import { CellInputMode, isNativeBooleanOrSpecialType, ItemRelationTypeSet, NativeGeneratedSet, NativePath, NativeTextInputSet, selectionStandardTypes, SELECTION_DROPDOWN_PAGE_SIZE, ValueDataType, } from '../../../shared';
import { getAccountCurrencySymbol } from '../../../utils/Account';
import { getIfNeedsFetchDataInCell, getNativeFieldCellContent, getNativeFieldCellOptions, getNativeType, isNativePrimaryColumn, } from '../../../utils/Collection';
import IndictmentPopover from '../../ScheduleView/IndictmentPopover';
import { RuleType } from '../../ScheduleView/Constant';
import { PayExceptionType } from '../../PayView/Constant';
import { emailValidation, phoneNumberValidation } from '../../../utils/Tools/InputValidation';
import { PayExceptionInfoMap } from '../../PayView/ExceptionsDrawer';
import AddressCell from './AddressCell';
import ApprovalStatusCell from './ApprovalStatusCell';
import DatePickerPopover from './DatePickerPopover';
import NativeCollectionTitle from './NativeCollectionTitle';
import SelectorCell from './SelectorCell';
import TextCellWithPopUp from './TextCellWithPopUp';
import './CustomTableCell.scss';
import SignatureCell from './SignatureCell';
import DOBDatePicker from './DOBDatePicker';
import PhoneInputCell from './PhoneInputCell';
var parseStringWithDecimal = function (value) {
    return _.toNumber(value).toFixed(2);
};
export var SelectorCellWrapper = function (props) {
    var _a;
    var schema = props.schema, editCell = props.editCell, addMultiSelectorOption = props.addMultiSelectorOption, mutateMultiSelectorOption = props.mutateMultiSelectorOption, deleteMultiSelectorOption = props.deleteMultiSelectorOption, defaultAvailableOptions = props.defaultAvailableOptions, defaultDisplayOptions = props.defaultDisplayOptions, canEdit = props.canEdit, detailModalProps = props.detailModalProps, timeZone = props.timeZone, allowAddNewAssignee = props.allowAddNewAssignee;
    var autoSchedulerSetting = useRecoilValue(autoSchedulerSettingState);
    var _b = useMemo(function () { return schema; }, [schema]), inputMode = _b.inputMode, nativePath = _b.nativePath, type = _b.type, id = _b.id;
    var _c = useMemo(function () {
        return detailModalProps !== null && detailModalProps !== void 0 ? detailModalProps : {
            isDetailModal: false,
            modalRecord: {},
            schemaList: undefined,
            updateWithDefault: false,
            onEditModalCell: undefined,
        };
    }, [detailModalProps]), isDetailModal = _c.isDetailModal, modalRecord = _c.modalRecord, _d = _c.schemaList, schemaList = _d === void 0 ? [] : _d, updateWithDefault = _c.updateWithDefault, onEditModalCell = _c.onEditModalCell;
    var nativeSchemaMap = useMemo(function () {
        return schemaList.reduce(function (acc, schema) {
            var _a;
            if (!schema.nativePath)
                return acc;
            return __assign(__assign({}, acc), (_a = {}, _a[schema.nativePath] = schema, _a));
        }, {});
    }, [schemaList]);
    var recordId = (_a = new URLSearchParams(location.search).get('rid')) !== null && _a !== void 0 ? _a : undefined;
    var displayOptions = useMemo(function () { return defaultDisplayOptions; }, [defaultDisplayOptions]);
    var _e = useState(defaultAvailableOptions), availableOptions = _e[0], setAvailableOptions = _e[1];
    var _f = useState(false), dropdownOpen = _f[0], setDropdownOpen = _f[1];
    var _g = useAccountApi(), useGetShiftUserList = _g.useGetShiftUserList, useGetUniversalSimpleListV2 = _g.useGetUniversalSimpleListV2, useGetClientList = _g.useGetClientList;
    var getShiftUserListMutation = useGetShiftUserList();
    var getClientList = useGetClientList();
    var isShiftAssignmentType = useMemo(function () { return nativePath === NativePath.SHIFT_USER_ID && isDetailModal; }, [isDetailModal, nativePath]);
    var isShiftDetailModal = !!detailModalProps;
    var fetchType = getIfNeedsFetchDataInCell(schema).fetchType;
    //TODO: add pagination here
    var _h = useGetUniversalSimpleListV2({
        schemaId: id,
        options: {
            enabled: dropdownOpen && !isNativeBooleanOrSpecialType(type) && !isShiftAssignmentType,
        },
    }), universalListData = _h.data, refetch = _h.refetch, listIsLoading = _h.isLoading;
    var _j = useMemo(function () {
        var _a, _b;
        // user id
        var userId = (_a = getNativeFieldCellOptions(modalRecord, nativeSchemaMap, NativePath.SHIFT_USER_ID)[0]) === null || _a === void 0 ? void 0 : _a.id;
        // location id
        var locationId = (_b = getNativeFieldCellOptions(modalRecord, nativeSchemaMap, NativePath.SHIFT_LOCATION_ID)[0]) === null || _b === void 0 ? void 0 : _b.id;
        // role ids
        var rolesCell = getNativeFieldCellOptions(modalRecord, nativeSchemaMap, NativePath.SHIFT_ROLE_IDS);
        var roleIds = rolesCell.length ? rolesCell.map(function (_a) {
            var id = _a.id;
            return id;
        }) : undefined;
        var shiftStart = getNativeFieldCellContent(modalRecord, nativeSchemaMap, NativePath.SHIFT_START_AT);
        var shiftEnd = getNativeFieldCellContent(modalRecord, nativeSchemaMap, NativePath.SHIFT_END_AT);
        return {
            shiftStart: moment(shiftStart !== null && shiftStart !== void 0 ? shiftStart : undefined).toISOString(),
            shiftEnd: shiftEnd
                ? moment(shiftEnd).toISOString()
                : moment(shiftStart !== null && shiftStart !== void 0 ? shiftStart : undefined).toISOString(),
            locationId: locationId ? [locationId] : undefined,
            roleIds: roleIds,
            userRid: userId,
        };
    }, [modalRecord, nativeSchemaMap]), shiftStart = _j.shiftStart, shiftEnd = _j.shiftEnd, locationId = _j.locationId, roleIds = _j.roleIds, userRid = _j.userRid;
    var _k = useState(true), fetchFromShortlist = _k[0], setFetchFromShortlist = _k[1];
    var _l = useState(0), shortListPage = _l[0], setShortListPage = _l[1];
    var _m = useState(0), remainingUsersPage = _m[0], setRemainingUsersPage = _m[1];
    var _o = useState(true), shouldResetPage = _o[0], setShouldResetPage = _o[1];
    var _p = useState(false), isLoading = _p[0], setIsLoading = _p[1];
    var _q = useState(''), searchText = _q[0], setSearchText = _q[1];
    useEffect(function () {
        setShouldResetPage(true);
        setFetchFromShortlist(true);
        setShortListPage(0);
    }, [shiftStart, shiftEnd, locationId, roleIds]);
    var fetchShiftUserList = useCallback(function (searchText) {
        if (dropdownOpen && isShiftAssignmentType && (searchText || !isLoading)) {
            setIsLoading(true);
            getShiftUserListMutation.mutate({
                shiftStart: shiftStart,
                shiftEnd: shiftEnd,
                shiftRid: recordId,
                location: locationId,
                roles: roleIds,
                start: timeZone
                    ? moment(shiftStart).tz(timeZone).startOf('week').toISOString()
                    : moment(shiftStart).startOf('week').toISOString(),
                end: timeZone
                    ? moment(shiftEnd)
                        .tz(timeZone)
                        .add(1, 'weeks')
                        .startOf('week')
                        .toISOString()
                    : moment(shiftEnd).add(1, 'weeks').startOf('week').toISOString(),
                fromShortlist: fetchFromShortlist,
                query: searchText,
                pagination: !shouldResetPage && fetchFromShortlist
                    ? {
                        page: shortListPage,
                        size: SELECTION_DROPDOWN_PAGE_SIZE,
                    }
                    : !shouldResetPage && !fetchFromShortlist
                        ? {
                            page: remainingUsersPage,
                            size: SELECTION_DROPDOWN_PAGE_SIZE,
                        }
                        : {
                            page: 0,
                            size: SELECTION_DROPDOWN_PAGE_SIZE,
                        },
            }, {
                onSuccess: function (shiftUserList) {
                    if (shiftUserList) {
                        var locationRoleList_1 = shiftUserList.locationAndRoleUserShortlist.map(function (user) { return ({
                            id: user.recordId,
                            text: user.name,
                            color: '',
                            userAvatar: user.avatarUrl,
                            indictmentNames: user.indictmentNames,
                        }); });
                        var remainingUserList_1 = shiftUserList.remainingUsers.map(function (user) { return ({
                            id: user.recordId,
                            text: user.name,
                            color: '',
                            userAvatar: user.avatarUrl,
                            indictmentNames: user.indictmentNames,
                        }); });
                        if (searchText) {
                            setLocationRoleList(locationRoleList_1);
                            setRemainingUserList(remainingUserList_1);
                            setAvailableOptions(locationRoleList_1.concat(remainingUserList_1));
                            setShortListPage(0);
                            setRemainingUsersPage(0);
                        }
                        else if (shouldResetPage) {
                            setLocationRoleList(locationRoleList_1);
                            setRemainingUserList([]);
                            setAvailableOptions(locationRoleList_1);
                            if (locationRoleList_1.length === SELECTION_DROPDOWN_PAGE_SIZE) {
                                setShortListPage(function (prevPage) { return prevPage + 1; });
                            }
                            setRemainingUsersPage(0);
                            setShouldResetPage(false);
                        }
                        else if (fetchFromShortlist) {
                            setLocationRoleList(function (prevLocationRoleList) {
                                return _.uniqBy(prevLocationRoleList.concat(locationRoleList_1), 'id');
                            });
                            setAvailableOptions(function (prevAvailableOption) {
                                return _.uniqBy(prevAvailableOption.concat(locationRoleList_1), 'id');
                            });
                            if (locationRoleList_1.length === SELECTION_DROPDOWN_PAGE_SIZE) {
                                setShortListPage(function (prevPage) { return prevPage + 1; });
                            }
                        }
                        else {
                            setRemainingUserList(function (prevRemainingUserList) {
                                return _.uniqBy(prevRemainingUserList.concat(remainingUserList_1), 'id');
                            });
                            setAvailableOptions(function (prevAvailableOption) {
                                return _.uniqBy(prevAvailableOption.concat(remainingUserList_1), 'id');
                            });
                            if (remainingUserList_1.length === SELECTION_DROPDOWN_PAGE_SIZE) {
                                setRemainingUsersPage(function (prevPage) { return prevPage + 1; });
                            }
                        }
                        if (shiftUserList.shortlistLoaded) {
                            if (locationRoleList_1.length < SELECTION_DROPDOWN_PAGE_SIZE) {
                                setIsLoading(false);
                            }
                            setFetchFromShortlist(false);
                        }
                    }
                },
                onSettled: function () {
                    setIsLoading(false);
                },
            });
        }
    }, [
        dropdownOpen,
        fetchFromShortlist,
        getShiftUserListMutation,
        isLoading,
        isShiftAssignmentType,
        locationId,
        recordId,
        remainingUsersPage,
        roleIds,
        shiftEnd,
        shiftStart,
        shortListPage,
        shouldResetPage,
        timeZone,
    ]);
    var _r = useState(false), isEvaluationDataFetched = _r[0], setIsEvaluationDataFetched = _r[1];
    var fetchClientList = useCallback(function (clientRids) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (isShiftDetailModal) {
                getClientList.mutate({
                    shiftStart: shiftStart,
                    shiftEnd: shiftEnd,
                    shiftRid: recordId,
                    location: locationId,
                    roles: roleIds,
                    start: timeZone
                        ? moment(shiftStart).tz(timeZone).startOf('week').toISOString()
                        : moment(shiftStart).startOf('week').toISOString(),
                    end: timeZone
                        ? moment(shiftEnd)
                            .tz(timeZone)
                            .add(1, 'weeks')
                            .startOf('week')
                            .toISOString()
                        : moment(shiftEnd).add(1, 'weeks').startOf('week').toISOString(),
                    clientRids: clientRids,
                    userRid: userRid,
                }, {
                    onSuccess: function (clientData) {
                        var _a, _b;
                        setIsEvaluationDataFetched(true);
                        if ((clientData === null || clientData === void 0 ? void 0 : clientData.clientList.length) !== 0) {
                            var shortlistRuleKey_1 = autoSchedulerSetting === null || autoSchedulerSetting === void 0 ? void 0 : autoSchedulerSetting.shortlistRule;
                            var shortlistRule = autoSchedulerSetting === null || autoSchedulerSetting === void 0 ? void 0 : autoSchedulerSetting.rulesMap[shortlistRuleKey_1];
                            if (shortlistRuleKey_1 && shortlistRule) {
                                var clientShortList = _.filter(clientData === null || clientData === void 0 ? void 0 : clientData.clientList, function (client) {
                                    return !client.indictmentNames.includes(shortlistRuleKey_1);
                                });
                                var remainingClientList = _.filter(clientData === null || clientData === void 0 ? void 0 : clientData.clientList, function (client) {
                                    return client.indictmentNames.includes(shortlistRuleKey_1);
                                });
                                setOptionShortList(__spreadArray(__spreadArray([], clientShortList.map(function (option) {
                                    return {
                                        id: option.recordId,
                                        text: option.name,
                                        color: '',
                                        indictmentNames: option.indictmentNames,
                                    };
                                }), true), defaultDisplayOptions, true));
                                setRemainingOptionList((_a = remainingClientList.map(function (option) {
                                    return {
                                        id: option.recordId,
                                        text: option.name,
                                        color: '',
                                        indictmentNames: option.indictmentNames,
                                    };
                                })) !== null && _a !== void 0 ? _a : []);
                            }
                            setAvailableOptions(__spreadArray(__spreadArray([], ((_b = clientData === null || clientData === void 0 ? void 0 : clientData.clientList.map(function (option) {
                                return {
                                    id: option.recordId,
                                    text: option.name,
                                    color: '',
                                    indictmentNames: option.indictmentNames,
                                };
                            })) !== null && _b !== void 0 ? _b : []), true), defaultDisplayOptions, true));
                        }
                    },
                    onError: function () {
                        var _a;
                        setIsEvaluationDataFetched(false);
                        setAvailableOptions((_a = universalListData.options) === null || _a === void 0 ? void 0 : _a.map(function (option) { return ({
                            id: option.id,
                            text: option.name,
                            color: option.color || '',
                            userAvatar: option.image,
                        }); }));
                    },
                });
            }
            return [2 /*return*/];
        });
    }); }, [
        autoSchedulerSetting === null || autoSchedulerSetting === void 0 ? void 0 : autoSchedulerSetting.rulesMap,
        autoSchedulerSetting === null || autoSchedulerSetting === void 0 ? void 0 : autoSchedulerSetting.shortlistRule,
        defaultDisplayOptions,
        getClientList,
        isShiftDetailModal,
        locationId,
        recordId,
        roleIds,
        shiftEnd,
        shiftStart,
        timeZone,
        universalListData,
        userRid,
    ]);
    useEffect(function () {
        if (dropdownOpen && isShiftAssignmentType && !searchText) {
            fetchShiftUserList();
        }
        else if (dropdownOpen &&
            !fetchFromShortlist &&
            locationRoleList.length < SELECTION_DROPDOWN_PAGE_SIZE &&
            !searchText) {
            fetchShiftUserList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropdownOpen, isShiftAssignmentType, fetchFromShortlist]);
    var onSearch = useCallback(function (newSearchText) {
        if (newSearchText) {
            fetchShiftUserList(newSearchText);
        }
        if (searchText && !newSearchText) {
            setFetchFromShortlist(true);
            setShouldResetPage(true);
        }
        setSearchText(newSearchText);
    }, [fetchShiftUserList, searchText]);
    var _s = useState([]), locationRoleList = _s[0], setLocationRoleList = _s[1];
    var _t = useState([]), remainingUserList = _t[0], setRemainingUserList = _t[1];
    var _u = useState([]), optionShortList = _u[0], setOptionShortList = _u[1];
    var _v = useState([]), remainingOptionList = _v[0], setRemainingOptionList = _v[1];
    var generateIdList = useCallback(function (data) {
        var idList = [];
        data === null || data === void 0 ? void 0 : data.map(function (option) {
            idList.push(option.id);
        });
        return idList;
    }, []);
    useEffect(function () {
        var _a;
        if (universalListData) {
            if (universalListData.scheduleEvaluateObject &&
                universalListData.scheduleEvaluateObject === 'CLIENT') {
                var idList = generateIdList(universalListData.options);
                if (idList.length > 0) {
                    fetchClientList(idList);
                }
            }
            else {
                setAvailableOptions((_a = universalListData.options) === null || _a === void 0 ? void 0 : _a.map(function (option) { return ({
                    id: option.id,
                    text: option.name,
                    color: option.color || '',
                    userAvatar: option.image,
                }); }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [universalListData, generateIdList, modalRecord]);
    var mutateColumnOption = useCallback(function (optionId, newName, newColor) { return __awaiter(void 0, void 0, void 0, function () {
        var prevOption, newOptionData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    prevOption = _.find(availableOptions, { id: optionId });
                    if (!prevOption)
                        return [2 /*return*/];
                    newOptionData = {
                        inputMode: schema.inputMode,
                        type: 'text',
                        text: newName || prevOption.text || '',
                        color: newColor || prevOption.color || '',
                    };
                    return [4 /*yield*/, (mutateMultiSelectorOption === null || mutateMultiSelectorOption === void 0 ? void 0 : mutateMultiSelectorOption(optionId, schema.id, newOptionData))];
                case 1:
                    _a.sent();
                    refetch();
                    return [2 /*return*/];
            }
        });
    }); }, [availableOptions, mutateMultiSelectorOption, refetch, schema.id, schema.inputMode]);
    var addColumnOption = useCallback(function (newOptionId, newName, newColor) {
        addMultiSelectorOption === null || addMultiSelectorOption === void 0 ? void 0 : addMultiSelectorOption(newOptionId, newName, newColor);
    }, [addMultiSelectorOption]);
    var deleteColumnOption = useCallback(function (optionId) {
        if (deleteMultiSelectorOption) {
            return deleteMultiSelectorOption(optionId);
        }
        return Promise.resolve();
    }, [deleteMultiSelectorOption]);
    var isEvaluationType = useMemo(function () { return (universalListData === null || universalListData === void 0 ? void 0 : universalListData.scheduleEvaluateObject) === 'CLIENT' && isEvaluationDataFetched; }, [isEvaluationDataFetched, universalListData]);
    var onMutateDisplayValuesInItem = useCallback(function (values) { return editCell(values); }, [editCell]);
    return (_jsx(SelectorCell, { nativePath: nativePath, inputMode: inputMode, isUserSelecting: fetchType === 'users', mutateDisplayedValueInItem: onMutateDisplayValuesInItem, mutateColumnOption: mutateColumnOption, addColumnOption: addColumnOption, deleteColumnOption: deleteColumnOption, displayedOption: displayOptions, availableOption: availableOptions, canEdit: canEdit, isDetailModal: isDetailModal, type: type, showColorMenu: true, dropdownOpen: dropdownOpen, setDropdownOpen: setDropdownOpen, isLoading: isLoading || listIsLoading, onSearch: onSearch, evaluationTypeProps: {
            isEvaluationType: isEvaluationType,
            schemaName: schema.name,
            shortListOptions: optionShortList,
            remainingOptions: remainingOptionList,
        }, shiftAssigneeProps: {
            isShiftAssignmentType: !!isShiftAssignmentType,
            locationAndRoleUserOptions: locationRoleList,
            remainingUserOptions: remainingUserList,
            refetchShiftUserList: fetchShiftUserList,
            onEditModalCell: onEditModalCell,
            allowAddNewAssignee: allowAddNewAssignee,
        }, updateWithDefault: updateWithDefault }));
};
var CustomTableCell = function (props) {
    var _a, _b, _c;
    var schema = props.schema, collectionId = props.collectionId, recordColor = props.recordColor, primitiveValues = props.primitiveValues, displayValueData = props.displayValueData, nativeTypeValue = props.nativeTypeValue, relationValues = props.relationValues, editCell = props.editCell, editFileCell = props.editFileCell, canEdit = props.canEdit, detailModalProps = props.detailModalProps, payExceptionEvaluation = props.payExceptionEvaluation, recordId = props.recordId, defaultTimeZone = props.timeZone;
    var dataType = schema.type, inputMode = schema.inputMode, availableValues = schema.values;
    var _d = detailModalProps !== null && detailModalProps !== void 0 ? detailModalProps : {
        isDetailModal: false,
    }, isDetailModal = _d.isDetailModal, _e = _d.updateWithDefault, updateWithDefault = _e === void 0 ? false : _e;
    var accountCurrency = getAccountCurrencySymbol();
    var timeZone = useTimeZone(defaultTimeZone).timeZone;
    var parseDecimal = useMemo(function () {
        return schema.type === ValueDataType.METRIC_CURRENCY ||
            schema.type === ValueDataType.METRIC_PERCENTAGE;
    }, [schema.type]);
    var onEditCellWithSingleValue = useCallback(function (newValue) {
        editCell([newValue]);
    }, [editCell]);
    var _f = useMemo(function () {
        var _a, _b, _c;
        var exceptions = [];
        if (payExceptionEvaluation) {
            var payExceptions_1 = (_b = (_a = _.find(payExceptionEvaluation === null || payExceptionEvaluation === void 0 ? void 0 : payExceptionEvaluation.exceptionMatches, function (exception) { return exception.shiftRid === recordId; })) === null || _a === void 0 ? void 0 : _a.exceptionTypes) !== null && _b !== void 0 ? _b : [];
            var exceptionTypes = __spreadArray([], payExceptions_1, true);
            if (exceptionTypes === null || exceptionTypes === void 0 ? void 0 : exceptionTypes.length) {
                // Add exceptions
                for (var _i = 0, exceptionTypes_1 = exceptionTypes; _i < exceptionTypes_1.length; _i++) {
                    var name_1 = exceptionTypes_1[_i];
                    var exceptionKey = PayExceptionType[name_1];
                    var exceptionLevel = RuleType.STRICT;
                    var exceptionName = (_c = PayExceptionInfoMap[exceptionKey]) === null || _c === void 0 ? void 0 : _c.name;
                    exceptions.push({
                        name: exceptionName,
                        type: exceptionLevel,
                    });
                }
            }
            return {
                payExceptions: exceptions,
                hasPayException: !!exceptions.length,
            };
        }
        return { payExceptions: [], hasPayException: false };
    }, [payExceptionEvaluation, recordId]), payExceptions = _f.payExceptions, hasPayException = _f.hasPayException;
    /**
     * Plain or Metric Type Cell
     */
    // Datetime type
    if (dataType === ValueDataType.DATETIME) {
        var isDateTime = displayValueData
            ? displayValueData.type === ValueDataType.DATETIME
            : true;
        return isDateTime ? (_jsx(DatePickerPopover, { data: {
                type: ValueDataType.DATETIME,
                showTime: true,
                dateTime: displayValueData === null || displayValueData === void 0 ? void 0 : displayValueData.datetime,
            }, onEditTime: editCell, canEdit: canEdit, isDetailModal: isDetailModal, timeZone: timeZone, updateWithDefault: updateWithDefault })) : (_jsx(DatePickerPopover, { data: {
                type: ValueDataType.DATETIME_RANGE,
                from: displayValueData === null || displayValueData === void 0 ? void 0 : displayValueData.from,
                to: displayValueData === null || displayValueData === void 0 ? void 0 : displayValueData.to,
                showTime: displayValueData === null || displayValueData === void 0 ? void 0 : displayValueData.showTime,
            }, onEditTime: editCell, canEdit: canEdit, isDetailModal: isDetailModal, timeZone: timeZone, updateWithDefault: updateWithDefault }));
    }
    /**
     * File Type cell
     */
    if (dataType === ValueDataType.FILE) {
        // TODO: shitty code here, will be fixed with new response format
        var nativeFiles = (nativeTypeValue === null || nativeTypeValue === void 0 ? void 0 : nativeTypeValue.file) ? [nativeTypeValue.file] : [];
        var files = (displayValueData === null || displayValueData === void 0 ? void 0 : displayValueData.files) || nativeFiles;
        return (_jsx("span", { className: "ml-1 w-full", children: _jsx(FileCell, { files: files, editCell: editFileCell, canEdit: canEdit, mode: schema.inputMode === CellInputMode.NATIVE ? 'single' : 'multiple', placeHolder: isDetailModal ? (_jsx("span", { className: "relative mr-1 inline-block min-h-full min-w-full py-1 font-medium text-gray-300", children: "Upload File" })) : undefined, linkTemplateEnabled: true, isModalCell: isDetailModal }) }));
    }
    /**
     * Signatue Type Cell
     */
    if (dataType === ValueDataType.SIGNATURE) {
        return (_jsx(SignatureCell, { url: displayValueData === null || displayValueData === void 0 ? void 0 : displayValueData.url, onSave: onEditCellWithSingleValue, updateWithDefault: updateWithDefault }));
    }
    /**
     * native item
     */
    if (inputMode === CellInputMode.NATIVE || inputMode === CellInputMode.GENERATED) {
        var nativeType = schema.type;
        var nativePath = schema.nativePath;
        if (getNativeType(nativeType) === ValueDataType.DATETIME) {
            if (nativePath === NativePath.USER_DOB) {
                var dateTime = (nativeTypeValue === null || nativeTypeValue === void 0 ? void 0 : nativeTypeValue.localDate) === null ? undefined : nativeTypeValue === null || nativeTypeValue === void 0 ? void 0 : nativeTypeValue.localDate;
                return (_jsx(DOBDatePicker, { data: {
                        type: ValueDataType.DATETIME,
                        showTime: false,
                        dateTime: dateTime,
                    }, onEditTime: editCell, canEdit: canEdit, isDetailModal: isDetailModal }));
            }
            if (nativePath === NativePath.USER_LAST_ACTIVE) {
                var text = (nativeTypeValue === null || nativeTypeValue === void 0 ? void 0 : nativeTypeValue.text) === null ? undefined : nativeTypeValue === null || nativeTypeValue === void 0 ? void 0 : nativeTypeValue.text;
                return _jsx(TextCellWithPopUp, { text: text || '', canEdit: false });
            }
            var notShowTime = nativePath === NativePath.PAY_PERIOD_START_DATE ||
                nativePath === NativePath.PAY_PERIOD_END_DATE;
            return (_jsx(DatePickerPopover, { data: {
                    type: ValueDataType.DATETIME,
                    showTime: notShowTime ? false : true,
                    dateTime: (nativeTypeValue === null || nativeTypeValue === void 0 ? void 0 : nativeTypeValue.timestamp) === null
                        ? undefined
                        : nativeTypeValue === null || nativeTypeValue === void 0 ? void 0 : nativeTypeValue.timestamp,
                }, onEditTime: editCell, canEdit: canEdit, isDetailModal: isDetailModal, timeZone: timeZone }));
        }
        else if (NativeTextInputSet.has(getNativeType(nativeType))) {
            var colorTagProp_1 = {
                colorTag: { color: recordColor !== null && recordColor !== void 0 ? recordColor : nativeTypeValue === null || nativeTypeValue === void 0 ? void 0 : nativeTypeValue.color },
            };
            var extraPropsForTitle_1 = isNativePrimaryColumn(schema) || nativePath === NativePath.SHIFT_STATUS
                ? colorTagProp_1
                : {};
            var canEditText = NativeGeneratedSet.has(nativePath) ? false : canEdit;
            var isCurrencyType = getNativeType(schema.type) === ValueDataType.CURRENCY;
            var validationFunction = getNativeType(schema.type) === ValueDataType.EMAIL
                ? emailValidation
                : getNativeType(schema.type) === ValueDataType.PHONE
                    ? phoneNumberValidation
                    : undefined;
            if (getNativeType(nativeType) === ValueDataType.PHONE) {
                return (_jsx(PhoneInputCell, { phone: (nativeTypeValue === null || nativeTypeValue === void 0 ? void 0 : nativeTypeValue.text) || '', editCell: onEditCellWithSingleValue, canEdit: canEditText }));
            }
            if (getNativeType(nativeType) === ValueDataType.NATIVE_DURATION) {
                var hoursText = nativeTypeValue
                    ? moment.duration(nativeTypeValue.duration).asHours().toFixed(2)
                    : null;
                return _jsx(TextCellWithPopUp, { text: hoursText || '', canEdit: false });
            }
            return (_jsx(TextCellWithPopUp, __assign({ text: (_b = (_a = nativeTypeValue === null || nativeTypeValue === void 0 ? void 0 : nativeTypeValue.text) !== null && _a !== void 0 ? _a : nativeTypeValue === null || nativeTypeValue === void 0 ? void 0 : nativeTypeValue.number) !== null && _b !== void 0 ? _b : '', editCell: onEditCellWithSingleValue, canEdit: canEditText }, extraPropsForTitle_1, { isDetailModal: isDetailModal, isCurrency: isCurrencyType, validationFunction: validationFunction, updateWithDefault: [NativePath.SHIFT_WAGE, NativePath.SHIFT_BILL_RATE].includes(nativePath) ||
                    updateWithDefault })));
        }
        else if (getNativeType(nativeType) === ValueDataType.NATIVE_SELECTION ||
            selectionStandardTypes.has(getNativeType(nativeType))) {
            var nativeDisplayOption_1 = [];
            if (nativeTypeValue) {
                var nativeTypeStr = getNativeType(nativeType);
                if ((nativeTypeStr == 'role' || nativeTypeStr == 'location') &&
                    nativeTypeValue.nativeObjects) {
                    (_c = nativeTypeValue.nativeObjects) === null || _c === void 0 ? void 0 : _c.forEach(function (obj) {
                        nativeDisplayOption_1.push({
                            id: obj.rid,
                            text: obj.text,
                            color: obj.color,
                        });
                    });
                }
                else if (nativeTypeValue.contentId)
                    nativeDisplayOption_1.push({
                        id: nativeTypeValue.contentId,
                        text: nativeTypeValue.text,
                        color: nativeTypeValue.color || '',
                    });
            }
            return (_jsx(SelectorCellWrapper, __assign({}, props, { defaultDisplayOptions: nativeDisplayOption_1, defaultAvailableOptions: [] })));
        }
        else if (getNativeType(nativeType) === ValueDataType.BOOLEAN) {
            return (_jsx(SelectorCellWrapper, __assign({}, props, { defaultDisplayOptions: (nativeTypeValue === null || nativeTypeValue === void 0 ? void 0 : nativeTypeValue.boolean) === undefined
                    ? []
                    : [
                        {
                            id: nativeTypeValue === null || nativeTypeValue === void 0 ? void 0 : nativeTypeValue.boolean,
                            text: (nativeTypeValue === null || nativeTypeValue === void 0 ? void 0 : nativeTypeValue.boolean) ? 'Yes' : 'No',
                            color: (nativeTypeValue === null || nativeTypeValue === void 0 ? void 0 : nativeTypeValue.boolean) ? '#E5F4EF' : '#FFEDEC',
                        },
                    ], defaultAvailableOptions: [
                    {
                        id: 'true',
                        text: 'Yes',
                        color: '#E5F4EF',
                    },
                    {
                        id: 'false',
                        text: 'No',
                        color: '#FFEDEC',
                    },
                ] })));
        }
        else if (getNativeType(nativeType) === ValueDataType.NATIVE_SPECIAL) {
            // !More special native to be handled here
            if (nativePath === NativePath.TIMEOFF_APPROVAL_STATUS) {
                return (_jsx(ApprovalStatusCell, { extraProps: props, nativeTypeValue: nativeTypeValue, editCell: onEditCellWithSingleValue, isDetailModal: isDetailModal }));
            }
            else if (nativePath === NativePath.LOCATION_ADDRESS) {
                return (_jsx(AddressCell, { isDetailModal: isDetailModal, defaultValue: (nativeTypeValue === null || nativeTypeValue === void 0 ? void 0 : nativeTypeValue.text) || '', canEdit: canEdit, editCell: onEditCellWithSingleValue }));
            }
        }
        else if (dataType === ValueDataType.TITLE) {
            return (_jsxs(NativeCollectionTitle, { data: displayValueData, color: recordColor, children: [hasPayException && (_jsx(IndictmentPopover, { requirementsMet: [], indictments: payExceptions, trigger: "click", className: "PayExceptionIndictmentPopover" })), ' '] }));
        }
        else if (dataType === ValueDataType.FORMULA) {
            return (_jsx(FormulaCellHOC, { cellPos: {
                    schemaId: schema.id,
                    recordId: recordId,
                    collectionId: collectionId,
                }, canEdit: canEdit }));
        }
        else {
            return (_jsxs("div", { style: { padding: '0.6rem 0.5rem' }, children: [nativePath, " lllll not supported yet."] }));
        }
    }
    /**
     * multiSelection
     */
    if (inputMode == CellInputMode.SINGLE ||
        inputMode == CellInputMode.MULTI ||
        inputMode == CellInputMode.INTEGRATION) {
        var displayOptions_1 = [];
        var availableOptions_1 = [];
        // case 1: plain type choose
        if (dataType === ValueDataType.PLAIN_SELECTION) {
            primitiveValues === null || primitiveValues === void 0 ? void 0 : primitiveValues.map(function (value) {
                if (value) {
                    displayOptions_1.push({
                        id: value.id,
                        text: value.data.text,
                        color: value.data.color,
                    });
                }
            });
            availableValues === null || availableValues === void 0 ? void 0 : availableValues.map(function (value) {
                if (value) {
                    availableOptions_1.push({
                        id: value.id,
                        text: value.data.text,
                        color: value.data.color,
                    });
                }
            });
        }
        // case 2: relation type choose
        if (ItemRelationTypeSet.has(dataType)) {
            if (relationValues && relationValues.length !== 0) {
                relationValues.map(function (relation) {
                    if (relation) {
                        displayOptions_1.push({
                            id: relation.id,
                            text: relation.title,
                            color: relation.color || '',
                            userAvatar: relation.avatarUrl || '',
                        });
                    }
                });
            }
        }
        return (_jsx(SelectorCellWrapper, __assign({}, props, { defaultAvailableOptions: availableOptions_1, defaultDisplayOptions: displayOptions_1 })));
    }
    //TODO: deal with more keys
    var displayedPlainText = (displayValueData === null || displayValueData === void 0 ? void 0 : displayValueData.text) ||
        (displayValueData === null || displayValueData === void 0 ? void 0 : displayValueData.number) ||
        (displayValueData === null || displayValueData === void 0 ? void 0 : displayValueData.amount) ||
        (displayValueData === null || displayValueData === void 0 ? void 0 : displayValueData.title) ||
        (displayValueData === null || displayValueData === void 0 ? void 0 : displayValueData.metric);
    var colorTagProp = {
        colorTag: { color: recordColor },
    };
    var extraPropsForTitle = (displayValueData === null || displayValueData === void 0 ? void 0 : displayValueData.title) ? colorTagProp : {};
    var showPercentSign = schema.type === ValueDataType.METRIC_PERCENTAGE ? '%' : '';
    var showCurrencySign = schema.type === ValueDataType.METRIC_CURRENCY ? accountCurrency : '';
    var value = parseDecimal ? parseStringWithDecimal(displayedPlainText) : displayedPlainText;
    return (_jsx(TextCellWithPopUp, __assign({ text: "".concat(showCurrencySign).concat(value === undefined ? '' : value).concat(showPercentSign), editCell: onEditCellWithSingleValue, canEdit: canEdit, isDetailModal: isDetailModal }, extraPropsForTitle, { updateWithDefault: updateWithDefault })));
};
export default React.memo(CustomTableCell);
