var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var CurrencyIconSvg = function () { return (_jsxs("svg", { width: "19", height: "19", viewBox: "0 0 19 19", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsxs("g", { clipPath: "url(#clip0_18168_12537)", children: [_jsx("path", { d: "M12.0049 5.655C11.181 4.91022 10.1095 4.49853 8.99888 4.5C7.80645 4.50159 6.6634 4.9764 5.82078 5.82014C4.97816 6.66388 4.50488 7.80757 4.50488 9C4.50488 11.4855 6.51638 13.5 8.99888 13.5C10.107 13.5008 11.1761 13.0903 11.9989 12.348", stroke: "#959595", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M9 15V13.5", stroke: "#959595", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M9 4.5V3", stroke: "#959595", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_18168_12537", children: _jsx("rect", { width: "18", height: "18", fill: "white" }) }) })] })); };
export var CurrencyOutlineIcon = function (props) { return (_jsx(Icon, __assign({ component: CurrencyIconSvg }, props))); };
