import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { accountIdSelector } from 'src/state';
import { useFetch } from '.';
var REPORT_BLOCK_CACHE_TIME = 1000 * 60 * 15; // 15 minutes
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
var useReportApi = function () {
    var customFetch = useFetch().request;
    var accountId = useRecoilValue(accountIdSelector);
    var getAvailableMetrics = function (reportId) {
        return customFetch({
            method: 'GET',
            url: 'metrics/list/' + reportId,
        });
    };
    var useGetReportBuilderData = function (reportId) {
        return useQuery('reportBuilderData', function () {
            return customFetch({
                method: 'POST',
                url: 'trends/setup',
            }, { accountId: accountId, reportId: reportId });
        }, { cacheTime: 0 });
    };
    var useSaveReportBuilderData = function (request) {
        var _a;
        return useQuery(['reportBuilder', request.body], function () { return saveReportBuilderData(request.body); }, {
            enabled: (_a = request === null || request === void 0 ? void 0 : request.options) === null || _a === void 0 ? void 0 : _a.enabled,
        });
    };
    var saveReportBuilderData = function (request) {
        return customFetch({
            method: 'POST',
            url: 'trends/save',
        }, request);
    };
    var useGetReportBlock = function (reportId, search) {
        return useQuery(['reportBlock', reportId, search], function () {
            return customFetch({
                method: 'POST',
                url: "trends",
            }, {
                reportId: reportId,
                search: search,
            });
        }, { cacheTime: REPORT_BLOCK_CACHE_TIME });
    };
    return {
        getAvailableMetrics: getAvailableMetrics,
        useGetReportBuilderData: useGetReportBuilderData,
        useSaveReportBuilderData: useSaveReportBuilderData,
        saveReportBuilderData: saveReportBuilderData,
        useGetReportBlock: useGetReportBlock,
    };
};
export { useReportApi };
