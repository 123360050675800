var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Divider, Tabs } from 'antd';
import { produce } from 'immer';
import React, { useCallback, useMemo, useState } from 'react';
import LoadingStateAnimation from 'src/ui/LoadingStateAnimation';
import { ObjType, SubMenuContentIcon } from 'src/shared';
import { useSetting } from 'src/hooks/api';
import SettingsState from 'src/hooks/state/Settings';
import { getColumnTypeKey } from 'src/utils/Collection';
import { SchemaPermissionLevel } from '../Constants';
import SchemaPermissionDropdown from './SchemaPermissionDropdown';
import './SchemaPermissionOverviewContent.scss';
var SchemaPermissionOverviewContent = function (_a) {
    var collectionIconUrl = _a.collectionIconUrl, collectionName = _a.collectionName, defaultSchemas = _a.schemas, collectionSettings = _a.collectionSettings;
    var _b = useState(defaultSchemas), schemas = _b[0], setSchemas = _b[1];
    var schemaGroup = useMemo(function () {
        return (collectionSettings === null || collectionSettings === void 0 ? void 0 : collectionSettings.schemaGroup) || [
            {
                name: 'Basic Details',
                schemas: schemas.map(function (s) { return s.schemaId; }),
                description: null,
                translations: null,
            },
        ];
    }, [collectionSettings === null || collectionSettings === void 0 ? void 0 : collectionSettings.schemaGroup, schemas]);
    var visibleSchemas = useMemo(function () { return schemas.filter(function (s) { return s.permission != SchemaPermissionLevel.NONE; }); }, [schemas]);
    var handleUpdateSchemaPermission = useCallback(function (schemaId, permission) {
        setSchemas(produce(schemas, function (draft) {
            var schema = draft.find(function (s) { return s.schemaId == schemaId; });
            if (schema) {
                schema.permission = permission;
            }
        }));
    }, [schemas]);
    return (_jsxs("div", { className: "SchemaPermissionOverviewContent", children: [_jsxs("div", { className: "relative left-2 top-4 flex items-center space-x-3 pb-3", children: [_jsx("img", { className: "flex-none", src: collectionIconUrl }), _jsxs("div", { className: "grow", children: [_jsx("div", { className: "font-semibold", children: collectionName }), _jsx("span", { className: "font-medium text-gray-500", children: "Modify data access below." })] })] }), _jsx(Divider, {}), _jsx(Tabs, { defaultActiveKey: "visibleSchemas", items: [
                    {
                        label: "Visible Data (".concat(visibleSchemas.length, ")"),
                        key: 'visibleSchemas',
                        children: (_jsx(SchemaPermissionList, { schemas: visibleSchemas, group: schemaGroup, onUpdatePermission: handleUpdateSchemaPermission })),
                    },
                    {
                        label: "All Data (".concat(schemas.length, ")"),
                        key: 'allSchemas',
                        children: (_jsx(SchemaPermissionList, { schemas: schemas, group: schemaGroup, onUpdatePermission: handleUpdateSchemaPermission })),
                    },
                ] })] }));
};
var SchemaPermissionList = function (_a) {
    var schemas = _a.schemas, group = _a.group, onUpdatePermission = _a.onUpdatePermission;
    if (!schemas.length)
        return _jsx("div", { className: "EmptyState", children: "No Columns Here" });
    var missingSchemaFromGroups = schemas.filter(function (s) {
        return !(group === null || group === void 0 ? void 0 : group.map(function (g) { return g.schemas; }).flat().includes(s.schemaId));
    });
    return (_jsx(_Fragment, { children: group === null || group === void 0 ? void 0 : group.map(function (schemaGroup, index) {
            var foundSchemas = __spreadArray(__spreadArray([], (index === 0 && missingSchemaFromGroups.length
                ? missingSchemaFromGroups
                : []), true), schemaGroup.schemas
                .map(function (schemaId) { return schemas.find(function (schema) { return schema.schemaId === schemaId; }); })
                .filter(function (schema) { return schema !== undefined; }), true);
            if (!foundSchemas.length)
                return null;
            return (_jsxs("div", { children: [_jsx("div", { className: "mb-3 mt-6 text-xs font-medium text-gray-600", children: schemaGroup.name }), foundSchemas.map(function (schema) { return (_jsx(SchemaEntry, { schema: schema, onUpdatePermission: onUpdatePermission }, schema.schemaId)); })] }, index));
        }) }));
};
var SchemaEntry = function (_a) {
    var schema = _a.schema, onUpdatePermission = _a.onUpdatePermission;
    var schemaIcon = SubMenuContentIcon[getColumnTypeKey(schema.inputMode, schema.type, schema.nativePath)];
    var accessGroupDetail = SettingsState.useContainer().accessGroupDetail;
    var useUpdateObjectPermission = useSetting().useUpdateObjectPermission;
    var _b = useUpdateObjectPermission(), updateObjectPermission = _b.mutateAsync, isLoading = _b.isLoading, isError = _b.isError;
    var onHandleUpdatePermission = useCallback(function (permission) {
        updateObjectPermission({
            objectId: schema.schemaId,
            type: ObjType.SCHEMA,
            permission: permission,
            accessGroupId: accessGroupDetail.id,
        });
        onUpdatePermission(schema.schemaId, permission);
    }, [accessGroupDetail, onUpdatePermission, schema.schemaId, updateObjectPermission]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex items-center space-x-2", children: [_jsx("div", { className: "w-6 flex-none", children: schemaIcon }), _jsx("span", { className: "font-medium", children: schema.schemaName }), _jsx("div", { className: "grow" }), _jsx(LoadingStateAnimation, { isLoading: isLoading, loadingFailed: isError }), _jsx(SchemaPermissionDropdown, { currentPermission: schema.permission, onUpdate: function (p) { return onHandleUpdatePermission(p); }, labelClassName: "rounded-sm bg-gray-50 py-[0.4em] w-[6rem] text-center px-2 font-medium text-gray-400 hover:cursor-pointer", canUpdate: (accessGroupDetail === null || accessGroupDetail === void 0 ? void 0 : accessGroupDetail.type) !== 'owner' })] }), _jsx(Divider, { className: "mb-2 mt-2" })] }));
};
export default React.memo(SchemaPermissionOverviewContent);
