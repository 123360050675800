var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form } from 'antd';
import { useIntegrationsApi } from 'src/hooks/api/useIntegrationsApi';
import { toast } from 'react-toastify';
import { getIntegrationIcon, getIpDisplayText } from '../../../../../utils/Account';
import teambridgeIconNew from '../../../../../utils/icons/images/teambridge_icon_new.png';
import IpUserMatchingModal from '../IpUserMatchingModal';
import SchemaMappingSelector from './SchemaMappingSelector';
var SchemaMapping = function (_a) {
    var ipName = _a.ipName, ipCollection = _a.ipCollection, teambridgeCollectionId = _a.teambridgeCollectionId;
    var _b = useIntegrationsApi(), useGetSchemaMappingData = _b.useGetSchemaMappingData, useSaveSchemasMappingResult = _b.useSaveSchemasMappingResult, getSchemaLinks = _b.getSchemaLinks;
    var saveSchemasMapping = useSaveSchemasMappingResult().mutateAsync;
    var _c = useState(undefined), schemaMappings = _c[0], setSchemaMappings = _c[1];
    var _d = useState(false), isSaving = _d[0], setIsSaving = _d[1];
    var _e = useState(false), isUserMatchingModalOpen = _e[0], setIsUserMatchingModalOpen = _e[1];
    var formInstance = Form.useForm()[0];
    var schemaMappingData = useGetSchemaMappingData({
        ipCollectionName: ipCollection,
        ziraCollectionId: teambridgeCollectionId,
    }).data;
    var handleFetchedMappingData = useCallback(function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var links_1, dataCopy_1;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    if (!data) return [3 /*break*/, 2];
                    return [4 /*yield*/, getSchemaLinks({
                            teambridgeCollectionId: teambridgeCollectionId,
                            ipCollectionName: ipCollection,
                            ipName: ipName,
                        })];
                case 1:
                    links_1 = (_b = (_a = (_d.sent())) === null || _a === void 0 ? void 0 : _a.schemaLinks) !== null && _b !== void 0 ? _b : [];
                    dataCopy_1 = __assign({}, data);
                    // Add tb schema and lookupSchemaId onto ipSchemas in mapping data for ease of use/access
                    (_c = data.ipSchemas) === null || _c === void 0 ? void 0 : _c.forEach(function (ipSchema, idx) {
                        var matchingMapping = data.predefinedMapping.find(function (mapping) { return mapping.ipSchema.internalName === ipSchema.internalName; });
                        // Default to predefined mapping if it exists
                        if (matchingMapping) {
                            dataCopy_1.ipSchemas[idx].ziraSchema = matchingMapping.ziraSchema;
                        }
                        var schemaLink = links_1.find(function (link) { return link.ipSchema.internalName === ipSchema.internalName; });
                        // Prio schema link over predefined mapping if the link exists
                        if (schemaLink) {
                            dataCopy_1.ipSchemas[idx].lookupSchemaId = schemaLink.lookupSchemaId;
                            dataCopy_1.ipSchemas[idx].ziraSchema = schemaLink.ziraSchema;
                        }
                    });
                    setSchemaMappings(dataCopy_1);
                    _d.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, [getSchemaLinks, ipCollection, ipName, teambridgeCollectionId]);
    useEffect(function () {
        handleFetchedMappingData(schemaMappingData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schemaMappingData]);
    var getSaveSchemaRequestBodyLinks = useCallback(function (mapData) {
        if (!mapData) {
            return [];
        }
        var formValues = formInstance.getFieldsValue();
        var ipSchemas = mapData.ipSchemas;
        return ipSchemas.map(function (schema) {
            var _a, _b;
            var schemaId = (_a = formValues[schema.displayName]) === null || _a === void 0 ? void 0 : _a.schemaId;
            var lookupSchemaId = (_b = formValues[schema.displayName]) === null || _b === void 0 ? void 0 : _b.lookupSchemaId;
            return {
                ipSchema: schema,
                ziraSchema: schemaId === '' ? null : schemaId,
                lookupSchemaId: lookupSchemaId,
            };
        });
    }, [formInstance]);
    var handleSave = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsSaving(true);
                    return [4 /*yield*/, saveSchemasMapping({
                            collectionId: teambridgeCollectionId,
                            ipCollectionName: ipCollection,
                            links: getSaveSchemaRequestBodyLinks(schemaMappingData),
                        }, {
                            onSuccess: function () {
                                toast.success('Mapping saved successfully!');
                                setIsSaving(false);
                            },
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [
        setIsSaving,
        saveSchemasMapping,
        schemaMappingData,
        getSaveSchemaRequestBodyLinks,
        ipCollection,
        teambridgeCollectionId,
    ]);
    return (_jsxs(_Fragment, { children: [_jsxs(Form, { className: "flex flex-col", onFinish: handleSave, form: formInstance, children: [_jsxs("div", { className: "grid grid-cols-2 place-items-start mb-2", children: [_jsx("div", { className: "flex flex-col gap-3", children: _jsxs("div", { className: "flex items-center gap-2", children: [getIntegrationIcon(ipName, 'rounded-xl shadow-lg min-w-8 max-w-8 min-h-8 max-h-8'), _jsxs("span", { className: "text-xs text-gray-400 font-medium", children: [getIpDisplayText(ipName), " Columns"] })] }) }), _jsxs("div", { className: "flex items-center gap-2", children: [_jsx("img", { src: teambridgeIconNew, alt: "TeamBridge Logo", className: "min-w-8 max-w-8 rounded-xl shadow-lg ml-2" }), _jsx("span", { className: "text-xs text-gray-400 font-medium", children: "TeamBridge Columns" })] })] }), schemaMappings === null || schemaMappings === void 0 ? void 0 : schemaMappings.ipSchemas.map(function (ipSchema, idx) { return (_jsx(SchemaMappingSelector, { isSaving: isSaving, ipSchema: ipSchema, teambridgeSchemas: (schemaMappings === null || schemaMappings === void 0 ? void 0 : schemaMappings.ziraSchemas) || [] }, "".concat(idx, "-").concat(ipSchema.displayName))); }), _jsxs("div", { className: "flex flex-row items-center gap-2 self-end", children: [_jsx(Button, { className: "text-xs px-2 py-1 font-semibold bg-white text-black hover:bg-white hover:text-black", style: { border: '1px solid rgba(0, 0, 0, .1)' }, onClick: function () { return setIsUserMatchingModalOpen(true); }, children: "Match Values" }), _jsx(Button, { className: "text-xs px-2 py-1 font-semibold text-white bg-blue-400 hover:bg-blue-400 hover:text-gray-50", htmlType: "submit", disabled: isSaving, children: "Save Changes" })] })] }), isUserMatchingModalOpen && (_jsx(IpUserMatchingModal, { ipCollection: ipCollection, isOpen: isUserMatchingModalOpen, closeModal: function () { return setIsUserMatchingModalOpen(false); } }))] }));
};
export default React.memo(SchemaMapping);
