var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { DnDHandleIcon } from 'src/utils/icons/DnDHandleIcon';
var FileRecordItem = function (_a) {
    var item = _a.item, title = _a.title, onUnSelectFile = _a.onUnSelectFile, draggableProvided = _a.draggableProvided;
    return (_jsxs("div", __assign({}, draggableProvided === null || draggableProvided === void 0 ? void 0 : draggableProvided.draggableProps, { ref: draggableProvided === null || draggableProvided === void 0 ? void 0 : draggableProvided.innerRef, children: [title && (_jsxs("div", { className: "text-xs font-medium text-gray-400 mb-1 pl-[25px]", children: [title, " ", item.taskAssigneeName && " - ".concat(item.taskAssigneeName)] })), _jsxs("div", { className: "flex", children: [draggableProvided && (_jsx(DnDHandleIcon, __assign({ style: { cursor: 'grab' } }, draggableProvided.dragHandleProps))), _jsxs("div", { className: "border border-gray-60 px-2 py-1 rounded-lg w-full flex justify-between", children: [_jsx("div", { children: item.fileName }), _jsx("div", { className: "cursor-pointer text-gray-300 hover:text-black", onClick: onUnSelectFile, children: "x" })] })] })] })));
};
export default React.memo(FileRecordItem);
