import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Divider, Input, Popover } from 'antd';
import { FormulaItemIcon } from 'src/utils/icons/FormulaItemIcon';
import { FormulaMathFunctionList } from './Constant';
import './FormulaBuilder.scss';
var FormulaBuilder = function (_a) {
    var defaultInputValue = _a.defaultInputValue, updateUpperValue = _a.updateUpperValue, inputAddOnAfter = _a.inputAddOnAfter, formulaSchemas = _a.formulaSchemas;
    var _b = useState(false), formulaPopoverVisible = _b[0], setFormulaPopoverVisible = _b[1];
    var inputFocusRef = useRef(false);
    var inputRef = useRef(null);
    var _c = useState(function () {
        return defaultInputValue;
    }), inputValue = _c[0], setInputValue = _c[1];
    var handleInputValueChange = function (e) {
        var newValue = e.target.value;
        setInputValue(newValue);
        if (newValue.startsWith('=') || defaultInputValue.startsWith('=')) {
            setFormulaPopoverVisible(true);
        }
        else {
            setFormulaPopoverVisible(false);
        }
        updateUpperValue(newValue);
    };
    var handleInsertSchemaVariable = useCallback(function (value) {
        var _a;
        var tbValue = "".concat('TB_VAR(' + value + ')');
        var input = (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.input; // Get the underlying input element
        if (input) {
            var start = input.selectionStart;
            var end = input.selectionEnd;
            if (!!start && !!end) {
                var newValue = inputValue.slice(0, start) + tbValue + inputValue.slice(end);
                setInputValue(newValue);
                updateUpperValue(newValue);
                input.focus();
            }
        }
    }, [inputValue, updateUpperValue]);
    var handleInsertMathFunction = useCallback(function (value) {
        var _a;
        var functionValue = "".concat(value + '()');
        var input = (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.input;
        if (input) {
            var start = input.selectionStart;
            var end = input.selectionEnd;
            if (!!start && !!end) {
                var newValue = inputValue.slice(0, start) + functionValue + inputValue.slice(end);
                setInputValue(newValue);
                updateUpperValue(newValue);
                input.focus();
            }
        }
    }, [inputValue, updateUpperValue]);
    var builderContent = useMemo(function () {
        return (_jsxs("div", { className: "py-4 max-h-[400px] overflow-y-auto", children: [_jsxs("div", { className: "px-4", children: [_jsx("div", { className: "font-medium", children: "Mathematical" }), _jsx("div", { children: FormulaMathFunctionList.map(function (item, index) {
                                return (_jsxs("div", { className: "font-medium cursor-pointer hover:bg-gray-50 px-2 py-1 rounded flex items-center gap-1", onClick: function () { return handleInsertMathFunction(item); }, children: [_jsx(FormulaItemIcon, {}), _jsx("div", { children: item })] }, "".concat(item, "+").concat(index)));
                            }) })] }), _jsx(Divider, { className: "m-0" }), _jsxs("div", { className: "px-4  mt-3", children: [_jsx("div", { className: "font-medium", children: "Variables" }), _jsx("div", { children: formulaSchemas.map(function (schema) {
                                if (!schema)
                                    return;
                                return (_jsxs("div", { onClick: function () { return handleInsertSchemaVariable(schema.name); }, className: "font-medium cursor-pointer hover:bg-gray-50 px-2 py-1 rounded flex items-center gap-1", children: [_jsx(FormulaItemIcon, {}), _jsx("div", { children: schema.name })] }, schema.id));
                            }) })] })] }));
    }, [formulaSchemas, handleInsertMathFunction, handleInsertSchemaVariable]);
    return (_jsx(Popover, { open: formulaPopoverVisible, onOpenChange: function (visible) {
            if (!visible) {
                if (!inputFocusRef.current) {
                    setFormulaPopoverVisible(false);
                }
            }
        }, content: builderContent, placement: "bottomLeft", trigger: "click", overlayClassName: "FormulaBuilderPopover", children: _jsx(Input, { className: "flex-1 rounded-md", value: inputValue, onChange: handleInputValueChange, addonAfter: _jsx("div", { className: "", children: inputAddOnAfter }), "data-testid": "PaymentAdjustmentSelectorInput", onFocus: function () {
                inputFocusRef.current = true;
                if (inputValue.startsWith('=')) {
                    setFormulaPopoverVisible(true);
                }
            }, onBlur: function () {
                if (inputFocusRef.current) {
                    inputFocusRef.current = false;
                }
            }, 
            // onInput={handleCursorChange}
            // onKeyUp={(e) => {
            //     if (
            //         e.key === 'ArrowLeft' ||
            //         e.key === 'ArrowRight' ||
            //         e.key === 'ArrowUp' ||
            //         e.key === 'ArrowDown'
            //     ) {
            //         handleCursorChange()
            //     }
            // }}
            // onClick={handleCursorChange}
            ref: inputRef }) }));
};
export default React.memo(FormulaBuilder);
