var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PlusOutlined } from '@ant-design/icons';
import { isEqual, uniqBy, uniqWith } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { useChatComponent } from 'src/hooks/component/useChat';
import { participantsInNewThread, recipientsDropdownOpenState, selectedThreadState, threadSectionExpandedStatusState, threadSummaryListState, } from 'src/state/atoms/chatState';
import { isCreatingThreadSelector } from 'src/state/selectors/withChatState';
import { useChatApi } from 'src/hooks/api/useChatApi';
import { usePaginationWithSearch } from 'src/hooks/component';
import { ThreadType } from '../interface';
import { threadIsBroadcast, threadParticipantsToBroadcastRecipients } from '../utils';
import ChatItem from './ChatItem';
import ThreadSectionExpandActionButton from './ThreadSectionExpandActionButton';
var ChatList = function (_a) {
    var onChatSiderCollapsed = _a.onChatSiderCollapsed;
    var _b = useRecoilState(threadSummaryListState), threadSummaryList = _b[0], setThreadSummaryList = _b[1];
    var setSelectedThread = useSetRecoilState(selectedThreadState);
    var setCreateThreadSelector = useSetRecoilState(isCreatingThreadSelector);
    var setParticipantsInThread = useSetRecoilState(participantsInNewThread);
    var setRecipientsDropdownOpen = useSetRecoilState(recipientsDropdownOpenState);
    var useGetThreadsQuery = useChatApi().useGetThreadsQuery;
    var sortedThread = useChatComponent().sortedThread;
    var broadcasts = useMemo(function () {
        return sortedThread(uniqWith(threadSummaryList.filter(function (thread) { return threadIsBroadcast(thread); }), function (a, b) {
            return isEqual(threadParticipantsToBroadcastRecipients(a.participants), threadParticipantsToBroadcastRecipients(b.participants));
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [threadSummaryList]);
    var directMessages = useMemo(function () {
        return sortedThread(threadSummaryList.filter(function (thread) { return !threadIsBroadcast(thread); }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [threadSummaryList]);
    var resetThread = useCallback(function (threadType) {
        setSelectedThread(undefined);
        setParticipantsInThread(function (prev) { return (__assign(__assign({}, prev), { type: threadType, participants: [] })); });
        setCreateThreadSelector(threadType);
        setRecipientsDropdownOpen(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var isBroadcastSectionExpanded = useRecoilValue(threadSectionExpandedStatusState(ThreadType.BROADCAST));
    var isDMSectionExpanded = useRecoilValue(threadSectionExpandedStatusState(ThreadType.DM));
    // Direct messages pagination
    var _c = usePaginationWithSearch({}), page = _c.page, setIsLoading = _c.setIsLoading, onScroll = _c.onScroll;
    useGetThreadsQuery({
        page: page,
        size: 20,
        options: {
            onSuccess: function (data) {
                if (data) {
                    setThreadSummaryList(function (prev) {
                        return uniqBy(__spreadArray(__spreadArray([], prev, true), data, true), 'id');
                    });
                }
            },
            onSettled: function () {
                setIsLoading(false);
            },
            enabled: page > 0,
        },
    });
    return (_jsxs("div", { className: "ChatSidebarScrollbar", onScroll: onScroll, children: [_jsxs("div", { className: "ChatSidebarGroupName", children: [_jsxs("div", { className: "flex items-center", children: [!!broadcasts.length && (_jsx(ThreadSectionExpandActionButton, { type: ThreadType.BROADCAST })), _jsx("div", { className: "mt-[2px]".concat(broadcasts.length ? '' : ' pl-[24px]'), children: "Broadcasts" })] }), _jsx(ZiraTooltip, { title: "New Broadcast", children: _jsx(PlusOutlined, { className: "rounded-lg p-[4px] hover:bg-gray-60", onClick: function () {
                                resetThread(ThreadType.BROADCAST);
                            } }) })] }), isBroadcastSectionExpanded &&
                broadcasts.map(function (item) { return (_jsx(ChatItem, { threadId: item.id, participants: item.participants, onChatSiderCollapsed: onChatSiderCollapsed }, item.id)); }), _jsxs("div", { className: "ChatSidebarGroupName !border-t-0", children: [_jsxs("div", { className: "flex items-center", children: [!!directMessages.length && (_jsx(ThreadSectionExpandActionButton, { type: ThreadType.DM })), _jsx("div", { className: "mt-[2px]".concat(directMessages.length ? '' : ' pl-[24px]'), children: "Direct Messages" })] }), _jsx(ZiraTooltip, { title: "New Direct Message", children: _jsx(PlusOutlined, { className: "rounded-lg p-[4px] hover:bg-gray-60", onClick: function () {
                                resetThread(ThreadType.DM);
                            } }) })] }), isDMSectionExpanded &&
                (directMessages === null || directMessages === void 0 ? void 0 : directMessages.map(function (item) { return (_jsx(ChatItem, { threadId: item.id, participants: item.participants, onChatSiderCollapsed: onChatSiderCollapsed }, item.id)); }))] }));
};
export default ChatList;
