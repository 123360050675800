var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var ZipFileIconSvg = function () { return (_jsxs("svg", { width: "20", height: "21", viewBox: "0 0 20 21", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsxs("g", { clipPath: "url(#clip0_27333_11504)", children: [_jsx("path", { d: "M11.6666 3V6.33333C11.6666 6.55435 11.7544 6.76631 11.9107 6.92259C12.067 7.07887 12.2789 7.16667 12.5 7.16667H15.8333", stroke: "#717177", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M4.16663 10.5V4.66667C4.16663 4.22464 4.34222 3.80072 4.65478 3.48816C4.96734 3.17559 5.39127 3 5.83329 3H11.6666L15.8333 7.16667V10.5", stroke: "#717177", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M13.3334 15.5H14.5834C14.9149 15.5 15.2328 15.3683 15.4673 15.1339C15.7017 14.8995 15.8334 14.5815 15.8334 14.25C15.8334 13.9185 15.7017 13.6005 15.4673 13.3661C15.2328 13.1317 14.9149 13 14.5834 13H13.3334V18", stroke: "#717177", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M10 13V18", stroke: "#717177", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M4.16663 13H6.66663L4.16663 18H6.66663", stroke: "#717177", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_27333_11504", children: _jsx("rect", { width: "20", height: "20", fill: "white", transform: "translate(0 0.5)" }) }) })] })); };
export var ZipFileIcon = function (props) { return (_jsx(Icon, __assign({ component: ZipFileIconSvg }, props))); };
