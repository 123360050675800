var _a, _b;
import { jsx as _jsx } from "react/jsx-runtime";
import { DataUpdateIcon } from 'src/utils/icons/DashboardIcons/AutomationIcons/DataUpdateIcon';
import { EmailIcon } from 'src/utils/icons/DashboardIcons/AutomationIcons/EmailIcon';
import { InAppMsgIcon } from 'src/utils/icons/DashboardIcons/AutomationIcons/InAppMsgIcon';
import { SMSIcon } from 'src/utils/icons/DashboardIcons/AutomationIcons/SMSIcon';
import { ShiftRequestMetricIcon } from 'src/utils/icons/DashboardIcons/MetricsIcons/ShiftRequestMetricIcon';
import { OpenShiftsMetricIcon } from 'src/utils/icons/DashboardIcons/MetricsIcons/OpenShiftsMetricIcon';
import { TimeSheetMetricIcon } from 'src/utils/icons/DashboardIcons/MetricsIcons/TimeSheetMetricIcon';
export var defaultSnapshotOptionSetting = {
    LATE: false,
    NO_SHOW: true,
    UPCOMING: true,
    ONGOING: true,
    COMPLETED: true,
};
export var ScheduleSnapshotStatus;
(function (ScheduleSnapshotStatus) {
    ScheduleSnapshotStatus["UPCOMING"] = "UPCOMING";
    ScheduleSnapshotStatus["ONGOING"] = "ONGOING";
    ScheduleSnapshotStatus["COMPLETED"] = "COMPLETED";
    ScheduleSnapshotStatus["LATE"] = "LATE";
    ScheduleSnapshotStatus["NO_SHOW"] = "NO_SHOW";
})(ScheduleSnapshotStatus || (ScheduleSnapshotStatus = {}));
export var schedulingSnapshotStatusInfoMap = (_a = {},
    _a[ScheduleSnapshotStatus.UPCOMING] = {
        displayStatusName: 'Upcoming',
        headerColor: '#6F6F6F',
        cardColor: '',
    },
    _a[ScheduleSnapshotStatus.ONGOING] = {
        displayStatusName: 'Ongoing',
        headerColor: '#167EE4',
        cardColor: '#E8F2FC',
    },
    _a[ScheduleSnapshotStatus.COMPLETED] = {
        displayStatusName: 'Completed',
        headerColor: '#00A76F',
        cardColor: '#E5F4EF',
    },
    _a[ScheduleSnapshotStatus.LATE] = {
        displayStatusName: 'Late',
        headerColor: '#FFC047',
        cardColor: '#FFF9ED',
    },
    _a[ScheduleSnapshotStatus.NO_SHOW] = {
        displayStatusName: 'No Show',
        headerColor: '#FF4A43',
        cardColor: '#FFEDEC',
    },
    _a);
export var OnboardingProgressListItemType;
(function (OnboardingProgressListItemType) {
    OnboardingProgressListItemType["GENERAL"] = "GENERAL";
    OnboardingProgressListItemType["USER"] = "USER";
    OnboardingProgressListItemType["SHIFT"] = "SHIFT";
    OnboardingProgressListItemType["AUTOMATION"] = "AUTOMATION";
})(OnboardingProgressListItemType || (OnboardingProgressListItemType = {}));
export var DashboardMetricType;
(function (DashboardMetricType) {
    DashboardMetricType["SHIFT_REQUESTS"] = "shiftRequests";
    DashboardMetricType["OPEN_SHIFTS"] = "openShifts";
    DashboardMetricType["LATEST_TIMESHEET"] = "latestTimeSheet";
    DashboardMetricType["LATEST_BILL_AMOUNT"] = "latestBillAmount";
    DashboardMetricType["TIMESHEET_DATE_RANGE"] = "timeSheetDateRange";
    DashboardMetricType["CURRENT_TIMESHEET"] = "currentTimeSheet";
    DashboardMetricType["CURRENT_BILL_AMOUNT"] = "currentBillAmount";
    DashboardMetricType["CURRENT_DATE_RANGE"] = "currentDateRange";
})(DashboardMetricType || (DashboardMetricType = {}));
export var DashboardAutomationType;
(function (DashboardAutomationType) {
    DashboardAutomationType["EMAIL"] = "EMAIL";
    DashboardAutomationType["SMS"] = "SMS";
    DashboardAutomationType["IN_APP_MSG"] = "IN_APP_MSG";
    DashboardAutomationType["DATA_UPDATED"] = "DATA_UPDATED";
})(DashboardAutomationType || (DashboardAutomationType = {}));
export var dashboardAutomationSummaryInfoMap = (_b = {},
    _b[DashboardAutomationType.EMAIL] = {
        displayName: 'Email Sent',
        icon: _jsx(EmailIcon, {}),
    },
    _b[DashboardAutomationType.SMS] = {
        displayName: 'SMS Sent',
        icon: _jsx(SMSIcon, {}),
    },
    _b[DashboardAutomationType.IN_APP_MSG] = {
        displayName: 'In App Messages Sent',
        icon: _jsx(InAppMsgIcon, {}),
    },
    _b[DashboardAutomationType.DATA_UPDATED] = {
        displayName: 'Data Updated',
        icon: _jsx(DataUpdateIcon, {}),
    },
    _b);
export var shiftAlertTableTimeRanges = [
    {
        label: 'Yesterday',
        key: 'yesterday',
    },
    {
        label: 'Today',
        key: 'today',
    },
    {
        label: 'This Week',
        key: 'this_week',
    },
    {
        label: 'This Month',
        key: 'this_month',
    },
    {
        label: 'This Year',
        key: 'this_year',
    },
    {
        label: 'Last Pay Period',
        key: 'last_pay_period',
    },
    {
        label: 'Last 7 Days',
        key: 'last_seven_days',
    },
    {
        label: 'Last 14 Days',
        key: 'last_fourteen_days',
    },
    {
        label: 'Last 30 Days',
        key: 'last_thirty_days',
    },
    {
        label: 'Last Week',
        key: 'last_week',
    },
    {
        label: 'Last Month',
        key: 'last_month',
    },
    {
        label: 'Last Year',
        key: 'last_year',
    },
    {
        label: 'Week to Date',
        key: 'week_to_date',
    },
    {
        label: 'Month to Date',
        key: 'month_to_date',
    },
    {
        label: 'Year to Date',
        key: 'year_to_date',
    },
    {
        label: 'Before Today',
        key: 'before_today',
    },
    {
        label: 'Current Pay Period',
        key: 'current_pay_period',
    },
];
export var DashboardTableColumnType;
(function (DashboardTableColumnType) {
    DashboardTableColumnType["TEXT"] = "TEXT";
    DashboardTableColumnType["TAGS"] = "TAGS";
    DashboardTableColumnType["USER"] = "USER";
})(DashboardTableColumnType || (DashboardTableColumnType = {}));
export var dashboardMetricsInfoMap = [
    {
        displayName: 'Shift Requests',
        icon: _jsx(ShiftRequestMetricIcon, {}),
        dateRange: 'NONE',
        dataKey: DashboardMetricType.SHIFT_REQUESTS,
    },
    {
        displayName: 'Open Shifts',
        icon: _jsx(OpenShiftsMetricIcon, {}),
        dateRange: 'NONE',
        dataKey: DashboardMetricType.OPEN_SHIFTS,
    },
    {
        displayName: 'Last Payroll Amount',
        icon: _jsx(TimeSheetMetricIcon, {}),
        dateRange: 'LATEST',
        dataKey: DashboardMetricType.LATEST_TIMESHEET,
    },
    {
        displayName: 'Current Payroll Amount',
        icon: _jsx(TimeSheetMetricIcon, {}),
        dateRange: 'CURRENT',
        dataKey: DashboardMetricType.CURRENT_TIMESHEET,
    },
    {
        displayName: 'Last Billed Amount',
        icon: _jsx(TimeSheetMetricIcon, {}),
        dateRange: 'LATEST',
        dataKey: DashboardMetricType.LATEST_BILL_AMOUNT,
    },
    {
        displayName: 'Current Billed Amount',
        icon: _jsx(TimeSheetMetricIcon, {}),
        dateRange: 'CURRENT',
        dataKey: DashboardMetricType.CURRENT_BILL_AMOUNT,
    },
];
