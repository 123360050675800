var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useAuth0 } from '@auth0/auth0-react';
import { produce } from 'immer';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { accountAccessGroupDetailsState, accountIdSelector } from 'src/state';
import { createContainer } from 'unstated-next';
import { ActionType, ObjectPermission } from '../../shared';
import octoClient from '../../services/octoClient';
import { useSetting } from '../api';
var useSettings = function () {
    var accountId = useRecoilValue(accountIdSelector);
    var getAccessTokenSilently = useAuth0().getAccessTokenSilently;
    var _a = useSetting(), getAccountAccessData = _a.getAccountAccessDataFetch, getAccessGroupDetailApi = _a.useGetAccessGroupDetail, useCreateAccessGroup = _a.useCreateAccessGroup, useDuplicateAccessGroup = _a.useDuplicateAccessGroup, useDeleteAccessGroup = _a.useDeleteAccessGroup, useUpdateAccessGroupMutation = _a.useUpdateAccessGroupMutation;
    //permision related states
    var _b = useState(true), loadingAccountAccessGroups = _b[0], setLoadingAccountAccessGroups = _b[1];
    var _c = useState([]), accountAccessGroups = _c[0], setAccountAccessGroups = _c[1];
    var _d = useState(), accessGroupDetail = _d[0], setAccessGroupDetail = _d[1];
    var _e = useState(false), newGroup = _e[0], setNewGroup = _e[1];
    var _f = useState([]), activeFilters = _f[0], setActiveFilters = _f[1];
    var _g = useState([]), inactiveFilters = _g[0], setInactiveFilters = _g[1];
    var pageRef = useRef(null);
    var setAccountAccessGroupDetails = useSetRecoilState(accountAccessGroupDetailsState);
    // Sync account access groups
    useEffect(function () {
        if (accountAccessGroups === null || accountAccessGroups === void 0 ? void 0 : accountAccessGroups.length) {
            setAccountAccessGroupDetails(accountAccessGroups);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountAccessGroups]);
    //permission related functions
    var getAccountAccessGroups = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var accessData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!accountId)
                        return [2 /*return*/];
                    setLoadingAccountAccessGroups(true);
                    return [4 /*yield*/, getAccountAccessData()];
                case 1:
                    accessData = _a.sent();
                    setLoadingAccountAccessGroups(false);
                    if (accessData) {
                        setAccountAccessGroups(accessData.accessGroups);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [accountId, getAccessTokenSilently]);
    var selectNewFilter = useCallback(function (value, index) {
        var newActiveFilters = __spreadArray([], activeFilters, true);
        var addedFilter = inactiveFilters.find(function (filter) { return filter.id == value; });
        if (!addedFilter)
            return;
        newActiveFilters[index] = addedFilter;
        setActiveFilters(newActiveFilters);
        setInactiveFilters(function (prev) { return prev.filter(function (filter) { return filter.id != value; }); });
    }, [activeFilters, inactiveFilters]);
    var getAccessGroupDetail = useCallback(function (accessGroupId_1) {
        var args_1 = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args_1[_i - 1] = arguments[_i];
        }
        return __awaiter(void 0, __spreadArray([accessGroupId_1], args_1, true), void 0, function (accessGroupId, newGroup) {
            var resp;
            if (newGroup === void 0) { newGroup = false; }
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getAccessGroupDetailApi(accessGroupId)];
                    case 1:
                        resp = _a.sent();
                        if (resp) {
                            setNewGroup(newGroup);
                            setAccessGroupDetail(resp);
                        }
                        return [2 /*return*/];
                }
            });
        });
    }, [getAccessGroupDetailApi]);
    var goBackToAccessGroupOverview = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAccountAccessGroups()];
                case 1:
                    _a.sent();
                    setAccessGroupDetail(undefined);
                    return [2 /*return*/];
            }
        });
    }); }, [getAccountAccessGroups]);
    var createAccessGroup = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            useCreateAccessGroup.mutate("Access Group ".concat(accountAccessGroups.length + 1), {
                onSuccess: function (resp) {
                    if (resp) {
                        getAccessGroupDetail(resp.id, true);
                    }
                },
            });
            return [2 /*return*/];
        });
    }); }, [accountAccessGroups.length, getAccessGroupDetail, useCreateAccessGroup]);
    var duplicateAccessGroup = useCallback(function (accessGroupId) {
        useDuplicateAccessGroup.mutate(accessGroupId, {
            onSuccess: function () {
                getAccountAccessGroups();
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var deleteAccessGroup = useCallback(function (accessGroupId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, useDeleteAccessGroup.mutateAsync(accessGroupId)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, getAccountAccessGroups()];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [useDeleteAccessGroup, getAccountAccessGroups]);
    var updateAccessGroupMutation = useUpdateAccessGroupMutation().mutateAsync;
    var handleGeneralPermissionChange = useCallback(function (id, checked, linkedPermissionIds //Also set all these to ${checked} value
    ) { return __awaiter(void 0, void 0, void 0, function () {
        var linkedIdsObject;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!accessGroupDetail)
                        return [2 /*return*/];
                    linkedIdsObject = linkedPermissionIds
                        ? linkedPermissionIds.reduce(function (acc, setId) {
                            acc[setId] = checked;
                            return acc;
                        }, {})
                        : {};
                    return [4 /*yield*/, updateAccessGroupMutation({
                            accessGroupId: accessGroupDetail.id,
                            generalPermissions: __assign(__assign(__assign({}, accessGroupDetail.generalPermissions), (_a = {}, _a[id] = checked, _a)), linkedIdsObject),
                        }, {
                            onSuccess: function () {
                                var newAccessGroupDetail = produce(accessGroupDetail, function (draft) {
                                    draft.generalPermissions[id] = checked;
                                });
                                setAccessGroupDetail(newAccessGroupDetail);
                            },
                        })];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [accessGroupDetail, updateAccessGroupMutation]);
    var getObjectPermission = useCallback(function (obj) {
        var writeAccess = obj.permissions.find(function (permission) { return permission.permission == ObjectPermission.WRITE; }).hasAccess;
        if (writeAccess)
            return 'edit';
        var readAccess = obj.permissions.find(function (permission) { return permission.permission == ObjectPermission.READ; }).hasAccess;
        if (readAccess) {
            return 'view';
        }
        else {
            return 'hide';
        }
    }, []);
    var updateAccessGroupName = useCallback(function (name) {
        if (!accessGroupDetail)
            return;
        var nextState = produce(accessGroupDetail, function (draft) {
            draft.name = name;
        });
        setAccessGroupDetail(nextState);
    }, [accessGroupDetail]);
    var removePermission = useCallback(function (permissionId) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    if (!accessGroupDetail)
                        return [2 /*return*/];
                    _b = (_a = octoClient).updatePermissionToAccessGroup;
                    _c = [accessGroupDetail.id,
                        permissionId,
                        ActionType.REMOVE];
                    return [4 /*yield*/, getAccessTokenSilently()];
                case 1: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                case 2:
                    _d.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [accessGroupDetail, getAccessTokenSilently]);
    var removeObjPermission = useCallback(function (objWithPermission, objPermission) { return __awaiter(void 0, void 0, void 0, function () {
        var permissionId;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    permissionId = objWithPermission.permissions.find(function (item) { return item.permission == objPermission; }).permissionId;
                    return [4 /*yield*/, removePermission(permissionId)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [removePermission]);
    var addPermission = useCallback(function (permissionId) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    if (!accessGroupDetail)
                        return [2 /*return*/];
                    _b = (_a = octoClient).updatePermissionToAccessGroup;
                    _c = [accessGroupDetail.id,
                        permissionId,
                        ActionType.ADD];
                    return [4 /*yield*/, getAccessTokenSilently()];
                case 1: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                case 2:
                    _d.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [accessGroupDetail, getAccessTokenSilently]);
    var addObjPermission = useCallback(function (objWithPermission, objPermission) { return __awaiter(void 0, void 0, void 0, function () {
        var permissionId;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    permissionId = objWithPermission.permissions.find(function (item) { return item.permission == objPermission; }).permissionId;
                    return [4 /*yield*/, addPermission(permissionId)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [addPermission]);
    var handleToggleObjectPermission = useCallback(function (checked, item) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!checked) return [3 /*break*/, 2];
                    return [4 /*yield*/, Promise.all([
                            addObjPermission(item, ObjectPermission.READ),
                            addObjPermission(item, ObjectPermission.WRITE),
                        ])];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, Promise.all([
                        removeObjPermission(item, ObjectPermission.READ),
                        removeObjPermission(item, ObjectPermission.WRITE),
                    ])];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); }, [addObjPermission, removeObjPermission]);
    useEffect(function () {
        getAccountAccessGroups();
    }, [getAccountAccessGroups]);
    return {
        pageRef: pageRef,
        newGroup: newGroup,
        activeFilters: activeFilters,
        inactiveFilters: inactiveFilters,
        selectNewFilter: selectNewFilter,
        loadingAccountAccessGroups: loadingAccountAccessGroups,
        getAccountAccessGroups: getAccountAccessGroups,
        accountAccessGroups: accountAccessGroups,
        createAccessGroup: createAccessGroup,
        duplicateAccessGroup: duplicateAccessGroup,
        deleteAccessGroup: deleteAccessGroup,
        handleGeneralPermissionChange: handleGeneralPermissionChange,
        getObjectPermission: getObjectPermission,
        getAccessGroupDetail: getAccessGroupDetail,
        updateAccessGroupName: updateAccessGroupName,
        goBackToAccessGroupOverview: goBackToAccessGroupOverview,
        addPermission: addPermission,
        removePermission: removePermission,
        addObjPermission: addObjPermission,
        removeObjPermission: removeObjPermission,
        handleToggleObjectPermission: handleToggleObjectPermission,
        accessGroupDetail: accessGroupDetail,
        setAccessGroupDetail: setAccessGroupDetail,
    };
};
var SettingsState = createContainer(useSettings);
export default SettingsState;
