import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { EllipsisOutlined, EyeOutlined } from '@ant-design/icons';
import { Space, Divider, Menu } from 'antd';
import { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { useRecordDetail } from 'src/hooks/component';
import { currentUserRecordIdSelector } from 'src/state/selectors/withAccountDetailState';
import ZiraDropdown from 'src/ui/ZiraDropdown';
import { GeneralPermissionKey } from 'src/components/Settings/Permission/Constants';
import { ifUserHasGeneralPermission } from 'src/state';
import HardConfirmWrapper from 'src/components/HardConfirmWrapper/HardConfirmWrapper';
import { getMessageTimeStamp } from '../../utils';
import MessageAttachmentItem from './MessageAttachmentItem';
import MessageItemUserAvatar from './MessageItemUserAvatar';
import ChatUserListModal from './ChatUserListModal';
var BroadcastMessageItem = function (_a) {
    var _b, _c;
    var message = _a.message, deleteMessage = _a.deleteMessage;
    var currentUserRId = useRecoilValue(currentUserRecordIdSelector);
    var hasDeleteBroadcastPermission = useRecoilValue(ifUserHasGeneralPermission(GeneralPermissionKey.DELETE_BROADCAST));
    var isBroadcast = ((_b = message.departmentSenderDetail) === null || _b === void 0 ? void 0 : _b.id) !== null;
    var showDeleteButton = hasDeleteBroadcastPermission && isBroadcast;
    var openRecordDetail = useRecordDetail().openRecordDetail;
    // Filter out current user/sender
    var messageViewedUserList = useMemo(function () { var _a; return (_a = message.readBy) === null || _a === void 0 ? void 0 : _a.filter(function (user) { return user.id !== currentUserRId; }); }, [currentUserRId, message.readBy]);
    var _d = useState(false), isDropdownVisible = _d[0], setIsDropdownVisible = _d[1];
    var menu = (_jsx(Menu, { children: _jsx(HardConfirmWrapper, { changeType: "delete", entityName: "broadcast message", onConfirm: function () {
                deleteMessage(message.id);
            }, children: _jsx(Menu.Item, { children: _jsx("span", { className: "text-red-1000", children: "Delete" }) }, "delete") }) }));
    return (_jsxs("div", { className: "BroadcastMessageItem shadow-block", children: [_jsxs(Space, { className: "BroadcastMessageItemHeader", children: [_jsx(MessageItemUserAvatar, { message: message }), _jsxs("div", { className: "BroadcastMessageItemHeaderLabel", children: [_jsxs(Space, { className: "BroadcastMessageItemHeaderLabelSender", children: [_jsx(ZiraTooltip, { title: "View Details", children: _jsx("span", { className: "cursor-pointer hover:underline", onClick: function () { return openRecordDetail(message.senderDetail.id); }, children: message.senderDetail.name }) }), message.departmentSenderDetail && (_jsx("div", { className: "ChatDepartmentTag", children: (_c = message.departmentSenderDetail) === null || _c === void 0 ? void 0 : _c.name }))] }), _jsx("div", { className: "BroadcastMessageItemTime", children: getMessageTimeStamp(message.createdAt) })] })] }), _jsx(Divider, { className: "Divider" }), _jsx("div", { className: "BroadcastMessageItemContent", children: message.body.text }), message.body.attachments.map(function (attachment, index) {
                return _jsx(MessageAttachmentItem, { attachment: attachment }, "attachment-".concat(index));
            }), _jsx(Divider, { className: "Divider" }), _jsxs("div", { className: "flex items-center", children: [_jsx("div", { className: "BroadcastMessageItemDeliverySummary", children: "Delivered" }), _jsx("div", { className: "ml-auto flex justify-center items-center", children: messageViewedUserList && messageViewedUserList.length > 0 && (_jsx(ChatUserListModal, { defaultUserList: messageViewedUserList, showReadStatus: true, children: _jsx(ZiraTooltip, { title: "Open Viewed List", placement: "bottomRight", children: _jsxs("div", { className: "BroadcastMessageItemDeliverySummary cursor-pointer", children: [_jsx(EyeOutlined, { className: "pb-[2px] pr-[4px]" }), _jsxs("span", { className: "underline", children: [messageViewedUserList.length, " View", messageViewedUserList.length > 1 ? 's' : ''] })] }) }) })) }), showDeleteButton && (_jsx("div", { className: "ml-2", children: _jsx(ZiraDropdown, { placement: "bottomRight", overlay: menu, trigger: ['click'], overlayStyle: { minWidth: '200px' }, open: isDropdownVisible, onLocalVisibleChange: setIsDropdownVisible, children: _jsx(EllipsisOutlined, { className: "EllipsisIcon" }) }) }))] })] }));
};
export default BroadcastMessageItem;
