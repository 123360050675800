import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Select } from 'antd';
import { getIpDisplayText } from 'src/utils/Account';
import { TEAMBRIDGE_TITLE } from 'src/shared';
import { SourceOfTruth, SyncType } from '../../Interface';
import { formatSnakeCase } from '../../utils';
var CollectionConfiguration = function (_a) {
    var ipName = _a.ipName, syncType = _a.syncType, setSyncType = _a.setSyncType, supportedTypes = _a.supportedTypes, sourceOfTruth = _a.sourceOfTruth, setSourceOfTruth = _a.setSourceOfTruth, supportedSourcesOfTruth = _a.supportedSourcesOfTruth;
    return (_jsxs("div", { className: "mx-4 mb-4 p-4 bg-gray-50 border-gray-100 rounded-lg", children: [_jsx("h6", { className: "text-md font-semibold mb-3", children: "Configuration" }), _jsxs("div", { className: "flex flex-col gap-4", children: [_jsxs("div", { className: "flex items-center justify-between", children: [_jsxs("div", { className: "flex flex-col", children: [_jsx("span", { className: "text-md font-medium", children: "Sync Type" }), _jsxs("span", { className: "text-xs font-medium text-gray-500", children: ["Specify the data flow between ", getIpDisplayText(ipName), " and TeamBridge"] })] }), _jsx(Select, { value: syncType, onChange: setSyncType, children: supportedTypes === null || supportedTypes === void 0 ? void 0 : supportedTypes.map(function (type) {
                                    return (_jsx(Select.Option, { value: type, children: formatSnakeCase(type) }, type));
                                }) })] }), syncType === SyncType.ONE_WAY && (_jsxs("div", { className: "flex items-center justify-between", children: [_jsxs("div", { className: "flex flex-col", children: [_jsx("span", { className: "text-md font-medium", children: "One way" }), _jsx("span", { className: "text-xs font-medium text-gray-500", children: "Specify the direction of the data flow" })] }), _jsxs(Select, { value: sourceOfTruth, onChange: setSourceOfTruth, getPopupContainer: function (triggerNode) { return triggerNode.parentNode; }, children: [(supportedSourcesOfTruth === null || supportedSourcesOfTruth === void 0 ? void 0 : supportedSourcesOfTruth.includes(SourceOfTruth.IP)) && (_jsxs(Select.Option, { value: SourceOfTruth.IP, children: ["Import from ", getIpDisplayText(ipName)] })), (supportedSourcesOfTruth === null || supportedSourcesOfTruth === void 0 ? void 0 : supportedSourcesOfTruth.includes(SourceOfTruth.ZIRA)) && (_jsxs(Select.Option, { value: SourceOfTruth.ZIRA, children: ["Export to ", getIpDisplayText(ipName)] }))] })] })), syncType === SyncType.TWO_WAY && (_jsxs(_Fragment, { children: [_jsx("div", { className: "IntegrationSubTitle", children: "Two way: in case of a conflict, we should keep" }), _jsxs(Select, { value: sourceOfTruth, onChange: setSourceOfTruth, getPopupContainer: function (triggerNode) { return triggerNode.parentNode; }, children: [(supportedSourcesOfTruth === null || supportedSourcesOfTruth === void 0 ? void 0 : supportedSourcesOfTruth.includes(SourceOfTruth.IP)) && (_jsxs(Select.Option, { value: SourceOfTruth.IP, children: [ipName, "'s version"] })), (supportedSourcesOfTruth === null || supportedSourcesOfTruth === void 0 ? void 0 : supportedSourcesOfTruth.includes(SourceOfTruth.ZIRA)) && (_jsxs(Select.Option, { value: SourceOfTruth.ZIRA, children: [TEAMBRIDGE_TITLE, "'s version"] }))] })] }))] })] }));
};
export default CollectionConfiguration;
