var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import { getMutateSchemaProperties } from 'src/services';
import { accountIdSelector, tableCollectionIdSelector, collectionTableSchemaNativePathMapSelector, } from 'src/state';
import { draggingTemplateState, scheduleTemplateState, scheduleTimeRangeState, templateIsDraggingState, } from 'src/state/atoms/schedule';
import { v4 as uuidv4 } from 'uuid';
import { scheduleGroupBySelector, scheduleTemplateFilterSelector, scheduleTimeZoneSelector, } from 'src/state/selectors/schedule';
import { RecordSchemaType, NativePath, ScheduleGroupBy } from '../../shared';
import { useScheduleTemplateApi } from '../api/useScheduleTemplateApi';
import { useRefetchCollection } from './useRefetchCollection';
import { useScheduleComponent } from './useSchedule';
var useTemplate = function (tableId) {
    var accountId = useRecoilValue(accountIdSelector);
    var refetchCollection = useRefetchCollection();
    var locationFilter = useRecoilValue(scheduleTemplateFilterSelector(tableId));
    var collectionId = useRecoilValue(tableCollectionIdSelector(tableId));
    var scheduleNativeSchemaMap = useRecoilValue(collectionTableSchemaNativePathMapSelector(collectionId));
    var groupBy = useRecoilValue(scheduleGroupBySelector(tableId));
    var timeRange = useRecoilValue(scheduleTimeRangeState(tableId));
    var blockTimeZone = useRecoilValue(scheduleTimeZoneSelector(tableId));
    var _a = useRecoilState(scheduleTemplateState(locationFilter)), templateList = _a[0], setTemplateList = _a[1];
    var applyScheduleDisplaySettings = useScheduleComponent().applyScheduleDisplaySettings;
    var isInRecordDetail = new URLSearchParams(location.search).has('rid');
    var saveTemplateLocationFilter = useCallback(function (templateLocationFilter) {
        applyScheduleDisplaySettings(tableId, { templateFilter: templateLocationFilter }, isInRecordDetail ? 'reverseCollection' : 'block');
    }, [applyScheduleDisplaySettings, isInRecordDetail, tableId]);
    var queryClient = useQueryClient();
    var refetchTemplates = useCallback(function () {
        queryClient.invalidateQueries('templateList');
    }, [queryClient]);
    var _b = useScheduleTemplateApi(), useFetchScheduleTemplateApply = _b.useFetchScheduleTemplateApply, useFetchScheduleTemplateSave = _b.useFetchScheduleTemplateSave, useFetchScheduleTemplateDelete = _b.useFetchScheduleTemplateDelete, useFetchScheduleTemplateEdit = _b.useFetchScheduleTemplateEdit;
    var fetchTemplateApply = useFetchScheduleTemplateApply();
    var fetchTemplateSave = useFetchScheduleTemplateSave();
    var fetchTemplateEdit = useFetchScheduleTemplateEdit();
    var fetchTemplateDelete = useFetchScheduleTemplateDelete();
    /**
     * drag and drop actions
     */
    var _c = useRecoilState(templateIsDraggingState), isDraggingTemplate = _c[0], setIsDraggingTemplate = _c[1];
    var _d = useRecoilState(draggingTemplateState), draggingTemplate = _d[0], setDraggingTemplate = _d[1];
    var _e = useState(undefined), overPosition = _e[0], setOverPosition = _e[1];
    var overPositionRef = useRef(overPosition);
    useEffect(function () {
        overPositionRef.current = overPosition;
    }, [overPosition]);
    var moveTemplate = useCallback(function (toX, toY) {
        var tempOverPosition = {
            recordIndex: toX,
            columnIndex: toY,
        };
        setOverPosition(tempOverPosition);
    }, []);
    var canMoveTemplate = useCallback(function (toX, toY) {
        var tempOverPosition = cloneDeep(overPositionRef.current);
        if (!tempOverPosition)
            return false;
        var columnIndex = tempOverPosition.columnIndex;
        var dy = toY - columnIndex;
        return dy >= 0 && dy < 3;
    }, []);
    /**
     * Api call
     */
    var saveTemplate = useCallback(function (name, shifts, rangeStart, timezone, includedSchemaIds) { return __awaiter(void 0, void 0, void 0, function () {
        var newTemplateId, request;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (shifts.length == 0)
                        return [2 /*return*/];
                    newTemplateId = uuidv4();
                    request = {
                        proposedId: newTemplateId,
                        accountId: accountId,
                        name: name,
                        shifts: shifts,
                        rangeStart: rangeStart,
                        timezone: timezone,
                        includedSchemaIds: includedSchemaIds,
                    };
                    return [4 /*yield*/, fetchTemplateSave.mutateAsync(request, {
                            onSuccess: function () {
                                refetchTemplates();
                            },
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId, getMutateSchemaProperties, templateList]);
    var applyTemplate = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (templateId, toColKey, tableId, recordId) { return __awaiter(void 0, void 0, void 0, function () {
            var release, droppedDate, request, currentGroupBy, nativePath, nativeSchemaId, recordSchemaType;
            var _a;
            var _b;
            return __generator(this, function (_c) {
                release = snapshot.retain();
                try {
                    droppedDate = toColKey;
                    request = {
                        accountId: accountId,
                        templateId: templateId,
                        applyToDate: droppedDate,
                        applyToZone: blockTimeZone,
                        schemaProperties: [],
                    };
                    if (recordId) {
                        currentGroupBy = groupBy;
                        nativePath = currentGroupBy == ScheduleGroupBy.USER
                            ? NativePath.SHIFT_USER_ID
                            : currentGroupBy == ScheduleGroupBy.ROLE
                                ? NativePath.SHIFT_ROLE_IDS
                                : NativePath.SHIFT_LOCATION_ID;
                        nativeSchemaId = (_b = scheduleNativeSchemaMap[nativePath]) === null || _b === void 0 ? void 0 : _b.id;
                        if (!nativeSchemaId)
                            return [2 /*return*/];
                        recordSchemaType = currentGroupBy == ScheduleGroupBy.ROLE
                            ? RecordSchemaType.UUIDS
                            : RecordSchemaType.UUID;
                        request.schemaProperties.push((_a = {
                                schemaId: nativeSchemaId,
                                type: recordSchemaType
                            },
                            _a[recordSchemaType] = recordSchemaType === RecordSchemaType.UUIDS ? [recordId] : recordId,
                            _a));
                    }
                    fetchTemplateApply.mutate(request, {
                        onSuccess: function () {
                            var shiftCollectionId = snapshot
                                .getLoadable(tableCollectionIdSelector(tableId))
                                .valueMaybe();
                            if (shiftCollectionId) {
                                refetchCollection(shiftCollectionId);
                            }
                        },
                    });
                }
                finally {
                    release();
                }
                return [2 /*return*/];
            });
        }); };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId, groupBy, refetchCollection, scheduleNativeSchemaMap, timeRange, blockTimeZone]);
    var deleteTemplate = useCallback(function (templateId) {
        fetchTemplateDelete.mutate({ accountId: accountId, templateId: templateId }, {
            onSuccess: function () {
                refetchTemplates();
            },
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId, refetchTemplates]);
    var editTemplate = useCallback(function (templateId, name) {
        var request = {
            accountId: accountId,
            templateId: templateId,
            name: name,
        };
        fetchTemplateEdit.mutate(request, {
            onSuccess: function () {
                refetchTemplates();
            },
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId, refetchTemplates]);
    return {
        templateList: templateList,
        setTemplateList: setTemplateList,
        isDraggingTemplate: isDraggingTemplate,
        setIsDraggingTemplate: setIsDraggingTemplate,
        draggingTemplate: draggingTemplate,
        setDraggingTemplate: setDraggingTemplate,
        overPosition: overPosition,
        moveTemplate: moveTemplate,
        canMoveTemplate: canMoveTemplate,
        applyTemplate: applyTemplate,
        saveTemplate: saveTemplate,
        deleteTemplate: deleteTemplate,
        editTemplate: editTemplate,
        locationFilter: locationFilter,
        saveTemplateLocationFilter: saveTemplateLocationFilter,
    };
};
export { useTemplate as useTemplateComponent };
