var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo, useRef, useState } from 'react';
import './CommentFeed.scss';
import { useCommentApi } from 'src/hooks/api/useCommentApi';
import ZiraDeletePopconfirm from 'src/ui/ZiraDeletePopconfirm';
import { DEFAULT_COMMENT_PAGE_SIZE } from 'src/shared';
import { usePaginationWithSearch } from 'src/hooks/component';
import CommentInput from './CommentInput';
import Comment from './Comment';
import CommentFeedEmptyState from './CommentFeedEmptyState';
var RecordCommentFeed = function (_a) {
    var recordId = _a.recordId;
    var _b = useCommentApi(), useGetRecordComments = _b.useGetRecordComments, createCommentRequest = _b.createCommentRequest, deleteRecordComment = _b.deleteRecordComment;
    var _c = useState([]), recordComments = _c[0], setRecordComments = _c[1];
    var bottomRef = useRef(null);
    var _d = usePaginationWithSearch({}), page = _d.page, canLoadMore = _d.canLoadMore, setCanLoadMore = _d.setCanLoadMore, onScroll = _d.onScroll, setIsLoading = _d.setIsLoading;
    var charLimit = 3000;
    useGetRecordComments({
        recordId: recordId,
        pagination: {
            page: page,
            size: DEFAULT_COMMENT_PAGE_SIZE,
        },
    }, {
        onSuccess: function (data) {
            if (data && page === 0) {
                setRecordComments(data.commentAndAuthorView);
            }
            else if (data && page > 0) {
                setRecordComments(function (prev) { return __spreadArray(__spreadArray([], prev, true), data.commentAndAuthorView, true); });
                if (data.commentAndAuthorView.length === 0) {
                    setCanLoadMore(false);
                }
            }
        },
        onSettled: function () {
            setIsLoading(false);
        },
    });
    var _e = useState(''), commentValue = _e[0], setCommentValue = _e[1];
    var _f = useState(false), isError = _f[0], setIsError = _f[1];
    var onSendComment = function () { return __awaiter(void 0, void 0, void 0, function () {
        var request, newComment;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!commentValue) {
                        return [2 /*return*/];
                    }
                    else if (commentValue.length + 1 > charLimit) {
                        setIsError(true);
                        return [2 /*return*/];
                    }
                    request = {
                        body: { text: commentValue },
                        recordId: recordId,
                    };
                    return [4 /*yield*/, createCommentRequest(request)];
                case 1:
                    newComment = _a.sent();
                    if (newComment) {
                        setCommentValue('');
                        if (canLoadMore || recordComments.length < DEFAULT_COMMENT_PAGE_SIZE) {
                            setRecordComments(__spreadArray(__spreadArray([], recordComments, true), [newComment], false));
                        }
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var _g = useState(null), commentToDelete = _g[0], setCommentToDelete = _g[1];
    var actionMenuItems = useMemo(function () { return [
        {
            key: 'delete comment',
            label: (_jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to delete this comment?", onConfirm: function () {
                    if (commentToDelete) {
                        deleteRecordComment(commentToDelete);
                        var newCommentsList = recordComments.filter(function (comment) { return comment.id !== commentToDelete; });
                        setRecordComments(newCommentsList);
                    }
                }, placement: "bottom", children: _jsx("div", { className: "text-negative", children: "Delete" }, "delete") })),
        },
    ]; }, [commentToDelete, deleteRecordComment, recordComments]);
    return (_jsxs("div", { className: "commentsContainer", children: [recordComments.length !== 0 && (_jsxs(_Fragment, { children: [_jsxs("div", { className: "recordModalFeedScrollable", onScroll: onScroll, children: [recordComments
                                .sort(function (a, b) {
                                return new Date(a.createdAt).getTime() -
                                    new Date(b.createdAt).getTime();
                            })
                                .map(function (comment, index) {
                                return (_jsx(Comment, { isLastComment: index === recordComments.length - 1, comment: comment, setCommentToDelete: setCommentToDelete, actionMenuItems: actionMenuItems }, index));
                            }), _jsx("div", { ref: bottomRef })] }), _jsx(CommentInput, { charLimit: charLimit, commentValue: commentValue, setCommentValue: setCommentValue, onSendComment: onSendComment, isError: isError, setIsError: setIsError })] })), recordComments.length === 0 && (_jsxs("div", { className: "h-full", children: [_jsx(CommentFeedEmptyState, {}), _jsx(CommentInput, { charLimit: charLimit, commentValue: commentValue, setCommentValue: setCommentValue, onSendComment: onSendComment, isError: isError, setIsError: setIsError })] }))] }));
};
export default React.memo(RecordCommentFeed);
