var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { PlusOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { cloneDeep, isEmpty } from 'lodash';
import ZiraDropdown from 'src/ui/ZiraDropdown';
import { useAccountApi } from 'src/hooks/api';
import { UploadUrlType } from 'src/hooks/api/useUploadApi';
import { useFileApi } from 'src/hooks/api/useFileApi';
import { documentPreviewModalDataState } from 'src/components/PageView/DocumentPreviewModal/Atom';
import { disableScroll } from 'src/utils/Collection';
import { IpName } from 'src/shared';
import ComponentWithUpload from '../../../ComponentWithUpload';
import FileWithIconTag from './FileWithIconTag';
import './FileCell.scss';
import FileQuickView from './FileQuickView';
import { getFileExtension } from './util';
var FileCell = function (props) {
    var placeHolder = props.placeHolder, mode = props.mode, files = props.files, canEdit = props.canEdit, editCell = props.editCell, linkTemplateEnabled = props.linkTemplateEnabled, isModalCell = props.isModalCell;
    var validFiles = useMemo(function () {
        return files === null || files === void 0 ? void 0 : files.filter(function (file) {
            return (file.url && file.name && file.type) ||
                !!file.docusignTemplateId ||
                !!file.signNowTemplateId ||
                !!file.kamanaFile;
        });
    }, [files]);
    var _a = useState(validFiles || []), filesData = _a[0], setFilesData = _a[1];
    useEffect(function () {
        setFilesData(validFiles || []);
    }, [validFiles]);
    var setDocumentPreviewData = useSetRecoilState(documentPreviewModalDataState);
    var isNonEmptyNativeFileCell = useMemo(function () { return isModalCell && filesData.length > 0 && filesData[0].url; }, [filesData, isModalCell]);
    var handleFileDataUpdate = useCallback(function (fileData) {
        var _a;
        var tempFileData = (_a = cloneDeep(validFiles)) !== null && _a !== void 0 ? _a : [];
        var editedData = mode === 'multiple' ? __spreadArray(__spreadArray([], tempFileData, true), [fileData], false) : [fileData];
        editCell(editedData);
        setFilesData(editedData);
        disableScroll(false);
    }, [validFiles, mode, editCell]);
    var uploadNewFile = useCallback(function (fileInfo) {
        var file = fileInfo.file;
        var newFileData = { name: file.name, url: file.response.data.url, type: file.type };
        handleFileDataUpdate(newFileData);
    }, [handleFileDataUpdate]);
    var uploadButton = useMemo(function () {
        return (_jsx(ComponentWithUpload, { acceptedFileType: [], successAction: uploadNewFile, type: UploadUrlType.Collection, children: "Upload File" }));
    }, [uploadNewFile]);
    var _b = useState(false), templateDropdownVisible = _b[0], setTemplateDropdownVisible = _b[1];
    var useGetAccountDocSigningTemplatesListQuery = useAccountApi().useGetAccountDocSigningTemplatesListQuery;
    var _c = useGetAccountDocSigningTemplatesListQuery({
        enabled: templateDropdownVisible && linkTemplateEnabled,
    }), signingTemplates = _c.data, isLoading = _c.isLoading;
    var docuSignTemplates = useMemo(function () {
        var _a;
        return ((_a = signingTemplates === null || signingTemplates === void 0 ? void 0 : signingTemplates.templates.filter(function (template) { return template.partner === IpName.DOCUSIGN; })) !== null && _a !== void 0 ? _a : []);
    }, [signingTemplates === null || signingTemplates === void 0 ? void 0 : signingTemplates.templates]);
    var signNowTemplates = useMemo(function () {
        var _a;
        return ((_a = signingTemplates === null || signingTemplates === void 0 ? void 0 : signingTemplates.templates.filter(function (template) { return template.partner === IpName.SIGNNOW; })) !== null && _a !== void 0 ? _a : []);
    }, [signingTemplates === null || signingTemplates === void 0 ? void 0 : signingTemplates.templates]);
    var uploadDropdownContent = useMemo(function () {
        var customPadding = 'py-1 px-3';
        return (_jsxs(Menu, { className: "FileCellDropdownContentMenu", children: [_jsxs(Menu.ItemGroup, { title: _jsx("div", { className: "text-xs font-semibold", children: "File" }), children: [_jsx(Menu.Divider, {}), _jsx(Menu.Item, { className: "px-5", children: uploadButton }), filesData.length > 1 && (_jsxs(_Fragment, { children: [_jsx(Menu.Divider, {}), _jsx(Menu.Item, { className: "px-5", onClick: function () { return setDocumentPreviewData(filesData); }, children: "View Files" })] }))] }), linkTemplateEnabled && !isEmpty(docuSignTemplates) && (_jsx(_Fragment, { children: _jsxs(Menu.ItemGroup, { title: _jsx("div", { className: "mx-0 mt-4 text-xs font-semibold", children: "DocuSign Templates" }), children: [!isLoading &&
                                docuSignTemplates.map(function (template) {
                                    return (template === null || template === void 0 ? void 0 : template.templateName) && (_jsxs(_Fragment, { children: [_jsx(Menu.Divider, {}), _jsx(Menu.Item, { className: "px-5", onClick: function () {
                                                    return handleFileDataUpdate({
                                                        name: template.templateName,
                                                        url: '',
                                                        type: '',
                                                        docusignTemplateId: template.templateId,
                                                    });
                                                }, children: template.templateName }, template.templateId)] }));
                                }), !isLoading && docuSignTemplates.length === 0 && (_jsx("div", { className: "".concat(customPadding, " text-gray-100"), children: "No Templates Found" })), isLoading && (_jsx("div", { className: "".concat(customPadding, " text-gray-100"), children: "Loading..." }))] }) })), linkTemplateEnabled && !isEmpty(signNowTemplates) && (_jsx(_Fragment, { children: _jsxs(Menu.ItemGroup, { title: _jsx("div", { className: "mx-0 mt-4 text-xs font-semibold", children: "SignNow Templates" }), children: [!isLoading &&
                                signNowTemplates.map(function (template) {
                                    return (template === null || template === void 0 ? void 0 : template.templateName) && (_jsxs(_Fragment, { children: [_jsx(Menu.Divider, {}), _jsx(Menu.Item, { className: "px-5", onClick: function () {
                                                    return handleFileDataUpdate({
                                                        name: template.templateName,
                                                        url: '',
                                                        type: '',
                                                        signNowTemplateId: template.templateId,
                                                    });
                                                }, children: template.templateName }, template.templateId)] }));
                                }), !isLoading && signNowTemplates.length === 0 && (_jsx("div", { className: "".concat(customPadding, " text-gray-100"), children: "No Templates Found" })), isLoading && (_jsx("div", { className: "".concat(customPadding, " text-gray-100"), children: "Loading..." }))] }) }))] }));
    }, [
        uploadButton,
        filesData,
        linkTemplateEnabled,
        docuSignTemplates,
        isLoading,
        signNowTemplates,
        setDocumentPreviewData,
        handleFileDataUpdate,
    ]);
    var onTemplateDropdownVisibleChange = useCallback(function (visible) { return setTemplateDropdownVisible(visible); }, []);
    var uploadDropdown = useMemo(function () {
        if (filesData.length && mode === 'single') {
            return _jsx(_Fragment, {});
        }
        var padding = filesData.length ? 'ml-1 pb-2' : 'pt-2';
        return (_jsx(ZiraDropdown, { onOpenChange: onTemplateDropdownVisibleChange, dropdownRender: function () { return uploadDropdownContent; }, trigger: ['click'], overlayClassName: "FileCellDropdown", children: _jsx("div", { onClick: function (e) { return e.stopPropagation(); }, children: !placeHolder || filesData.length ? (_jsx(PlusOutlined, { className: "".concat(padding, " FillCellUpload text-gray-200") })) : (placeHolder) }) }));
    }, [
        filesData.length,
        mode,
        onTemplateDropdownVisibleChange,
        placeHolder,
        uploadDropdownContent,
    ]);
    var renameFile = useCallback(function (newName, fileIndex) {
        var newTempFiles = cloneDeep(filesData);
        // Better logic to handle file name with multiple dots
        var fileName = newTempFiles[fileIndex].name;
        var lastDotIndex = fileName.lastIndexOf('.');
        var oldName = fileName.slice(0, lastDotIndex);
        // To handle file name that already has invalid extension bc of old logic, ex: file.name = 'file.08'
        // Use correnct extension in url to fix it
        var fileUrl = newTempFiles[fileIndex].url;
        var fileExt = getFileExtension(fileUrl);
        if (fileIndex > newTempFiles.length || fileIndex < 0 || oldName === newName) {
            return;
        }
        newTempFiles[fileIndex] = __assign(__assign({}, newTempFiles[fileIndex]), { name: fileExt ? newName + '.' + fileExt : newName });
        setFilesData(newTempFiles);
        editCell(newTempFiles);
    }, [editCell, filesData]);
    var handleDeleteFile = useFileApi().deleteFile;
    var deleteFile = useCallback(function (fileIndex) {
        var newTempFiles = cloneDeep(filesData);
        if (fileIndex > newTempFiles.length || fileIndex < 0) {
            return;
        }
        handleDeleteFile(newTempFiles[fileIndex].url);
        newTempFiles.splice(fileIndex, 1);
        setFilesData(newTempFiles);
        editCell(newTempFiles);
    }, [editCell, filesData, handleDeleteFile]);
    //Only apply this style to non empty native file cell in modal
    var filesTagContainerClassName = useMemo(function () {
        return isNonEmptyNativeFileCell
            ? 'rounded-sm border border-gray-60 pl-2 py-2 mr-[10px] FileCell'
            : '';
    }, [isNonEmptyNativeFileCell]);
    return (_jsxs("div", { className: "mx-1 my-1 inline-block w-full FileCellContainer", children: [_jsxs("div", { className: filesTagContainerClassName, children: [filesData.map(function (file, index) { return (_jsx(FileWithIconTag, { file: file, actions: canEdit
                            ? {
                                onRename: !file.docusignTemplateId
                                    ? function (name) { return renameFile(name, index); }
                                    : undefined,
                                onDelete: function () { return deleteFile(index); },
                            }
                            : undefined }, index)); }), canEdit && uploadDropdown] }), isNonEmptyNativeFileCell && _jsx(FileQuickView, { fileData: filesData[0] })] }));
};
export default React.memo(FileCell);
