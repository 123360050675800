import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import '../../../../../App.less';
import { useIntegrationsApi } from 'src/hooks/api/useIntegrationsApi';
import { Collapse, Tag } from 'antd';
import { formatSnakeCase } from '../../utils';
import CollectionColumnMapping from './CollectionColumnMapping';
var CollectionMapping = function (_a) {
    var ipName = _a.ipName;
    var _b = useIntegrationsApi(), useGetAvailableIpCollections = _b.useGetAvailableIpCollections, useDisconnectIpCollection = _b.useDisconnectIpCollection;
    var _c = useGetAvailableIpCollections(ipName.toLowerCase()), ipCollectionOptions = _c.data, refetchAvailableIpCollections = _c.refetch;
    var disconnectIpCollection = useDisconnectIpCollection().mutate;
    var onDisconnectIpCollection = useCallback(function (teambridgeCollectionId) {
        disconnectIpCollection({ ipName: ipName, teambridgeCollectionId: teambridgeCollectionId }, {
            onSuccess: function () {
                refetchAvailableIpCollections();
            },
        });
    }, [ipName, disconnectIpCollection, refetchAvailableIpCollections]);
    return (_jsxs("div", { className: "flex flex-col gap-6 w-full", children: [_jsx("h2", { className: "text-lg font-semibold", children: "Map Imported Data" }), _jsx(Collapse, { expandIconPosition: "end", className: "border-none", style: { backgroundColor: 'transparent' }, defaultActiveKey: ['1'], children: ipCollectionOptions === null || ipCollectionOptions === void 0 ? void 0 : ipCollectionOptions.map(function (_a, i) {
                    var ipCollectionName = _a.ipCollectionName, possibleZiraCollectionId = _a.possibleZiraCollectionId;
                    return (_jsx(Collapse.Panel, { className: "rounded-md drop-shadow-[0_0px_4px_rgba(0,0,0,0.1)] bg-white border-1 border-gray-300 mb-4", header: _jsxs("div", { className: "flex items-center justify-between w-full pr-10", children: [_jsx("span", { className: "px-4 text-[16px] font-semibold", children: formatSnakeCase(ipCollectionName) }), possibleZiraCollectionId && (_jsx(Tag, { className: "rounded-md bg-green-50 font-medium text-green-400 border-green-400 border-1", children: "Connected" }))] }), children: _jsx(CollectionColumnMapping, { ipName: ipName, ipCollectionName: ipCollectionName, teambridgeCollectionId: possibleZiraCollectionId, onDisconnectIpCollection: onDisconnectIpCollection, refetchIpCollections: refetchAvailableIpCollections }) }, "ip-collection-".concat(i, "-").concat(ipCollectionName)));
                }) })] }));
};
export default CollectionMapping;
