var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMutation, useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { accountIdSelector } from 'src/state';
import { useFetch } from './useFetch';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export var useFeed = function () {
    var _a = useFetch(), customFetch = _a.request, toastRequest = _a.toastRequest;
    var accountId = useRecoilValue(accountIdSelector);
    //* Shift Requests Block *//
    var useAddMessageInteraction = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: 'in_app_msg/reactji',
            }, __assign(__assign({}, request), { accountId: accountId }));
        });
    };
    var usePostReply = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: 'in_app_msg/reply',
            }, __assign(__assign({}, request), { accountId: accountId }));
        });
    };
    var useFetchMoreReplies = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: 'in_app_msg/get_more_replies',
            }, request);
        });
    };
    var usePostMessage = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: 'in_app_msg/post',
            }, __assign(__assign({}, request), { accountId: accountId }));
        });
    };
    var useGetAuthor = function () {
        return useQuery(['author', accountId], function () {
            return customFetch({
                method: 'GET',
                url: 'in_app_msg/get_author/' + accountId,
            });
        }, {
            enabled: accountId !== '',
        });
    };
    var useFetchNewsFeed = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: 'in_app_msg/list',
            }, __assign(__assign({}, request), { accountId: accountId }));
        });
    };
    var useDeleteFeedPost = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'in_app_msg/delete',
            }, __assign(__assign({}, request), { accountId: accountId }), undefined, 'Successfully deleted post.', 'Failed to delete post.');
        });
    };
    return {
        useAddMessageInteraction: useAddMessageInteraction,
        usePostReply: usePostReply,
        useFetchMoreReplies: useFetchMoreReplies,
        usePostMessage: usePostMessage,
        useGetAuthor: useGetAuthor,
        useFetchNewsFeed: useFetchNewsFeed,
        useDeleteFeedPost: useDeleteFeedPost(),
    };
};
