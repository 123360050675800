import { useEffect, useRef, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { useChatApi } from 'src/hooks/api/useChatApi';
import { useChatComponent } from 'src/hooks/component/useChat';
import { currentUserState } from 'src/state';
import { departmentsState, threadSummaryListState } from 'src/state/atoms/chatState';
import sound from 'src/utils/sounds/Communicate/chat_notification.mp3';
var ChatData = function () {
    /**
     * Fetching thread list
     */
    var setThreadSummaryList = useSetRecoilState(threadSummaryListState);
    var departments = useRecoilValue(departmentsState);
    var useGetThreads = useChatApi().useGetThreadsQuery;
    var _a = useGetThreads({ page: 0, size: 20 }), data = _a.data, refetch = _a.refetch;
    useEffect(function () {
        if (data) {
            setThreadSummaryList(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    // Refetch threads on department change
    useEffect(function () {
        if (departments) {
            refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [departments]);
    /**
     * Listening to incoming message
     */
    var currentUserInfo = useRecoilValue(currentUserState);
    var audioRef = useRef(new Audio(sound));
    var _b = useState(false), canPlayAudio = _b[0], setCanPlayAudio = _b[1];
    var _c = useState(false), audioInitialized = _c[0], setAudioInitialized = _c[1];
    var addMessage = useChatComponent().addMessage;
    var lastJsonMessage = useWebSocket("".concat(process.env.REACT_APP_SERVER_BASE_SOCKET, "chat/").concat(currentUserInfo === null || currentUserInfo === void 0 ? void 0 : currentUserInfo.accountId, "/").concat(currentUserInfo === null || currentUserInfo === void 0 ? void 0 : currentUserInfo.recordId), { shouldReconnect: function () { return true; } }, !!(currentUserInfo === null || currentUserInfo === void 0 ? void 0 : currentUserInfo.recordId)).lastJsonMessage;
    // Enable audio after document interaction/click
    useEffect(function () {
        var enableAudio = function () {
            setCanPlayAudio(true);
            // Try to play a silent audio to initialize audio playback
            audioRef.current.volume = 0;
            audioRef.current
                .play()
                .then(function () {
                audioRef.current.pause();
                audioRef.current.volume = 1;
                setAudioInitialized(true);
            })
                .catch(function (error) {
                // eslint-disable-next-line no-console
                console.error('Error initializing audio:', error);
            });
        };
        document.addEventListener('click', enableAudio, { once: true });
        return function () {
            document.removeEventListener('click', enableAudio);
        };
    }, []);
    useEffect(function () {
        if (lastJsonMessage) {
            var chatMessage = lastJsonMessage;
            addMessage(chatMessage);
            if (canPlayAudio &&
                audioInitialized &&
                chatMessage.senderDetail.id !== (currentUserInfo === null || currentUserInfo === void 0 ? void 0 : currentUserInfo.recordId) &&
                audioRef.current.paused) {
                audioRef.current.play().catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.error('Error playing audio:', error);
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastJsonMessage]);
    return null;
};
export default ChatData;
