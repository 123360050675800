var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var PhoneOutlinedSvg = function () { return (_jsx("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M11.3471 10.6462L10.2061 12.0722C8.43909 11.0332 6.96609 9.56119 5.92809 7.79419L7.35409 6.65319C7.69809 6.37819 7.81309 5.90519 7.63409 5.50319L6.33409 2.57619C6.14109 2.14219 5.66309 1.91219 5.20409 2.03119L2.72909 2.67319C2.25109 2.79819 1.94209 3.26119 2.01009 3.75019C2.90209 10.1042 7.89609 15.0982 14.2511 15.9912C14.7401 16.0582 15.2031 15.7492 15.3271 15.2722L15.9691 12.7972C16.0881 12.3382 15.8581 11.8612 15.4251 11.6682L12.4981 10.3682C12.0961 10.1892 11.6241 10.3042 11.3481 10.6472L11.3471 10.6462Z", stroke: "#959595", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }) })); };
export var PhoneOutlinedIcon = function (props) { return (_jsx(Icon, __assign({ component: PhoneOutlinedSvg }, props))); };
