import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, Fragment } from 'react';
import moment from 'moment';
import { TaskRuleInfoProperty, TaskTemplateInfoProperty } from '../../Interface';
import { getTaskTypeDisplayText } from './util';
export var useRenderContentType = function (timeFormatFn) {
    // Custom hook to handle rendering content types
    var renderContentType = useCallback(function (event) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47, _48, _49, _50, _51, _52, _53, _54, _55, _56, _57, _58, _59, _60, _61, _62, _63, _64, _65, _66, _67, _68, _69, _70, _71, _72, _73, _74, _75, _76, _77, _78;
        var messageContent = ((_a = event.content) === null || _a === void 0 ? void 0 : _a.messageContent) || '';
        var elements;
        // Avoids using dangerouslySetInnerHTML
        if (((_b = event.content) === null || _b === void 0 ? void 0 : _b.type) === 'email_sent') {
            var lines = messageContent.split('<br/>');
            elements = lines.map(function (part, index) { return (_jsxs(Fragment, { children: [index > 0 && _jsx("br", {}), part] }, index)); });
        }
        switch (event.content.type) {
            case 'email_sent':
                return (_jsxs("div", { children: [!!event.content.recipients && (_jsxs(_Fragment, { children: ['Sent an email to ', _jsx("span", { className: "bold", children: event.content.recipients
                                        .map(function (recipient) {
                                        if (recipient.user)
                                            return recipient.user.name;
                                        else
                                            return recipient.emailOrPhone;
                                    })
                                        .join(', ') }), _jsx("br", {})] })), 'Email: ', _jsx("span", { className: "", children: elements })] }));
            case 'sms_sent':
                return (_jsxs("div", { children: [!!event.content.recipients && (_jsxs(_Fragment, { children: ['Sent an SMS to ', _jsx("span", { className: "bold", children: event.content.recipients
                                        .map(function (recipient) {
                                        if (recipient.user)
                                            return recipient.user.name;
                                        else
                                            return recipient.emailOrPhone;
                                    })
                                        .join(', ') }), _jsx("br", {})] })), 'Message: ', _jsx("span", { className: "", children: messageContent })] }));
            case 'in_app_message':
                return (_jsxs("div", { children: ['Message: ', _jsx("span", { className: "", children: messageContent })] }));
            case 'break_created':
                return (_jsxs("div", { children: ['Shift break was created by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'location_created':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.location.name }), ' was created by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'location_updated':
                return (_jsxs("div", { children: [((_c = event.content.location) === null || _c === void 0 ? void 0 : _c.name) + ' ', _jsx("span", { className: "bold", children: (_d = event.content.schema) === null || _d === void 0 ? void 0 : _d.name }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_e = event.content.oldValues) === null || _e === void 0 ? void 0 : _e.map(function (val) { return val + ' '; }) }) }), ' to ', _jsx("span", { className: "", children: (_f = event.content.newValues) === null || _f === void 0 ? void 0 : _f.map(function (val) { return val + ' '; }) })] }));
            case 'location_deleted':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.location.name }), ' was deleted by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'role_created':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.role.name }), ' was created by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'role_updated':
                return (_jsxs("div", { children: [((_g = event.content.role) === null || _g === void 0 ? void 0 : _g.name) + ' ', _jsx("span", { className: "bold", children: (_h = event.content.schema) === null || _h === void 0 ? void 0 : _h.name }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_j = event.content.oldValues) === null || _j === void 0 ? void 0 : _j.map(function (val) { return val + ' '; }) }) }), ' to ', _jsx("span", { className: "", children: (_k = event.content.newValues) === null || _k === void 0 ? void 0 : _k.map(function (val) { return val + ' '; }) })] }));
            case 'role_deleted':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.role.name }), ' was deleted by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'timeoff_created':
                return (_jsxs("div", { children: [_jsx("span", { className: "", children: event.content.timeoff.approvalStatus }), ' ', _jsx("span", { className: "", children: event.content.timeoff.reason }), ' timeoff was created for ', _jsx("span", { className: "bold", children: event.content.user.name }), ' from ', _jsx("span", { className: "", children: timeFormatFn(event.content.timeoff.startAt, 'lll', false) }), ' to ', _jsx("span", { className: "", children: timeFormatFn(event.content.timeoff.endAt, 'lll') }), ' by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'timeoff_updated':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: (_l = event.content.schema) === null || _l === void 0 ? void 0 : _l.name }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_m = event.content.oldValues) === null || _m === void 0 ? void 0 : _m.map(function (val) { return val + ' '; }) }) }), ' to ', _jsx("span", { className: "", children: (_o = event.content.newValues) === null || _o === void 0 ? void 0 : _o.map(function (val) { return val + ' '; }) })] }));
            case 'timeoff_deleted':
                return (_jsxs("div", { children: [_jsx("span", { className: "", children: event.content.timeoff.approvalStatus }), ' ', _jsx("span", { className: "", children: event.content.timeoff.reason }), ' timeoff was deleted for ', _jsx("span", { className: "bold", children: event.content.user.name }), ' from ', _jsx("span", { className: "", children: timeFormatFn(event.content.timeoff.startAt, 'lll') }), ' to ', _jsx("span", { className: "", children: timeFormatFn(event.content.timeoff.endAt, 'lll', true) }), ' by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'pay_period_created':
                return (_jsxs("div", { children: ['A pay period from ', _jsx("span", { className: "", children: timeFormatFn(event.content.payPeriod.startDate, 'll') }), ' to ', _jsx("span", { className: "", children: timeFormatFn(event.content.payPeriod.endDate, 'll') }), ' was created by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'pay_period_updated':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: (_p = event.content.schema) === null || _p === void 0 ? void 0 : _p.name }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_q = event.content.oldValues) === null || _q === void 0 ? void 0 : _q.map(function (val) { return val + ' '; }) }) }), ' to ', _jsx("span", { className: "", children: (_r = event.content.newValues) === null || _r === void 0 ? void 0 : _r.map(function (val) { return val + ' '; }) })] }));
            case 'pay_period_deleted':
                return (_jsxs("div", { children: ['A pay period from ', _jsx("span", { className: "", children: timeFormatFn(event.content.payPeriod.startDate, 'll', false) }), ' to ', _jsx("span", { className: "", children: timeFormatFn(event.content.payPeriod.endDate, 'll') }), ' was deleted by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'user_created':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.user.name }), ' was created by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'user_updated':
                return (_jsxs("div", { children: [((_s = event.content.user) === null || _s === void 0 ? void 0 : _s.name) + ' ', _jsx("span", { className: "bold", children: (_t = event.content.schema) === null || _t === void 0 ? void 0 : _t.name }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_u = event.content.oldValues) === null || _u === void 0 ? void 0 : _u.map(function (val) { return val + ' '; }) }) }), ' to ', _jsx("span", { className: "", children: (_v = event.content.newValues) === null || _v === void 0 ? void 0 : _v.map(function (val) { return val + ' '; }) })] }));
            case 'user_pay_updated':
                return (_jsxs("div", { children: [((_w = event.content.user) === null || _w === void 0 ? void 0 : _w.name) + ' ', _jsx("span", { className: "bold", children: "Pay Rate" }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_x = event.content.oldValues) === null || _x === void 0 ? void 0 : _x.map(function (val) { return val + ' '; }) }) }), ' to ', _jsx("span", { className: "", children: (_y = event.content.newValues) === null || _y === void 0 ? void 0 : _y.map(function (val) { return val + ' '; }) })] }));
            case 'user_deleted':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.user.name }), ' was deleted by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'contact_created':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.contact.name }), ' was created by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'contact_updated':
                return (_jsxs("div", { children: [((_z = event.content.contact) === null || _z === void 0 ? void 0 : _z.name) + ' ', _jsx("span", { className: "bold", children: (_0 = event.content.schema) === null || _0 === void 0 ? void 0 : _0.name }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_1 = event.content.oldValues) === null || _1 === void 0 ? void 0 : _1.map(function (val) { return val + ' '; }) }) }), ' to ', _jsx("span", { className: "", children: (_2 = event.content.newValues) === null || _2 === void 0 ? void 0 : _2.map(function (val) { return val + ' '; }) })] }));
            case 'contact_deleted':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.contact.name }), ' was deleted by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'shift_created': {
                var _79 = event.content, user = _79.user, shift = _79.shift;
                if ((shift === null || shift === void 0 ? void 0 : shift.startAt) && (shift === null || shift === void 0 ? void 0 : shift.endAt)) {
                    // Scenario with both startAt and endAt
                    return (_jsxs("div", { children: ['Shift created for ', _jsx("span", { className: "bold", children: user ? user.name : 'Unassigned User' }), ' from ', _jsx("span", { children: timeFormatFn(shift.startAt, 'lll', false) }), ' to ', _jsx("span", { children: timeFormatFn(shift.endAt, 'lll') })] }));
                }
                else if (shift === null || shift === void 0 ? void 0 : shift.clockIn) {
                    // Scenario with only clockIn
                    return (_jsxs("div", { children: ['Shift created for ', _jsx("span", { className: "bold", children: user ? user.name : 'Unassigned User' }), ' with a clock-in time of ', _jsx("span", { children: timeFormatFn(shift.clockIn, 'lll', false) })] }));
                }
                else {
                    // Fallback if neither startAt/endAt nor clockIn is available
                    return (_jsxs("div", { children: ['Shift created for ', _jsx("span", { className: "bold", children: user ? user.name : 'Unassigned User' })] }));
                }
            }
            case 'shift_deleted':
                return (_jsxs("div", { children: ['Shift deleted for ', _jsx("span", { className: "bold", children: event.content.user ? event.content.user.name : 'Unassigned User' }), ' from ', _jsx("span", { className: "", children: timeFormatFn(event.content.shift.startAt, 'lll', false) }), ' to ', _jsx("span", { className: "", children: timeFormatFn(event.content.shift.endAt, 'lll') })] }));
            case 'shift_group_created':
                return (_jsxs("div", { children: ['Shift group created for ', _jsx("span", { className: "bold", children: event.content.user ? event.content.user.name : 'Unassigned User' }), ' from ', _jsx("span", { className: "", children: timeFormatFn(event.content.shiftGroup.startAt, 'lll', false) }), ' to ', _jsx("span", { className: "", children: timeFormatFn(event.content.shiftGroup.endAt, 'lll') })] }));
            case 'shift_group_deleted':
                return (_jsxs("div", { children: ['Shift group deleted for ', _jsx("span", { className: "bold", children: event.content.user ? event.content.user.name : 'Unassigned User' }), ' from ', _jsx("span", { className: "", children: timeFormatFn(event.content.shiftGroup.startAt, 'lll', false) }), ' to ', _jsx("span", { className: "", children: timeFormatFn(event.content.shiftGroup.endAt, 'lll') })] }));
            case 'shift_wage_updated':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: "Pay Rate" }), ' updated from', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: event.content.oldPayRate }) }), ' to', _jsx("span", { className: "", children: event.content.newPayRate + ' ' }), _jsx("span", { className: "bold", children: "Bill Rate" }), ' updated from', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: event.content.oldBillRate }) }), ' to', _jsx("span", { className: "", children: event.content.newBillRate })] }));
            case 'task_template_created':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.taskTemplate.name }), ' was created by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'task_template_updated':
                return event.content.propertyName === TaskTemplateInfoProperty.TASK_TYPE ? (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.propertyName }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_3 = event.content.oldValues) === null || _3 === void 0 ? void 0 : _3.map(function (val) {
                                    return val ? getTaskTypeDisplayText(val) : '';
                                }) }) }), ' to ', _jsx("span", { className: "", children: (_4 = event.content.newValues) === null || _4 === void 0 ? void 0 : _4.map(function (val) {
                                return val ? getTaskTypeDisplayText(val) : '';
                            }) })] })) : (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.propertyName }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_5 = event.content.oldValues) === null || _5 === void 0 ? void 0 : _5.map(function (val) { return val + ' '; }) }) }), ' to ', _jsx("span", { className: "", children: (_6 = event.content.newValues) === null || _6 === void 0 ? void 0 : _6.map(function (val) { return val + ' '; }) })] }));
            case 'task_template_deleted':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.taskTemplate.name }), ' was deleted by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'task_created':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.task.name }), ' was created by ', _jsx("span", { className: "", children: event.content.actor.name }), ' for ', _jsx("span", { className: "bold", children: event.content.user ? event.content.user.name : 'Unassigned User' })] }));
            case 'task_updated':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: (_7 = event.content.schema) === null || _7 === void 0 ? void 0 : _7.name }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_8 = event.content.oldValues) === null || _8 === void 0 ? void 0 : _8.map(function (val) { return val + ' '; }) }) }), ' to ', _jsx("span", { className: "", children: (_9 = event.content.newValues) === null || _9 === void 0 ? void 0 : _9.map(function (val) { return val + ' '; }) })] }));
            case 'task_deleted':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.task.name }), ' was deleted by ', _jsx("span", { className: "", children: event.content.actor.name }), ' for ', _jsx("span", { className: "bold", children: event.content.user ? event.content.user.name : 'Unassigned User' })] }));
            case 'task_group_template_created':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.taskGroup.name }), ' was created by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'task_group_template_updated':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.propertyName }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_10 = event.content.oldValues) === null || _10 === void 0 ? void 0 : _10.map(function (val) { return val + ' '; }) }) }), ' to ', _jsx("span", { className: "", children: (_11 = event.content.newValues) === null || _11 === void 0 ? void 0 : _11.map(function (val) { return val + ' '; }) })] }));
            case 'automation_created':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.automationName }), ' was created by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'automation_saved':
                return event.content.oldValues && event.content.oldValues.length > 0 ? (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.propertyName }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_12 = event.content.oldValues) === null || _12 === void 0 ? void 0 : _12.map(function (val, index) { return (_jsx("span", { children: val + ' ' }, index)); }) }) }), ' to ', _jsx("span", { className: "", children: (_13 = event.content.newValues) === null || _13 === void 0 ? void 0 : _13.map(function (val, index) { return (_jsx("span", { children: val + ' ' }, index)); }) })] })) : (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.automationName }), ' was updated by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'task_group_template_deleted':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.taskGroup.name }), ' was deleted by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'task_rule_created':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.taskRule.name }), ' was created by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'task_rule_updated':
                return event.content.propertyName === TaskRuleInfoProperty.CONDITIONS ? (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.propertyName }), ' were updated'] })) : (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.propertyName }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_14 = event.content.oldValues) === null || _14 === void 0 ? void 0 : _14.map(function (val) { return val + ' '; }) }) }), ' to ', _jsx("span", { className: "", children: (_15 = event.content.newValues) === null || _15 === void 0 ? void 0 : _15.map(function (val) { return val + ' '; }) })] }));
            case 'task_rule_deleted':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.taskRule.name }), ' was deleted by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'document_created':
                return (_jsxs("div", { children: [' Document was created by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'document_updated':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: (_16 = event.content.schema) === null || _16 === void 0 ? void 0 : _16.name }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_17 = event.content.oldValues) === null || _17 === void 0 ? void 0 : _17.map(function (val) { return val + ' '; }) }) }), ' to ', _jsx("span", { className: "", children: (_18 = event.content.newValues) === null || _18 === void 0 ? void 0 : _18.map(function (val) { return val + ' '; }) })] }));
            case 'document_deleted':
                return (_jsxs("div", { children: ['Document was deleted by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'document_completed':
                return (_jsxs("div", { children: [' Document was completed by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'placement_created':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.placement.name }), ' was created by ', _jsx("span", { className: "", children: event.content.actor.name }), ' for ', _jsx("span", { className: "bold", children: event.content.user ? event.content.user.name : 'Unassigned User' })] }));
            case 'placement_updated':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: (_19 = event.content.schema) === null || _19 === void 0 ? void 0 : _19.name }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_20 = event.content.oldValues) === null || _20 === void 0 ? void 0 : _20.map(function (val) { return val + ' '; }) }) }), ' to ', _jsx("span", { className: "", children: (_21 = event.content.newValues) === null || _21 === void 0 ? void 0 : _21.map(function (val) { return val + ' '; }) })] }));
            case 'placement_deleted':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.placement.name }), ' was deleted by ', _jsx("span", { className: "", children: event.content.actor.name }), ' for ', _jsx("span", { className: "bold", children: event.content.user ? event.content.user.name : 'Unassigned User' })] }));
            case 'job_created':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.job.name }), ' was created by ', _jsx("span", { className: "", children: event.content.actor.name }), ' for ', _jsx("span", { className: "bold", children: event.content.user ? event.content.user.name : 'Unassigned User' })] }));
            case 'job_updated':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: (_22 = event.content.schema) === null || _22 === void 0 ? void 0 : _22.name }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_23 = event.content.oldValues) === null || _23 === void 0 ? void 0 : _23.map(function (val) { return val + ' '; }) }) }), ' to ', _jsx("span", { className: "", children: (_24 = event.content.newValues) === null || _24 === void 0 ? void 0 : _24.map(function (val) { return val + ' '; }) })] }));
            case 'job_deleted':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.job.name }), ' was deleted by ', _jsx("span", { className: "", children: event.content.actor.name }), ' for ', _jsx("span", { className: "bold", children: event.content.user ? event.content.user.name : 'Unassigned User' })] }));
            case 'record_created':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: ((_25 = event.content.record) === null || _25 === void 0 ? void 0 : _25.title)
                                ? event.content.record.title
                                : event.content.collectionName }), ' was created by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'record_deleted':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: ((_26 = event.content.record) === null || _26 === void 0 ? void 0 : _26.title)
                                ? (_27 = event.content.record) === null || _27 === void 0 ? void 0 : _27.title
                                : event.content.collectionName }), ' was deleted by ', _jsx("span", { className: "", children: event.content.actor.name })] }));
            case 'record_updated':
            case 'shift_group_updated':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: (_28 = event.content.schema) === null || _28 === void 0 ? void 0 : _28.name }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_29 = event.content.oldValues) === null || _29 === void 0 ? void 0 : _29.map(function (val) { return val + ' '; }) }) }), ' to ', _jsx("span", { className: "", children: (_30 = event.content.newValues) === null || _30 === void 0 ? void 0 : _30.map(function (val) { return val + ' '; }) })] }));
            case 'shift_updated':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: (_31 = event.content.schema) === null || _31 === void 0 ? void 0 : _31.name }), ' updated from ', _jsx("span", { className: "oldValues", children: _jsx("span", { className: "oldValuesText", children: (_32 = event.content.oldValues) === null || _32 === void 0 ? void 0 : _32.map(function (val) { return val + ' '; }) }) }), ' to ', _jsx("span", { className: "", children: (_33 = event.content.newValues) === null || _33 === void 0 ? void 0 : _33.map(function (val) { return val + ' '; }) })] }));
            case 'shift_clock_in':
                return (_jsxs("div", { children: ['Clocked in at ', _jsx("span", { className: "bold", children: timeFormatFn((_34 = event.content.shift) === null || _34 === void 0 ? void 0 : _34.clockIn, 'LT', false) }), ' for Shift scheduled on ', _jsx("span", { className: "bold", children: timeFormatFn((_35 = event.content.shift) === null || _35 === void 0 ? void 0 : _35.startAt, 'lll') }), ' for ', _jsx("span", { className: "bold", children: (_36 = event.content.user) === null || _36 === void 0 ? void 0 : _36.name })] }));
            case 'shift_unscheduled_clock_in':
                return (_jsxs("div", { children: ['Clocked in at ', _jsx("span", { className: "bold", children: timeFormatFn((_37 = event.content.shift) === null || _37 === void 0 ? void 0 : _37.clockIn, 'LT') }), ' for ', _jsx("span", { className: "bold", children: (_38 = event.content.user) === null || _38 === void 0 ? void 0 : _38.name })] }));
            case 'shift_clock_out':
                return (_jsxs("div", { children: ['Clocked out at ', _jsx("span", { className: "bold", children: timeFormatFn((_39 = event.content.shift) === null || _39 === void 0 ? void 0 : _39.clockOut, 'LT') }), ' for ', _jsx("span", { className: "bold", children: (_40 = event.content.user) === null || _40 === void 0 ? void 0 : _40.name })] }));
            case 'shift_break_in':
                return (_jsxs("div", { children: ['Clocked in a ', _jsx("span", { className: "bold", children: (_41 = event.content.breakRule) === null || _41 === void 0 ? void 0 : _41.name }), ' break at ', _jsx("span", { className: "bold", children: timeFormatFn((_42 = event.content.shiftBreak) === null || _42 === void 0 ? void 0 : _42.clockIn, 'LT') }), ' for ', _jsx("span", { className: "bold", children: (_43 = event.content.user) === null || _43 === void 0 ? void 0 : _43.name })] }));
            case 'shift_break_out':
                return (_jsxs("div", { children: ['Clocked out of a ', _jsx("span", { className: "bold", children: (_44 = event.content.breakRule) === null || _44 === void 0 ? void 0 : _44.name }), ' break at ', _jsx("span", { className: "bold", children: timeFormatFn((_45 = event.content.shiftBreak) === null || _45 === void 0 ? void 0 : _45.clockOut, 'LT') }), ' for ', _jsx("span", { className: "bold", children: (_46 = event.content.user) === null || _46 === void 0 ? void 0 : _46.name })] }));
            case 'shift_claim':
                return (_jsxs("div", { children: ['Claimed a Shift scheduled on ', _jsx("span", { className: "bold", children: timeFormatFn((_47 = event.content.shift) === null || _47 === void 0 ? void 0 : _47.startAt, 'lll') })] }));
            case 'shift_release':
                return (_jsxs("div", { children: ['Released a Shift scheduled on ', _jsx("span", { className: "bold", children: timeFormatFn((_48 = event.content.shift) === null || _48 === void 0 ? void 0 : _48.startAt, 'lll') })] }));
            case 'release_request_approved':
                return (_jsxs("div", { children: ['Approved release request of a Shift scheduled on ', _jsx("span", { className: "bold", children: timeFormatFn((_49 = event.content.shift) === null || _49 === void 0 ? void 0 : _49.startAt, 'lll') }), ' for ', _jsx("span", { className: "bold", children: (_50 = event.content.user) === null || _50 === void 0 ? void 0 : _50.name })] }));
            case 'release_request_rejected':
                return (_jsxs("div", { children: ['Rejected release request of a Shift scheduled on ', _jsx("span", { className: "bold", children: timeFormatFn((_51 = event.content.shift) === null || _51 === void 0 ? void 0 : _51.startAt, 'lll') }), ' for ', _jsx("span", { className: "bold", children: (_52 = event.content.user) === null || _52 === void 0 ? void 0 : _52.name })] }));
            case 'shift_request_approved':
                return (_jsxs("div", { children: ['Approved a Shift scheduled on ', _jsx("span", { className: "bold", children: timeFormatFn((_53 = event.content.shift) === null || _53 === void 0 ? void 0 : _53.startAt, 'lll') }), ' for ', _jsx("span", { className: "bold", children: (_54 = event.content.user) === null || _54 === void 0 ? void 0 : _54.name })] }));
            case 'shift_request_rejected':
                return (_jsxs("div", { children: ['Rejected a Shift scheduled on ', _jsx("span", { className: "bold", children: timeFormatFn((_55 = event.content.shift) === null || _55 === void 0 ? void 0 : _55.startAt, 'lll') }), ' for ', _jsx("span", { className: "bold", children: (_56 = event.content.user) === null || _56 === void 0 ? void 0 : _56.name })] }));
            case 'shift_group_claimed':
                return (_jsxs("div", { children: ['Claimed a Shift Group scheduled on ', _jsx("span", { className: "bold", children: timeFormatFn((_57 = event.content.shiftGroup) === null || _57 === void 0 ? void 0 : _57.startAt, 'lll') })] }));
            case 'shift_group_request_approved':
                return (_jsxs("div", { children: ['Approved a Shift scheduled on ', _jsx("span", { className: "bold", children: timeFormatFn((_58 = event.content.shiftGroup) === null || _58 === void 0 ? void 0 : _58.startAt, 'lll') }), ' for ', _jsx("span", { className: "bold", children: (_59 = event.content.user) === null || _59 === void 0 ? void 0 : _59.name })] }));
            case 'shift_group_request_rejected':
                return (_jsxs("div", { children: ['Rejected a Shift scheduled on ', _jsx("span", { className: "bold", children: timeFormatFn((_60 = event.content.shiftGroup) === null || _60 === void 0 ? void 0 : _60.startAt, 'lll') }), ' for ', _jsx("span", { className: "bold", children: (_61 = event.content.user) === null || _61 === void 0 ? void 0 : _61.name })] }));
            case 'shift_recommended':
                return (_jsx("div", { children: !!event.content.users && (_jsxs(_Fragment, { children: ['Recommended a Shift to ', _jsx("span", { className: "bold", children: event.content.users
                                    .map(function (user) {
                                    return user.name;
                                })
                                    .join(', ') })] })) }));
            case 'shift_recommendation_accepted':
                return (_jsxs("div", { children: ['Accepted a Shift Recommendation for a shift scheduled on ', _jsx("span", { className: "bold", children: timeFormatFn((_62 = event.content.shift) === null || _62 === void 0 ? void 0 : _62.startAt, 'lll') }), ' for ', _jsx("span", { className: "bold", children: (_63 = event.content.user) === null || _63 === void 0 ? void 0 : _63.name })] }));
            case 'shift_recommendation_rejected':
                return (_jsxs("div", { children: ['Rejected a Shift Recommendation for a shift scheduled on ', _jsx("span", { className: "bold", children: timeFormatFn((_64 = event.content.shift) === null || _64 === void 0 ? void 0 : _64.startAt, 'lll') }), ' for ', _jsx("span", { className: "bold", children: (_65 = event.content.user) === null || _65 === void 0 ? void 0 : _65.name })] }));
            case 'project_started':
                return (_jsxs("div", { children: ['Project ', _jsx("span", { className: "bold", children: (_66 = event.content.project) === null || _66 === void 0 ? void 0 : _66.name }), ' started at ', _jsx("span", { className: "bold", children: timeFormatFn((_67 = event.content.tracking) === null || _67 === void 0 ? void 0 : _67.clockIn, 'lll') }), ' by ', _jsx("span", { className: "bold", children: (_68 = event.content.user) === null || _68 === void 0 ? void 0 : _68.name })] }));
            case 'project_ended':
                return (_jsxs("div", { children: ['Project ', _jsx("span", { className: "bold", children: (_69 = event.content.project) === null || _69 === void 0 ? void 0 : _69.name }), ' end at ', _jsx("span", { className: "bold", children: timeFormatFn((_70 = event.content.tracking) === null || _70 === void 0 ? void 0 : _70.clockIn, 'lll') }), ' by ', _jsx("span", { className: "bold", children: (_71 = event.content.user) === null || _71 === void 0 ? void 0 : _71.name })] }));
            case 'task_assigned':
                return (_jsx("span", { className: "bold", children: "".concat(event.content.actor.name, " assigned ").concat(event.content.taskName, " to ").concat(event.content.assignee) }));
            case 'task_completed':
                return (_jsx("span", { className: "bold", children: "".concat(event.content.assignee, " completed ").concat(event.content.taskName) }));
            case 'task_group_assigned':
                return (_jsx("span", { className: "bold", children: "".concat(event.content.assignee, " was assigned ").concat(event.content.taskGroupName) }));
            case 'task_group_completed':
                return (_jsx("span", { className: "bold", children: "".concat(event.content.assignee, " completed ").concat(event.content.taskGroupName) }));
            case 'permission_updated':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.actor.name }), ' updated ', _jsx("span", { className: "bold", children: (_72 = event.content.accessGroup) === null || _72 === void 0 ? void 0 : _72.name }), "'s ", ((_73 = event.content.accessGroup) === null || _73 === void 0 ? void 0 : _73.id) ? (_jsxs(_Fragment, { children: [_jsx("span", { className: "bold", children: (_74 = event.content.accessGroup) === null || _74 === void 0 ? void 0 : _74.type }), ' ', event.content.accessGroup.id, ' permission to ', _jsx("span", { className: "bold", children: event.content.accessGroup.permission })] })) : (_jsxs(_Fragment, { children: [_jsx("span", { className: "bold", children: (_75 = event.content.accessGroup) === null || _75 === void 0 ? void 0 : _75.type }), ' permission'] }))] }));
            case 'schedule_published':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.actor.name }), ' published schedule from ', _jsx("span", { className: "bold", children: event.content.from }), ' to ', _jsx("span", { className: "bold", children: event.content.to }), ', ', _jsx("span", { className: "bold", children: event.content.shiftNumber }), ' shifts were published'] }));
            case 'schedule_copied':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.actor.name }), ' copied schedule from ', _jsx("span", { className: "bold", children: event.content.from }), ' to ', _jsx("span", { className: "bold", children: event.content.to }), ', ', _jsx("span", { className: "bold", children: event.content.shiftNumber }), ' shifts were copied'] }));
            case 'schedule_cleared':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.actor.name }), ' cleared schedule from ', _jsx("span", { className: "bold", children: event.content.from }), ' to ', _jsx("span", { className: "bold", children: event.content.to }), ', ', _jsx("span", { className: "bold", children: event.content.shiftNumber }), ' shifts were cleared'] }));
            case 'button_clicked':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: event.content.actor.name }), ' clicked ', _jsx("span", { className: "bold", children: event.content.buttonName }), ' button.'] }));
            case 'shift_split':
                return (_jsxs("div", { children: [_jsx("span", { className: "bold", children: ((_76 = event.content.record) === null || _76 === void 0 ? void 0 : _76.title)
                                ? (_77 = event.content.record) === null || _77 === void 0 ? void 0 : _77.title
                                : event.content.collectionName }), ' was split by ', _jsx("span", { className: "", children: event.content.actor.name }), _jsx("span", { className: "bold", children: " Automation" })] }));
            case 'broadcast_deleted':
                return (_jsxs("div", { children: [_jsx("span", { children: "A broadcast" }), ((_78 = event === null || event === void 0 ? void 0 : event.content) === null || _78 === void 0 ? void 0 : _78.createdAt) && (_jsxs(_Fragment, { children: [_jsx("span", { children: " created at " }), _jsx("span", { className: "bold", children: "".concat(moment(event.content.createdAt).format('MMMM Do YYYY [at] h:mm a')) })] })), ' was deleted by ', _jsx("span", { children: event.content.actor.name })] }));
        }
    }, [timeFormatFn]);
    return renderContentType;
};
