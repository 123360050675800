var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useRecoilValue } from 'recoil';
import { collectionTableSchemaFetchTypeSelector } from 'src/state';
import React from 'react';
import DummyUserCell from '../../DummyUserCell';
import DummyTagCell from '../DummyTagCell';
import LogicSelectionCellWrapper from './LogicSelectionCellWrapper';
var SelectionCellHOC = function (props) {
    var selections = props.selections, cellPos = props.cellPos, canEdit = props.canEdit, isClicked = props.isClicked, setIsClicked = props.setIsClicked, _a = props.isModalCell, isModalCell = _a === void 0 ? false : _a, _b = props.isPrintOnly, isPrintOnly = _b === void 0 ? false : _b;
    var fetchType = useRecoilValue(collectionTableSchemaFetchTypeSelector(cellPos)).fetchType;
    return (_jsxs(_Fragment, { children: [isClicked && (_jsx(LogicSelectionCellWrapper, __assign({}, props, { fetchType: fetchType, setIsClicked: setIsClicked }))), !isClicked && fetchType !== 'users' && (_jsx(DummyTagCell, { tags: selections, cellPos: cellPos, setIsClicked: setIsClicked, canEdit: canEdit, isModalCell: isModalCell })), !isClicked && fetchType === 'users' && (_jsx(DummyUserCell, { tags: selections, setIsClicked: setIsClicked, canEdit: canEdit, isPrintOnly: isPrintOnly }))] }));
};
export default React.memo(SelectionCellHOC);
