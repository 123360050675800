var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var NumberlinedSvg = function () { return (_jsxs("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M2.25 7.5L3.75 6V12", stroke: "#959595", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M6.75 6H9C9.19891 6 9.38968 6.07902 9.53033 6.21967C9.67098 6.36032 9.75 6.55109 9.75 6.75V8.25C9.75 8.44891 9.67098 8.63968 9.53033 8.78033C9.38968 8.92098 9.19891 9 9 9H7.5C7.30109 9 7.11032 9.07902 6.96967 9.21967C6.82902 9.36032 6.75 9.55109 6.75 9.75V11.25C6.75 11.4489 6.82902 11.6397 6.96967 11.7803C7.11032 11.921 7.30109 12 7.5 12H9.75", stroke: "#959595", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M12.75 6H14.625C14.9234 6 15.2095 6.11853 15.4205 6.3295C15.6315 6.54048 15.75 6.82663 15.75 7.125V7.875C15.75 8.17337 15.6315 8.45952 15.4205 8.6705C15.2095 8.88147 14.9234 9 14.625 9M14.625 9H13.5M14.625 9C14.9234 9 15.2095 9.11853 15.4205 9.3295C15.6315 9.54048 15.75 9.82663 15.75 10.125V10.875C15.75 11.1734 15.6315 11.4595 15.4205 11.6705C15.2095 11.8815 14.9234 12 14.625 12H12.75", stroke: "#959595", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] })); };
export var NumberlinedIcon = function (props) { return (_jsx(Icon, __assign({ component: NumberlinedSvg }, props))); };
