import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { Switch } from 'antd';
import { SchemaConfigurationType } from 'src/shared';
var PhoneSchemaConfigurationSection = function (_a) {
    var phoneSchemaConfig = _a.phoneSchemaConfig, schemaConfigOnChange = _a.schemaConfigOnChange;
    var showExtension = useMemo(function () {
        var _a;
        return (_a = phoneSchemaConfig === null || phoneSchemaConfig === void 0 ? void 0 : phoneSchemaConfig.showExtension) !== null && _a !== void 0 ? _a : true;
    }, [phoneSchemaConfig === null || phoneSchemaConfig === void 0 ? void 0 : phoneSchemaConfig.showExtension]);
    var setShowExtension = function (showExtension) {
        schemaConfigOnChange({
            type: SchemaConfigurationType.PHONE,
            showExtension: showExtension,
        });
    };
    return (_jsxs("div", { className: "flex items-center justify-between font-semibold", children: [_jsx("div", { children: "Show Extension" }), _jsx(Switch, { checked: showExtension, onChange: setShowExtension })] }));
};
export default React.memo(PhoneSchemaConfigurationSection);
