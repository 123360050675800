import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Breadcrumb } from 'antd';
import { useRecoilValue } from 'recoil';
import { accountNameState, isInternalUserSelector, } from 'src/state/selectors/withAccountDetailState';
import { TEAMBRIDGE_TITLE } from 'src/shared';
import SettingsContentHeader from '../SettingsContentHeader';
import GenerateTranslations from './Actions/GenerateTranslations';
import UnlockNeedsAndCoverages from './Actions/UnlockNeedsAndCoverages';
var InternalToolsPage = function () {
    var isInternalUser = useRecoilValue(isInternalUserSelector);
    var accountName = useRecoilValue(accountNameState);
    return isInternalUser ? (_jsxs("div", { children: [_jsx(SettingsContentHeader, { children: _jsx(Breadcrumb.Item, { children: "Internal Tools" }) }), _jsx("div", { children: _jsxs("div", { className: "SettingContentView", id: "SettingContentView", children: [_jsx("div", { className: "text-[32px] font-semibold", children: "Internal Tools" }), _jsxs("div", { className: "text-sm font-medium flex flex-col", children: [_jsxs("span", { className: "text-[#666] mb-4", children: ["Tools to be used by ", TEAMBRIDGE_TITLE, " employees internally. Exercise caution when utilizing these tools since they can modify app data for the account."] }), _jsxs("span", { className: "font-semibold mb-4 text-[#FF0000]", children: ["Current customer account: ", accountName] })] }), _jsxs("div", { className: "mb-4", children: [_jsx(GenerateTranslations, {}), _jsx(UnlockNeedsAndCoverages, {})] })] }) })] })) : (_jsx("div", { children: "Not authorized" }));
};
export default React.memo(InternalToolsPage);
