var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMutation, useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { accountIdSelector, currentUserState } from 'src/state';
import { useFetch } from './useFetch';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export var useIntegrationsApi = function () {
    var customFetch = useFetch().request;
    var customSyncsServerFetch = useFetch({
        baseUrl: process.env.REACT_APP_SYNCS_SERVER_BASE_URL,
    }).request;
    var currentUserInfo = useRecoilValue(currentUserState);
    var accountId = useRecoilValue(accountIdSelector);
    //integration related hooks
    var useConnectToIp = function (requestBody, ipName) {
        return useQuery(['connectToIp', ipName], function () {
            return customFetch({
                method: 'GET',
                url: "ip/oauth/".concat(ipName, "/").concat(accountId),
            });
        }, requestBody.options);
    };
    var useConnectToSignNow = function (request, ipName) {
        return useQuery(['connectToSignNow', ipName], function () {
            return customFetch({
                method: 'POST',
                url: "ip/oauth/".concat(ipName, "/key_id/").concat(accountId),
            }, request.body);
        }, request.options);
    };
    var useConnectToIntegrationClient = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: "ip/oauth/".concat(request.ipName, "/authenticate/").concat(accountId),
            }, {
                clientId: request.clientId,
                clientSecret: request.clientSecret,
            });
        });
    };
    var useConnectToIntegrationAPI = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: "ip/oauth/".concat(request.ipName, "/apiKey/").concat(accountId),
            }, {
                apiKey: request.apiKey,
            });
        });
    };
    var getGustoCompanyProvision = function (body) {
        var _a;
        return customFetch({
            method: 'POST',
            url: "ip/gusto/provision",
        }, __assign(__assign({}, body), { accountId: accountId, userRId: (_a = currentUserInfo === null || currentUserInfo === void 0 ? void 0 : currentUserInfo.recordId) !== null && _a !== void 0 ? _a : '' }));
    };
    var usePeriodicResync = function () {
        return useMutation(function (ipName) {
            return customSyncsServerFetch({
                method: 'POST',
                url: "ip/setup/periodic_sync/".concat(ipName),
            });
        });
    };
    var useGetAvailableIpCollections = function (ipName) {
        return useQuery(['getAvailableIpCollections', ipName], function () {
            return customFetch({
                method: 'GET',
                url: "ip/setup/collection_mapping/".concat(accountId, "/").concat(ipName),
            });
        });
    };
    var useDisconnectIpCollection = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: "ip/setup/remove/".concat(accountId, "/").concat(request.ipName, "/").concat(request.teambridgeCollectionId),
            });
        });
    };
    var useLinkCollections = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: 'ip/setup/collection_mapping',
            }, request);
        });
    };
    var useGetSchemaMappingData = function (requestBody) {
        return useQuery(['getSchemaMappingData', requestBody], function () {
            return customFetch({
                method: 'POST',
                url: 'ip/setup/schema_mapping/v2',
            }, requestBody);
        });
    };
    var getSchemaLinks = function (request) {
        return customFetch({
            method: 'POST',
            url: "ip/setup/schema_links",
        }, __assign({}, request));
    };
    var useSaveSchemasMappingResult = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'PUT',
                url: 'ip/setup/schema_mapping',
            }, request);
        });
    };
    var useGetProposedMatches = function (ziraCollectionId) {
        return useQuery(['getRecordMappingData', ziraCollectionId], function () {
            return customFetch({
                method: 'GET',
                url: "ip/setup/record_mapping/".concat(accountId, "/").concat(ziraCollectionId),
            });
        });
    };
    var useSaveRecordMappingResult = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: 'ip/setup/record_mapping',
            }, request);
        });
    };
    var useGetIntegrationSyncSettingOptions = function (ipCollection, options) {
        return useQuery(['getIntegrationSyncSettingOptions', ipCollection], function () {
            return customFetch({
                method: 'GET',
                url: "ip/setup/sync_settings/".concat(ipCollection),
            });
        }, __assign({}, options));
    };
    var useGetDeltaUnmatchedUserInfo = function (params, options) {
        var ipName = params.ipName, ipCollection = params.ipCollection;
        return useQuery(['getDeltaUnmatchedUserInfo', ipName, ipCollection], function () {
            return customFetch({
                method: 'GET',
                url: "ip/setup/delta/".concat(ipName, "/").concat(ipCollection),
            });
        }, __assign({}, options));
    };
    var useSubmitDeltaMatchedUserPairs = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: "ip/setup/delta/".concat(request.ipName, "/").concat(request.ipCollection),
            }, request.deltaMatchedUserPairs);
        });
    };
    var useGetIpUnmatchedUserCount = function (ipName, options) {
        return useQuery(['getIpUnmatchedUserCount', ipName], function () {
            return customFetch({
                method: 'GET',
                url: "ip/setup/not_matched_count/".concat(ipName),
            });
        }, options);
    };
    return {
        getGustoCompanyProvision: getGustoCompanyProvision,
        useGetIpUnmatchedUserCount: useGetIpUnmatchedUserCount,
        useConnectToIp: useConnectToIp,
        useConnectToSignNow: useConnectToSignNow,
        useConnectToIntegrationClient: useConnectToIntegrationClient(),
        useConnectToIntegrationAPI: useConnectToIntegrationAPI(),
        usePeriodicResync: usePeriodicResync,
        useGetAvailableIpCollections: useGetAvailableIpCollections,
        useDisconnectIpCollection: useDisconnectIpCollection,
        useLinkCollections: useLinkCollections(),
        useGetSchemaMappingData: useGetSchemaMappingData,
        useSaveSchemasMappingResult: useSaveSchemasMappingResult,
        useGetProposedMatches: useGetProposedMatches,
        useSaveRecordMappingResult: useSaveRecordMappingResult,
        useGetIntegrationSyncSettingOptions: useGetIntegrationSyncSettingOptions,
        useGetDeltaUnmatchedUserInfo: useGetDeltaUnmatchedUserInfo,
        useSubmitDeltaMatchedUserPairs: useSubmitDeltaMatchedUserPairs(),
        getSchemaLinks: getSchemaLinks,
    };
};
