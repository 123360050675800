import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import { Dropdown } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import './LogoDropdown.scss';
import { useNavigate } from 'react-router-dom';
import { useAppSession } from 'src/hooks/component';
import { renderAccountUserInfo, setPrevPageRoute } from 'src/utils/Account';
import { SettingsIcon } from 'src/utils/icons/SettingsIcon';
import { FormIcon } from 'src/utils/icons/FormIcon';
import { AutomateIcon } from 'src/utils/icons/Automate';
import { LogoutIcon } from 'src/utils/icons/LogoutIcon';
import { TasksSettingIcon } from 'src/utils/icons/TaskGroupSettingIcon';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { accountIdSelector, currentGeneralPermissions, currentUserState, firstAvailableSettingSectionPathSelector, ifCurrentUserHasTasksPermission, showSettingsSiderSelector, } from 'src/state';
import { UserProfileIcon } from 'src/utils/icons/UserProfileIcon';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import ZiraDeletePopconfirm from '../../ui/ZiraDeletePopconfirm';
import { taskSettingModalVisibleState } from '../Settings/Tasks/atom';
import { RECIPES_FEATURE_GATING_KEY } from '../FeatureGatingKeyConstant';
var LogoDropdown = function (_a) {
    var onOpenFormModal = _a.onOpenFormModal, onOpenAutomationModal = _a.onOpenAutomationModal, onOpenRecordDetail = _a.onOpenRecordDetail, leftNavCollapsed = _a.leftNavCollapsed;
    var userInfo = useRecoilValue(currentUserState);
    var accountId = useRecoilValue(accountIdSelector);
    var _b = useState(false), isDropdownVisible = _b[0], setIsDropdownVisible = _b[1];
    var logoutAndClearCache = useAppSession().logoutAndClearCache;
    var navigate = useNavigate();
    var showSettingsSider = useRecoilValue(showSettingsSiderSelector);
    var firstAvailableSettingSectionPath = useRecoilValue(firstAvailableSettingSectionPathSelector);
    var generalPermissions = useRecoilValue(currentGeneralPermissions);
    var setTaskSettingModalVisible = useSetRecoilState(taskSettingModalVisibleState);
    var hasTasksPermission = useRecoilValue(ifCurrentUserHasTasksPermission);
    var isRecipesFeatureEnabled = useRecoilValue(featureEnableStatusState(RECIPES_FEATURE_GATING_KEY));
    var className = leftNavCollapsed || (generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.READ_WEB) === false
        ? 'StaticUserLogoDropdownItem'
        : 'UserLogoDropdownItem';
    var dropdownMenu = useMemo(function () {
        var items = [];
        if (showSettingsSider) {
            items.push({
                key: 'settings',
                icon: _jsx(SettingsIcon, { className: "dropdownMenuIcon w-[16px]" }),
                label: 'Settings',
                onClick: function () {
                    setPrevPageRoute(location.pathname);
                    navigate({ pathname: "/settings/".concat(firstAvailableSettingSectionPath) });
                },
            });
        }
        if (generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.AUTOMATION) {
            items.push({
                key: 'automations',
                icon: _jsx(AutomateIcon, { className: "dropdownMenuIcon" }),
                label: 'Automations',
                onClick: function () {
                    setIsDropdownVisible(false);
                    onOpenAutomationModal();
                },
            });
        }
        if (hasTasksPermission) {
            items.push({
                key: 'tasks',
                icon: _jsx(TasksSettingIcon, { className: "dropdownMenuIcon" }),
                label: 'Tasks',
                onClick: function () {
                    setIsDropdownVisible(false);
                    setTaskSettingModalVisible(true);
                },
            });
        }
        if (isRecipesFeatureEnabled) {
            items.push({
                key: 'recipes',
                icon: _jsx("div", { className: "ml-[-2px] pr-[9px]", children: "\uD83E\uDDD1\u200D\uD83C\uDF73" }),
                label: 'Recipes',
                onClick: function () {
                    setIsDropdownVisible(false);
                    navigate({
                        pathname: location.pathname,
                        search: '?recipe=d5e0dac7-b3a5-4775-a9fc-12288cf1efa7',
                    });
                },
            });
        }
        if (generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.FORMS) {
            items.push({
                key: 'forms',
                icon: _jsx(FormIcon, { className: "dropdownMenuIcon" }),
                label: 'Forms',
                onClick: function () {
                    setIsDropdownVisible(false);
                    onOpenFormModal();
                },
            });
        }
        if (userInfo && onOpenRecordDetail && (generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.READ_WEB)) {
            items.push({
                key: 'myProfile',
                icon: _jsx(UserProfileIcon, { className: "-ml-0.5 mr-[7px]" }),
                label: 'My Profile',
                onClick: function () {
                    setIsDropdownVisible(false);
                    onOpenRecordDetail(userInfo.recordId);
                },
            });
        }
        // Only add divider when at least one previous items exist
        if (items.length > 0) {
            items.push({
                type: 'divider',
            });
        }
        items.push({
            key: 'logout',
            icon: _jsx(LogoutIcon, { className: "dropdownMenuIcon" }),
            label: (_jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to log out?", onConfirm: logoutAndClearCache, placement: "right", okText: "Yes", children: _jsx("div", { className: "text-red-1000", children: "Log Out" }) })),
        });
        return { items: items };
    }, [
        firstAvailableSettingSectionPath,
        generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.AUTOMATION,
        generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.FORMS,
        generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.READ_WEB,
        hasTasksPermission,
        isRecipesFeatureEnabled,
        navigate,
        logoutAndClearCache,
        onOpenAutomationModal,
        onOpenFormModal,
        onOpenRecordDetail,
        setTaskSettingModalVisible,
        showSettingsSider,
        userInfo,
    ]);
    var isSwitchingOrLoadingAccount = !accountId;
    return (_jsx("div", { className: className, children: _jsx(Dropdown, { disabled: isSwitchingOrLoadingAccount, menu: dropdownMenu, trigger: ['click'], placement: "top", open: isDropdownVisible, onOpenChange: function (visible) { return setIsDropdownVisible(visible); }, className: isSwitchingOrLoadingAccount ? 'cursor-not-allowed' : undefined, overlayClassName: "LogoDropdownMenu", children: _jsxs("div", { className: "UserDropdownButton flex w-full items-center justify-between font-medium text-gray-500", children: [renderAccountUserInfo(userInfo === null || userInfo === void 0 ? void 0 : userInfo.avatarUrl, userInfo === null || userInfo === void 0 ? void 0 : userInfo.fullName), isDropdownVisible ? (_jsx(CaretDownOutlined, { className: "UpDownIcon" })) : (_jsx(CaretUpOutlined, { className: "UpDownIcon" }))] }) }) }));
};
export default React.memo(LogoDropdown);
