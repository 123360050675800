import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { lazy, Suspense, useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ValueDataType } from 'src/shared';
import { useTimeZone } from 'src/hooks/component';
import moment from 'moment';
import { collectionTableSchemaShouldShowTimeSelector, collectionTableSchemaIsDOBSelector, collectionTableSchemaShouldShowCountdownSelector, shiftModalTimeZoneIdState, } from 'src/state';
import DummyTextCell from '../DummyTextCell';
import DummyTimeCellWithCountdown from '../DummyTimeCellWithCountdown';
var LogicDOBCell = lazy(function () { return import('./LogicDOBCell'); });
var LogicDateTimeCell = lazy(function () { return import('./LogicDateTimeCell'); });
var DateTimeCellHOC = function (props) {
    var time = props.time, editCell = props.editCell, cellPos = props.cellPos, canEdit = props.canEdit, isClicked = props.isClicked, setIsClicked = props.setIsClicked, collectionTableType = props.collectionTableType;
    var timeZoneIdState = useRecoilState(shiftModalTimeZoneIdState({
        type: collectionTableType,
        collectionId: cellPos.collectionId,
        recordId: cellPos.recordId,
    }))[0];
    var _a = useTimeZone(timeZoneIdState), timeZone = _a.timeZone, timeFormatFn = _a.timeFormatFn, timezoneAbbr = _a.timezoneAbbr;
    var timeZoneSuffix = " ".concat(timezoneAbbr(true));
    var shouldShowTime = useRecoilValue(collectionTableSchemaShouldShowTimeSelector(cellPos));
    var shouldShowCountdownTag = useRecoilValue(collectionTableSchemaShouldShowCountdownSelector(cellPos));
    var isDOBSchema = useRecoilValue(collectionTableSchemaIsDOBSelector(cellPos));
    var data = {
        type: ValueDataType.DATETIME,
        showTime: shouldShowTime,
        dateTime: time && time[0],
    };
    var formatString = shouldShowTime ? 'MMM D, YYYY h:mm A' : 'MMM D, YYYY';
    var formattedDOBTime = time[0] ? moment(time[0]).format(formatString) : '';
    var formattedTime = time[0]
        ? "".concat(timeFormatFn(time[0], formatString, false), " ").concat(shouldShowTime ? timeZoneSuffix : '')
        : '';
    var dummyCell = (_jsx(_Fragment, { children: !isClicked &&
            (shouldShowCountdownTag ? (_jsx(DummyTimeCellWithCountdown, { time: time[0], formatString: formatString, recordTimeZone: timeZone, setIsClicked: setIsClicked, canEdit: canEdit, shouldShowTime: shouldShowTime })) : (_jsx(DummyTextCell, { text: isDOBSchema ? formattedDOBTime : formattedTime, setIsClicked: setIsClicked, canEdit: canEdit }))) }));
    var onEditDateTimeCellData = useCallback(function (newTime) {
        var newTimeValue = timeZoneSuffix
            ? moment.tz(newTime[0], 'MMM D, YYYY h:mm A', timeZone).toISOString()
            : moment(newTime[0]).toISOString();
        var prevTimeValue = time[0];
        var needToUpdate = !moment(newTimeValue).isSame(moment(prevTimeValue));
        if (!needToUpdate)
            return;
        // Only update if the new time is different from the old time
        editCell([newTimeValue], timeZone);
    }, [editCell, time, timeZone, timeZoneSuffix]);
    return (_jsxs(_Fragment, { children: [isClicked && (_jsx(Suspense, { fallback: dummyCell, children: isDOBSchema ? (_jsx(LogicDOBCell, { data: data, editCell: editCell, isClicked: isClicked, setIsClicked: setIsClicked })) : (_jsx(LogicDateTimeCell, { data: data, editCell: onEditDateTimeCellData, isClicked: isClicked, setIsClicked: setIsClicked, timeZone: timeZone })) })), dummyCell] }));
};
export default React.memo(DateTimeCellHOC);
