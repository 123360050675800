var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CloseOutlined } from '@ant-design/icons';
import { Space, Tag } from 'antd';
import React, { useMemo } from 'react';
import UserAvatar from './UserAvatar';
import './UserTagWithIcon.scss';
var UserTagWithIcon = function (_a) {
    var defaultClassName = _a.className, name = _a.name, defaultSize = _a.size, defaultIsActive = _a.isActive, onClick = _a.onClick, avatarProps = _a.avatarProps, selectCellProps = _a.selectCellProps, children = _a.children, _b = _a.shouldWrap, shouldWrap = _b === void 0 ? false : _b;
    var className = "UserTagWithIcon ".concat(defaultClassName !== null && defaultClassName !== void 0 ? defaultClassName : '', " ").concat(shouldWrap ? 'overflow-visible' : '');
    var size = defaultSize || 24;
    var isActive = defaultIsActive !== false;
    var baseContentExtraClassName = useMemo(function () {
        if (name.length > 300) {
            return 'UserTagWithIconContentCutOff';
        }
        else {
            return '';
        }
    }, [name.length]);
    var baseContent = useMemo(function () { return (_jsxs(_Fragment, { children: [_jsx(UserAvatar, __assign({ name: name, size: size, isActive: isActive }, avatarProps)), _jsx("div", { className: "UserTagWithIconNameWrapper ".concat(isActive ? 'text-black' : 'text-gray-300', " text-cell font-medium"), style: shouldWrap ? { overflow: 'visible' } : undefined, children: name })] })); }, [avatarProps, isActive, name, size, shouldWrap]);
    if (selectCellProps) {
        var closable = selectCellProps.closable, onRemoveTag_1 = selectCellProps.onRemoveTag, label_1 = selectCellProps.label;
        return (_jsx(Tag, { className: "selector-user-tag", onMouseDown: function (e) {
                e.preventDefault();
                e.stopPropagation();
            }, children: _jsxs("div", { className: className, onClick: isActive ? onClick : undefined, children: [_jsx(Space, { align: "center", size: 5, className: "h-full", children: baseContent }), _jsx(Space, { align: "center", size: 3, children: closable && (_jsx(CloseOutlined, { className: "selector-user-tag-close", onClick: function () { return onRemoveTag_1(label_1); } })) })] }) }));
    }
    return (_jsxs("div", { className: className, onClick: onClick, children: [_jsx("div", { className: "UserTagWithIconContentContainer ".concat(baseContentExtraClassName), children: baseContent }), children] }));
};
export default React.memo(UserTagWithIcon);
