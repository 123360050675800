var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var PdfFileIconSvg = function () { return (_jsxs("svg", { width: "20", height: "21", viewBox: "0 0 20 21", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsxs("g", { clipPath: "url(#clip0_27333_11517)", children: [_jsx("path", { d: "M11.6666 3V6.33333C11.6666 6.55435 11.7544 6.76631 11.9107 6.92259C12.067 7.07887 12.2789 7.16667 12.5 7.16667H15.8333", stroke: "#717177", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M4.16663 10.5V4.66667C4.16663 4.22464 4.34222 3.80072 4.65478 3.48816C4.96734 3.17559 5.39127 3 5.83329 3H11.6666L15.8333 7.16667V10.5", stroke: "#717177", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M4.16663 15.5H5.41663C5.74815 15.5 6.06609 15.3683 6.30051 15.1339C6.53493 14.8995 6.66663 14.5815 6.66663 14.25C6.66663 13.9185 6.53493 13.6005 6.30051 13.3661C6.06609 13.1317 5.74815 13 5.41663 13H4.16663V18", stroke: "#717177", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M14.1666 15.5H15.8333", stroke: "#717177", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M16.6666 13H14.1666V18", stroke: "#717177", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M9.16663 13V18H9.99996C10.442 18 10.8659 17.8244 11.1785 17.5118C11.491 17.1993 11.6666 16.7754 11.6666 16.3333V14.6667C11.6666 14.2246 11.491 13.8007 11.1785 13.4882C10.8659 13.1756 10.442 13 9.99996 13H9.16663Z", stroke: "#717177", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_27333_11517", children: _jsx("rect", { width: "20", height: "20", fill: "white", transform: "translate(0 0.5)" }) }) })] })); };
export var PdfFileIcon = function (props) { return (_jsx(Icon, __assign({ component: PdfFileIconSvg }, props))); };
