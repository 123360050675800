var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useRef } from 'react';
import { Skeleton } from 'antd';
import Tree, { moveItemOnTree, } from '@atlaskit/tree';
import { useLocation } from 'react-router-dom';
import { produce } from 'immer';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import { accountIdSelector, ifCurrentUserHasEditWorkspacePermission, naviTreeStructureStateSelector, } from 'src/state';
import { pageTemplateModalState } from 'src/state/atoms/pageTemplateModalState';
import { cloneDeep } from 'lodash';
import './NaviTree.scss';
import { useAccountApi } from 'src/hooks/api';
import PageTemplateModal from 'src/components/PageView/PageTemplateModal/PageTemplateModal';
import { getPageGroupExpandedStatusMap, setPageGroupExpandedStatusMap } from 'src/utils/Account';
import { toast } from 'react-toastify';
import { generateTreeNode } from 'src/services';
import TreeNodeTitle from './TreeNodeTitle/TreeNodeTitle';
import AddNewGroupButton from './AddNewGroupButton';
var NaviTree = function (props) {
    var onSiderCollapse = props.onSiderCollapse;
    var _a = useRecoilState(naviTreeStructureStateSelector), treeNode = _a[0], setTreeNode = _a[1];
    var treeNodeRef = useRef(treeNode);
    useEffect(function () {
        treeNodeRef.current = treeNode;
    }, [treeNode]);
    var accountId = useRecoilValue(accountIdSelector);
    var useMoveNode = useAccountApi().useMoveNode;
    var onExpand = useCallback(function (itemId) {
        setTreeNode(produce(function (draft) {
            if (!draft || !draft.items[itemId] || draft.items[itemId].isExpanded)
                return;
            draft.items[itemId].isExpanded = true;
        }));
        var pageGroupExpandedStatusMap = getPageGroupExpandedStatusMap();
        pageGroupExpandedStatusMap[itemId] = true;
        setPageGroupExpandedStatusMap(pageGroupExpandedStatusMap);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var onCollapse = useCallback(function (itemId) {
        setTreeNode(produce(function (draft) {
            if (!draft || !draft.items[itemId] || !draft.items[itemId].isExpanded)
                return;
            draft.items[itemId].isExpanded = false;
        }));
        var pageGroupExpandedStatusMap = getPageGroupExpandedStatusMap();
        pageGroupExpandedStatusMap[itemId] = false;
        setPageGroupExpandedStatusMap(pageGroupExpandedStatusMap);
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var onDragEnd = useCallback(function (source, destination) {
        var tempTree = cloneDeep(treeNodeRef.current);
        if (!destination || !tempTree) {
            return;
        }
        var requestBody = null;
        if (source.parentId === 'rootId' && destination.parentId === 'rootId') {
            // Moving page group
            var groupId = tempTree.items['rootId'].children[source.index];
            if (!groupId)
                return;
            requestBody = {
                accountId: accountId,
                type: 'page_group',
                objectId: groupId,
                newIndex: destination.index,
            };
        }
        else if (destination.parentId === 'rootId' || source.parentId === 'rootId') {
            if (destination.parentId === 'rootId' && source.parentId !== 'rootId') {
                // !Mark: Two cases fall into this logic:
                // 1. Dragging a page into empty page group
                // 2. Dragging a page to the end of page group
                var item = tempTree.items[source.parentId].children[source.index];
                var destItem = tempTree.items[destination.parentId].children[destination.index - 1]; //! -1 because the destination is below/after the empty page group
                if (!item || !destItem)
                    return;
                var newIndex = tempTree.items[destItem].children.length; // End of page group
                requestBody = {
                    accountId: accountId,
                    type: 'page',
                    objectId: item,
                    newIndex: newIndex,
                    fromParentId: String(source.parentId),
                    toParentId: destItem,
                };
            }
            else {
                return;
            }
        }
        else {
            var fromItemParent = tempTree.items[source.parentId];
            var desItemParent = tempTree.items[destination.parentId];
            if (!('data' in fromItemParent) || !('data' in desItemParent))
                return;
            if (desItemParent.data.type === 'pageGroup' &&
                desItemParent.children.length >= 50) {
                toast('Cannot have more than 50 pages in group', {
                    type: 'error',
                    position: toast.POSITION.BOTTOM_CENTER,
                });
                return;
            }
            var fromType = fromItemParent.data.type === 'page' ? 'page_block' : 'page';
            var destType = desItemParent.data.type === 'page' ? 'page_block' : 'page';
            if (fromType !== destType)
                return;
            var item = tempTree.items[String(source.parentId)].children[source.index];
            if (!item)
                return;
            requestBody = {
                accountId: accountId,
                type: fromType,
                objectId: item,
                newIndex: destination.index,
                fromParentId: String(source.parentId),
                toParentId: String(destination.parentId),
            };
        }
        if (requestBody) {
            var newTree = moveItemOnTree(tempTree, source, destination);
            setTreeNode(newTree);
            useMoveNode.mutate(requestBody, {
                onSuccess: function (data) {
                    if (data) {
                        setTreeNode(generateTreeNode(data));
                    }
                },
                onError: function () {
                    setTreeNode(tempTree);
                },
            });
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId]);
    var openPageTemplateModal = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (treeItemId) {
            set(pageTemplateModalState, {
                isVisible: true,
                selectedTreeItemId: treeItemId,
            });
        };
    }, []);
    var location = useLocation();
    var renderItem = function (_a) {
        var item = _a.item, provided = _a.provided;
        var isSelected = location.pathname.split('/')[4] == item.id;
        var isFirstGroup = item.data.type === 'pageGroup' && Object.keys(treeNode.items)[1] == item.id;
        var shouldHide = 'isHidden' in item.data && item.data.isHidden;
        var canAddPage = item.data.type === 'pageGroup' && item.children.length < 50;
        return (_jsx("div", __assign({ className: 'treeNodeItem' +
                (item.data.type === 'pageGroup' && !isFirstGroup ? ' pageGroupBorder' : '') +
                (isSelected ? ' selected' : ''), onClick: function () {
                if (window.innerWidth < 576) {
                    onSiderCollapse(true);
                }
            }, ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { children: shouldHide ? (_jsx(_Fragment, {})) : (_jsx(TreeNodeTitle, { itemId: item.id, onExpand: onExpand, onCollapse: onCollapse, openPageTemplateModal: openPageTemplateModal, canAddPage: canAddPage })) })));
    };
    var hasEditWorkspaceAccess = useRecoilValue(ifCurrentUserHasEditWorkspacePermission);
    return treeNode ? (_jsxs("div", { className: "naviTreeWrapper", children: [_jsx(Tree, { tree: treeNode, renderItem: renderItem, onDragEnd: onDragEnd, offsetPerLevel: 16, isDragEnabled: hasEditWorkspaceAccess }), hasEditWorkspaceAccess && (_jsxs(_Fragment, { children: [_jsx(AddNewGroupButton, {}), _jsx(PageTemplateModal, {})] }))] })) : (_jsx("div", { className: "mt-5", children: _jsx(Skeleton, { active: true, className: "px-8", paragraph: {
                rows: 40,
            } }) }));
};
export default React.memo(NaviTree);
