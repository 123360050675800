var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { moveItemOnTree } from '@atlaskit/tree';
import _, { cloneDeep, indexOf } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { isNaviTreeShowableBlockContentType, TransactionTableNameMap } from 'src/shared';
import { accountDetailState, accountIdSelector, naviTreeStructureState, naviTreeStructureStateSelector, } from 'src/state';
import { PageType } from 'src/components/CenterPanel/Interface';
import { CALENDAR_DEFAULT_ICON_INFO, LIST_WORKSPACE_ICON_INFO, } from 'src/components/NavigationSideBar/NaviTree/CreatePageOptionMenu/DefaultPageIconInfo';
import { usePageApi, useAccountApi } from '../api';
import { isNaviTreeDataItem, } from '../api/interfaces/Interface';
import { useFileApi } from '../api/useFileApi';
var useNaviTree = function () {
    var accountId = useRecoilValue(accountIdSelector);
    var _a = usePageApi(), useDeleteBlockOrCollection = _a.useDeleteBlockOrCollection, useDeletePage = _a.useDeletePage, useDeletePageGroup = _a.useDeletePageGroup, createPageInGroup = _a.createPageInGroup, createPageTemplateInPageGroup = _a.createPageTemplateInPageGroup, useUpdatePage = _a.useUpdatePage, useUpdatePageBlock = _a.useUpdatePageBlock, useUpdatePageGroup = _a.useUpdatePageGroup, useUpdatePageIcon = _a.useUpdatePageIcon, useUpdatePageGroupIcon = _a.useUpdatePageGroupIcon, useUpdatePageEmoji = _a.useUpdatePageEmoji, useUpdatePageGroupEmoji = _a.useUpdatePageGroupEmoji;
    var useMoveNode = useAccountApi().useMoveNode;
    var handleDeleteFile = useFileApi().deleteFile;
    var navigate = useNavigate();
    var moveBlockUpDown = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot, set = _a.set;
        return function (pageId, blockId, isMoveUp) { return __awaiter(void 0, void 0, void 0, function () {
            var tempTree, blockIds, prevIndex, newIndex, requestBody;
            return __generator(this, function (_a) {
                tempTree = cloneDeep(snapshot.getLoadable(naviTreeStructureStateSelector).valueMaybe());
                if (!tempTree)
                    return [2 /*return*/];
                blockIds = tempTree.items[pageId].children;
                if (!blockIds)
                    return [2 /*return*/];
                prevIndex = _.indexOf(blockIds, blockId);
                newIndex = isMoveUp
                    ? _.max([0, prevIndex - 1])
                    : _.min([blockIds.length - 1, prevIndex + 1]);
                requestBody = {
                    accountId: accountId,
                    type: 'page_block',
                    objectId: blockId,
                    newIndex: newIndex,
                    fromParentId: pageId,
                    toParentId: pageId,
                };
                useMoveNode.mutate(requestBody, {
                    onSuccess: function () {
                        var newTree = moveItemOnTree(tempTree, { parentId: pageId, index: prevIndex }, { parentId: pageId, index: newIndex });
                        set(naviTreeStructureStateSelector, newTree);
                    },
                });
                return [2 /*return*/];
            });
        }); };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId]);
    var createPageInPageGroup = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot, set = _a.set;
        return function (pageGroupId, templateId, type) { return __awaiter(void 0, void 0, void 0, function () {
            var tempTree, data, defaultIcon, blockIds;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        tempTree = cloneDeep(snapshot.getLoadable(naviTreeStructureStateSelector).valueMaybe());
                        if (tempTree === undefined || (!templateId && !type))
                            return [2 /*return*/];
                        if (!templateId) return [3 /*break*/, 2];
                        return [4 /*yield*/, createPageTemplateInPageGroup(pageGroupId, templateId)];
                    case 1:
                        data = _a.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        if (!type) return [3 /*break*/, 4];
                        defaultIcon = type === PageType.SCHEDULE
                            ? CALENDAR_DEFAULT_ICON_INFO
                            : LIST_WORKSPACE_ICON_INFO;
                        return [4 /*yield*/, createPageInGroup(pageGroupId, type, defaultIcon)];
                    case 3:
                        data = _a.sent();
                        _a.label = 4;
                    case 4:
                        if (data) {
                            blockIds = data.blocks.map(function (block) { return block.id; });
                            tempTree.items[data.id] = {
                                id: data.id,
                                children: blockIds,
                                hasChildren: !!data.blocks.length,
                                isExpanded: false,
                                parentId: pageGroupId,
                                data: {
                                    type: 'page',
                                    emoji: data.emoji,
                                    iconInfo: data.iconInfo,
                                    name: data.name,
                                    pageType: data.type,
                                    nodeKeyPaths: [pageGroupId, data.id],
                                    titleId: '2',
                                },
                            };
                            if (data.blocks.length) {
                                data.blocks.map(function (block) {
                                    tempTree.items[block.id] = {
                                        id: block.id,
                                        hasChildren: false,
                                        isExpanded: false,
                                        children: [],
                                        parentId: data.id,
                                        data: {
                                            name: block.title,
                                            type: 'block',
                                            nodeKeyPaths: [pageGroupId, data.id, block.id],
                                            titleId: block.id,
                                            contentType: block.contentType,
                                            isHidden: false,
                                        },
                                    };
                                });
                            }
                            // 2. append page id into groups child ids array
                            tempTree.items[pageGroupId].children.push(data.id);
                            // 3. update tree state
                            set(naviTreeStructureStateSelector, tempTree);
                            return [2 /*return*/, data.id];
                        }
                        return [2 /*return*/];
                }
            });
        }); };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var addBlockToPage = function (pageId, blockInfo, nextToBlockId) {
        var nextToIndex = nextToBlockId ? findBlockIndexInPage(pageId, nextToBlockId) : undefined;
        return addNodeOnGlobal(TransactionTableNameMap.PAGE, pageId, blockInfo, nextToIndex);
    };
    var addNodeOnGlobal = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot, set = _a.set;
        return function (parentLevel, parentNodeKey, newNode, nextToObjIndex) {
            var _a;
            var tempTreeNode = cloneDeep(snapshot.getLoadable(naviTreeStructureStateSelector).valueMaybe());
            if (!tempTreeNode)
                return;
            switch (parentLevel) {
                case TransactionTableNameMap.PAGE: {
                    var parentPageItem = tempTreeNode.items[parentNodeKey];
                    if (!('data' in parentPageItem) || !('parentId' in parentPageItem))
                        return;
                    tempTreeNode.items[newNode.id] = {
                        id: newNode.id,
                        hasChildren: false,
                        isExpanded: false,
                        children: [],
                        parentId: parentPageItem.id,
                        data: {
                            name: (_a = newNode.title) !== null && _a !== void 0 ? _a : '',
                            nodeKeyPaths: [parentPageItem.parentId, parentNodeKey, newNode.id],
                            titleId: newNode.id,
                            type: 'block',
                            contentType: newNode.contentType,
                            isHidden: !isNaviTreeShowableBlockContentType(newNode.contentType),
                        },
                    };
                    var tempBlocks = parentPageItem.children;
                    if (nextToObjIndex) {
                        tempBlocks === null || tempBlocks === void 0 ? void 0 : tempBlocks.splice(nextToObjIndex, 0, newNode.id);
                    }
                    else {
                        tempBlocks === null || tempBlocks === void 0 ? void 0 : tempBlocks.push(newNode.id);
                    }
                }
            }
            set(naviTreeStructureStateSelector, tempTreeNode);
        };
    }, []);
    var saveRenameNode = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot, set = _a.set;
        return function (type_1, objectId_1, title_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(void 0, __spreadArray([type_1, objectId_1, title_1], args_1, true), void 0, function (type, objectId, title, requestSave) {
                var tempTreeNode, item;
                if (requestSave === void 0) { requestSave = false; }
                return __generator(this, function (_a) {
                    tempTreeNode = cloneDeep(snapshot.getLoadable(naviTreeStructureStateSelector).valueMaybe());
                    item = tempTreeNode === null || tempTreeNode === void 0 ? void 0 : tempTreeNode.items[objectId];
                    if (tempTreeNode && item && isNaviTreeDataItem(item)) {
                        item.data.name = title;
                        switch (type) {
                            case 'pageGroup': {
                                if (requestSave) {
                                    useUpdatePageGroup.mutate({
                                        pageGroupId: objectId,
                                        name: title,
                                    });
                                }
                                break;
                            }
                            case 'page': {
                                if (requestSave) {
                                    useUpdatePage.mutate({
                                        pageId: objectId,
                                        name: title,
                                    });
                                }
                                break;
                            }
                            case 'block': {
                                if (requestSave) {
                                    useUpdatePageBlock.mutate({
                                        blockId: objectId,
                                        title: title,
                                    });
                                }
                                break;
                            }
                        }
                    }
                    set(naviTreeStructureStateSelector, tempTreeNode);
                    return [2 /*return*/];
                });
            });
        };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var saveIcon = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot, set = _a.set;
        return function (id, type, icon) { return __awaiter(void 0, void 0, void 0, function () {
            var tempTreeNode;
            return __generator(this, function (_a) {
                tempTreeNode = cloneDeep(snapshot.getLoadable(naviTreeStructureStateSelector).valueMaybe());
                if (type === 'page') {
                    useUpdatePageIcon.mutate({
                        pageId: id,
                        iconInfo: icon,
                    }, {
                        onSuccess: function () {
                            var item = tempTreeNode === null || tempTreeNode === void 0 ? void 0 : tempTreeNode.items[id];
                            if (!item ||
                                !('data' in item) ||
                                !('emoji' in item.data) ||
                                !('iconInfo' in item.data)) {
                                return;
                            }
                            item.data.iconInfo = icon;
                            item.data.emoji = null;
                            set(naviTreeStructureStateSelector, tempTreeNode);
                        },
                    });
                }
                else if (type === 'pageGroup') {
                    useUpdatePageGroupIcon.mutate({
                        pageGroupId: id,
                        iconInfo: icon,
                    }, {
                        onSuccess: function () {
                            var item = tempTreeNode === null || tempTreeNode === void 0 ? void 0 : tempTreeNode.items[id];
                            if (!item ||
                                !('data' in item) ||
                                !('emoji' in item.data) ||
                                !('iconInfo' in item.data)) {
                                return;
                            }
                            item.data.iconInfo = icon;
                            item.data.emoji = null;
                            set(naviTreeStructureStateSelector, tempTreeNode);
                        },
                    });
                }
                return [2 /*return*/];
            });
        }); };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var saveEmoji = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot, set = _a.set;
        return function (id, type, emoji) { return __awaiter(void 0, void 0, void 0, function () {
            var tempTreeNode;
            return __generator(this, function (_a) {
                tempTreeNode = cloneDeep(snapshot.getLoadable(naviTreeStructureStateSelector).valueMaybe());
                if (type === 'page') {
                    useUpdatePageEmoji.mutate({
                        pageId: id,
                        emoji: emoji,
                    }, {
                        onSuccess: function () {
                            var item = tempTreeNode === null || tempTreeNode === void 0 ? void 0 : tempTreeNode.items[id];
                            if (!item ||
                                !('data' in item) ||
                                !('emoji' in item.data) ||
                                !('iconInfo' in item.data)) {
                                return;
                            }
                            item.data.emoji = emoji;
                            item.data.iconInfo = null;
                            set(naviTreeStructureStateSelector, tempTreeNode);
                        },
                    });
                }
                else if (type === 'pageGroup') {
                    useUpdatePageGroupEmoji.mutate({
                        pageGroupId: id,
                        emoji: emoji,
                    }, {
                        onSuccess: function () {
                            var item = tempTreeNode === null || tempTreeNode === void 0 ? void 0 : tempTreeNode.items[id];
                            if (!item ||
                                !('data' in item) ||
                                !('emoji' in item.data) ||
                                !('iconInfo' in item.data)) {
                                return;
                            }
                            item.data.emoji = emoji;
                            item.data.iconInfo = null;
                            set(naviTreeStructureStateSelector, tempTreeNode);
                        },
                    });
                }
                return [2 /*return*/];
            });
        }); };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var removeNode = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot, set = _a.set, reset = _a.reset;
        return function (nodeId, url) { return __awaiter(void 0, void 0, void 0, function () {
            var tempTreeNode, tempAccountDetailsData, item, type, resp, blocks, parentItem, pageId, groupId;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        tempTreeNode = cloneDeep(snapshot.getLoadable(naviTreeStructureStateSelector).valueMaybe());
                        tempAccountDetailsData = cloneDeep(snapshot.getLoadable(accountDetailState).valueMaybe());
                        if (!tempTreeNode)
                            return [2 /*return*/];
                        if (!tempAccountDetailsData)
                            return [2 /*return*/];
                        item = tempTreeNode.items[nodeId];
                        if (!item || !isNaviTreeDataItem(item))
                            return [2 /*return*/];
                        type = item.data.type;
                        tempTreeNode === null || tempTreeNode === void 0 ? true : delete tempTreeNode.items[nodeId];
                        if (!(type === 'block')) return [3 /*break*/, 2];
                        if (!('parentId' in item))
                            return [2 /*return*/];
                        // remove node from account detail state
                        delete tempAccountDetailsData.blocks[nodeId];
                        blocks = (_a = tempAccountDetailsData.pages[item.parentId]) === null || _a === void 0 ? void 0 : _a.blocks;
                        if (blocks)
                            _.remove(blocks, function (n) { return n === nodeId; });
                        return [4 /*yield*/, useDeleteBlockOrCollection.mutateAsync({
                                blockId: nodeId,
                                deleteCollection: false,
                            })];
                    case 1:
                        resp = _b.sent();
                        if (resp) {
                            set(accountDetailState, tempAccountDetailsData);
                            reset(naviTreeStructureState);
                        }
                        if (resp && url) {
                            handleDeleteFile(url);
                        }
                        return [3 /*break*/, 6];
                    case 2:
                        if (!(type === 'page')) return [3 /*break*/, 4];
                        // delete collections underneath this page
                        if (!('parentId' in item))
                            return [2 /*return*/];
                        item.children.map(function (collectionId) {
                            delete tempTreeNode.items[collectionId];
                        });
                        delete tempTreeNode.items[nodeId];
                        parentItem = tempTreeNode.items[item.parentId];
                        _.remove(parentItem.children, function (n) { return n === nodeId; });
                        return [4 /*yield*/, useDeletePage.mutateAsync(nodeId)];
                    case 3:
                        resp = _b.sent();
                        return [3 /*break*/, 6];
                    case 4:
                        if (!(type === 'pageGroup')) return [3 /*break*/, 6];
                        // delete page underneath the group
                        item.children.map(function (pageId) {
                            var pageItem = tempTreeNode.items[pageId];
                            // delete collections underneath this page
                            pageItem.children.map(function (collectionId) {
                                delete tempTreeNode.items[collectionId];
                            });
                            delete tempTreeNode.items[pageId];
                        });
                        delete tempTreeNode.items[nodeId];
                        _.remove(tempTreeNode.items['rootId'].children, function (n) { return n === nodeId; });
                        return [4 /*yield*/, useDeletePageGroup.mutateAsync(nodeId)];
                    case 5:
                        resp = _b.sent();
                        _b.label = 6;
                    case 6:
                        if (resp) {
                            set(naviTreeStructureStateSelector, tempTreeNode);
                        }
                        pageId = location.pathname.split('/')[4];
                        groupId = location.pathname.split('/')[3];
                        if (resp &&
                            ((type === 'page' && nodeId === pageId) ||
                                (type === 'pageGroup' && nodeId === groupId))) {
                            // Redirect from deleted page
                            navigate('/home', { replace: true });
                        }
                        return [2 /*return*/];
                }
            });
        }); };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate]);
    var findBlockIndexInPage = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (pageId, blockId) {
            var tempTreeNode = cloneDeep(snapshot.getLoadable(naviTreeStructureStateSelector).valueMaybe());
            if (!tempTreeNode)
                return -1;
            return indexOf(tempTreeNode.items[pageId].children, blockId);
        };
    }, []);
    return {
        removeNode: removeNode,
        createPageInPageGroup: createPageInPageGroup,
        moveBlockUpDown: moveBlockUpDown,
        saveIcon: saveIcon,
        saveEmoji: saveEmoji,
        addBlockToPage: addBlockToPage,
        addNodeOnGlobal: addNodeOnGlobal,
        saveRenameNode: saveRenameNode,
        findBlockIndexInPage: findBlockIndexInPage,
    };
};
export { useNaviTree as useNaviTreeComponent };
